/*
 * This utility mimics all behaviors from HotelNavigationUtility#placeUtils.
 *
 * It turned out that the core HotelNavigationUtility doesn't handle non-latin
 * names of locations and names very well, so SMCC app overrides it.
 * The content of this file is the same as the one found in SMCC overrides
 * because apac/cars must also work with SMCC.
 */

import { ensureExhaustive } from "@b2bportal/core-utilities";
import type { Suggestion } from "@b2bportal/ground-api";

export const placeLabelToUrl = (label?: string) => {
  return label ?? "";
};

export const placeLabelAndSublabelToUrl = (
  label?: string,
  subLabel?: string
) => {
  if ((subLabel?.length ?? 0) > 0) {
    return `${label ?? ""}_${subLabel}`;
  }
  return label ?? "";
};

export const placeLabelToCity = (label?: string) => {
  return label != null ? label.split(",")[0] : "";
};

export const urlToPlaceQuery = (url?: string) => {
  return url?.replace(/_/g, " ") ?? "";
};

export const placeSuggestionToUrl = (suggestion: Suggestion) => {
  switch (suggestion.id.Id) {
    case "Flight":
      return suggestion.id.code.code;
    case "Grounds":
      return placeLabelAndSublabelToUrl(suggestion.label, suggestion.subLabel);
    default:
      ensureExhaustive(
        suggestion.id,
        placeLabelAndSublabelToUrl(suggestion.label, suggestion.subLabel)
      );
  }
};
