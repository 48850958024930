import { useEffect, useMemo, useState } from "react";
import type { FareDetailsV2, TripDetailsV2 } from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type FlightFareSelectionFunction,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { getFilteredFareDetails } from "@b2bportal/core-utilities";
import { FareDetails } from "@components/flights";
import {
  Icon,
  InfoBanner,
  InfoBannerSeverity,
  PriceDisplay,
} from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import { useFlightSlot } from "../../../../slots";
import defaultStyles from "./FlightCardFareList.module.scss";

export interface FlightCardFareListComponentProps
  extends PlatformComponentProps {
  fareIdList?: string[];
  clickedFareId?: string;
  trip: TripDetailsV2;
  fareDetails: Array<FareDetailsV2>;
  sliceIndex: number;
  fareShelfFilter: number[];
  sliceId: string;
  tripId: string;
  onFareSelect: FlightFareSelectionFunction;
  goToNextStep: () => void;
  fareFormatOptions?: Intl.NumberFormatOptions;
}
export const FlightCardFareList = ({
  clickedFareId,
  fareDetails,
  sliceIndex,
  fareShelfFilter,
  sliceId,
  tripId,
  trip,
  onFareSelect,
  goToNextStep,
  className,
  children,
  fareFormatOptions,
}: FlightCardFareListComponentProps) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardFareList,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardFareList
  );

  const FlightSlots = useFlightSlot();

  const { t } = useI18nContext();

  const [selectedFareId, setSelectedFareOption] = useState("");

  const filteredAndSortedFares = useMemo(() => {
    return getFilteredFareDetails({
      fareDetails,
      selectedFareClassFilters: fareShelfFilter,
      sliceIndex,
      selectedFareId: clickedFareId,
    });
  }, [fareDetails, sliceIndex, clickedFareId, fareShelfFilter]);

  const fares = useMemo(() => {
    return filteredAndSortedFares.map((fare) => {
      const { id } = fare;
      return (
        <FlightSlots.FlightCardFareListItem
          key={`fare-${fare.id}`}
          className={cn("fare-root", { selected: clickedFareId === id })}
          trip={trip}
          fare={fare}
          sliceIndex={sliceIndex}
          fareFormatOptions={fareFormatOptions}
          onClick={() => {
            onFareSelect({ sliceId, fareId: id, tripId });
            goToNextStep();
          }}
        />
      );
    });
  }, [
    filteredAndSortedFares,
    FlightSlots,
    cn,
    clickedFareId,
    trip,
    sliceIndex,
    fareFormatOptions,
    onFareSelect,
    sliceId,
    tripId,
    goToNextStep,
  ]);

  useEffect(() => {
    setSelectedFareOption(filteredAndSortedFares[0].id);
  }, [filteredAndSortedFares]);

  useEffect(() => {
    onFareSelect({ sliceId, fareId: filteredAndSortedFares[0].id, tripId });
  }, [filteredAndSortedFares, onFareSelect, sliceId, tripId]);

  const selectedFareSlice = useMemo(() => {
    return (
      filteredAndSortedFares.find((f) => f.id === selectedFareId)?.slices[
        sliceIndex
      ] ?? filteredAndSortedFares[0]?.slices[sliceIndex]
    );
  }, [filteredAndSortedFares, selectedFareId, sliceIndex]);

  const fareRadios = useMemo(() => {
    return filteredAndSortedFares.map((fare) => {
      const { id, paxPricings } = fare;
      const prices = paxPricings && paxPricings[0].pricing.total;

      const brandName = fare.slices[sliceIndex].fareShelf?.brandName ?? "";
      const isSelectedFare = id === selectedFareId;
      const restrictions = fare.restrictions?.[sliceIndex];

      return (
        <label
          htmlFor={id}
          key={`fare-radio-${id}`}
          className={cn("fare-radio-button", {
            "selected-fare": isSelectedFare,
          })}
        >
          <input
            type="radio"
            id={id}
            value={id}
            name={`fare-${sliceId}`}
            checked={isSelectedFare}
            onChange={() => {
              setSelectedFareOption(id);
              onFareSelect({ sliceId, fareId: id, tripId });
            }}
          />
          <div className={cn("fare-name")}>{brandName}</div>
          <div className={cn("fare-per-person")}>{t("perPerson")}</div>
          <div className={cn("fare-amount")}>
            {prices && (
              <PriceDisplay prices={prices} formatOptions={fareFormatOptions} />
            )}
          </div>
          {isSelectedFare && (
            <div className={cn("fare-details")}>
              <FareDetails
                segments={trip.slices[sliceIndex].segmentDetails}
                fareSlice={selectedFareSlice}
                restrictions={restrictions}
                multiFareNotice={fareDetails[sliceIndex]?.multiTicket}
              />
            </div>
          )}
        </label>
      );
    });
  }, [
    filteredAndSortedFares,
    sliceIndex,
    selectedFareId,
    cn,
    sliceId,
    t,
    fareFormatOptions,
    trip.slices,
    selectedFareSlice,
    fareDetails,
    onFareSelect,
    tripId,
  ]);

  const isVi = selectedFareSlice.fareDetails.segments.some(
    (segment) => segment.isSelfTransferLayover ?? false
  );

  return (
    <div className={clsx(block, className)}>
      <ul className={cn("fare-list")}>{fares}</ul>
      {isVi ? (
        <InfoBanner severity={InfoBannerSeverity.INFO}>
          <Icon iconName={IconNameEnum.airplaneDiagonal} />{" "}
          {t("core-flights.vi.multipleAirlinesNotice")}
        </InfoBanner>
      ) : null}
      <div className={cn("fare-list-radio")} role="radiogroup">
        {fareRadios}
      </div>
      {children}
    </div>
  );
};
