import { ProductType } from "@hopper-b2b/types";
import { isFlightProduct, isLodgingProduct } from "../../utilities";

export const getBaseTrackingProperties = (context) => {
  const productType = isFlightProduct(context)
    ? ProductType.FLIGHTS
    : isLodgingProduct(context)
    ? ProductType.LODGING
    : undefined;

  return {
    productType,
    product_type: productType,
  };
};
