import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  type LodgingMediaAsset,
  MediaCategory,
  type NonResizableImage,
} from "@b2bportal/lodging-api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, Slot, DefaultLodgingPlaceholder } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Grid, Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import {
  getSelectedLodging,
  getSelectedLodgingGroupedMedia,
  getSelectedLodgingMediaCategories,
} from "../../../../reducer/selectors";
import { HotelDetailsDialog } from "../../../HotelDetailsDialog";
import "./styles.scss";

type ModalGalleryProps = {
  open: boolean;
  onClose: () => void;
  viewRoomOnClick: () => void;
  imageUrlsArray: string[];
  media: LodgingMediaAsset[];
  room?: boolean;
  carouselIndex: number;
  handleSetCarouselIndex: (index: number) => void;
};

type PopoverGalleryProps = {
  carouselIndex: number;
  handleImageOnClick: (index: number) => void;
  imageUrlsArray: string[];
  filteredMedia: NonResizableImage[];
};

export const ModalGallery = ({
  open,
  onClose,
  viewRoomOnClick,
  imageUrlsArray,
  media,
  room = false,
  carouselIndex,
  handleSetCarouselIndex,
}: ModalGalleryProps) => {
  const { matchesMobile } = useDeviceTypes();

  const { t } = useI18nContext();

  const selectedLodging = useSelector(getSelectedLodging);

  const [selectedCategory, setSelectedCategory] =
    useState<MediaCategoryWithAllPhotos>(MediaCategoryWithAllPhotos.AllPhotos);

  const handleChangeCategory = useCallback(
    (category: MediaCategoryWithAllPhotos) => {
      setSelectedCategory(category);
    },
    []
  );

  const handleImageOnClick = useCallback(
    (index: number) => handleSetCarouselIndex(index),
    [handleSetCarouselIndex]
  );

  const handleCloseCarousel = useCallback(
    () => handleSetCarouselIndex(null),
    [handleSetCarouselIndex]
  );

  const handleOnBack = useCallback(() => {
    if (room || carouselIndex === null) {
      onClose();
    } else {
      handleCloseCarousel();
    }
  }, [room, carouselIndex, onClose]);

  const groupedMedia = useSelector(getSelectedLodgingGroupedMedia);
  const filteredMedia = useMemo(() => {
    if (selectedCategory === MediaCategoryWithAllPhotos.AllPhotos) return media;
    return groupedMedia[selectedCategory];
  }, [groupedMedia, media, selectedCategory]);

  const expanded = useMemo(
    () => (carouselIndex !== null ? true : false),
    [carouselIndex]
  );

  const mediaCategoryTranslationMap = useMediaCategoryTranslationMap();

  return (
    <HotelDetailsDialog
      maxWidth="lg"
      className={clsx("expanded-gallery-popover", { room: room })}
      open={open}
      onClose={onClose}
      fullScreen
    >
      <div className="header-container">
        <Slot
          id="hotel-details-gallery-header"
          onBack={handleOnBack}
          close={expanded}
          title={
            expanded
              ? t("roomPhotoGallery", {
                  count: filteredMedia.length,
                  currentIndex: carouselIndex + 1,
                })
              : null
          }
          component={
            <div>
              <Grid container spacing={4} wrap="nowrap" alignItems="center">
                <Grid item xs={matchesMobile ? 12 : 10}>
                  <div className="title-category-container">
                    <HotelDetailsDialog.TitleWithCloseButton
                      className="title-section"
                      onClose={onClose}
                      fullScreen
                    >
                      <I18nMarkup
                        tKey="viewingCategory"
                        replacements={{
                          category:
                            selectedCategory ===
                            MediaCategoryWithAllPhotos.AllPhotos
                              ? t("mediaCategory.allPhotosLowercase")
                              : mediaCategoryTranslationMap[selectedCategory],
                        }}
                      />
                      {` - ${selectedLodging.lodging.name}`}
                    </HotelDetailsDialog.TitleWithCloseButton>

                    <div className="category-container">
                      <CategoryFilter
                        onClick={handleChangeCategory}
                        selected={selectedCategory}
                      />
                    </div>
                  </div>
                </Grid>
                {!matchesMobile && (
                  <Grid item xs={2}>
                    <ActionButton
                      autoFocus
                      onClick={viewRoomOnClick}
                      size="medium"
                      message={
                        matchesMobile
                          ? t("viewRoomsButtonLabel")
                          : t("chooseRoomButtonLabel")
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          }
        />
      </div>
      <HotelDetailsDialog.DialogContent>
        <GalleryGrid
          carouselIndex={carouselIndex}
          handleImageOnClick={handleImageOnClick}
          imageUrlsArray={imageUrlsArray}
          filteredMedia={filteredMedia}
        />
      </HotelDetailsDialog.DialogContent>
      {matchesMobile && (
        <HotelDetailsDialog.DialogActions className="expanded-gallery-button-section">
          <ActionButton
            autoFocus
            onClick={viewRoomOnClick}
            fullWidth
            message={
              matchesMobile
                ? t("viewRoomsButtonLabel")
                : t("chooseRoomButtonLabel")
            }
          />
        </HotelDetailsDialog.DialogActions>
      )}
    </HotelDetailsDialog>
  );
};

const GalleryGrid = ({
  carouselIndex,
  handleImageOnClick,
  imageUrlsArray,
  filteredMedia,
}: PopoverGalleryProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();

  const [validImages, setValidImages] = useState<Array<boolean>>([]);

  useEffect(() => {
    const validImages = Array(filteredMedia.length).fill(true);
    setValidImages(validImages);
  }, []);

  return (
    <div className="shared-gallery-container">
      <Slot
        id="hotel-details-gallery-grid"
        carouselIndex={carouselIndex}
        imageUrlsArray={imageUrlsArray}
        filteredMedia={filteredMedia}
        handleImageOnClick={handleImageOnClick}
        component={
          <div className={"expanded-gallery-content"}>
            <div
              className={clsx("expanded-gallery-grid", {
                mobile: matchesMobile,
              })}
            >
              {filteredMedia.map((image, index) => {
                return validImages[index] ? (
                  <img
                    key={index}
                    className={clsx("expanded-gallery-thumbnail", {
                      mobile: matchesMobile,
                    })}
                    alt={t("hotelImageAltWithCategory", {
                      category: image.category,
                    })}
                    src={image.url}
                    onClick={() => handleImageOnClick(index)}
                    onError={(_) =>
                      setValidImages((validImages) => {
                        const newValidImages = [...validImages];
                        newValidImages[index] = false;
                        return newValidImages;
                      })
                    }
                  />
                ) : (
                  <DefaultLodgingPlaceholder />
                );
              })}
            </div>
          </div>
        }
      />
    </div>
  );
};

type CategoryFilterProps = {
  selected: MediaCategoryWithAllPhotos;
  onClick: (category: MediaCategoryWithAllPhotos) => void;
};

const CategoryFilter = ({ onClick, selected }: CategoryFilterProps) => {
  const { t } = useI18nContext();

  const mediaCategoryTranslationMap = useMediaCategoryTranslationMap();

  const getMapOfMediaCategoriesToTranslatedStrings = (
    categories: Array<MediaCategory>
  ): Array<[MediaCategory, string]> => {
    const fallbackTranslation = t("mediaCategory.uncategorized");

    const mappedKeys: Array<[MediaCategory, string]> = categories.map(
      (category) => [
        category,
        mediaCategoryTranslationMap[category] || fallbackTranslation,
      ]
    );

    // Pushes Uncategoried to the end of the list of categories
    return mappedKeys.findIndex(
      ([, translatedString]) => translatedString === fallbackTranslation
    ) !== -1
      ? [
          ...mappedKeys.filter(
            ([, translatedString]) => translatedString !== fallbackTranslation
          ),
          [MediaCategory.Uncategorized, fallbackTranslation],
        ]
      : mappedKeys;
  };

  const categories = useSelector(getSelectedLodgingMediaCategories);
  const mappedCategoriesToTranslatedStrings =
    getMapOfMediaCategoriesToTranslatedStrings(categories);

  return (
    <div className="categories-filter-container">
      <Tabs
        value={selected}
        onChange={(_, value) => onClick(value)}
        className="filter-tabs-container"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="hotel image categories tabs"
      >
        <Tab
          key={MediaCategoryWithAllPhotos.AllPhotos}
          id={MediaCategoryWithAllPhotos.AllPhotos}
          value={MediaCategoryWithAllPhotos.AllPhotos}
          className="tab"
          tabIndex={MediaCategoryWithAllPhotos.AllPhotos === selected ? 0 : -1}
          label={t("mediaCategory.allPhotos")}
        />
        {mappedCategoriesToTranslatedStrings.map(
          ([category, translatedString]) => (
            <Tab
              key={category}
              id={category}
              value={category}
              className="tab"
              tabIndex={category === selected ? 0 : -1}
              label={translatedString}
            />
          )
        )}
      </Tabs>
    </div>
  );
};

const MediaCategoryWithAllPhotos = {
  ...MediaCategory,
  AllPhotos: "AllPhotos",
} as const;
export type MediaCategoryWithAllPhotos =
  (typeof MediaCategoryWithAllPhotos)[keyof typeof MediaCategoryWithAllPhotos];

const useMediaCategoryTranslationMap = () => {
  const { t } = useI18nContext();

  const categoryToTranslatedStringMap: Record<MediaCategory, string> = useMemo(
    () => ({
      // Copied from hopper-web-bff
      // https://github.com/hopper-org/hopper-web-bff/blob/06be1333811990040b9af458b758803138957367/server/src/main/scala/com/hopper/web/service/lodging/converter/ReservationConverters.scala#L670-L687
      [MediaCategory.Primary]: t("mediaCategory.primary"),
      [MediaCategory.Lobby]: t("mediaCategory.lobby"),
      [MediaCategory.GuestRoom]: t("mediaCategory.guestRoom"),
      [MediaCategory.Pool]: t("mediaCategory.pool"),
      [MediaCategory.Fitness]: t("mediaCategory.fitness"),
      [MediaCategory.Spa]: t("mediaCategory.spa"),
      [MediaCategory.SportsFacility]: t("mediaCategory.sportsFacility"),
      [MediaCategory.Amenity]: t("mediaCategory.amenity"),
      [MediaCategory.Dining]: t("mediaCategory.dining"),
      [MediaCategory.Bar]: t("mediaCategory.bar"),
      [MediaCategory.Interior]: t("mediaCategory.interior"),
      [MediaCategory.Exterior]: t("mediaCategory.exterior"),
      [MediaCategory.View]: t("mediaCategory.view"),
      [MediaCategory.PointOfInterest]: t("mediaCategory.pointOfInterest"),
      [MediaCategory.Uncategorized]: t("mediaCategory.uncategorized"),
    }),
    []
  );

  return categoryToTranslatedStringMap;
};
