/*
 * @param _value The value to check for exhaustiveness.
 */
export function ensureExhaustive(_value: never): never;
export function ensureExhaustive<T>(_value: never, defaultValue: T): T;
export function ensureExhaustive<T>(_value: never, defaultValue?: T): T {
  console.warn(
    `There is a type not being handled properly, please view the stack trace for more info. [${JSON.stringify(
      _value
    )}]`
  );
  return defaultValue as T;
}
export function typedKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export function typedEntries<T extends object>(
  obj: T
): [keyof T, T[keyof T]][] {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}
