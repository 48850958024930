import { getObjectKeysAsObject } from "@checkout/helpers";
import {
  trackChooseTraveler,
  trackEditTravelers,
  trackNewTraveler,
  trackViewTravelers,
} from "@checkout/tracking/passengerInformation";
import { deleteUserPassengersService } from "./deleteUserPassenger";
import { fetchUserPassengersService } from "./fetchUserPassengers";
import { updateUserPassengerService } from "./updateUserPassenger";

export const services = {
  deleteUserPassengersService,
  fetchUserPassengersService,
  trackChooseTraveler,
  trackEditTravelers,
  trackNewTraveler,
  trackViewTravelers,
  updateUserPassengerService,
};

export const ServiceTypes = getObjectKeysAsObject(services);
