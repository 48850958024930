import type { FlightContext } from "@checkout/states";
import { isFlightProduct } from "@checkout/utilities";
import { ADD_CONTACT_INFO, type ProductType } from "@hopper-b2b/types";
import { trackBaseEvent } from "../utils";

export const trackAddContactEvent = async (
  context,
  event: { productType: ProductType; tripType?: string }
) => {
  let tripType = event?.tripType;

  if (!tripType && isFlightProduct(context)) {
    tripType = (context as FlightContext).flightSearch.tripCategory;
  }

  return trackBaseEvent(context, {
    eventName: ADD_CONTACT_INFO,
    properties: {
      product_type: event?.productType,
      trip_type: tripType,
    },
  });
};
