import { ReactComponent as User } from "../../icons/b2b-user.svg";
import { ReactComponent as Bags } from "../../icons/bags.svg";
import { ReactComponent as AirConditioning } from "../../icons/car/airCondition.svg";
import { ReactComponent as Airport } from "../../icons/car/airport.svg";
import { ReactComponent as Transmission } from "../../icons/car/transmission.svg";
import { ReactComponent as check } from "../../icons/check-circle.svg";
import { CarIconName, type CarIconSet } from "./types";

export const defaultCarIcons: CarIconSet = {
  [CarIconName.car__airConditioning]: AirConditioning,
  [CarIconName.car__airport]: Airport,
  [CarIconName.car__bags]: Bags,
  [CarIconName.car__passenger]: User,
  [CarIconName.car__rentalAttribute]: check,
  [CarIconName.car__transmission]: Transmission,
};
