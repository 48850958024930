import type { Airline } from "@b2bportal/air-booking-api";
import type { CipherText } from "@b2bportal/purchase-api";
import type { Amount, CaseClassValue, Uuid } from "../../index";
import type { AgentLocator } from "../apis/tysons/payment-machine";

export enum HotelCancelFulfillPollResEnum {
  Pending = "Pending",
  Success = "Success",
  Aborted = "Aborted",
}

export enum LodgingCancelFulfillResEnum {
  Failure = "LodgingCancelFulfillFailure",
  Success = "LodgingCancelFulfillSuccess",
}

export enum LodgingCancelQuoteResEnum {
  CartFailure = "LodgingCancelQuoteCartFailure",
  CartSuccess = "LodgingCancelQuoteCartSuccess",
  Failure = "LodgingCancelQuoteFailure",
  NonCartFailure = "LodgingCancelQuoteNonCartFailure",
  NonCartSuccess = "LodgingCancelQuoteNonCartSuccess",
  Success = "LodgingCancelQuoteSuccess",
}

export enum LodgingChangeQuoteResEnum {
  Failure = "LodgingChangeQuoteFailure",
  NonCartFailure = "LodgingChangeQuoteNonCartFailure",
  NonCartSuccess = "LodgingChangeQuoteNonCartSuccess",
  Success = "LodgingChangeQuoteSuccess",
}

export enum NonCfarEnum {
  /**
   * Fully refundable
   */
  AirlineRefund = "AirlineRefund",
  ContactAirline = "ContactAirline",
  ContactCustomerService = "ContactCustomerService",
  FTC = "Ftc",
  FTCWithPenalty = "FtcWithPenalty",
  NonRefundable = "NonRefundable",
  PartialRefund = "PartialRefund",
  RefundableComplex = "RefundableComplex",
  TicketedVoid = "TicketedVoid",
  TicketlessVoid = "TicketlessVoid",
}
export enum CancelModalFlowStepEnum {
  appService = "appService",
  error = "error",
  loading = "loading",
  policy = "policy",
  confirm = "confirm",
  success = "success",
  nonRefundable = "nonrefundable",
  support = "support",
  pending = "pending",
  cancelled = "cancelled",
  tryAgain = "tryAgain",
  processingCancellation = "processingCancellation",
  tenantError = "tenantError",
}
export enum CancellationResponseStatus {
  Success = "Success",
  Failure = "Failure",
}
export enum CancelScenarioEnum {
  PartialRefund = "PartialRefund",
  FullyRefundableComplex = "FullyRefundableComplex",
  NonRefundable = "NonRefundable",
  Pending = "Pending",
  CancellationFailure = "CancellationFailure",
  PartiallyRefundableComplex = "PartiallyRefundableComplex",
  FullRefund = "FullRefund",
  FullyRefundable = "FullyRefundable",
  ContactCustomerService = "ContactCustomerService",
  AirlineControl = "AirlineControl",
  BookingPending = "BookingPending",
  CancellationPending = "CancellationPending",
  Canceled = "Canceled",
  Departed = "Departed",
  NonCfar = "NonCfar",
  PastCheckInDate = "PastCheckInDate",
  OutOfPolicy = "OutOfPolicy",
  CfarRefundable = "CfarRefundable",
  ChFarRefundable = "ChFarRefundable",
  LfarRefundable = "LfarRefundable",
  HopperRefundable = "HopperRefundable",
}
export interface Copy {
  body: string[];
  title: string;
}
export interface CopyWithExtra extends Copy {
  disclaimer?: string;
  importantInfo: string[];
}
export interface CancelCopy extends CopyWithExtra {
  informativeSection: Copy;
}
export interface CancelScenario {
  GroundCancelScenario?: CancelScenarioEnum;
  HotelCancelScenario?: CancelScenarioEnum;
  CancelScenario?: CancelScenarioEnum;
  customerServiceCopy?: CancelCopy;
  cancelCopy?: CancelCopy;
  cancelConfirmationCopy?: CancelCopy;
  preparedPayment?: string;
  refundAmount?: Amount;
}

export interface CancellationResult {
  success: boolean;
}
export interface CancelErrorResponse {
  code: string;
  details: string;
}
export interface CancellationInfoRequest {
  reservationId?: string;
  itineraryId?: Uuid;
}
export interface CancellationRequest extends CancellationInfoRequest {
  cancelScenario: CancelScenario;
}
export interface FlightCancellationRequest
  extends Omit<CancellationRequest, "cancelScenario"> {
  cancelScenario: FlightCancelInfoResponse;
}
export interface CancellationInfoResponse {
  Response: CancellationResponseStatus;
  value?: CancelScenario;
}
export interface FlightCancelInfoResponse {
  CancelScenario: CancelScenarioEnum;
  NonCfar: NonCfarEnum;
  agentLocator?: AgentLocator;
  airlines?: Airline[];
  cancelConfirmationCopy?: CopyWithExtra;
  cancelCopy?: CancelCopy;
  copy?: Copy;
  customerServiceCopy?: CopyWithExtra;
  penaltyAmount?: Amount;
  preparedPayment?: string;
  voidWindowEnd?: number;
}
export interface CancellationResponse extends CancellationInfoResponse {
  errors?: CancelErrorResponse[];
  copy?: Copy;
}
export interface HotelCancelInfoReq {
  reservationId: Uuid;
}

export interface LodgingCancelScenario {
  LodgingCancelScenario: CancelScenarioEnum;
  cancelCopy: CancelCopy;
  cancelConfirmationCopy: CancelCopy;
}

export interface CartHotelCancelFulfillReq {
  quoteId: CaseClassValue;
}

export interface NonCartHotelCancelFulfullReq {
  clientTokenId: string;
}

export interface CartHotelCancelFulfillRes {
  LodgingCancelFulfillResponse: LodgingCancelFulfillResEnum.Success;
  fulfillmentSessionId: CaseClassValue;
}

export interface CartHotelCancelFulfillPollRes {
  LodgingCancelFulfillmentPollResponse: HotelCancelFulfillPollResEnum;
}

export interface NonCartHotelCancelFulfillRes {
  LodgingCancelFulfillResponse: LodgingCancelFulfillResEnum.Success;
}

export interface CartHotelCancelInfoRes {
  LodgingCancelQuoteResponse:
    | LodgingCancelQuoteResEnum.Success
    | LodgingCancelQuoteResEnum.CartSuccess;
  cancelScenario: LodgingCancelScenario;
  quoteId: CaseClassValue;
}
export interface NonCartHotelCancelInfoRes {
  LodgingCancelQuoteResponse: LodgingCancelQuoteResEnum.NonCartSuccess;
  cancelScenario: LodgingCancelScenario;
  clientTokenId: string;
}

export interface HotelChangeQuoteReq {
  reservationId: Uuid;
}

export interface HotelChangeQuoteFailureRes {
  LodgingChangeQuoteResponse: LodgingChangeQuoteResEnum.Failure;
}

export interface BaseHotelChangeQuoteSuccessRes {
  cancelScenario: LodgingCancelScenario;
  changeCopy: Copy;
}

export interface CartHotelChangeQuoteSuccessRes
  extends BaseHotelChangeQuoteSuccessRes {
  LodgingChangeQuoteResponse: LodgingChangeQuoteResEnum.Success;
  quoteId: CipherText;
}

export interface NonCartHotelChangeQuoteSuccessRes
  extends BaseHotelChangeQuoteSuccessRes {
  LodgingChangeQuoteResponse: LodgingChangeQuoteResEnum.NonCartSuccess;
  clientTokenId: CipherText;
}

export type HotelCancelFulfillReq =
  | CartHotelCancelFulfillReq
  | NonCartHotelCancelFulfullReq;

export type HotelCancelFulfillRes =
  | CartHotelCancelFulfillRes
  | NonCartHotelCancelFulfillRes;

export type HotelCancelInfoRes =
  | CartHotelCancelInfoRes
  | NonCartHotelCancelInfoRes;

export type HotelChangeQuoteRes =
  | CartHotelChangeQuoteSuccessRes
  | NonCartHotelChangeQuoteSuccessRes;
