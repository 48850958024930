import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useEnableCfar,
  useEnableChfar,
  useEnableScheduleChange,
  useEnableMissedConnection,
} from "@hopper-b2b/utilities";
import { fetchCfarOffers } from "../../cfar/actions/actions";
import { fetchChfarOffer } from "../../chfar/actions/actions";
import { useFetchDisruptionOffersWithParams } from "@b2bportal/core-disruption";
import { fetchDisruptionOffers } from "../../disruption/actions/actions";
import { numberOfSeatedPassengersSelector } from "../reducer/selectors";

export const useFetchFintechProducts = () => {
  const dispatch = useDispatch();

  const enableScheduleChange = useEnableScheduleChange();
  const enableMissedConnection = useEnableMissedConnection();

  const showFlightCFAR = useEnableCfar();
  const showFlightChFar = useEnableChfar();
  const fetchDisruptionOffersFromCoreLib = useFetchDisruptionOffersWithParams();

  const numberOfPassengers = useSelector(numberOfSeatedPassengersSelector);

  return useCallback(
    (tripId: string, fareId: string) => {
      if (enableScheduleChange || enableMissedConnection) {
        dispatch(fetchDisruptionOffers());
      }
      fetchDisruptionOffersFromCoreLib(tripId, fareId, numberOfPassengers); // Eneabling disruption in all cases as it is controlled by disruption-offers repo.

      if (showFlightCFAR) {
        tripId && fareId && dispatch(fetchCfarOffers(tripId, fareId));
      }
      if (showFlightChFar) {
        tripId && fareId && dispatch(fetchChfarOffer(tripId, fareId));
      }
    },
    [
      dispatch,
      enableMissedConnection,
      enableScheduleChange,
      showFlightCFAR,
      showFlightChFar,
      numberOfPassengers,
      fetchDisruptionOffersFromCoreLib,
    ]
  );
};
