import React, { type ReactNode, useCallback } from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button, { type ButtonProps } from "@material-ui/core/Button";
import clsx from "clsx";
import "./styles.scss";

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!  CAUTION  !!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * This component is being overridden by the following tenants
 *  - Hopper Web (libs/hopper/ui/core)
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

export interface IActionButtonProps {
  className?: string;
  buttonClassName?: string;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  message: ReactNode | string | null;
  defaultStyle?: "h4r-primary" | "h4r-secondary";
  icon?: string;
  fill?: "blue" | "red";
  ariaLabelText?: string;
  ariaLabelledBy?: string;
  disableElevation?: boolean;
  size?: "small" | "large" | "medium";
  testId?: string;
  submit?: boolean;
}

export const ActionButton = ({
  className,
  buttonClassName,
  onClick,
  disabled = false,
  message = "",
  defaultStyle,
  icon,
  fill = "blue",
  ariaLabelText = undefined,
  ariaLabelledBy = undefined,
  disableElevation = false,
  size = "large",
  testId = "",
  variant = "contained",
  submit = false,
}: IActionButtonProps & ButtonProps) => {
  const handleClick = useCallback(
    (e?: React.MouseEvent) => onClick(e),
    [onClick]
  );

  return (
    <Box
      className={clsx(
        className,
        defaultStyle,
        fill,
        "action-button",
        "btn-wrapper"
      )}
    >
      <Button
        {...{ disabled }}
        data-testid={testId}
        disableElevation={disableElevation}
        className={clsx(buttonClassName, "button")}
        aria-label={ariaLabelText}
        aria-labelledby={ariaLabelledBy}
        onClick={handleClick}
        variant={variant}
        role="button"
        size={size as "small" | "medium" | "large"}
        {...(submit ? { type: "submit" } : {})}
      >
        <Typography variant="button">{message}</Typography>
        {icon && (
          <Box className="action-button-icon">
            <img src={icon} alt="icon" />
          </Box>
        )}
      </Button>
    </Box>
  );
};
