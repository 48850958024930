import { Product, type ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { SelectedStatus } from "@overrides/types";
import { assign } from "xstate";
import { getCfarCartUpdateRequest } from "./selectors";
import type {
  LodgingCfarProductV1,
  PartialParentContextWithLodgingCfarV1,
} from "./types";

export const actions = {
  addCfarProduct: assign(
    (context: PartialParentContextWithLodgingCfarV1, _event: unknown) => {
      if (
        context[ParentState.lodgingCancelForAnyReasonV1]?.selectedOffer
          .status === SelectedStatus.SELECTED
      ) {
        const filterOutCfarFromProducts = (
          context[ParentState.cartUpdate].addProducts ?? []
        ).filter((p: ProductOpaqueValue) => p.type !== Product.LodgingCfar);

        const request = getCfarCartUpdateRequest({ context });
        const newCfarProduct: LodgingCfarProductV1 = {
          type: Product.LodgingCfar,
          value: request,
        };

        context[ParentState.cartUpdate].addProducts = [
          ...filterOutCfarFromProducts,
          newCfarProduct,
        ];
      }
      return context;
    }
  ),
};

export const ActionTypes = getObjectKeysAsObject(actions);
