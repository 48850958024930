import { getObjectKeysAsObject } from "@checkout/helpers";
import { ParentState } from "@checkout/types";
import { SelectedStatus } from "@overrides/types";
import type { PartialParentContextWithLodgingCfarV1 } from "./types";

export const guards = {
  hasCfarIdAndEnabled: (
    ctx: PartialParentContextWithLodgingCfarV1,
    _event: unknown
  ) => {
    const selectedOffer =
      ctx[ParentState.lodgingCancelForAnyReasonV1]?.selectedOffer;
    return selectedOffer?.status === SelectedStatus.SELECTED;
  },
};

export const GuardTypes = getObjectKeysAsObject(guards);
