import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import {
  TripCategory,
  type IPassengerCounts,
  type ITripTerminus,
} from "@b2bportal/core-types";
import {
  FLIGHTS_SHOP_DEPARTURE_PATH,
  FLIGHT_SEARCH_CALENDAR_HASH,
} from "@b2bportal/core-utilities";
import { ChangeTripTypeScreenEnum } from "@hopper-b2b/types";
import {
  FlightRawEventName,
  useEmitCoreFlightRawEvent,
} from "../../../../../tracking";
import {
  buildFlightShopQuery,
  type BuildFlightShopQueryArgs,
} from "../../../hooks";
import {
  FlightSearchActions,
  FlightSearchSelectors,
  type FlightSearchValues,
} from "../../../store";

export type ActiveSearchContext = FlightSearchValues & {
  isSearchComplete: boolean;
  isSearchCompleteWithoutDates: boolean;
  openCalendar: boolean;
  validSearchParams?: BuildFlightShopQueryArgs;
};

export type ActiveSearchHandlers = {
  onOriginChange: (origin: ITripTerminus) => void;
  onDestinationChange: (dest: ITripTerminus) => void;
  onDepartureDateChange: (date?: string) => void;
  onReturnDateChange: (date?: string) => void;
  onPassengerCountChange: (pax: IPassengerCounts) => void;
  onTripCategoryChange: (tripCat: TripCategory) => void;
  onSearch: (params: BuildFlightShopQueryArgs) => void;
  onSearchReset: () => void;
  onNavigateToCalendar: () => void;
  onCalendarClose: () => void;
};

export const useFlightSearch = (): {
  context: ActiveSearchContext;
  handlers: ActiveSearchHandlers;
} => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const emitChangedTripTypeEvent = useEmitCoreFlightRawEvent(
    FlightRawEventName.ChangedTripType
  );

  const searchState = useSelector(FlightSearchSelectors.getSearchState);
  const {
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts,
  } = useSelector(FlightSearchSelectors.getSearchFormValues);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const onSearch = useCallback(
    (params: BuildFlightShopQueryArgs) => {
      const shopQuery = buildFlightShopQuery(params);
      navigate(`${FLIGHTS_SHOP_DEPARTURE_PATH}?${shopQuery}`);
    },
    [navigate]
  );

  const onSearchReset = useCallback(() => {
    dispatch(FlightSearchActions.setSearchFormValues(searchState));
  }, [dispatch, searchState]);

  const onDepartureDateChange = (departureDate?: string) => {
    dispatch(FlightSearchActions.setSearchFormDepartureDate(departureDate));
  };
  const onReturnDateChange = (returnDate?: string) => {
    dispatch(FlightSearchActions.setSearchFormReturnDate(returnDate));
  };
  const onPassengerCountChange = (passengerCount: IPassengerCounts) =>
    dispatch(FlightSearchActions.setSearchFormPassengerCount(passengerCount));

  const onTripCategoryChange = (tripCategory: TripCategory) => {
    dispatch(FlightSearchActions.setSearchFormTripCategory(tripCategory));
    emitChangedTripTypeEvent({
      properties: { screen: ChangeTripTypeScreenEnum.LOCATION_PICKER },
    });
  };

  const onOriginChange = (origin: ITripTerminus) =>
    dispatch(FlightSearchActions.setSearchFormOrigin(origin));
  const onDestinationChange = (destination: ITripTerminus) => {
    dispatch(FlightSearchActions.setSearchFormDestination(destination));
  };

  const validSearchParams = validateSearchParams({
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts,
  });

  const isSearchCompleteWithoutDates =
    origin != null && destination != null && tripCategory != null;

  const isSearchComplete = validSearchParams != null;

  useEffect(() => {
    if (location.hash === FLIGHT_SEARCH_CALENDAR_HASH) {
      if (isSearchCompleteWithoutDates) {
        setOpenCalendar(true);
      } else {
        navigate(`${location.pathname}${location.search}`, { replace: true });
      }
    } else {
      setOpenCalendar(false);
    }
  }, [location, isSearchCompleteWithoutDates, navigate]);

  const onCalendarClose = useCallback(() => {
    navigate(`${location.pathname}${location.search}`, { replace: true });
    navigate(-1);
  }, [navigate, location]);

  const onNavigateToCalendar = useCallback(() => {
    navigate(
      `${location.pathname}${location.search}${FLIGHT_SEARCH_CALENDAR_HASH}`
    );
  }, [navigate, location]);

  return {
    context: {
      origin,
      destination,
      tripCategory,
      passengerCounts,
      departureDate,
      returnDate,
      isSearchComplete,
      isSearchCompleteWithoutDates,
      openCalendar,
      validSearchParams,
    },
    handlers: {
      onSearch,
      onSearchReset,
      onTripCategoryChange,
      onDepartureDateChange,
      onReturnDateChange,
      onDestinationChange,
      onOriginChange,
      onPassengerCountChange,
      onNavigateToCalendar,
      onCalendarClose,
    },
  };
};

const validateSearchParams = ({
  origin,
  destination,
  departureDate,
  returnDate,
  tripCategory,
  passengerCounts,
}: ReturnType<typeof FlightSearchSelectors.getSearchFormValues>):
  | BuildFlightShopQueryArgs
  | undefined => {
  if (departureDate == null || destination == null || origin == null) {
    return undefined;
  }
  if (tripCategory === TripCategory.ONE_WAY) {
    return {
      origin,
      destination,
      departureDate,
      tripCategory,
      passengerCounts,
    };
  }
  if (returnDate == null) return undefined;
  return {
    origin,
    destination,
    departureDate,
    returnDate,
    tripCategory,
    passengerCounts,
  };
};
