import type { MouseEventHandler } from "react";
import type {
  MultiTicketType,
  PassengerPricing,
  TripType,
} from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import {
  FlightMissedConnectionDialog,
  ReviewBreakdown,
} from "@components/flights";
import { Icon } from "@components/ui";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./ReviewFlightSecondaryContent.module.scss";

export interface ReviewFlightSecondaryContentProps
  extends PlatformComponentProps {
  paxPricing: PassengerPricing;
  tripType: TripType;
  multiTicketType: MultiTicketType;
  onClick: MouseEventHandler<HTMLButtonElement>;
}
export const ReviewFlightSecondaryContent = (
  props: ReviewFlightSecondaryContentProps
) => {
  const { className, paxPricing, multiTicketType, tripType, onClick } = props;
  const COMPONENT_KEY = CoreFlightsComponent.ReviewFlightSecondaryContent;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();

  return (
    <div className={clsx(block, className)}>
      {multiTicketType === "virtualInterline" ? (
        <FlightMissedConnectionDialog>
          <span className={cn("missed-connection-guarantee")}>
            <Trans
              i18nKey="core-flights.vi.reviewPage.missedConnectionGuaranteeCTA"
              components={{
                info: (
                  <Icon
                    className={cn("missed-connection-guarantee", ["icon"])}
                    iconName={IconNameEnum.info}
                  />
                ),
              }}
            />
          </span>
        </FlightMissedConnectionDialog>
      ) : null}
      <ReviewBreakdown
        passengerPricing={paxPricing}
        tripType={tripType}
        className={cn("review-breakdown")}
      />
      <button className={cn("continue-button")} onClick={onClick}>
        {t("continue")}
      </button>
    </div>
  );
};
