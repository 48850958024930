import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { DisruptionExerciseMoreInfo } from "@b2bportal/core-disruption";
import { DisruptionExerciseBulletPoint } from "@b2bportal/core-disruption";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
  useTenantIcons,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  DisruptionExerciseProgress,
  resetDisruptionExerciseState,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { DisruptionExerciseMoreInfoType } from "../../components/DisruptionExerciseMoreInfo/DisruptionExerciseMoreInfo";
import defaultStyles from "./DisruptionExerciseRebookConfirmationPage.module.scss";

export interface DisruptionExerciseRebookConfirmationPageProps {
  email: string;
  disruptionProductName: string;
  shouldBookReturn: boolean;
  rebookingLimit?: string;
  termsLink: string;
  delayHours: string;
  hoursString: string;
  handleDoneAction?: () => void;
  onSupportClick: () => void;
  handleRefundClick: () => void;
}

export const DisruptionExerciseRebookConfirmationPage = ({
  email,
  disruptionProductName,
  shouldBookReturn,
  rebookingLimit,
  termsLink,
  delayHours,
  hoursString,
  handleDoneAction,
  onSupportClick,
  handleRefundClick,
}: DisruptionExerciseRebookConfirmationPageProps) => {
  const { t, brand } = useI18nContext();
  const dispatch = useDispatch();
  const [openBagsLearnMore, setOpenBagsLearnMore] = useState(false);
  const [openReturnLearnMore, setOpenReturnLearnMore] = useState(false);
  const icons = useTenantIcons();
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRebookConfirmationPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const handleDoneClick = () => {
    dispatch(resetDisruptionExerciseState());
    handleDoneAction?.();
  };

  const handleBookReturnCta = () => {
    // when user click on book return, we redirect him to the review page so we load rebook plan again to start the flow
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
    );
  };

  const onCloseBagsLearnMore = useCallback(
    () => setOpenBagsLearnMore(false),
    [setOpenBagsLearnMore]
  );

  const onCloseReturnLearnMore = useCallback(
    () => setOpenReturnLearnMore(false),
    [setOpenReturnLearnMore]
  );

  const onOpenReturnLearnMore = useCallback(
    () => setOpenReturnLearnMore(true),
    [setOpenReturnLearnMore]
  );

  const onOpenBagsLearnMore = useCallback(
    () => setOpenBagsLearnMore(true),
    [setOpenBagsLearnMore]
  );

  const bullets = () => {
    const baseBullets = [
      {
        type: "bodyOne",
        title: t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.bulletOneTitle"
        ),
        text: t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.bulletOneSubtitle"
        ),
        learnMore: {
          title: t("core-disruption.disruptionUniversalExercise.learnMore"),
          onClick: onOpenBagsLearnMore,
        },
      },
      {
        type: "bodyOne",
        title: t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.bulletTwoTitle"
        ),
        text: t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.bulletTwoSubtitle"
        ),
      },
    ];

    if (shouldBookReturn) {
      baseBullets.push({
        type: "bodyOne",
        title: t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.bulletThreeTitle"
        ),
        text: t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.bulletThreeSubtitleWithRebookingLimit",
          {
            portalName: brand.portalName,
            maxPerPax: rebookingLimit,
          }
        ),
        learnMore: {
          title: t("core-disruption.disruptionUniversalExercise.learnMore"),
          onClick: onOpenReturnLearnMore,
        },
      });
    }
    return baseBullets;
  };

  const bagsLearnMore = (
    <DisruptionExerciseMoreInfo
      open={openBagsLearnMore}
      onClose={onCloseBagsLearnMore}
      type={DisruptionExerciseMoreInfoType.Baggage}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      disruptionProductName={disruptionProductName}
      termsLink={termsLink}
      delayHours={delayHours}
      numberOfHours={hoursString}
    ></DisruptionExerciseMoreInfo>
  );

  const returnLearnMore = (
    <DisruptionExerciseMoreInfo
      open={openReturnLearnMore}
      onClose={onCloseReturnLearnMore}
      type={DisruptionExerciseMoreInfoType.Return}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      rebookingLimit={rebookingLimit}
      disruptionProductName={disruptionProductName}
      termsLink={termsLink}
      delayHours={delayHours}
      numberOfHours={hoursString}
    ></DisruptionExerciseMoreInfo>
  );

  const confirmationIcon =
    icons.disruptionConfirmationIcon != null ? (
      <Icon
        className={cn("icon")}
        iconName={IconNameEnum.disruptionConfirmationIcon}
        useCurrentColor={false}
      />
    ) : (
      <Icon className={cn("icon")} iconName={IconNameEnum.checkFilled} />
    );

  const topContent = (
    <div className={cn("confirmationContent")}>
      {confirmationIcon}
      <span className={cn("title")}>
        {t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.title"
        )}
      </span>

      <span className={cn("subtitle")}>
        {t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.subtitle",
          {
            productName: disruptionProductName,
            email: email,
          }
        )}
      </span>

      <button
        onClick={handleDoneClick}
        disabled={false}
        className={cn("doneButton")}
      >
        {t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.done"
        )}
      </button>
    </div>
  );

  const whatNext = (
    <div className={cn("whatNextContent")}>
      <span className={cn("contentTitle")}>
        {t(
          "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.whatNext.title"
        )}
      </span>
      <div className={cn("bulletsContainer")}>
        {bullets().map((bulletPoint, index) => (
          <DisruptionExerciseBulletPoint
            title={bulletPoint.title}
            text={bulletPoint.text}
            index={index}
            learnMore={bulletPoint.learnMore}
          />
        ))}
      </div>
      {shouldBookReturn && (
        <button
          onClick={handleBookReturnCta}
          disabled={false}
          className={cn("bookReturnButton")}
        >
          {t(
            "core-disruption.disruptionUniversalExercise.rebook.checkoutConfirmationPage.bookReturnButton"
          )}
        </button>
      )}
    </div>
  );

  return (
    <div
      className={cn("disruptionUniversalExerciseRebookFlightConfirmationPage")}
    >
      {topContent}
      <hr />
      {whatNext}
      {bagsLearnMore}
      {returnLearnMore}
    </div>
  );
};

export default DisruptionExerciseRebookConfirmationPage;
