import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  type Airport,
  type BookedFlightItineraryWithDepartureTime,
  DisruptionContractStatusEnum,
} from "@b2bportal/air-booking-api";
import type {
  Fare,
  FlightItinerarySegment,
  FlightsFareSlice,
  OrderedFlight,
  PaymentOpaqueValue,
  RebookPlanSuccess,
  Slice,
  Trip,
  TripSlice,
} from "@b2bportal/air-disruption-api";
import type { FiatPrice } from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useDisruptionStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  DisruptionExerciseLandingPage,
  DisruptionExerciseRefundReviewPage,
  DisruptionExerciseRefundConfirmationPage,
} from "@components/disruption";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  getDisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../features/exercise";
import { DisruptionExerciseProgress } from "../../features/exercise";
import type { FetchTripDetailsThunk } from "../../types";
import DisruptionContactSupport from "./components/DisruptionContactSupport/component";
import DisruptionExerciseNotEligiblePage from "./pages/DisruptionExerciseNotEligiblePage/DisruptionExerciseNotEligiblePage";
import { DisruptionExerciseRebookCheckoutPage } from "./pages/DisruptionExerciseRebookCheckoutPage";
import DisruptionExerciseRebookConfirmationPage from "./pages/DisruptionExerciseRebookConfirmationPage/DisruptionExerciseRebookConfirmationPage";
import { DisruptionExerciseRebookFlightShopPage } from "./pages/DisruptionExerciseRebookFlightShopPage";
import DisruptionExerciseRebookOnboardingPage from "./pages/DisruptionExerciseRebookOnboardingPage/DisruptionExerciseRebookOnboardingPage";
import { DisruptionExerciseRebookSegmentSelectPage } from "./pages/DisruptionExerciseRebookSegmentSelectPage";
import DisruptionExerciseRebookSelectFlightPage from "./pages/DisruptionExerciseRebookSelectFlightPage/DisruptionExerciseRebookSelectFlightPage";
import defaultStyles from "./styles.module.scss";
import { useRebookingLimit } from "./utils/currencyUtils";

export interface DisruptionUniversalExerciseProps {
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  onContactSupport?: () => void;
  handleExerciseCompleted?: () => void;
  displayExternalLinkAsModal: boolean;
  airports: { [key: string]: Airport };
  disruptionSelfServeRebook?: boolean;
  cancelFlowAction?: () => void;
  fetchTripDetails: FetchTripDetailsThunk;
}

export const DisruptionUniversalExercise = (
  props: DisruptionUniversalExerciseProps
) => {
  const dispatch = useDispatch();
  const { isDesktopAndUp } = useDeviceType();
  const { t, brand } = useI18nContext();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionUniversalExercise;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [_, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const [openSupport, setOpenSupport] = useState<boolean>(false);
  const disruptionExerciseProgress = useSelector(getDisruptionExerciseProgress);
  const [reimbursementTotalAmount, setReimbursementTotalAmount] =
    useState<FiatPrice | null>(null);
  const [refundFulfillmentToken, setRefundFulfillmentToken] = useState<
    string | null
  >(null);
  const [refundBreakdown, setRefundBreakdown] =
    useState<Array<PaymentOpaqueValue> | null>(null);
  const contractId =
    props.disruptedFlight?.ancillaries?.disruptionProtection?.id;
  const email = props.disruptedFlight?.emailAddress;
  const phoneNumber =
    props.disruptedFlight?.bookedItinerary.passengers.contact.contact
      .phoneNumber;

  const policyDetails =
    props.disruptedFlight?.ancillaries?.disruptionProtection?.policyDetails;
  const openCustomerSupport = props.onContactSupport
    ? props.onContactSupport
    : () => {
        setOpenSupport(true);
      };
  const termsAndConditions =
    brand.urls.disruptionTerms ??
    "https://www.hopper.com/legal/Premium-Disruption-Assistance";
  const policyDetailsThreshold =
    policyDetails?.delayThreshold !== undefined
      ? policyDetails.delayThreshold
      : 180;
  const delayThresholdHours = (policyDetailsThreshold / 60).toString();
  const hoursString =
    delayThresholdHours === "1"
      ? t("core-disruption.disruptionUniversalExercise.notEligiblePage.hour")
      : t("core-disruption.disruptionUniversalExercise.notEligiblePage.hours");
  const displayLinkAsModal = isDesktopAndUp
    ? false
    : props.displayExternalLinkAsModal;
  const { rebookingLimit } = useRebookingLimit();
  const [rebookPlanResponse, setRebookPlanResponse] =
    useState<RebookPlanSuccess | null>(null);
  const [selectedSlice, setSelectedSlice] = useState<{
    segments: Array<FlightItinerarySegment>;
  } | null>(null);
  const [rebookedFlight, setRebookedFlight] = useState<OrderedFlight | null>(
    null
  );
  const originalSlices =
    props.disruptedFlight.bookedItinerary.travelItinerary.slices;
  const isEligible =
    policyDetails?.policyStatus.DisruptionContractStatus ===
    DisruptionContractStatusEnum.AnyExerciseEligible;
  const isRebookOnlyEligible =
    policyDetails?.policyStatus.DisruptionContractStatus ===
    DisruptionContractStatusEnum.RebookOnlyEligible;
  const [flightSearch, setFlightSearch] = useState<{
    origin: string;
    destination: string;
    departure: string;
    tripSlice?: TripSlice | null;
  }>({ origin: "", destination: "", departure: "", tripSlice: null });
  const [shoppedFlight, setShoppedFlight] = useState<{
    selectedFare?: Fare;
    selectedTrip?: Trip;
    selectedTripSlice?: Slice;
    selectedFareSlice?: FlightsFareSlice;
  }>({
    selectedFare: undefined,
    selectedTrip: undefined,
    selectedTripSlice: undefined,
    selectedFareSlice: undefined,
  });
  const productName = t("core-disruption.productName");
  const disruptionSelfServeRebookFlag =
    props.disruptionSelfServeRebook ?? false;

  // Currently the user is booking the return flight
  const isBookingReturn = useMemo(() => {
    if (isRebookOnlyEligible) {
      return true;
    } else if (originalSlices.length > 1 && rebookedFlight) {
      const outboundSlice = originalSlices[0];
      const rebookedSlice = rebookedFlight.travelItinerary.slices[0];
      const rebookedDestination =
        rebookedSlice.segments[rebookedSlice.segments.length - 1].destination;
      const outboundSliceDestination =
        outboundSlice.segments[outboundSlice.segments.length - 1].destination;
      return (
        rebookedDestination.locationCode ===
        outboundSliceDestination.locationCode
      );
    } else {
      return false;
    }
  }, [isRebookOnlyEligible, rebookedFlight, originalSlices]);

  // User should have the option to rebook the return flight
  // we need to book return flight if the user has disrupted flight with 2 slices (outbound - return)
  // and he rebooked the outbound slice
  const shouldBookReturn = useMemo(
    () => originalSlices.length > 1 && isEligible,
    [originalSlices.length, isEligible]
  );

  const onFlightSelectContinue = useCallback(
    (props: {
      origin: string;
      destination: string;
      departure: string;
      tripSlice: TripSlice;
    }) => {
      setFlightSearch(props);
    },
    [setFlightSearch]
  );

  const handleRefundClick = useCallback(() => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.LandingPage)
    );
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.RefundSelected)
    );
  }, [dispatch]);

  useEffect(() => {
    if (disruptionExerciseProgress === DisruptionExerciseProgress.NotStarted) {
      props.handleExerciseCompleted?.();
    } else if (
      disruptionExerciseProgress === DisruptionExerciseProgress.LandingPage &&
      isBookingReturn
    ) {
      dispatch(
        setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
      );
    }
  }, [disruptionExerciseProgress, props, isBookingReturn, dispatch]);

  if (contractId === undefined || policyDetails === undefined) {
    setOpenSupport(true);
    return (
      <DisruptionContactSupport
        open={openSupport}
        onClose={() => {
          setOpenSupport(false);
        }}
      />
    );
  } else {
    return (
      <div className={cn("disruption-universal-exercise")}>
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.NotEligible && (
          <DisruptionExerciseNotEligiblePage
            disruptionProductName={productName}
            disruptionProductLogo={IconNameEnum.disruptionProtection}
            policyDetails={policyDetails}
            onContactSupport={openCustomerSupport}
            termsLink={termsAndConditions}
            displayExternalLinkAsModal={displayLinkAsModal}
          />
        )}
        {(disruptionExerciseProgress ===
          DisruptionExerciseProgress.LandingPage ||
          disruptionExerciseProgress ===
            DisruptionExerciseProgress.RebookSelected ||
          disruptionExerciseProgress ===
            DisruptionExerciseProgress.RefundSelected) && (
          <DisruptionExerciseLandingPage
            disruptionProductName={productName}
            disruptionProductLogo={IconNameEnum.disruptionProtection}
            disruptedFlight={props.disruptedFlight}
            contractId={contractId}
            setReimbursementTotalAmount={setReimbursementTotalAmount}
            setRefundBreakdown={setRefundBreakdown}
            setRefundFulfillmentToken={setRefundFulfillmentToken}
            policyDetails={policyDetails}
            onContactSupport={openCustomerSupport}
            termsLink={termsAndConditions}
            displayExternalLinkAsModal={displayLinkAsModal}
            hoursString={hoursString}
            delayHours={delayThresholdHours}
            rebookingLimit={rebookingLimit(policyDetails)}
            disruptionSelfServeRebook={disruptionSelfServeRebookFlag}
            cancelFlowAction={props.cancelFlowAction}
          />
        )}

        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.RefundReview &&
          refundFulfillmentToken !== null &&
          reimbursementTotalAmount != null && (
            <DisruptionExerciseRefundReviewPage
              disruptionProductName={productName}
              disruptionProductLogo={IconNameEnum.disruptionProtection}
              disruptedFlight={props.disruptedFlight}
              contractId={contractId}
              reimbursementTotalAmount={reimbursementTotalAmount}
              refundFulfillmentToken={refundFulfillmentToken}
              refundBreakdown={refundBreakdown}
              onContactSupport={openCustomerSupport}
              termsLink={termsAndConditions}
              policyDetails={policyDetails}
              displayExternalLinkAsModal={displayLinkAsModal}
              cancelFlowAction={props.cancelFlowAction}
            />
          )}

        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.RefundConfirmation &&
          email !== undefined && (
            <DisruptionExerciseRefundConfirmationPage
              email={email}
              productName={productName}
              handleExerciseCompleted={props.handleExerciseCompleted}
            />
          )}
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.RebookReview && (
          <DisruptionExerciseRebookOnboardingPage
            disruptionProductName={productName}
            disruptionProductLogo={IconNameEnum.disruptionProtection}
            disruptedFlight={props.disruptedFlight}
            rebookingLimit={rebookingLimit(policyDetails)}
            setRebookPlanResponse={setRebookPlanResponse}
            contractId={contractId}
            setSelectedSlice={setSelectedSlice}
            onFlightSelectContinue={onFlightSelectContinue}
            bookingReturn={isBookingReturn}
            termsLink={termsAndConditions}
            displayExternalLinkAsModal={displayLinkAsModal}
            hoursString={hoursString}
            delayHours={delayThresholdHours}
            onContactSupport={openCustomerSupport}
            handleRefundClick={handleRefundClick}
            cancelFlowAction={props.cancelFlowAction}
          />
        )}
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.SegmentSelect &&
          selectedSlice && (
            <DisruptionExerciseRebookSegmentSelectPage
              segments={selectedSlice.segments}
              disruptedFlight={props.disruptedFlight}
              airports={props.airports}
              disruptionProductName={productName}
              onFlightSelectContinue={onFlightSelectContinue}
              termsLink={termsAndConditions}
              displayExternalLinkAsModal={displayLinkAsModal}
              hoursString={hoursString}
              delayHours={delayThresholdHours}
              onSupportClick={openCustomerSupport}
              handleRefundClick={handleRefundClick}
              cancelFlowAction={props.cancelFlowAction}
            />
          )}
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.FlightSelect &&
          rebookPlanResponse && (
            <DisruptionExerciseRebookSelectFlightPage
              planResponse={rebookPlanResponse}
              setSelectedSlice={setSelectedSlice}
              airports={props.airports}
              onFlightSelectContinue={onFlightSelectContinue}
              hoursString={hoursString}
              delayHours={delayThresholdHours}
              onSupportClick={openCustomerSupport}
              termsLink={termsAndConditions}
              handleRefundClick={handleRefundClick}
              disruptionProductName={productName}
              cancelFlowAction={props.cancelFlowAction}
            />
          )}
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.ConfirmationPage &&
          email != null && (
            <DisruptionExerciseRebookConfirmationPage
              disruptionProductName={productName}
              email={email}
              shouldBookReturn={shouldBookReturn}
              rebookingLimit={rebookingLimit(policyDetails)}
              handleDoneAction={props.handleExerciseCompleted}
              onSupportClick={openCustomerSupport}
              handleRefundClick={handleRefundClick}
              hoursString={hoursString}
              delayHours={delayThresholdHours}
              termsLink={termsAndConditions}
            />
          )}
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.FlightSearch && (
          <DisruptionExerciseRebookFlightShopPage
            {...flightSearch}
            contractId={contractId}
            disruptedFlight={props.disruptedFlight}
            airports={props.airports}
            setShoppedFlight={setShoppedFlight}
            bookingReturn={isBookingReturn}
            onSupportClick={openCustomerSupport}
            handleRefundClick={handleRefundClick}
            hoursString={hoursString}
            delayHours={delayThresholdHours}
            termsLink={termsAndConditions}
            cancelFlowAction={props.cancelFlowAction}
            fetchTripDetails={props.fetchTripDetails}
          />
        )}
        {disruptionExerciseProgress ===
          DisruptionExerciseProgress.RebookConfirmation &&
          shoppedFlight.selectedFare &&
          shoppedFlight.selectedTrip &&
          email != null &&
          phoneNumber != null && (
            <DisruptionExerciseRebookCheckoutPage
              selectedFare={shoppedFlight.selectedFare}
              selectedTrip={shoppedFlight.selectedTrip}
              contractId={contractId}
              disruptedFlight={props.disruptedFlight}
              airports={props.airports}
              email={email}
              phone={phoneNumber}
              rebookingLimit={rebookingLimit(policyDetails)}
              disruptionProductName={productName}
              setRebookedFlight={setRebookedFlight}
              hoursString={hoursString}
              delayHours={delayThresholdHours}
              termsLink={termsAndConditions}
              onSupportClick={openCustomerSupport}
              handleRefundClick={handleRefundClick}
              cancelFlowAction={props.cancelFlowAction}
            />
          )}
        <DisruptionContactSupport
          open={openSupport}
          onClose={() => {
            setOpenSupport(false);
          }}
        />
      </div>
    );
  }
};
