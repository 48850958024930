import type { DisruptionQuote, Prices } from "@b2bportal/air-disruption-api";

/**
 * Returns the per-passenger premium amount from DisruptionQuote.
 * Uses pricePerPaxLocalized if available; otherwise, calculates it by dividing premiumAmountLocalized (premium for for all passengers) by members (# passnegers).
 */
export function getPerPaxPremiumAmount(
  disruptionQuote: DisruptionQuote
): Prices {
  if (disruptionQuote.pricePerPaxLocalized != null) {
    return disruptionQuote.pricePerPaxLocalized;
  }

  const { fiat, accountSpecific } = disruptionQuote.premiumAmountLocalized;
  const passengerCount = disruptionQuote.members;

  return {
    fiat: { ...fiat, value: fiat.value / passengerCount },
    accountSpecific: Object.fromEntries(
      Object.entries(accountSpecific).map(([key, accountPrice]) => [
        key,
        { ...accountPrice, value: accountPrice.value / passengerCount },
      ])
    ),
  };
}
