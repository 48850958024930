import { CallState } from "@b2bportal/core-types";
import {
  PriceFreezeShopResponseEnum,
  type PriceFreezeOffer,
} from "@b2bportal/price-freeze-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPriceFreezeOffers } from "./thunks";

export interface IFlightsPriceFreezeShopState {
  flightsPriceFreezeCallState: CallState;
  flightsPriceFreezeOffers: PriceFreezeOffer[];
  failureReason?: string;
  isLoading: boolean;
  selectedOfferId?: string;
}

const initialState: IFlightsPriceFreezeShopState = {
  flightsPriceFreezeCallState: CallState.NotCalled,
  flightsPriceFreezeOffers: [],
  isLoading: false,
};

export const flightsPriceFreezePurchaseSlice = createSlice({
  name: "flightsPriceFreezePurchase",
  initialState,
  reducers: {
    setSelectedOfferId: (state, action: PayloadAction<string>) => {
      state.selectedOfferId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchPriceFreezeOffers thunk
    builder
      .addCase(fetchPriceFreezeOffers.pending, (state) => {
        state.flightsPriceFreezeOffers = [];
        state.flightsPriceFreezeCallState = CallState.InProcess;
        state.isLoading = true;
      })
      .addCase(fetchPriceFreezeOffers.fulfilled, (state, action) => {
        if (
          action.payload.PriceFreezeShopResponse ===
          PriceFreezeShopResponseEnum.Available
        ) {
          state.flightsPriceFreezeCallState = CallState.Success;
          state.flightsPriceFreezeOffers = action.payload.offers;
        } else {
          state.flightsPriceFreezeOffers = [];
          state.flightsPriceFreezeCallState = CallState.Failed;
          state.failureReason = action.payload.reason;
        }
        state.isLoading = false;
      })
      .addCase(fetchPriceFreezeOffers.rejected, (state, action) => {
        state.flightsPriceFreezeOffers = [];
        state.flightsPriceFreezeCallState = CallState.Failed;
        state.isLoading = false;
        state.failureReason = action.error.message;
      });
  },
});

export const FlightPriceFreezePurchaseActions =
  flightsPriceFreezePurchaseSlice.actions;

export const { reducer: flightsPriceFreezePurchaseReducer } =
  flightsPriceFreezePurchaseSlice;
