import {
  type AirCancelFulfillRequest,
  type AirCancelFulfillResponse,
  AirCancelFulfillResponseEnum,
} from "@b2bportal/air-cancel-api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";

import { axiosInstance } from "../AxiosInterceptor";
import { confirmFlightCancelPath } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

/**
 * @description
 * @param {CipherText} quoteId
 */
const confirmFlightCancel = async (quoteId: string) => {
  try {
    const req: AirCancelFulfillRequest = {
      quoteId,
    };
    const res = await axiosInstance.post<AirCancelFulfillResponse>(
      confirmFlightCancelPath,
      req
    );
    const { data } = res;

    if (
      data.AirCancelFulfillResponse ===
      AirCancelFulfillResponseEnum.AirCancelFulfillFailure
    ) {
      throw data;
    }

    return data;
  } catch (e) {
    trackEvent({
      eventName: NETWORK_CALL_FAILED,
      properties: {
        url: window.location.pathname,
      },
    });

    throw e;
  }
};

export default confirmFlightCancel;
