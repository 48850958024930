import {
  IconNameEnum,
  useModuleBEM,
  useTripsStyles,
} from "@b2bportal/core-themes";
import {
  CoreTripsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { Button, ButtonTypeEnum, Icon } from "@components/ui";
import clsx from "clsx";
import defaultStyles from "./styles.module.scss";

export interface TripsAction {
  label: React.ReactNode;
  action: () => void;
}

export interface TripsCardComponentProps extends PlatformComponentProps {
  banner?: JSX.Element;
  status?: JSX.Element;
  product: JSX.Element;
  title: string;
  onClick: () => void;
  actions?: TripsAction[];
}

const COMPONENT_KEY = CoreTripsComponent.TripsCard;

export const TripsCard = ({
  banner,
  className,
  children,
  status,
  product,
  title,
  onClick,
  actions = [],
}: TripsCardComponentProps) => {
  const styles = useTripsStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={clsx(block, className)}>
      <button onClick={() => onClick()} className={cn("card-button")} />
      {banner != null ? <div className={cn("banner")}>{banner}</div> : null}
      <div className={cn("product")}>{product}</div>
      <div className={cn("title")}>{title}</div>
      {children != null ? (
        <div className={cn("product-info")}>{children}</div>
      ) : null}
      <div className={cn("action-icon-container")}>
        <Icon iconName={IconNameEnum.chevronRight} />
      </div>
      {/* {status ? <div className={cn("status")}>{status}</div> : null} */}
      {actions.length > 0 ? (
        <ul className={cn("actions")}>
          {actions.map(({ label, action }, index) => (
            <li key={index} className={cn("action-item")}>
              <Button
                buttonType={ButtonTypeEnum.Link}
                className={cn("action-button")}
                onClick={action}
              >
                {label}
              </Button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
