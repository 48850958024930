import type {
  BookedFlightItineraryWithDepartureTime,
  SingleFlightItineraryResponse,
} from "@b2bportal/air-booking-api";
import type {
  AirExchangeEligibilityQuoteSuccess,
  Airline,
  Airport,
  ExchangeScenario,
  VoidScenario,
} from "@b2bportal/air-exchange-api";
import type { AirlineMap, AirportMap } from "@b2bportal/core-flights";
import { CallState } from "@b2bportal/core-types";
import type { CipherText } from "@b2bportal/purchase-api";
import {
  createAction,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { isObject } from "lodash-es";
import mockExchangePolicy from "./mockExchangePolicy";

export interface ExchangePolicyInitialState {
  airlines: AirlineMap;
  airports: AirportMap;
  exchangePolicy?: ExchangeScenario;
  itinerary?: BookedFlightItineraryWithDepartureTime;
  itineraryFetchState: CallState;
  policyFetchState: CallState;
  quoteId?: CipherText;
  voidPolicy?: VoidScenario;
}

export const initialExchangePolicyState: ExchangePolicyInitialState = {
  airlines: {},
  airports: {},
  exchangePolicy: mockExchangePolicy.exchangeScenario, // CXP-3485: Remove mock data
  itinerary: undefined,
  itineraryFetchState: CallState.NotCalled,
  policyFetchState: CallState.NotCalled,
  quoteId: undefined,
  voidPolicy: mockExchangePolicy.voidScenario, // CXP-3485: Remove mock data
};

const SLICE_NAME = "exchangePolicy";

export const exchangePolicySlice = createSlice({
  name: SLICE_NAME,
  initialState: initialExchangePolicyState,
  reducers: {
    addAirlines(
      state,
      action: PayloadAction<Airline | Airline[] | AirlineMap>
    ) {
      const { payload: airlines } = action;

      if (isObject(airlines)) {
        if (Array.isArray(airlines)) {
          for (let i = 0; i < airlines.length; i += 1) {
            state.airlines[airlines[i].code] = airlines[i];
          }
        } else {
          Object.assign(state.airlines, airlines);
        }
      } else {
        const airline = airlines as Airline;

        state.airlines[airline.code] = airline;
      }
    },
    addAirports(
      state,
      action: PayloadAction<Airport | Airport[] | AirportMap>
    ) {
      const { payload: airports } = action;

      if (isObject(airports)) {
        if (Array.isArray(airports)) {
          for (let i = 0; i < airports.length; i += 1) {
            state.airports[airports[i].code] = airports[i];
          }
        } else {
          Object.assign(state.airports, airports);
        }
      } else {
        const airport = airports as Airport;

        state.airports[airport.code] = airport;
      }
    },
    setPolicy(
      state,
      action: PayloadAction<AirExchangeEligibilityQuoteSuccess>
    ) {
      const { exchangeScenario, quoteId, voidScenario } = action.payload;

      if (quoteId != null) {
        state.quoteId = { value: quoteId };
      }

      state.exchangePolicy = exchangeScenario;
      state.policyFetchState = CallState.Success;
      state.voidPolicy = voidScenario;
    },
    setPrevItinerary(
      state,
      action: PayloadAction<SingleFlightItineraryResponse>
    ) {
      const { itinerary, context } = action.payload;

      state.airlines = context.airlines as unknown as Record<string, Airline>;
      state.airports = context.airports;
      state.itinerary = itinerary;
      state.itineraryFetchState = CallState.Success;
    },
  },
});

export const getExchangeContext = createAction<string>(
  `${SLICE_NAME}/getExchangeContext`
);

export const { addAirlines, addAirports, setPolicy, setPrevItinerary } =
  exchangePolicySlice.actions;

export default exchangePolicySlice.reducer;
