// Taken from https://hopper-jira.atlassian.net/wiki/spaces/B2BTP/pages/5966725146/Air+Tracking+Spec

import { createTrackingEmitterHooks } from "@b2bportal/core-tracking";
import type { FlightAppliedFilterRawEvent } from "./FlightAppliedFilterRawEvent";
import type { FlightChangedTripTypeRawEvent } from "./FlightChangedTripTypeRawEvent";
import { FlightCommonTrackingRawEventName } from "./FlightCommonTrackingRawEventName";
import type { FlightEntryRawEvent } from "./FlightEntryRawEvent";
import { FlightSearchTrackingRawEventName } from "./FlightSearchTrackingRawEventName";
import type { FlightSelectedRawEvent } from "./FlightSelectedRawEvent";
import { FlightShopTrackingRawEventName } from "./FlightShopTrackingRawEventName";
import type { FlightViewedAmenityReviewRawEvent } from "./FlightViewedAmenityReviewRawEvent";
import type { FlightViewedForecastRawEvent } from "./FlightViewedForecastRawEvent";
import type { FlightViewedListRawEvent } from "./FlightViewedListRawEvent";
import type { FlightViewedShopLoadingRawEvent } from "./FlightViewedShopLoadingRawEvent";
import type { FlightViewedSliceRawEvent } from "./FlightViewedSliceRawEvent";
import type { FlightViewedTripSummaryRawEvent } from "./FlightViewedTripSummaryRawEvent";

export const FlightRawEventName = {
  ...FlightCommonTrackingRawEventName,
  ...FlightSearchTrackingRawEventName,
  ...FlightShopTrackingRawEventName,
};

export type FlightCoreRawEvent =
  | FlightEntryRawEvent
  | FlightSelectedRawEvent
  | FlightViewedAmenityReviewRawEvent
  | FlightViewedForecastRawEvent
  | FlightViewedListRawEvent
  | FlightViewedShopLoadingRawEvent
  | FlightViewedSliceRawEvent
  | FlightViewedTripSummaryRawEvent
  | FlightChangedTripTypeRawEvent
  | FlightAppliedFilterRawEvent;

export const {
  useEmitRawEvent: useEmitCoreFlightRawEvent,
  useEmitRawEventOnce: useEmitCoreFlightRawEventOnce,
} = createTrackingEmitterHooks<FlightCoreRawEvent>();
