import type { Traveler } from "@b2bportal/traveler-api";
import {
  getChildState,
  getNestedChildState,
  getParentState,
} from "@checkout/helpers";
import { TravelerSelectors } from "@checkout/states";
import { getQuoteBreakdownFlightPricing } from "@checkout/states/Products/Flight/selectors";
import { ParentState } from "@checkout/types";
import { getDateTimeWithFormat } from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import type { State } from "xstate";
import type { PartialParentWithContactAndTraveler } from "../context";
import {
  ContactAndTravelerInformationState,
  ContactAndTravelerInformationChildState,
} from "../types";

type ContactAndTravelerStateType = State<PartialParentWithContactAndTraveler>;
type ContactAndTravelerStateWithoutValue = Pick<
  ContactAndTravelerStateType,
  "context"
>;

export const getIsTravelerInformationLoading = (
  state: ContactAndTravelerStateType
) => {
  const childState = getChildState(
    state.value
  ) as ContactAndTravelerInformationState;
  const nestedChildState = getNestedChildState(
    state.value
  ) as ContactAndTravelerInformationChildState;

  return (
    childState === ContactAndTravelerInformationState.loading ||
    [
      ContactAndTravelerInformationChildState.add,
      ContactAndTravelerInformationChildState.delete,
      ContactAndTravelerInformationChildState.update,
    ].includes(nestedChildState)
  );
};

export const getOpenTravelerFormModal = (state: ContactAndTravelerStateType) =>
  ContactAndTravelerInformationState.travelerForm ===
  (getChildState(state.value) as ContactAndTravelerInformationState);

export const getOpenTravelerDeleteModal = (
  state: ContactAndTravelerStateType
) =>
  ContactAndTravelerInformationState.deleteModal ===
  (getChildState(state.value) as ContactAndTravelerInformationState);

export const getOpenTravelerPicker = (state: ContactAndTravelerStateType) =>
  (getChildState(state.value) as ContactAndTravelerInformationState) ===
  ContactAndTravelerInformationState.travelerPicker;

export const getTravelerErrorOpen = (state: ContactAndTravelerStateType) => {
  const parentStateValue = getParentState(state.value);
  const stateValue = getChildState(state.value);

  const isErrorState =
    parentStateValue === ParentState.travelerInformation &&
    ContactAndTravelerInformationState.error === stateValue;
  return isErrorState;
};

export const getSelectedInfantTravelerIds = (
  state: ContactAndTravelerStateWithoutValue
) => state.context[ParentState.travelerInformation]?.selectedLapInfantIds;

export const getNumTravelerAlertDismissed = ({
  context,
}: ContactAndTravelerStateWithoutValue) =>
  context[ParentState.travelerInformation].numTravelerAlertDismissed;

export const getIsLapInfant = (traveler: Traveler) => {
  const age = dayjs().diff(
    getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  return age < 2;
};

export const mergePassengersAndBreakdownPersons = (state) => {
  const selectedPassengers = TravelerSelectors.getAllSelectedTravelers(state);
  const pricingSummary = getQuoteBreakdownFlightPricing(state);
  const personWithPassengerType = pricingSummary?.pricingByPassenger.reduce(
    (allPax, pax) => ({ ...allPax, [pax.person.id]: pax.passengerType }),
    {} as Record<string, string>
  );

  return selectedPassengers.map((passenger) => {
    const passengerType = personWithPassengerType?.[passenger.id];
    if (passengerType) {
      return { ...passenger, passengerType };
    }
    return passenger;
  });
};
