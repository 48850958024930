import { useRef, useEffect } from "react";
import type { AEvent } from "../createUseEmitRawEvent";
import { useEmitRawEvent } from "./useEmitRawEvent";

export const useEmitRawEventOnce = <TRawEvent extends AEvent>(
  event: TRawEvent,
  condition = true
) => {
  const isTracked = useRef(false);

  const emitRawEvent = useEmitRawEvent(event.name);
  useEffect(() => {
    if (condition && !isTracked.current) {
      isTracked.current = true;
      emitRawEvent(event);
    }
  }, [condition, emitRawEvent, event]);
};
