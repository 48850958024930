import { initializeLogs, initializeRum } from "@hopper-b2b/datadog";
import { ClientName } from "@hopper-b2b/types";
import { Environment } from "@hopper-b2b/utilities";
import { inspect } from "@xstate/inspect";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import "./nubank.index.scss";
import { Root } from "./Root";
import * as serviceWorker from "./serviceWorker";
import config from "./utils/config";

const isXstateDebuggerEnabled =
  import.meta.env.VITE_SHOW_XSTATE_DEBUGGER === "true";

!import.meta.env.PROD &&
  isXstateDebuggerEnabled &&
  inspect({
    iframe: false,
  });

const sessionId = uuidv4();

initializeLogs({
  sessionId,
  env: config.ENV,
  version: config.REACT_APP_VERSION ?? `local-${sessionId}`,
  service: ClientName.NUBANK,
  beforeSend: (log) => {
    log.browser_session_id = sessionId;
    log.path = window.location.pathname;
    return true;
  },
});

initializeRum({
  env: config.ENV,
  version: config.REACT_APP_VERSION ?? `local-${sessionId}`,
  service: ClientName.NUBANK,
  enableSessionRecording: true,
  allowedTracingUrls: [
    "http://localhost:3003",
    "https://nubank.eng.app-development.h4r.io/",
    "https://nubank.staging.hopper.com/",
    "https://nubank.hopper.com/",
  ],
});

// Only use MOCK mode if enabled and not in production
if (config.ENV !== Environment.production && config.MOCK) {
  import("@hopper-b2b/mock").then(({ setupTenantWorker }) => {
    setupTenantWorker(ClientName.NUBANK).start();
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
