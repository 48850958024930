import type { Failure } from "@b2bportal/air-booking-api";
import { setContextWithKey } from "@checkout/helpers";
import { ParentState } from "@checkout/index";
import type {
  SetCurrentPassengerEvent,
  ValidateAndFetchTripPricingEvent,
} from "@checkout/states/common/PassengerInformation/events";
import { type PassengerError, TravelerErrorModalTypes } from "@checkout/types";
import { assign, type DoneInvokeEvent } from "xstate";
import type { PartialParentWithContactAndTraveler } from "../context";

export const actions = {
  onFetchTripPricing: assign(
    (
      ctx: PartialParentWithContactAndTraveler,
      event: DoneInvokeEvent<ValidateAndFetchTripPricingEvent>
    ) => {
      const [, tripPricingEvent] = event.data;
      return setContextWithKey(ctx, "common.tripPricing", tripPricingEvent);
    }
  ),

  setValidated: assign(
    (
      ctx: PartialParentWithContactAndTraveler,
      event: DoneInvokeEvent<Failure>
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.isValidated`,
        true
      )
  ),

  onValidateAndTripPricingError: assign(
    (
      ctx: PartialParentWithContactAndTraveler,
      event: DoneInvokeEvent<PassengerError | Failure>
    ) => {
      const errorData = event.data;
      const isValidateError = (errorData as PassengerError)?.type;
      const tripPricingErrors = (errorData as Failure)?.errors;

      if (isValidateError) {
        return setContextWithKey(
          ctx,
          `${ParentState.travelerInformation}.error`,
          {
            type: (errorData as PassengerError).type,
            data: (errorData as PassengerError).data,
          }
        );
      } else if (tripPricingErrors) {
        return setContextWithKey(
          ctx,
          `${ParentState.travelerInformation}.error`,
          {
            type: TravelerErrorModalTypes.GenericError,
            data: tripPricingErrors,
          }
        );
      }
      return ctx;
    }
  ),

  clearPassengerInformationError: assign(
    (ctx: PartialParentWithContactAndTraveler, _event) => {
      ctx[ParentState.travelerInformation].infantOnlyError = false;
      ctx[ParentState.travelerInformation].error = undefined;
      return ctx;
    }
  ),

  dismissNumPassengerAlert: assign(
    (
      ctx: PartialParentWithContactAndTraveler,
      _event: SetCurrentPassengerEvent
    ) =>
      setContextWithKey(
        ctx,
        `${ParentState.travelerInformation}.numPassengerAlertDismissed`,
        true
      )
  ),
};
