import type { LodgingCfarSchedulePriceQuoteRequestV1 } from "@b2bportal/lodging-cfar-api";
import { Product } from "@b2bportal/purchase-api";
import { ParentState } from "@checkout/types";
import type { State } from "xstate";
import type {
  LodgingCfarQuotedProductV1,
  PartialParentContextWithLodgingCfarV1,
} from "./types";

type CfarUpdateStateType = State<PartialParentContextWithLodgingCfarV1>;
export type CfarUpdateStateWithoutValue = Pick<CfarUpdateStateType, "context">;

export const getCfarCartUpdateRequest = (
  state: CfarUpdateStateWithoutValue
) => {
  const cfarQuoteId = getCfarQuoteId(state) ?? "";
  const params: LodgingCfarSchedulePriceQuoteRequestV1 = {
    cfarQuoteId,
    // @FLEX-TODO: Remove by completing https://hopper-jira.atlassian.net/browse/FLEX-5091
    shoppedProductId: "",
  };

  return params;
};

export const getCfarQuoteId = (
  state: CfarUpdateStateType | CfarUpdateStateWithoutValue
) => state.context[ParentState.lodgingCancelForAnyReasonV1].cfarQuoteId;

export const getSelectedCfarOffer = (state: CfarUpdateStateWithoutValue) => {
  return state.context[ParentState.lodgingCancelForAnyReasonV1].selectedOffer;
};

export const getQuotedCfarProduct = (state: CfarUpdateStateWithoutValue) => {
  return state.context[ParentState.cartQuote].quoteBreakdown?.products
    .map((x) => x.product)
    .find(
      (product): product is LodgingCfarQuotedProductV1 =>
        product.type === Product.LodgingCfar
    );
};
