import {
  createContext,
  type FC,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initReactI18next } from "react-i18next";
import { logger } from "@hopper-b2b/datadog";
import {
  type BrandNames,
  type Branding,
  I18nNamespace,
  type Translation,
  TranslationLanguage,
} from "@hopper-b2b/types";
import {
  englishStrings as en_translations,
  strings as generatedTranslations,
} from "@i18n/translations";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/en-gb";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import "dayjs/locale/fr";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import i18next, { type i18n } from "i18next";
import HttpBackend from "i18next-http-backend";
// Do not add any more translations in this module.
// See https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6597771303/
import {
  B2B_CURRENCY_PREFERENCE_KEY,
  DEV_USE_HTTP_BACKEND,
  LANGUAGE_URL_PARAMETER,
  B2B_OVERRIDE_LOCALE,
} from "./constants";
import { getLangFromStorage, setLang } from "./utils/getLang";
import { getRootLang, ROOT_LANGUAGE_REGEX } from "./utils/getRootLang";
import getTranslationBundleUrl from "./utils/getTranslationBundleUrl";

export const I18nContext = createContext<i18n | undefined>(undefined);
// This may look a redundant function definition but it is necessary for tests to mock it
export const useI18nextContext = () => useContext(I18nContext);

interface iI18nProvider {
  children: ReactNode;
  enableDebugHook?: boolean;
  branding: Branding;
  defaultLng: string;
  tenantTranslation?: Translation;
  useGeneratedTranslations?: boolean;
}

export const I18nProvider: FC<iI18nProvider> = ({
  children,
  enableDebugHook = false, // Assume we are in production if we aren't told otherwise
  defaultLng,
  branding,
  tenantTranslation = {},
  useGeneratedTranslations = false,
}: iI18nProvider) => {
  const [state, setState] = useState<i18n | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [langFromStorage, setLangFromStorage] = useState(getLangFromStorage());

  const languageParam = useMemo(() => {
    const params = new URLSearchParams(window.location?.search);
    return params?.get(LANGUAGE_URL_PARAMETER);
  }, []);

  const isLanguageSupported = useCallback(
    (lang: string) => {
      return (
        lang in TranslationLanguage &&
        branding.supportedLanguages?.some((entry) => entry.key === lang)
      );
    },
    [branding]
  );

  /**
   * Need to set the scoped variable and the session storage variable to update hooks inside this context
   * If the full language (en-US, es-MX) is supported set that otherwise check the root (en, es) and set that
   *
   * This NEEDS to be used inside this scope instead of just setLang
   */
  const setStorageLanguage = useCallback(
    (lang: string) => {
      // using getRootLang would just return what is already in storage, need to evaluate this value
      const matches = lang.match(ROOT_LANGUAGE_REGEX);
      const root = matches?.[1];
      if (isLanguageSupported(lang)) {
        setLang(lang);
        setLangFromStorage(lang);
      } else if (root != null && isLanguageSupported(root)) {
        setLang(root);
        setLangFromStorage(root);
      }
    },
    [isLanguageSupported]
  );

  /**
   * Watch for language to come from url params, and force that value into storage
   */
  useEffect(() => {
    if (languageParam && languageParam !== langFromStorage) {
      setStorageLanguage(languageParam);
    }
  }, [languageParam, setStorageLanguage, langFromStorage]);

  // Deciding the language to use to initialize i18next
  //
  // It first looks at the user's preference stored either in the localStorage
  // or in the browser settings (getLangFromStorage) and check if the language is supported
  // by the app/tenant. Note that here it checks the whole tag of the language e.g. en-AU or es-MX.
  // LocalStorage can have a language tag when a language was selected by the language selector.
  //
  // If it is not supported, it tries the "language" part of the value (i.e. "en" for "en-AU" or
  // "es" for "es-MX") and check if it is supported.
  //
  // If it is still not supported, it uses the `defaultLng` and does the same check.
  //
  // Finally, it uses the "language" part of the `defaultLng` which is the legacy behavior.
  const lang = useMemo(() => {
    if (langFromStorage != null) {
      if (isLanguageSupported(langFromStorage)) {
        return langFromStorage;
      }
      const userLangRoot = getRootLang(langFromStorage);
      if (
        userLangRoot !== langFromStorage &&
        isLanguageSupported(userLangRoot)
      ) {
        return userLangRoot;
      }
    }
    if (isLanguageSupported(defaultLng)) {
      return defaultLng;
    }
    return getRootLang(defaultLng);
  }, [isLanguageSupported, langFromStorage, defaultLng]);

  const translationLanguage = useMemo(() => {
    // Look up supported languages in the following order:
    // `lang` -> branding.default -> "en"
    return lang in TranslationLanguage
      ? TranslationLanguage[lang as TranslationLanguage]
      : branding.default != null && branding.default in TranslationLanguage
      ? TranslationLanguage[branding.default]
      : TranslationLanguage.en;
  }, [lang, branding.default]);

  useEffect(() => {
    // If branding.default was `en-AU`,   fallbackLanguages will be ["en-AU", "en"]
    // If branding.default was `es-MX`,   fallbackLanguages will be ["es-MX", "es", "en"]
    // If branding.default was `pt`,      fallbackLanguages will be ["pt, en"]
    // If branding.default was `en`,      fallbackLanguages will be ["en"]
    // If branding.default was undefined, fallbackLanguages will be ["en"]
    const fallbackLanguages: string[] = [TranslationLanguage.en];
    if (
      branding.default != null &&
      branding.default !== TranslationLanguage.en
    ) {
      const brandDefaultLanguage = branding.default;
      const primaryLanguage =
        brandDefaultLanguage.match(ROOT_LANGUAGE_REGEX)?.[1];
      if (
        brandDefaultLanguage === primaryLanguage ||
        primaryLanguage === TranslationLanguage.en
      ) {
        fallbackLanguages.unshift(brandDefaultLanguage);
      } else {
        if (primaryLanguage != null) {
          fallbackLanguages.unshift(primaryLanguage);
        }
        fallbackLanguages.unshift(brandDefaultLanguage);
      }
    }

    // If non-prod and user opts-in fetch translations from CDN instead of bundle
    if (
      enableDebugHook &&
      localStorage.getItem(DEV_USE_HTTP_BACKEND) === "active"
    ) {
      // If dev is using override locale prefer that over all else
      const bundlePath = getTranslationBundleUrl(
        enableDebugHook,
        branding.clientName,
        lang
      );
      i18next
        .use(HttpBackend)
        .use(initReactI18next)
        .init(
          {
            resources: {},
            partialBundledLanguages: true,
            ns: ["translation"],
            lng: lang,
            interpolation: { escapeValue: false },
            backend: {
              loadPath: bundlePath,
            },
          },
          (err) => {
            if (err) {
              logger()?.error("Failed to fetch translations remotely:", err);
            }
            setState(i18next);
            setLoading(false);
          }
        );
    } else {
      // Default behavior - Use bundled translations

      // New behavior:
      // if useGeneratedTranslations == true, load all translations from
      // the imported `generatedTranslations`. The translation files are
      // generated by vite plugin at build time.
      // If useGeneratedTranslations != true, load the translations in the old way.
      const i18nextInitOptions = useGeneratedTranslations
        ? {
            partialBundledLanguages: true,
            resources: generatedTranslations,
            lng: lang,
            fallbackLng: fallbackLanguages,
          }
        : {
            resources: {},
            lng: lang,
            fallbackLng: fallbackLanguages,
          };
      i18next.use(initReactI18next).init(i18nextInitOptions, (err) => {
        if (err) {
          throw new Error(err);
        }
        setState(i18next);
        setLoading(false);
      });
    }

    // <html lang="" dir="">
    document.documentElement.setAttribute("lang", i18next.language);
    document.documentElement.setAttribute("dir", i18next.dir(i18next.language));

    i18next?.services?.formatter?.addCached("daterange", (lng, options) => {
      const format = new Intl.DateTimeFormat(lng, {
        month: "short",
        day: "numeric",
        ...options,
      });
      return (val) => (format as any).formatRange(val[0], val[1]);
    });

    // Set the locale for dayjs
    dayjs.locale(lang);
    dayjs.extend(LocalizedFormat);
  }, [branding.default, lang, useGeneratedTranslations]);

  useEffect(() => {
    // Deep merge the values in the namespace.
    const deep = true;
    // Overwrite the values in the namespace.
    const overwrite = true;

    function addResourceBundles(brand: BrandNames, language: string) {
      //Brand namespace
      i18next.addResourceBundle(
        language,
        I18nNamespace.brand,
        brand,
        deep,
        overwrite
      );

      // The code in the block to load translations in the translation namespace
      // are necessary only when useGeneratedTranslations == false.
      // Loading translations in to "translation" namespace is done automatically
      // by the backend under (useGeneratedTranslations == true).
      if (!useGeneratedTranslations) {
        //Translation namespace
        const translation = getTranslations(language);
        if (translation != null) {
          i18next.addResourceBundle(
            language,
            I18nNamespace.translation,
            translation,
            deep,
            overwrite
          );
        }

        if (tenantTranslation[language] != null) {
          i18next.addResourceBundle(
            language,
            I18nNamespace.translation,
            tenantTranslation[language],
            deep,
            overwrite
          );
        }
      }
    }

    const brandForLanguage = makeBrandNames(branding, translationLanguage);
    addResourceBundles(brandForLanguage, translationLanguage);

    // The type cast is safe because we set the value explicitly above
    const fallbackLanguages = i18next.options.fallbackLng as string[];

    // Load the resources from fallback languages if it was not loaded yet
    fallbackLanguages.forEach((fallbackLng) => {
      if (!!fallbackLng && fallbackLng !== translationLanguage) {
        const brandForFallbackLanguage = makeBrandNames(branding, fallbackLng);
        addResourceBundles(brandForFallbackLanguage, fallbackLng);
      }
    });
  }, [
    // TODO: Find better way to trigger useEffect.
    // Note: As these are objects, dynamically updating values in the object would not trigger use effect.
    // No usecase currently
    branding,
    tenantTranslation,
    translationLanguage,
    useGeneratedTranslations,
  ]);

  return (
    <I18nContext.Provider value={state}>
      {loading ? null : children}
    </I18nContext.Provider>
  );
};

function getTranslations(language: string): object | undefined {
  switch (language) {
    case TranslationLanguage.en:
      return en_translations;
    default:
      return undefined;
  }
}

/**
 * Merge global attributes of Branding object with locale specific attributes
 *
 * ```
 * const branding = {
 *   clientName: "Hopper",
 *   translation: {
 *     en: { home: { title: "Hello" } },
 *     es: { home: { title: "Hola" } },
 *   }
 * }
 * const brand = makeBrandNames(branding, "es")
 * // expect(brand).toMatchObject(
 * //   { clientName: "Hopper", home: { title: "Hola" } }
 * // )
 * ```
 *
 * @param branding {Branding} root branding object made from `branding.ts`
 * @param locale {string} Language tag e.g. en-US
 * @returns {BrandNames} object that holds branded attributes
 */
export function makeBrandNames(branding: Branding, locale: string): BrandNames {
  const { [I18nNamespace.translation]: translations, ...globals } = branding;
  const obj = Object.assign({}, globals);
  delete obj.default;
  const brand = obj as unknown as BrandNames;

  const translation = lookupTranslation(branding, locale);
  if (translation != null) {
    Object.assign(brand, translation);
  }

  const currency = lookupCurrency(branding, locale);
  brand.currency = currency;

  return brand;
}

/**
 * Finds a {TranslatableBrandAttributes} object whose locale matches the selected locale
 *
 * @param branding Branding object to extract translations from
 * @param locale Language tag to look for matching translations
 */
function lookupTranslation(branding: Branding, locale: string) {
  const { [I18nNamespace.translation]: translations } = branding;

  let translation = translations[locale];
  if (translation == null) {
    // proper fallback may have to be implemented later
    // c.f. https://github.com/tc39/proposal-intl-localematcher
    const matches = locale.match(ROOT_LANGUAGE_REGEX);
    if (matches != null && matches.length > 1) {
      translation = translations[matches[1]];
    }
  }
  if (translation == null) {
    translation = translations[branding.default ?? TranslationLanguage.en];
  }

  return translation;
}

/**
 * Finds a currency object whose locale matches the selected locale
 *
 * @param branding Branding object to extract currency from
 * @param locale Language tag to look for a matching currency configuration
 */
function lookupCurrency(branding: Branding, locale: string) {
  // Return the currency if only one is supported
  if (branding.currencies.length === 1) {
    return branding.currencies[0];
  }

  // If there is a user preferred currency and it's supported, return it
  const stored = localStorage.getItem(B2B_CURRENCY_PREFERENCE_KEY);
  if (stored) {
    const preferred = branding.currencies.find((c) => c.code === stored);
    if (preferred) {
      return preferred;
    }
  }

  // Find the currency that absolutely matches the language
  let found = branding.currencies.find((c) => c.locale === locale);
  if (found) {
    return found;
  }

  // Find the currency whose language part matches the language
  found = branding.currencies.find((c) => {
    const matches = c.locale.match(ROOT_LANGUAGE_REGEX);
    if (matches != null && matches.length > 1) {
      return matches[1] === locale;
    }
    return false;
  });

  return found ?? branding.currencies[0];
}
