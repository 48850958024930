import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useI18nContext } from "@hopper-b2b/i18n";
import { MyTripsFilter } from "@hopper-b2b/types";
import {
  getEnvVariables,
  useEnableLodging,
  useEnablePriceFreeze,
  useEnablePriceWatch,
} from "@hopper-b2b/utilities";
import clsx from "clsx";
import type { IMobileFilterMenuConnectorProps } from "./container";
import "./styles.scss";

export type IMobileFilterMenuProps = IMobileFilterMenuConnectorProps;

export const MobileFilterMenu = ({
  tripsFilter,
  setTripsFilter,
  populateTripQueryParams,
}: IMobileFilterMenuProps) => {
  const { t, brand } = useI18nContext();
  const history = useHistory();
  const showFlightPriceFreeze = useEnablePriceFreeze();
  const showFlightPriceWatch =
    useEnablePriceWatch() && !brand.hideWatchesInTrips;
  // const showFlights = useEnableAirTripSearch();
  const showFlights = true;
  const showHotels = useEnableLodging();

  const onChange = useCallback(
    (tripsFilter) => {
      setTripsFilter(tripsFilter);
      populateTripQueryParams(history, { tripsFilter });
    },
    [history, populateTripQueryParams, setTripsFilter]
  );

  const tabs = useMemo(
    () => [
      {
        label: t("upcomingFilterButton"),
        value: MyTripsFilter.UPCOMING_TRIPS,
        onChange: () => onChange(MyTripsFilter.UPCOMING_TRIPS),
        className: "upcoming",
      },
      ...(showFlights
        ? [
            {
              label: t("flights"),
              value: MyTripsFilter.FLIGHTS,
              onChange: () => onChange(MyTripsFilter.FLIGHTS),
              className: "flights",
            },
          ]
        : []),
      ...(showFlightPriceFreeze
        ? [
            {
              label: t("priceFreezeButton"),
              value: MyTripsFilter.PRIZE_FREEZES,
              onChange: () => onChange(MyTripsFilter.PRIZE_FREEZES),
              className: "price-freeze",
            },
          ]
        : []),
      ...(showFlightPriceWatch
        ? [
            {
              label: t("priceWatchButton"),
              value: MyTripsFilter.WATCHED_TRIPS,
              onChange: () => onChange(MyTripsFilter.WATCHED_TRIPS),
              className: "price-watch",
            },
          ]
        : []),
      ...(showHotels
        ? [
            {
              label: t("hotels"),
              value: MyTripsFilter.HOTELS,
              onChange: () => onChange(MyTripsFilter.HOTELS),
              className: "hotels",
            },
          ]
        : []),
      {
        label: t("pastFilterButton"),
        value: MyTripsFilter.PAST_TRIPS,
        onChange: () => onChange(MyTripsFilter.PAST_TRIPS),
        className: "past",
      },
    ],
    [
      onChange,
      showFlightPriceFreeze,
      showFlightPriceWatch,
      showFlights,
      showHotels,
      t,
    ]
  );

  return (
    <div className="my-trips-filters-container">
      <div className="scrollable-filters-container">
        {tabs.map((tab, idx) => (
          <TabButton key={idx} tab={tab} selected={tripsFilter === tab.value} />
        ))}
      </div>
    </div>
  );
};

interface TabButton {
  label: string;
  value: MyTripsFilter;
  onChange: () => void;
  className: string;
}

const TabButton = ({
  tab,
  selected,
}: {
  tab: TabButton;
  selected: boolean;
}) => (
  <button
    key={tab.value}
    className={clsx(
      "my-trips-filter-button",
      getEnvVariables("clientName"),
      tab.className,
      {
        selected,
      }
    )}
    onClick={tab.onChange}
  >
    {tab.label}
  </button>
);
