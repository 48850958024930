import React from "react";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import {
  FlightFareGridItem,
  FlightShopSearch,
  FlightShopBreadcrumbs,
  FlightListNoFlights,
  FlightCardFareList,
  FlightCardFareListItem,
  ReviewFlightHeader,
  ReviewFlightSecondaryContent,
} from "@components/flights";
import { FlightSlotProvider, type FlightSlotType } from "./create";

export const defaultFlightSlots = (): FlightSlotType => ({
  [CoreFlightsComponent.FlightFareGridItem]: FlightFareGridItem,
  [CoreFlightsComponent.FlightShopSearch]: FlightShopSearch,
  [CoreFlightsComponent.FlightShopBreadcrumbs]: FlightShopBreadcrumbs,
  [CoreFlightsComponent.FlightListNoFlights]: FlightListNoFlights,
  [CoreFlightsComponent.FlightCardFareList]: FlightCardFareList,
  [CoreFlightsComponent.FlightCardFareListItem]: FlightCardFareListItem,
  [CoreFlightsComponent.ReviewFlightHeader]: ReviewFlightHeader,
  [CoreFlightsComponent.ReviewFlightSecondaryContent]:
    ReviewFlightSecondaryContent,
});

export const DefaultFlightSlotProvider = ({ children }) => {
  const context = React.useMemo(
    () => ({
      components: defaultFlightSlots(),
      providerName: "flights",
    }),
    []
  );
  return <FlightSlotProvider context={context}>{children}</FlightSlotProvider>;
};
