import { getObjectKeysAsObject } from "@checkout/helpers";
import {
  trackAddContactEvent,
  trackViewContactInformation,
} from "@checkout/tracking/contactInformation";

export const services = {
  trackViewContactInformation,
  trackAddContactEvent,
};

export const ServiceTypes = getObjectKeysAsObject(services);
