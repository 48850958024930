import type { ClientName } from "@hopper-b2b/types";
// If translation-bundle.ts does not exist run "yarn" to generate it
import { TRANSLATION_BUNDLE_NAME } from "../translations/translation-bundle";

const getTranslationBundleUrl = (
  enableDebugHook: boolean,
  tenant: ClientName,
  lang: string
) => {
  let locale = lang;
  // Temp fix to add region to language tag while we consider refactoring b2bportal to include region
  if (locale === "en") {
    locale = "en-US";
  }
  if (locale === "ja") {
    locale = "ja-JP";
  }
  const basePath = enableDebugHook
    ? `https://intl.staging.h4r.io`
    : `https://intl-cdn.hopper.com`;
  return `${basePath}/frontend%3Ab2bportal-${tenant}/${TRANSLATION_BUNDLE_NAME}/${locale}.json`;
};

export default getTranslationBundleUrl;
