import type { ReactNode } from "react";
import {
  type GetElementClassNameFn,
  type IconNameEnum,
  useFintechUiStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFintechUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
  Icon,
  IframeDialogContent,
} from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import { FintechProductInfoLinksLayout } from "../FintechProduct";
import defaultStyles from "./FintechProductInfoLinksSection.module.scss";

export interface FintechProductInfoLinksSectionProps
  extends PlatformComponentProps {
  howItWorksDialog: JSX.Element;
  viewTermsLink: string;
  layout?: FintechProductInfoLinksLayout;
  showCloseButtonInDialog?: boolean;
  suffixIcon?: { iconName: IconNameEnum; className?: string };
  prefixIcon?: { iconName: IconNameEnum; className?: string };
  displayViewTermsAsModal?: boolean;
}

export const FintechProductInfoLinksSection = (
  props: FintechProductInfoLinksSectionProps
) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProductInfoLinksSection;
  const styles = useFintechUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  const closeButton = (
    <DialogClose asChild>
      <button className={cn("close-button")}>{t("close.button")}</button>
    </DialogClose>
  );

  return (
    <div className={clsx(block, props.className)}>
      <div
        className={cn("content", {
          horizontal: props.layout === FintechProductInfoLinksLayout.Horizontal,
          vertical:
            props.layout == null ||
            props.layout === FintechProductInfoLinksLayout.Vertical,
        })}
      >
        <Dialog>
          <DialogTrigger className={cn("link-trigger")}>
            <LinkContent
              prefixIcon={props.prefixIcon}
              suffixIcon={props.suffixIcon}
              cn={cn}
            >
              {t("howItWorksDialog.dialogTrigger")}
            </LinkContent>
          </DialogTrigger>

          <DialogContent className={cn("dialog")}>
            {props.howItWorksDialog}
            {props.showCloseButtonInDialog === true && closeButton}
          </DialogContent>
        </Dialog>

        {props.displayViewTermsAsModal === true ? (
          <Dialog>
            <DialogTrigger className={cn("link-trigger")}>
              <LinkContent
                prefixIcon={props.prefixIcon}
                suffixIcon={props.suffixIcon}
                cn={cn}
              >
                {t("viewTerms")}
              </LinkContent>
            </DialogTrigger>
            <IframeDialogContent>
              <iframe src={props.viewTermsLink}></iframe>
            </IframeDialogContent>
          </Dialog>
        ) : (
          <a
            href={props.viewTermsLink}
            target="_blank"
            rel="noreferrer"
            className={cn("link-trigger")}
          >
            <LinkContent
              prefixIcon={props.prefixIcon}
              suffixIcon={props.suffixIcon}
              cn={cn}
            >
              {t("viewTerms")}
            </LinkContent>
          </a>
        )}
      </div>
    </div>
  );
};

const LinkContent = (props: {
  prefixIcon?: { iconName: IconNameEnum; className?: string };
  suffixIcon?: { iconName: IconNameEnum; className?: string };
  children: ReactNode;
  cn: GetElementClassNameFn;
}) => {
  return (
    <span className={props.cn("link-content")}>
      {props.prefixIcon != null && (
        <Icon
          iconName={props.prefixIcon.iconName}
          className={clsx(props.cn("link-icon"), props.prefixIcon.className)}
        />
      )}
      <span>{props.children}</span>
      {props.suffixIcon != null && (
        <Icon
          iconName={props.suffixIcon.iconName}
          className={clsx(props.cn("link-icon"), props.suffixIcon.className)}
        />
      )}
    </span>
  );
};
