import { type Prices, Product } from "@b2bportal/purchase-api";
import {
  type CartUpdateError,
  CartUpdateState,
  ParentState,
} from "@checkout/types";
import type { State } from "xstate";
import { getChildState } from "../../../helpers";
import { LodgingCfarSelectorsV1 } from "../../Products";
import type { CartContext } from "../context";

type CartUpdateStateType = State<CartContext>;
type CartUpdateStateWithoutValue = Pick<CartUpdateStateType, "context">;

export const getCartUpdateParams = ({
  context,
}: CartUpdateStateWithoutValue) => ({
  addPayments: context[ParentState.cartUpdate].addPayments,
  addProducts: context[ParentState.cartUpdate].addProducts,
  removeProducts: context[ParentState.cartUpdate].removeProducts,
  removePayments: context[ParentState.cartUpdate].removePayments,
});

export const getCartUpdateCallState = (state: CartUpdateStateType) =>
  state.context[ParentState.cartUpdate].callState;

export const getCartUpdateError = (
  state: CartUpdateStateType
): CartUpdateError | undefined => state.context[ParentState.cartUpdate].error;

export const getOpenCartUpdateModal = (state: CartUpdateStateType) =>
  [CartUpdateState.update, CartUpdateState.getBreakdown].includes(
    getChildState(state.value) as CartUpdateState
  ) && Object.keys(state.value).includes(ParentState.cartUpdate);

export type CartBreakdownProducts = {
  [Product.LodgingCfar]?: {
    totalPremiumAmount: Prices;
  };
};

export const getCartSelectedProducts = (
  state: LodgingCfarSelectorsV1.CfarUpdateStateWithoutValue
): CartBreakdownProducts => {
  const selectedCfarOffer =
    LodgingCfarSelectorsV1.getSelectedCfarOffer(state)?.offer;

  return {
    [Product.LodgingCfar]: selectedCfarOffer
      ? {
          totalPremiumAmount: selectedCfarOffer.premiumAmount,
        }
      : undefined,
  };
};
