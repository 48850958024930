import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, ISelectedTrip } from "@b2bportal/core-types";
import { fetchDisruptionOffers } from "../thunks";
import { toFetchDisruptionOffersParams } from "../utils";

export const useFetchDisruptionOffers = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (params: { selectedTrip: ISelectedTrip; numberOfPassengers: number }) => {
      const fetchDisruptionOffersParams = toFetchDisruptionOffersParams(
        params.selectedTrip,
        params.numberOfPassengers
      );
      if (fetchDisruptionOffersParams != null) {
        dispatch(fetchDisruptionOffers(fetchDisruptionOffersParams));
      }
    },
    [dispatch]
  );
};

// TODO: remove this and refactor its usage in libs/flights
export const useFetchDisruptionOffersWithParams = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (tripId: string, fareId: string, numberOfPassengers: number) => {
      const requestParams = { tripId, fareId, numberOfPassengers };
      if (requestParams != null) {
        dispatch(fetchDisruptionOffers(requestParams));
      }
    },
    [dispatch]
  );
};
