import type { IconType } from "../../context";

export enum CarIconName {
  car__passenger = "car__passenger",
  car__bags = "car__bags",
  car__transmission = "car__transmission",
  car__airConditioning = "car__airConditioning",
  car__airport = "car__airport",
  car__rentalAttribute = "car__rentalAttribute",
}

export type CarIconSet = {
  [key in CarIconName]?: IconType;
};
