import type { ReactNode } from "react";
import { FormErrorText } from "@apac/form-ui";
import { useModuleBEM } from "@b2bportal/core-themes";
import clsx from "clsx";
import styles from "./styles.module.scss";

export interface ControlledFormControlWrapperProps {
  className?: string;
  label?: ReactNode;
  disabled?: boolean;
  error?: ReactNode;
  helperText?: ReactNode;
  required?: boolean;
  children?: ReactNode;
}

export const ControlledFormControlWrapper = ({
  className,
  children,
  label,
  error,
  helperText,
  required,
}: ControlledFormControlWrapperProps): JSX.Element => {
  const [block, cn] = useModuleBEM(styles, "FormControlWrapper");
  return (
    <div className={clsx(block, className)}>
      {label && <div className={cn("label", { required })}>{label}</div>}
      {children}
      {error && <FormErrorText error={error} />}
      {helperText && <div className={cn("helper-text")}>{helperText}</div>}
    </div>
  );
};
