import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import clsx from "clsx";
import defaultStyles from "./Day.module.scss";
import { useCalendarDay, type UseCalendarDayParams } from "./hooks";

export interface IDayProps extends UseCalendarDayParams {
  dayLabel: string;
  monthLabel: string;
  className?: string;
}

export const Day = (props: IDayProps) => {
  const { dayLabel, className } = props;

  const styles = useUiStyles(CoreUiComponents.DatePickerDay, defaultStyles);
  const [block, cn] = useModuleBEM(styles, CoreUiComponents.DatePickerDay);

  const {
    context: { isFocusedDate, currentDateRef, styleConditions, disabledDate },
    handlers,
  } = useCalendarDay(props);

  return (
    <button
      className={clsx(block, className, cn("root", styleConditions))}
      aria-label={handlers.getDayAriaLabel()}
      tabIndex={isFocusedDate && !disabledDate ? 0 : -1}
      ref={!disabledDate ? currentDateRef : null}
      type="button"
      {...handlers}
    >
      <div className={cn("label-wrapper")}>
        <div className={cn("day-label")}>{dayLabel}</div>
      </div>
    </button>
  );
};

export const EmptyDayTile = () => {
  const styles = useUiStyles(CoreUiComponents.DatePickerDay, defaultStyles);
  const [block, cn] = useModuleBEM(styles, CoreUiComponents.DatePickerDay);

  return <div className={clsx(block, cn("root"), cn("empty-day-tile"))}></div>;
};
