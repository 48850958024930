import {
  createContext,
  type MutableRefObject,
  type PropsWithChildren,
  useCallback,
  useContext,
  useRef,
} from "react";
import { isEqual } from "lodash-es";
import type { UseEmitRawEvent } from "./createUseEmitRawEvent";
import { TrackingScope, useTrackingScopeContext } from "./TrackingScope";
import type { GlobalTrackingAttributes } from "./types";

export type TrackingAttributesMapRef<
  TAttributeMap extends GlobalTrackingAttributes = GlobalTrackingAttributes
> = MutableRefObject<TAttributeMap>;

const EMPTY_ATTRIBUTES: Readonly<GlobalTrackingAttributes> = Object.freeze(
  {} as GlobalTrackingAttributes
);

const TrackingProviderContext = createContext<EventTrackingContext | undefined>(
  undefined
);

export interface EventTracking {
  setGlobalAttribute: <T extends keyof GlobalTrackingAttributes>(
    attributeName: T,
    value: GlobalTrackingAttributes[T]
  ) => void;
  setGlobalAttributes: (
    newAttributes: Partial<GlobalTrackingAttributes>
  ) => void;
  getAllAttributes: () => Readonly<GlobalTrackingAttributes>;
  emitRawEventHooks: UseEmitRawEvent[];
}

export function useEventTracking(): EventTracking {
  const context =
    useContext(TrackingProviderContext) ?? fallbackToDefaultContext();
  const trackingScopeContext = useTrackingScopeContext();
  const attributesRef = useRef({
    ...context.globalAttributesRef.current,
    ...trackingScopeContext.trackingAttributes,
  });

  const getAllAttributes = useCallback(() => {
    const allAttributes: GlobalTrackingAttributes = {
      ...context.globalAttributesRef.current,
      ...trackingScopeContext.trackingAttributes,
    };
    if (!isEqual(allAttributes, attributesRef.current)) {
      attributesRef.current = allAttributes;
    }
    return attributesRef.current;
  }, [context.globalAttributesRef, attributesRef]);

  const updateAttributeRef = useCallback(() => {
    attributesRef.current = {
      ...context.globalAttributesRef.current,
      ...trackingScopeContext.trackingAttributes,
    };
  }, [context.globalAttributesRef, trackingScopeContext.trackingAttributes]);

  const setGlobalAttributes = useCallback(
    (newAttributes: Partial<GlobalTrackingAttributes>) => {
      const updatedAttributes = {
        ...context.globalAttributesRef.current,
        ...newAttributes,
      };

      context.globalAttributesRef.current = updatedAttributes;
      updateAttributeRef();
    },
    [context.globalAttributesRef, updateAttributeRef]
  );

  const setGlobalAttribute = useCallback(
    <T extends keyof GlobalTrackingAttributes>(
      attributeName: T,
      value: GlobalTrackingAttributes[T]
    ) => {
      context.globalAttributesRef.current = {
        ...context.globalAttributesRef.current,
        [attributeName]: value,
      };
      updateAttributeRef();
    },
    [context.globalAttributesRef, updateAttributeRef]
  );

  return {
    setGlobalAttribute,
    setGlobalAttributes,
    getAllAttributes,
    emitRawEventHooks: context.emitRawEventHooks,
  };
}

export const TrackingProvider = ({
  children,
  emitRawEventHooks = [],
}: PropsWithChildren<{
  emitRawEventHooks?: UseEmitRawEvent[];
}>) => {
  const prevContext = useContext(TrackingProviderContext);

  // Use a ref to store attributes
  const globalAttributesRef =
    useRef<Readonly<GlobalTrackingAttributes>>(EMPTY_ATTRIBUTES);

  const newGlobalAttributesRef =
    prevContext?.globalAttributesRef ?? globalAttributesRef;

  const newEmitRawEventHooks = [
    ...(prevContext?.emitRawEventHooks ?? []),
    ...emitRawEventHooks,
  ];

  return (
    <TrackingProviderContext.Provider
      value={{
        globalAttributesRef: newGlobalAttributesRef,
        emitRawEventHooks: newEmitRawEventHooks,
      }}
    >
      <TrackingScope>{children}</TrackingScope>
    </TrackingProviderContext.Provider>
  );
};

function fallbackToDefaultContext(): EventTrackingContext {
  console.warn(
    `Must be used within a TrackingAttributesProvider. Add a TrackingProvider to your root App component`
  );

  return {
    globalAttributesRef: { current: EMPTY_ATTRIBUTES },
    emitRawEventHooks: [],
  };
}

interface EventTrackingContext {
  globalAttributesRef: TrackingAttributesMapRef<
    Readonly<GlobalTrackingAttributes>
  >;
  emitRawEventHooks: UseEmitRawEvent[];
}
