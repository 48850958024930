import type {
  AEvent,
  EventName,
  SmartEmitRawEvent,
} from "./createUseEmitRawEvent";
import { useEmitBeforeLoad } from "./hooks/useEmitBeforeLoad";
import { useEmitRawEvent } from "./hooks/useEmitRawEvent";
import { useEmitRawEventOnce } from "./hooks/useEmitRawEventOnce";

export interface TrackingHooks<TAnyEvent extends AEvent> {
  useEmitRawEvent: <TEventName extends EventName<TAnyEvent>>(
    eventName: TEventName
  ) => SmartEmitRawEvent<Extract<TAnyEvent, AEvent<TEventName>>>;
  useEmitRawEventOnce: (event: TAnyEvent, condition?: boolean) => void;
  useEmitBeforeLoad: (event: TAnyEvent, condition?: boolean) => void;
}

export function createTrackingEmitterHooks<
  TAnyEvent extends AEvent
>(): TrackingHooks<TAnyEvent> {
  return {
    useEmitRawEvent: useEmitRawEvent,
    useEmitRawEventOnce: useEmitRawEventOnce,
    useEmitBeforeLoad: useEmitBeforeLoad,
  };
}
