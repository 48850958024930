import type { CoreBaseColorConfig } from "./core-design-system";

export const coreDefaultBaseColors: CoreBaseColorConfig = {
  "success-fill-color": "rgba(34, 197, 94, 1.0)",
  "error-fill-color": "rgba(204, 36, 39, 1.0)",
  "caution-fill-color": "rgba(245, 158, 11, 1.0)",
  "neutral-fill-color": "rgba(103, 104, 104, 1.0)",
  "text-inverted-color": "#ffffff",

  error: "rgba(204, 36, 39, 1.0)",

  "core-color-text-primary": "#141414",
  "core-color-text-secondary": "rgba(103, 104, 104, 1.0)",
  "core-color-text-disabled": "#d7d7d7",
  "core-color-text-error": "rgba(204, 36, 39, 1.0)",

  "core-color-surface-color": "#ffffff",
  "core-color-surface-interactive": "#ffffff",
  "core-color-surface-interactive-disabled": "#f5f5f5",
  "core-color-neutral-bg": "#FFFFFF",
  "core-color-neutral-bg-fill-subtle": "#E6E6E6",
  "core-color-success-bg-fill-subtle": "rgb(199, 242, 203)",
  "core-color-success-bg-fill": "rgb(0, 136, 23)",
  "core-color-warning-bg-fill-subtle": "rgb(255, 234, 176)",
  "core-color-warning-bg-fill": "rgb(207, 113, 6)",
  "core-color-critical-bg-fill-subtle": "rgb(253, 224, 219)",
  "core-color-critical-bg-fill": "rgb(229, 34, 7)",
  "core-color-info-bg-fill-subtle": "rgb(217, 232, 246)",
  "core-color-info-bg-fill": "rgb(35, 120, 195)",

  "core-color-border": "#CECFCD",
  "core-color-border-disabled": "rgba(204, 204, 204, 1.0)",
  "core-color-border-error": "rgba(204, 36, 39, 1.0)",
  "core-color__trip-card-border--info": "rgba(115, 179, 231, 1.0)",
};
