import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

declare global {
  interface Window {
    DD_LOGS: any;
  }
}

export enum LOG_TYPE {
  error = "error",
  warn = "warn",
  info = "info",
}

// Please use the logger from "@hopper-b2b/datadog" instead
export const logger = () => {
  return window.DD_LOGS ? window.DD_LOGS.logger : undefined;
};

const isSuccessfulResponse = (responseStatus: number) => {
  return responseStatus >= 200 && responseStatus < 300;
};

export const createRequestLog = (req: AxiosRequestConfig) => {
  import.meta.env.PROD &&
    logger() &&
    logger().info(`Request: ${req.method?.toUpperCase()} ${req.url}`, {
      request: {
        URL: req.url,
        method: req.method,
      },
    });
};

export const createResponseLog = (res: AxiosResponse) => {
  import.meta.env.PROD &&
    logger() &&
    logger()[isSuccessfulResponse(res.status) ? LOG_TYPE.info : LOG_TYPE.error](
      `Response: ${res.config.method?.toUpperCase()} ${
        res.config.url
      } Status: ${res.status}`,
      {
        response: {
          URL: res.config.url,
          status: res.status,
          method: res.config.method,
        },
      }
    );
};

export const createResponseErrorLog = (error: AxiosError) => {
  if (!error || !error.config) return;

  import.meta.env.PROD &&
    logger() &&
    logger().error(
      `Response: ${error.config.method?.toUpperCase()} ${
        error.config.url
      } Status: ${error.response?.status}`,
      {
        error: {
          URL: error.config.url,
          status: error.response?.status,
          method: error.config.method,
        },
      }
    );
};
