import type { FiatPrice } from "@b2bportal/core-utilities";
import type {
  Suggestion,
  GroundSelectionEnum,
  VehicleAvailability,
} from "@b2bportal/ground-api";

// Define a type for search details
export interface SearchDetails {
  pickupDateTime: string;
  pickupLocation: Suggestion;
  dropoffType: DropoffType;
  dropoffDateTime: string;
  dropoffLocation: Suggestion;
  driverAge: number;
  searchType: GroundSelectionEnum;
}

export enum DropoffType {
  RoundTrip = "roundTrip",
  OneWay = "oneWay",
}

export interface NumberRange {
  min: number;
  max: number;
}

export interface FiatPriceRange {
  min: FiatPrice;
  max: FiatPrice;
}

export interface FilterState {
  priceRange?: NumberRange;
  vehicleClass?: string[];
  passengerQty?: string[];
  baggageQty?: string[];
  specification?: string[];
  cancellation?: string[];
  rentalCompany?: string[];
  [key: string]: string[] | NumberRange | undefined;
}

export interface FilterOptions {
  cancellation: Array<{ labelKey: string; value: string }>;
  priceRange: FiatPriceRange;
  vehicleClass: string[];
  passengerQty: string[];
  baggageQty: string[];
  rentalCompany: string[];
  specification: Array<{ labelKey: string; value: string }>;
  [key: string]:
    | string[]
    | FiatPriceRange
    | Array<{ labelKey: string; value: string }>
    | undefined;
}

export enum CarSortOptionEnum {
  Recommended = "recommended",
  Price = "price",
  Distance = "distance",
}

export type CarComparator = (
  a: VehicleAvailability,
  b: VehicleAvailability
) => number;
