import { useUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreUiComponents, type IMonthBucket } from "@b2bportal/core-types";
import { DateRangePicker, type PickerType } from "@components/ui";
import dayjs from "dayjs";
import defaultStyles from "./MonthAndDatePicker.module.scss";

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}

export interface IMonthAndDatePickerProps {
  viewType: MonthAndDatePickerType;
  header?: JSX.Element | string;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  startDateLabel?: string;
  endDateLabel?: string;
  className?: string;
  isMissingDate?: boolean;
  priceTagsElement: JSX.Element;
  showPriceTags?: boolean;
  pickerType?: PickerType;
  months: IMonthBucket[] | null;
  currency: string;
  priceTags: string[];
  preventSameDateSelection?: boolean;
}

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const {
    pickerType,
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    months,
    priceTags,
    currency,
    setStartDate,
    setEndDate,
    viewType,
    header,
    focusedMonthIndex,
    setFocusedMonthIndex,
    className,
    isMissingDate = false,
    showPriceTags = false,
    priceTagsElement,
    preventSameDateSelection,
  } = props;

  const styles = useUiStyles(
    CoreUiComponents.MonthAndDatePicker,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(styles, CoreUiComponents.MonthAndDatePicker);

  return (
    <>
      {header && (
        <div className={cn("header-container")}>
          <h2 className={cn("header-title")}>{header}</h2>
        </div>
      )}
      <DateRangePicker
        preventSameDateSelection={preventSameDateSelection}
        className={className}
        minAllowedDate={dayjs().toDate()}
        maxAllowedDate={dayjs().add(1, "year").toDate()}
        months={months}
        currency={currency ?? ""}
        startDate={startDate}
        endDate={endDate}
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        isMissingDate={isMissingDate}
        priceTagsElement={priceTagsElement}
        showPriceTags={showPriceTags}
        priceTags={priceTags}
        {...(viewType === MonthAndDatePickerType.Horizontal ||
        viewType === MonthAndDatePickerType.Column
          ? { setStartDate, setEndDate, pickerType }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Column
          ? { focusedMonthIndex, columnView: true }
          : undefined)}
        {...(viewType === MonthAndDatePickerType.Group
          ? { setFocusedMonthIndex, groupView: true }
          : undefined)}
      />
    </>
  );
};
