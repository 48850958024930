import { useMemo } from "react";
import type { FareDetailsV2, TripDetailsV2 } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { FareDetails } from "@components/flights";
import { PriceDisplay, type PriceDisplayProps } from "@components/ui";
import clsx from "clsx";
import defaultStyles from "./FlightCardFareListItem.module.scss";

type FareButtonProps = {
  className?: string;
  onClick: () => void;
  prices?: PriceDisplayProps["prices"];
} & Omit<PriceDisplayProps, "prices">;

interface FlightCardFareListItemProps extends PlatformComponentProps {
  trip: TripDetailsV2;
  fare: FareDetailsV2;
  sliceIndex: number;
  fareFormatOptions: Intl.NumberFormatOptions | undefined;
  button?: React.FC<FareButtonProps>;
  onClick: () => void;
}

export const FlightCardFareListItem = (props: FlightCardFareListItemProps) => {
  const {
    className,
    children,
    trip,
    fare,
    sliceIndex,
    fareFormatOptions,
    onClick,
    button: FareButton = ({ className, onClick, prices, ...props }) =>
      prices == null ? null : (
        <button className={className} onClick={onClick}>
          <PriceDisplay prices={prices} {...props} />
        </button>
      ),
  } = props;
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardFareListItem,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardFareListItem
  );

  const prices = useMemo(() => fare.paxPricings?.[0].pricing.total, [fare]);

  return (
    <li className={clsx(className, block)}>
      <div className={cn("fare-info")}>
        {fare.slices[sliceIndex].fareShelf?.brandName ?? ""}
      </div>
      <FareButton
        className={cn("fare-button")}
        onClick={onClick}
        prices={prices}
        formatOptions={fareFormatOptions}
      />
      <div className={cn("fare-details")}>
        <FareDetails
          segments={trip.slices[sliceIndex].segmentDetails}
          fareSlice={fare.slices[sliceIndex]}
          restrictions={fare.restrictions?.[sliceIndex]}
        />
      </div>
      {children}
    </li>
  );
};
