import type {
  DisruptionOffer,
  DisruptionQuote,
} from "@b2bportal/air-disruption-api";
import type { FintechMarketplaceProductOfferMeta } from "@b2bportal/core-fintech-ui";
import type { ExternalResource } from "@b2bportal/core-types";
import { createSelector } from "@reduxjs/toolkit";
import type { DisruptionState } from "../../../store/slice";
import { getPerPaxPremiumAmount } from "../utils";

export const getFetchDisruptionOffersCallState = (state: DisruptionState) =>
  state.disruption.disruptionOffers.fetchDisruptionOffersCallState;

export const getFetchDisruptionOffersLastRequest = (state: DisruptionState) =>
  state.disruption.disruptionOffers.lastRequest;

export const getFetchedDisruptionOffers = (state: DisruptionState) =>
  state.disruption.disruptionOffers.disruptionOffers;

export const getDisruptionOfferToShow = (
  state: DisruptionState
): DisruptionOffer | undefined =>
  state.disruption.disruptionOffers.disruptionOffers?.[0];

export const getFetchedDisruptionOfferQuotes = createSelector(
  getFetchedDisruptionOffers,
  (offers) => {
    return offers.flatMap((offer) => offer.quotes);
  }
);

export const getSelectedDisruptionOffer = (state: DisruptionState) => {
  return state.disruption.disruptionOffers.selectedDisruptionOffer;
};

export const getSelectedDisruptionTrackingProps = (state: DisruptionState) => {
  return state.disruption.disruptionOffers.selectedDisruptionOffer?.offer
    ?.quotes[0]?.trackingProperties;
};

export const getFetchedDisruptionFintechMarketplaceProductOffers =
  createSelector(
    getFetchDisruptionOffersCallState,
    getFetchedDisruptionOfferQuotes,
    (callState, quotes) =>
      ({
        state: callState,
        data: quotes.map<FintechMarketplaceProductOfferMeta<DisruptionQuote>>(
          (quote: DisruptionQuote) => ({
            premiumAmount: getPerPaxPremiumAmount(quote),
            payload: quote,
          })
        ),
      } as ExternalResource<
        Array<FintechMarketplaceProductOfferMeta<DisruptionQuote>>
      >)
  );
