import { useCallback } from "react";
import { ButtonWrap } from "@hopper-b2b/ui";
import Switch from "../Switch/component";
import styles from "./styles.module.scss";

interface ToggleRowItemProps {
  onClick: (value: boolean) => void;
  selected: boolean;
  title: string;
  subtitle?: string | JSX.Element;
}

const ToggleRowItem = ({
  onClick,
  selected,
  title,
  subtitle,
}: ToggleRowItemProps) => {
  const onClickHandler = useCallback(
    (e: Event | React.MouseEvent | React.KeyboardEvent) => {
      e.preventDefault();
      onClick(!selected);
    },
    [onClick, selected]
  );

  return (
    <ButtonWrap onClick={onClickHandler} className={styles.ToggleRowItem}>
      <div className="content">
        <p className="title">{title}</p>
        {subtitle ? <p className="subtitle">{subtitle}</p> : null}
      </div>
      <Switch checked={selected} onClick={onClickHandler} />
    </ButtonWrap>
  );
};

export default ToggleRowItem;
