declare global {
  interface Window {
    DD_LOGS: any;
  }
}

export const logger = () => {
  if (window.DD_LOGS) {
    return window.DD_LOGS.logger;
  } else {
    console.log(
      "Datadog Logger not initialized before being called. Please double check datadog is being initialized if you wish to use this logger.."
    );
    return undefined;
  }
};
