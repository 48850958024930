import { createContext, useContext } from "react";
import type {
  RenderExtraHotelAmenitiesVariant,
  RenderLodgingShopValuePropsVariant,
} from "@hopper-b2b/types";

export type CommonFeatureFlagsContextProps = {
  enableApplePay?: boolean;
  enableGooglePay?: boolean;

  // Fintech Flags
  enablePriceWatch?: boolean;
  enablePriceFreeze?: boolean;
  enablePricePrediction?: boolean;
  enableScheduleChange?: boolean;
  enableMissedConnection?: boolean;
  enableCfar?: boolean;
  enableCfarExerciseCore?: boolean;
  enableChfar?: boolean;
  enableVipSupport?: boolean;
  enableDisruptionExerciseRebooking?: boolean;
  enableDisruptionProtectionUniversalExercise?: boolean;
  enableDisruptionExerciseRebook?: boolean;
  enablePriceDropProtection?: boolean;
  enablePriceDrop?: boolean;
  hideCfarHackerFare?: boolean;
  hideChfarHackerFare?: boolean;
  hideDisruptionHackerFare?: boolean;

  // Features
  enableDarkMode?: boolean;
  enableLoadingSteps?: boolean;
  enableRetainFilters?: boolean;

  // Growth
  enableWallet?: boolean;
  enableWalletOffers?: boolean;
  enableWalletCredits?: boolean;

  enableSavePayment?: boolean;
  hideEmailOnContactInfoScreen?: boolean;
  showContactInfoInTravelerSelection?: boolean;
  // Experiment to replace Material UI Components
  replaceMaterialUI?: boolean;

  // NUBANK EXPERIMENTS
  // Black Friday Experiment (to be removed after 1/12/2024)
  enableBlackFridayExperiment?: boolean;
  // Controls number of installments users can pay in
  nubankMaxInstallments?: string;
  // Render value props in lodging shop list
  renderLodgingShopValueProps?: RenderLodgingShopValuePropsVariant;
  // Render extra amenities on room select screen
  renderExtraHotelAmenities?: RenderExtraHotelAmenitiesVariant;
  renderHotelMapAsDefaultView?: boolean;
  enableHotelDetailsCtaCopy?: boolean;
  roomDetailsCtaCopy?: string;
  enableInstallmentsCampaign?: string;
};

export type AirFeatureFlagsContextProps = CommonFeatureFlagsContextProps & {
  // CX Product
  enableAirCoreExchange?: boolean;
  enableAirAutomatedExchange?: boolean;
  enableAirExchange?: boolean;
  enableHFv2?: boolean;

  enableAirShopV4?: boolean;
  enableB6Upsell?: boolean;
  enableIataLookup?: boolean;

  // Experiments
  hideFareDetails?: boolean;
  hideSeats?: boolean;
  hideAirItineraryReview?: boolean;
  enableFintechSelection?: boolean;
  hideContactInfoScreen?: boolean;
  hideAirPriceFreezeHackerFare?: boolean;
  // Uber - experiment to render error state on fintech purchase selection screen
  enableFintechPurchaseErrorState?: boolean;
  /** experiment to only show one unique flight in the flight fare list on mobile */
  dedupeFlightsInList?: boolean;

  // Virtual Interline
  enableMergeSortingDrodpdownWithFilters?: boolean;
  enableRemoveRecommendSort?: boolean;
  /** experiment to show only one fare and change text within the shop/book flow */
  enableDisplayBasicFareOnly?: boolean;
  /** experiment to tag flights without using algomerch, and move it to the top of the list */
  enableTopFlights?: boolean;
  /** experiment to fare select flight shop row selection instead of expanding fare details*/
  enableSingleTapSelectFlight?: boolean;
  /** experiment to set maxPrice to actual Maximum after resetingAllFilter */
  enableSetMaxPriceWhenChoosingReturn?: boolean;
  /** experiment to show a different flight details summary for VI flights, not the 2 column summary/fareCard */
  enableFlightDetailsV2?: boolean;
};

export type LodgingFeatureFlagsContextProps = CommonFeatureFlagsContextProps & {
  enableLodging?: boolean;
  enableLodgingCfar?: boolean;
  enableLodgingRefundableRooms?: boolean;
  enableLodgingRefundableRoomsOrchestratorFlowWithKOandUpdatedCopy?: boolean;
  enableLodgingSelfServe?: boolean;
  enableLodgingPriceChangeBanner?: boolean;
  hideLodgingMap?: boolean;

  enableHotelPriceFreeze?: boolean;
  // Nubank - skip traveller step for users with saved traveller
  enableLodgingExpressCheckout?: boolean;

  // Features
  showLodgingRooms?: boolean;
  showHorizontalScrollListInMobileMap?: boolean;
  renderLodgingStrikethroughPrice?: boolean;
};

export type TripsFeatureFlagsContextProps = CommonFeatureFlagsContextProps & {
  enableAirTripSearch?: boolean;
};

export type FeatureFlagsContextProps = AirFeatureFlagsContextProps &
  LodgingFeatureFlagsContextProps &
  TripsFeatureFlagsContextProps;

export const FeatureFlagsContext = createContext<FeatureFlagsContextProps>({});

export const useFeatureFlagsContext = (): FeatureFlagsContextProps => {
  const ctx = useContext(FeatureFlagsContext);
  if (!ctx) {
    console.warn(`Must be used within a FeatureFlagsContext`);
  }

  return ctx as FeatureFlagsContextProps;
};

export const useAirFeatureFlagsContext = (): AirFeatureFlagsContextProps => {
  return useFeatureFlagsContext() as AirFeatureFlagsContextProps;
};
export const useLodgingFeatureFlagsContext =
  (): LodgingFeatureFlagsContextProps => {
    return useFeatureFlagsContext() as LodgingFeatureFlagsContextProps;
  };
export const useTripsFeatureFlagsContext =
  (): TripsFeatureFlagsContextProps => {
    return useFeatureFlagsContext() as TripsFeatureFlagsContextProps;
  };

// Fintech Flags

export const useEnablePriceWatch = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceWatch;
};

export const useEnableAirTripSearch = () => {
  const featureFlagsContext = useTripsFeatureFlagsContext();
  return featureFlagsContext?.enableAirTripSearch;
};

export const useEnablePriceFreeze = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceFreeze;
};

export const useEnablePricePrediction = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePricePrediction;
};

export const useEnableScheduleChange = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableScheduleChange;
};

export const useEnableMissedConnection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableMissedConnection;
};

export const useEnableCfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableCfar;
};

export const useEnableCfarExerciseCore = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableCfarExerciseCore;
};

export const useEnableRefundableRooms = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingRefundableRooms;
};

export const useEnableLodgingRefundableRoomsOrchestratorFlowWithKOandUpdatedCopy =
  () => {
    const featureFlagsContext = useFeatureFlagsContext();
    return featureFlagsContext?.enableLodgingRefundableRoomsOrchestratorFlowWithKOandUpdatedCopy;
  };

export const useEnableChfar = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableChfar;
};

export const useEnableWallet = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWallet;
};

export const useEnableWalletOffers = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWalletOffers;
};

export const useEnableWalletCredits = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableWalletCredits;
};

export const useEnableVipSupport = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableVipSupport;
};

export const useEnableDisruptionExerciseRebooking = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionExerciseRebooking;
};

export const useEnableDisruptionProtectionUniversalExercise = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionProtectionUniversalExercise;
};

export const useEnableDisruptionExerciseRebook = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDisruptionExerciseRebook;
};

export const useEnableCfarHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideCfarHackerFare;
};

export const useEnableChfarHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideChfarHackerFare;
};

export const useEnableDisruptionHackerFare = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideDisruptionHackerFare;
};

export const useEnablePriceDropProtection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enablePriceDropProtection;
};

// Features

export const useEnableDarkMode = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableDarkMode;
};

export const useEnableLoadingSteps = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLoadingSteps;
};

export const useEnableAirShopV4 = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableAirShopV4;
};

export const useEnableB6Upsell = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableB6Upsell;
};

// Virtual Interline
export const useEnableMergeSortingDrodpdownWithFilters = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableMergeSortingDrodpdownWithFilters;
};

export const useEnableRemoveRecommendSort = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableRemoveRecommendSort;
};

export const useEnableTopFlights = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableTopFlights;
};

export const useEnableDisplayBasicFareOnly = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableDisplayBasicFareOnly;
};

export const useEnableSingleTapSelectFlight = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableSingleTapSelectFlight;
};

export const useEnableSetMaxPriceWhenChoosingReturn = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableSetMaxPriceWhenChoosingReturn;
};

export const useEnableFlightDetailsV2 = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableFlightDetailsV2;
};

// Experiments

export const useHideFareDetails = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideFareDetails;
};

export const useHideSeats = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideSeats;
};

export const useHideReviewItitinerary = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideAirItineraryReview;
};

export const useEnableAirExchange = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableAirExchange;
};

export const useEnableAirCoreExchange = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableAirCoreExchange;
};

export const useEnableAirAutomatedExchange = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableAirAutomatedExchange;
};

export const useEnableFintechSelection = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableFintechSelection;
};

export const useEnableHFv2 = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableHFv2;
};

export const useHideContactInfoScreen = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideContactInfoScreen;
};

export const useHideEmailAddressOnContactInfo = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideEmailOnContactInfoScreen;
};

export const useEnableContactInfoInTravelerSelection = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.showContactInfoInTravelerSelection;
};

export const useEnableLodgingCfar = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingCfar;
};

export const useEnableLodgingSelfServe = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingSelfServe;
};

export const useEnableSavePayment = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableSavePayment;
};

export const useEnableIataLookup = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.enableIataLookup;
};

export const useHideAirPriceFreezeHackerFare = () => {
  const featureFlagsContext = useAirFeatureFlagsContext();
  return featureFlagsContext?.hideAirPriceFreezeHackerFare;
};

export const useEnableLodgingPriceChangeBanner = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingPriceChangeBanner;
};

export const useEnableApplePay = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableApplePay;
};

export const useEnableGooglePay = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableGooglePay;
};

export const useEnableLodging = () => {
  const featureFlagsContext = useLodgingFeatureFlagsContext();
  return featureFlagsContext?.enableLodging;
};

export const useEnableRetainFilters = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableRetainFilters;
};

export const useHideLodgingMap = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.hideLodgingMap;
};

export const useReplaceMaterialUI = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.replaceMaterialUI;
};

export const useRenderLodgingStrikethroughPrice = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.renderLodgingStrikethroughPrice;
};

// Nubank Black Friday Experiment (to be removed after 1/12/2024)
export const useEnableBlackFridayExperiment = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableBlackFridayExperiment;
};

// Nubank experiment - active for 30 days starting Feb. 13, 2025 - remove March 15, 2025
export const useEnableInstallmentsCampaign = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableInstallmentsCampaign;
};

export const useGetNubankMaxInstallments = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.nubankMaxInstallments;
};

export const useRenderLodgingShopValueProps = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.renderLodgingShopValueProps;
};

export const useRenderExtraHotelAmenities = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.renderExtraHotelAmenities;
};

export const useEnableLodgingExpressCheckout = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableLodgingExpressCheckout;
};

export const useRenderHotelMapAsDefaultView = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.renderHotelMapAsDefaultView;
};

export const useEnableFintechPurchaseErrorState = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableFintechPurchaseErrorState;
};

export const useDedupeFlightsInList = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.dedupeFlightsInList;
};

export const useEnableHotelDetailsCtaCopy = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.enableHotelDetailsCtaCopy;
};

export const useRoomDetailsCtaCopy = () => {
  const featureFlagsContext = useFeatureFlagsContext();
  return featureFlagsContext?.roomDetailsCtaCopy;
};
