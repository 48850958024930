import type { State } from "xstate";
import {
  getChildState,
  getNestedChildState,
  getParentState,
} from "../../../helpers";
import {
  ParentState,
  TravelerInformationChildState,
  TravelerInformationState,
} from "@checkout/types";
import type { PartialParentWithTravelerContext } from "./context";

type TravelerStateType = State<PartialParentWithTravelerContext>;
type TravelerStateWithoutValue = Pick<TravelerStateType, "context">;

export const getIsTravelerInformationLoading = (state: TravelerStateType) => {
  const childState = getChildState(state.value) as TravelerInformationState;
  const nestedChildState = getNestedChildState(
    state.value
  ) as TravelerInformationChildState;

  return (
    childState === TravelerInformationState.loading ||
    [
      TravelerInformationChildState.add,
      TravelerInformationChildState.delete,
      TravelerInformationChildState.update,
    ].includes(nestedChildState)
  );
};

export const getCurrentTraveler = (state: TravelerStateWithoutValue) =>
  state.context[ParentState.travelerInformation]?.currentTraveler;

export const getOpenTravelerFormModal = (state: TravelerStateType) =>
  TravelerInformationState.travelerForm ===
  (getChildState(state.value) as TravelerInformationState);

export const getOpenTravelerDeleteModal = (state: TravelerStateType) =>
  TravelerInformationState.deleteModal ===
  (getChildState(state.value) as TravelerInformationState);

export const getOpenTravelerPicker = (state: TravelerStateType) =>
  (getChildState(state.value) as TravelerInformationState) ===
  TravelerInformationState.travelerPicker;

export const getTravelerErrorOpen = (state: TravelerStateType) => {
  const parentStateValue = getParentState(state.value);
  const stateValue = getChildState(state.value);
  const isErrorState =
    parentStateValue === ParentState.travelerInformation &&
    TravelerInformationState.error === stateValue;
  return isErrorState;
};
export const getTravelerError = (state: TravelerStateWithoutValue) =>
  state.context[ParentState.travelerInformation]?.error;

export const getTravelerVisited = (state: TravelerStateWithoutValue) =>
  state.context[ParentState.travelerInformation]?.visited;

export const getTravelers = (state: TravelerStateWithoutValue) =>
  state.context[ParentState.travelerInformation]?.travelers;

export const getSelectedTravelerIds = (state: TravelerStateWithoutValue) =>
  state.context[ParentState.travelerInformation]?.selectedTravelerIds;

export const getAllSelectedTravelers = (state: TravelerStateWithoutValue) => {
  const selectedTravelerIds = getSelectedTravelerIds(state);
  const travelers = getTravelers(state);

  return travelers.filter((traveler) =>
    selectedTravelerIds.includes(traveler.id)
  );
};

export const getTravelerStateValidated = (state: TravelerStateWithoutValue) =>
  getSelectedTravelerIds(state).length > 0;
