export interface CheckoutPaymentProperties {
  other_payment_methods?: string[];
  preselected_payment_method?: string;
  saved_payment_methods?: string[];
  total_payment_methods?: number;
  payment_method_type?: "Card" | "APPLE_PAY" | "GOOGLE_PAY";
  amount?: number;
  usd_amount?: number;
  payment_method?: string;
  success?: boolean;
}

export enum CheckoutPaymentEvents {
  apple_pay_authentication = "apple_pay_authentication",
  dismiss_apple_pay_sheet = "dismiss_apple_pay_sheet",
  google_pay_authentication = "google_pay_authentication",
  dismiss_google_pay_sheet = "dismiss_google_pay_sheet",
}

export type ApplePayAuthenticationProperties = Pick<
  CheckoutPaymentProperties,
  "amount" | "usd_amount"
>;

export type DismissApplePaySheetProperties = Pick<
  CheckoutPaymentProperties,
  "amount" | "usd_amount" | "success"
>;

export type GooglePayAuthenticationProperties = Pick<
  CheckoutPaymentProperties,
  "amount" | "usd_amount"
>;

export type DismissGooglePaySheetProperties = Pick<
  CheckoutPaymentProperties,
  "amount" | "usd_amount" | "success"
>;

export interface ViewedContactInfoProperties {
  trip_type: string;
}

export const VIEWED_METASEARCH_LANDING = "viewed_metasearch_landing";
export const VIEWED_CONTACT_INFO = "viewed_contact_info";
export const VIEWED_PAYMENT_PAGE = "viewed_payment_page";
export const INSTALLMENT_SCREEN_SHOWN = "installment_screen_shown";
export const INSTALLMENT_SCREEN_SUBMIT = "installment_screen_submit";

export enum CommonTrackingEvents {
  user_handoff = "user_handoff",
  tapped_go_back = "tapped_go_back",
}

export enum CommonSurveyEvents {
  viewed_survey = "viewed_survey",
  exited_survey = "exited_survey",
  completed_survey = "completed_survey",
}

export interface CommonSurveyProperties {
  screen?: "booking-confirmation" | "room-selection" | "cancellation-modal";
  survey_name?:
    | "post-booking"
    | "abandonment"
    | "post-cancellation"
    | "whats-missing-room-selection";
}
