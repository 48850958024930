import { createContext, type PropsWithChildren, useContext } from "react";
import type { Amenity } from "@b2bportal/lodging-api";

export interface ITenantContextProps {
  icons?: IIconSet;
  images?: IImageSet;
}

export interface IImageSet {
  flightBookingConfirmationImage?: string;
  placeHolder?: {
    room?: string;
  };
  walletCardImage?: string;
  logo?: string;
  pageBackground?: string;
  homepageBackground?: string;
  htsPoweredByLogo?: string;
}

export type IconType = string;

export interface IIconSet {
  calendar?: IconType;
  calendarStartIcon?: IconType;
  calendarEndIcon?: IconType;
  flights?: IconType;
  guest?: IconType;
  hotels?: IconType;
  location?: IconType;
  airplane?: IconType;
  airplaneArrive?: IconType;
  airplaneDepart?: IconType;
  airplaneDouble?: IconType;
  airplaneFintech?: IconType;
  airplaneCalendar?: IconType;
  avatar?: IconType;
  bell?: IconType;
  card?: IconType;
  creditCards?: {
    visa?: IconType;
    masterCard?: IconType;
    americanExpress?: IconType;
    discover?: IconType;
    default?: IconType;
  };
  applePay?: IconType;
  googlePay?: IconType;
  google?: IconType;
  cfar?: IconType;
  cfarWhite?: IconType;
  check?: IconType;
  clock?: IconType;
  error?: IconType;
  checkmarkFilled?: IconType;
  checkmarkLightGreen?: IconType;
  checkmarkSimple?: IconType;
  chevron?: IconType;
  chevronDown?: IconType;
  chevronLeft?: IconType;
  filter?: IconType;
  flightDelay?: IconType;
  premiumDisruptionAssistance?: IconType;
  info?: IconType;
  infoQuestion?: IconType;
  locationAutocompleteMarker?: IconType;
  locationMarker?: IconType;
  lock?: IconType;
  blackLock?: IconType;
  oneway?: IconType;
  roundtrip?: IconType;
  passenger?: IconType;
  priceFreeze?: IconType;
  missedConnection?: IconType;
  shield?: IconType;
  carrotCoin?: IconType;
  wallet?: IconType;
  walletIcon?: IconType;
  warning?: IconType;
  logoSmall?: IconType;
  fareRatings?: {
    0: IconType;
    1: IconType;
    2: IconType;
    3: IconType;
    4: IconType;
  };
  amenities?: {
    [key in keyof typeof Amenity]?: IconType;
  };
  reload?: IconType;
  star?: IconType;
  bunnyWithSuitcase?: IconType;
  bunnyHappy?: IconType;
  bed?: IconType;
  close?: IconType;
  closeWhite?: IconType;
  closeInverse?: IconType;
  grayGuest?: IconType;
  grayShieldX?: IconType;
  infoWhite?: IconType;
  logoIcon?: IconType;
  greenShieldCheck?: IconType;
  copy?: IconType;
  vipIcon?: IconType;
  headset?: IconType;
  radio?: IconType;
  radioSelected?: IconType;
  loading?: IconType;
  pencil?: IconType;
  trashCan?: IconType;
  noTrips?: IconType;
  logoWhite?: IconType;
  multipleGuests?: IconType;
  back?: IconType;
  rewardsCoin?: IconType;
  settings?: IconType;
  unavailable?: IconType;
  listIcon?: IconType;
  mapIcon?: IconType;
  backWhite?: IconType;
  carouselArrowLeft?: IconType;
  carouselArrowRight?: IconType;
  skinnyCheck?: IconType;
  skinnyX?: IconType;
  purchase?: IconType;
  priceDropProtection?: IconType;
  success?: IconType;
  switch?: IconType;
  search?: IconType;
  pencilPaper?: IconType;
  layers?: IconType;
}

export interface ITenantContextProvider {
  tenantContext: ITenantContextProps;
}

const TenantContext = createContext<ITenantContextProps | undefined>(undefined);

export function useTenantContext(): ITenantContextProps {
  const ctx = useContext(TenantContext);
  if (ctx === undefined)
    throw new Error(`must be used within a TenantContextProvider`);
  return ctx;
}

export const useTenantIcons = (): IIconSet => {
  const ctx = useTenantContext();
  return ctx.icons ?? {};
};

export const useTenantImages = (): IImageSet => {
  const ctx = useTenantContext();
  return ctx.images ?? {};
};

export const TenantContextProvider = ({
  children,
  tenantContext,
}: PropsWithChildren<ITenantContextProvider>) => {
  return (
    <TenantContext.Provider value={tenantContext}>
      {children}
    </TenantContext.Provider>
  );
};
