import {
  ContactInformationActions,
  TravelerActions,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";
import { actions as apacTravelerActions } from "./travelerInfo";

export const actions = {
  ...ContactInformationActions,
  ...TravelerActions,
  ...apacTravelerActions,
};

export const ActionTypes = getObjectKeysAsObject(actions);
