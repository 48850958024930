import { useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import type { StepNavigationOptions } from "./NavigationFlowHooks";

export const getNavigateOptions = (
  replace: boolean,
  options: StepNavigationOptions = {}
) => {
  return {
    ...options.navigateOptions,
    replace,
    state: {
      ...options.navigateOptions?.state,
      ...(options.refetchData != null ? { refetchData: true } : {}),
    },
  };
};

export const useNavigateCheckRefetch = (): boolean => {
  const location = useLocation();

  return useMemo(() => Boolean(location.state?.refetchData), [location.state]);
};
