import type { Middleware } from "redux";
import type { MapState } from "@b2bportal/core-types";
import { navigateTo } from "@b2bportal/core-utilities";
import { GroundSelectionEnum } from "@b2bportal/ground-api";
import { type CarsAvailabilityActionTypes, CarsAvailabilityActions } from "..";
import type { FilterState, CarSortOptionEnum, SearchDetails } from "../types";
import { CarNavigationUtility } from "../utilities";
import { CAR_URL_PARAM_KEYS, CARS_AVAILABILITY_PATH } from "../utilities";
import type { CarsState } from "./slice";

// Type for URL Param Handler
type UrlParamHandler<T> = (payload: T) => {
  updatedUrl: URL;
  navigateWithReplace: boolean;
};

type ActionTypes = CarsAvailabilityActionTypes;

type HandlerMap = {
  [K in ActionTypes["type"]]?: UrlParamHandler<
    (ActionTypes & { type: K })["payload"]
  >;
};

const updateSearchParam = (
  updatedUrl: URL,
  key: string,
  value: string | null
) => {
  if (value !== null) {
    updatedUrl.searchParams.set(key, value);
  } else {
    updatedUrl.searchParams.delete(key);
  }
};

// Factory function to create the middleware with custom handlers
export const createCarUrlMiddleware =
  (customHandlers: HandlerMap = {}): Middleware =>
  (store) =>
  (next) =>
  (action: ActionTypes) => {
    const {
      cars: { carsAvailability },
    }: CarsState = store.getState();
    const currentUrl = new URL(window.location.toString());

    // Default handlers for each action
    const defaultHandlers: HandlerMap = {
      [CarsAvailabilityActions.setRequestedSearch.type]: (
        payload: SearchDetails
      ) => {
        const updatedUrl = new URL(currentUrl);

        const {
          pickupDateTime,
          pickupLocation,
          dropoffDateTime,
          dropoffLocation,
          driverAge,
        } = payload;
        const pickupLocationCode =
          CarNavigationUtility.placeSuggestionToUrl(pickupLocation);
        const dropoffLocationCode =
          CarNavigationUtility.placeSuggestionToUrl(dropoffLocation);
        updatedUrl.pathname = `${CARS_AVAILABILITY_PATH}/${pickupLocationCode}/${dropoffLocationCode}`;

        if (payload.searchType !== GroundSelectionEnum.Location) {
          updatedUrl.searchParams.delete(CAR_URL_PARAM_KEYS.LAT_LNG);
        }

        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.PICKUP_DATETIME,
          pickupDateTime
        );
        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.DROPOFF_DATETIME,
          dropoffDateTime
        );

        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.DRIVER_AGE,
          driverAge.toString()
        );

        return {
          updatedUrl,
          navigateWithReplace: false,
        };
      },
      [CarsAvailabilityActions.setSort.type]: (payload: CarSortOptionEnum) => {
        const updatedUrl = new URL(currentUrl);
        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.SORT_BY,
          CarNavigationUtility.isValidSort(payload) ? payload : null
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [CarsAvailabilityActions.resetFilters.type]: (_: undefined) => {
        const updatedUrl = new URL(currentUrl);
        [
          CAR_URL_PARAM_KEYS.PRICE_RANGE_MIN,
          CAR_URL_PARAM_KEYS.PRICE_RANGE_MAX,
        ].forEach((param) => updatedUrl.searchParams.delete(param));
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [CarsAvailabilityActions.setAllFilters.type]: (payload: FilterState) => {
        const updatedUrl = new URL(currentUrl);
        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.FILTERS,
          JSON.stringify(payload)
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [CarsAvailabilityActions.setMapData.type]: (payload: MapState) => {
        const updatedUrl = new URL(currentUrl);
        const { centroid, zoom } = payload;

        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.LAT_LNG,
          centroid ? `${centroid.lat},${centroid.lng}` : null
        );

        updateSearchParam(
          updatedUrl,
          CAR_URL_PARAM_KEYS.ZOOM,
          zoom ? zoom.toString() : null
        );

        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
    };

    const mergedHandlers = {
      ...defaultHandlers,
      ...customHandlers,
    };

    const handler = mergedHandlers[action.type];

    if (handler != null) {
      const { updatedUrl, navigateWithReplace } = handler(action.payload);
      if (updatedUrl.toString() !== currentUrl.toString()) {
        navigateTo(`${updatedUrl.pathname}${updatedUrl.search}`, {
          replace: navigateWithReplace,
        });
      }
    }
    return next(action);
  };
