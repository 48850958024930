import type { FiatPrice } from "@b2bportal/air-shopping-api";
import { createAction, createSlice } from "@reduxjs/toolkit";

export interface ShopInitialState {
  [prop: string]: unknown;
}

const SLICE_NAME = "exchangeShop";

const initialState: ShopInitialState = {};

/**
 * This slice's actions are only defined to dispatch sagas
 */
const shopSlice = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {},
});

export const getFlightSummaries = createAction(
  `${SLICE_NAME}/getFlightSummaries`,
  (bookingId: string, isMobile = false) => ({
    payload: {
      bookingId,
      isMobile,
    },
  })
);

export const submitExchangeReq = createAction(
  `${SLICE_NAME}/submitExchangeReq`,
  (totalPrice: FiatPrice) => ({
    payload: `${totalPrice.currencyCode}${totalPrice.value.toFixed(2)}`,
  })
);

export { initialState as shopInitialState };
export default shopSlice.reducer;
