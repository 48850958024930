import {
  type Amenities,
  type AmenitiesAndUtasV2,
  AmenityExists,
  type Uta,
  UtaAssessment,
  UtaCategory,
} from "@b2bportal/air-shopping-api";
import {
  AirRestrictionStatus,
  type IRestrictionProps,
} from "@b2bportal/core-types";
import { ensureExhaustive, typedEntries } from "@b2bportal/core-utilities";
import type { TFunction } from "i18next";

export const getRestrictionSymbol = (uta: Uta) => {
  switch (true) {
    case uta.category === UtaCategory.boarding_priority &&
      (uta.assessment === UtaAssessment.fee ||
        uta.assessment === UtaAssessment.restriction):
    case uta.category === UtaCategory.carry_on_allowance &&
      uta.assessment === UtaAssessment.restriction:
    case uta.category === UtaCategory.cancellation &&
      uta.headline === "Non-refundable": // !!! translated strings will not match this copy
      return AirRestrictionStatus.GENERIC;
    case uta.assessment === UtaAssessment.benefit:
      return AirRestrictionStatus.INCLUDED;
    case uta.assessment === UtaAssessment.fee:
      return AirRestrictionStatus.PURCHASABLE;
    case uta.assessment === UtaAssessment.restriction:
      return AirRestrictionStatus.UNAVAILABLE;
    default:
      return AirRestrictionStatus.GENERIC;
  }
};

export const collectRestrictions = (
  amenitiesUtas: AmenitiesAndUtasV2,
  getUtaCategoryLabel: (category: UtaCategory) => string | null | undefined,
  getAmenityLabel: (key: keyof Amenities) => string | null | undefined,
  options?: { includeAmenities?: boolean }
): IRestrictionProps[] => {
  const includeAmenities = options?.includeAmenities ?? true;
  const amenities = amenitiesUtas?.amenities;
  return [
    ...(amenitiesUtas?.utas.utas.map((uta) => {
      const name = getUtaCategoryLabel(uta.category);
      if (name == null) {
        return null;
      }
      return {
        name,
        // TODO: the assessment field should probably be an enum
        // Dulles for Uber is giving us incorrect info for several categories (hack)
        symbol: getRestrictionSymbol(uta),
        description: uta.description,
      };
    }) ?? []),
    ...(amenities == null || !includeAmenities
      ? []
      : typedEntries(amenities).map(
          ([key, amenity]): IRestrictionProps | null => {
            const amenityLabel = getAmenityLabel(key);
            if (amenityLabel == null) {
              return null;
            }
            return {
              name: amenityLabel,
              symbol: getAmenitiesSymbol(amenity),
              description: `${amenityLabel} - ${amenity.info.displayText}`,
            };
          }
        )),
  ].filter(
    (restriction): restriction is NonNullable<typeof restriction> =>
      restriction != null
  );
};

export const getAmenitiesSymbol = (amenity: Amenities[keyof Amenities]) => {
  if ("exists" in amenity) {
    switch (amenity.exists) {
      case AmenityExists.yes:
        return AirRestrictionStatus.INCLUDED;
      case AmenityExists.no:
        return AirRestrictionStatus.UNAVAILABLE;
      case AmenityExists.na:
        return AirRestrictionStatus.GENERIC;
      default:
        return ensureExhaustive(amenity.exists, AirRestrictionStatus.GENERIC);
    }
  } else {
    return AirRestrictionStatus.GENERIC;
  }
};

export const localizedAmenityLabel = (
  key: keyof Amenities,
  t: TFunction
): string => {
  // Explicitly use static keys instead of t(`amenitiesCategories.${key}`)
  // to help localization tools extract these keys for translation.
  switch (key) {
    case "seat":
      return t("amenitiesCategories.seat");
    case "aircraft":
      return t("amenitiesCategories.aircraft");
    case "entertainment":
      return t("amenitiesCategories.entertainment");
    case "beverage":
      return t("amenitiesCategories.beverage");
    case "wifi":
      return t("amenitiesCategories.wifi");
    case "layout":
      return t("amenitiesCategories.layout");
    case "power":
      return t("amenitiesCategories.power");
    case "freshFood":
      return t("amenitiesCategories.freshFood");
    default:
      ensureExhaustive(key);
  }
};

export const localizedUtaCategoryLabel = (
  category: UtaCategory,
  t: TFunction
): string => {
  // Explicitly use static keys instead of t(`amenityUtaCategories.${category}`)
  // to help localization tools extract these keys for translation.
  switch (category) {
    case "advance-change":
      return t("amenityUtaCategories.advance-change");
    case "boarding-priority":
      return t("amenityUtaCategories.boarding-priority");
    case "cancellation":
      return t("amenityUtaCategories.cancellation");
    case "carry-on-allowance":
      return t("amenityUtaCategories.carry-on-allowance");
    case "check-in-priority":
      return t("amenityUtaCategories.check-in-priority");
    case "checked-bag-allowance":
      return t("amenityUtaCategories.checked-bag-allowance");
    case "lounge-access":
      return t("amenityUtaCategories.lounge-access");
    case "same-day-change":
      return t("amenityUtaCategories.same-day-change");
    case "seat-selection":
      return t("amenityUtaCategories.seat-selection");
    case "upgrade-eligibility":
      return t("amenityUtaCategories.upgrade-eligibility");
    case "personal-item":
      return t("amenityUtaCategories.personal-item");
    case "refundable-fare":
      return t("amenityUtaCategories.refundable-fare");
    default:
      ensureExhaustive(category);
  }
};
