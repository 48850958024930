import React from "react";
import { IconNameEnum, useModuleBEM } from "@b2bportal/core-themes";
import { Icon } from "@components/ui";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import clsx from "clsx";
import styles from "./Accordion.module.scss";

export type AccordionTriggerProps = AccordionPrimitive.AccordionTriggerProps &
  React.RefAttributes<HTMLButtonElement>;

export const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, className, ...props }, forwardedRef) => {
  const [_, cn] = useModuleBEM(styles, "Accordion");
  return (
    <AccordionPrimitive.Header className={cn("accordion-header")}>
      <AccordionPrimitive.Trigger
        className={clsx(className, cn("accordion-trigger"))}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <Icon className={cn("icon")} iconName={IconNameEnum.chevronDown} />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});

export type AccordionContentProps = AccordionPrimitive.AccordionContentProps &
  React.RefAttributes<HTMLDivElement>;

export const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => {
  const [_, cn] = useModuleBEM(styles, "Accordion");

  return (
    <AccordionPrimitive.Content
      className={clsx(className, cn("accordion-content"))}
      {...props}
      ref={forwardedRef}
    >
      <div className="AccordionContentText">{children}</div>
    </AccordionPrimitive.Content>
  );
});

export type AccordionProps = Omit<
  AccordionPrimitive.AccordionSingleProps,
  "type"
>;

export const Accordion = ({ children, ...props }: AccordionProps) => {
  return (
    <AccordionPrimitive.Root type="single" collapsible {...props}>
      {children}
    </AccordionPrimitive.Root>
  );
};

export type AccordionMultiProps = Omit<
  AccordionPrimitive.AccordionMultipleProps,
  "type"
>;
export const AccordionMulti = ({ children, ...props }: AccordionMultiProps) => {
  return (
    <AccordionPrimitive.Root type="multiple" {...props}>
      {children}
    </AccordionPrimitive.Root>
  );
};

export const AccordionItem = AccordionPrimitive.Item;
