import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
} from "@b2bportal/air-booking-api";
import {
  type AirCancelFulfillSuccess,
  AirCancelFulfillmentPollResponseEnum,
  type AirCancelQuoteResponse,
  AirCancelQuoteResponseEnum,
  type AirCancelQuoteSuccess,
  CancelScenarioEnum,
  NonCfarEnum,
} from "@b2bportal/air-cancel-api";
import { ChatPropertiesType } from "@b2bportal/chat-api";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  confirmFlightCancel,
  getFlightCancelQuote,
  pollFlightCancelFulfill,
  trackEvent,
} from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  ClientName,
  type FlightSummaryInfo,
  type IConfirmationNumber,
  type IOpenModal,
  SelfServeEvents,
  getDepartureSlice,
  getReturnSlice,
} from "@hopper-b2b/types";
import {
  AirlineIcon,
  B2BLoadingPopup,
  ErrorPopup,
  MobileFlightSummaryRowNew,
  MobileFloatingButton,
  Slot,
  UberFlightCancelationInfo,
} from "@hopper-b2b/ui";
import {
  getEnvVariables,
  getHopperLocatorFromBookedFlightItinerary,
  openExternalLink,
  toggleAdaChat,
  useUberBridge,
} from "@hopper-b2b/utilities";
import { Box } from "@material-ui/core";

import { getTrackingNonCfar } from "../../../utils";

import "./styles.scss";

const POLICY_TIMEOUT = 30000;

export enum CancelStep {
  CancelationError,
  CancelationPending,
  CancelationFlowComplete,
  CancelationInfo,
  ConfirmCancelation,
  LoadingOrProcessing,
}

export interface ICancelFlightModalContentProps {
  airlines: Record<string, Airline>;
  airports: Record<string, Airport>;
  assets: Record<string, string>;
  flight: BookedFlightItineraryWithDepartureTime;
  isMobile?: boolean;
  onClose: (didCancel: boolean) => void;
  open: boolean;
  setCancellationCompleted: (done: boolean) => void;
  setOpenModal?: (openModal: IOpenModal) => void;
  redirectIcon?: JSX.Element;
  confirmationNumbers?: IConfirmationNumber[];
  onSupportClick?: (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => void;
}

/**
 * @description Dynamically render flight cancellation states
 * @param {ICancelFlightModalContentProps} props
 * @return {JSX.Element}
 */
const CancelFlightModalContent = (props: ICancelFlightModalContentProps) => {
  const {
    airlines,
    airports,
    assets,
    flight,
    isMobile,
    onClose,
    open,
    setCancellationCompleted,
    setOpenModal,
    confirmationNumbers,
    onSupportClick,
  } = props;

  const { t } = useI18nContext();
  const { setHeader } = useUberBridge();

  const cancelationInfoRef = useRef<AirCancelQuoteSuccess>();
  const disclaimerRef = useRef("");
  const infoItemsRef = useRef<string[]>([]);
  const policyTimeoutRef = useRef<number>(0);
  const subtitleRef = useRef<ReactNode>(t("selfServe.cancelLoading"));
  const subtitle2Ref = useRef<ReactNode>("");
  const tcHelpTextRef = useRef<ReactNode>("");
  const titleRef = useRef<string>("");
  const isNubank = getEnvVariables("clientName") === ClientName.NUBANK;

  const [didCancel, setDidCancel] = useState(false);
  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingOrProcessing
  );
  const [isTimedOut, setIsTimedOut] = useState(false);

  const clearPolicyTimeout = () => {
    const { current: policyTimeout } = policyTimeoutRef;

    if (policyTimeout) {
      clearTimeout(policyTimeout);
      setIsTimedOut(false);

      policyTimeoutRef.current = undefined;
    }
  };

  /**
   * @description Updates the redux store to close this modal
   */
  const closeModal = useCallback(
    (isCancelled = true) => {
      const agentLocator = getHopperLocatorFromBookedFlightItinerary(flight);

      if (isCancelled) {
        window.parent.postMessage(
          {
            type: "BOOKING_CANCELED",
            payload: { agentLocator },
          },
          import.meta.env["VITE_UBER_URL"]
        );
      }

      onClose(didCancel);
      if (setOpenModal) {
        setOpenModal({ type: null, selectedItinerary: null });
      }
    },
    [onClose, didCancel, setOpenModal, flight]
  );

  const getTrackingProps = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;
    if (!cancelationInfo?.cancelScenario?.CancelScenario) return;

    const { cancelScenario } = cancelationInfo;
    const { CancelScenario } = cancelScenario;

    return {
      cancel_scenario: CancelScenario,
      non_cfar:
        "NonCfar" in cancelScenario
          ? getTrackingNonCfar(cancelScenario.NonCfar)
          : "",
      penalty_amount:
        ("penaltyAmount" in cancelScenario
          ? cancelScenario.penaltyAmount
          : false) || "",
      product: "flight",
    };
  }, []);

  const getFlightSummaryInfo = useCallback(
    (isDeparture: boolean): FlightSummaryInfo | null => {
      if (flight) {
        const slice = isDeparture
          ? getDepartureSlice(flight.bookedItinerary)
          : getReturnSlice(flight.bookedItinerary);
        const departureSegment = slice?.segments.at(0);
        const arrivalSegment = slice?.segments.at(-1);
        const departure = departureSegment?.zonedScheduledDeparture;
        const arrival = arrivalSegment?.zonedScheduledArrival;
        const originLocationCode = departureSegment?.origin.locationCode;
        const destinationLocationCode =
          arrivalSegment?.destination.locationCode;
        const stops = (slice?.segments.length || 0) - 1;

        return {
          originCity: airports?.[originLocationCode || ""]?.cityName,
          destinationCity: airports?.[destinationLocationCode || ""]?.cityName,
          departure: departure || "",
          arrival: arrival || "",
          airlineName:
            airlines[departureSegment?.marketingAirline?.code || ""]
              ?.displayName || "",
          airlineCode: departureSegment?.marketingAirline?.code || "",
          isDeparture,
          title: t("originToDestination", {
            origin: airports?.[originLocationCode || ""]?.cityName,
            destination: airports?.[destinationLocationCode || ""]?.cityName,
            interpolation: { escapeValue: false },
          }),
          stops,
        };
      }
      return null;
    },
    [airlines, airports, flight, t]
  );

  /**
   * @description Part 2 of the self serve cancel flow on the BE.
   * Confirms the cancellation of the flight.
   */
  const confirmCancelation = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;

    clearPolicyTimeout();

    if (cancelationInfo && "quoteId" in cancelationInfo) {
      const { quoteId } = cancelationInfo;

      titleRef.current = "";
      infoItemsRef.current = [];
      subtitleRef.current = t("selfServe.cancelProcessing");
      setCancelStep(CancelStep.LoadingOrProcessing);

      trackEvent({
        eventName: SelfServeEvents.ConfirmCancelation,
        properties: getTrackingProps(),
      });

      confirmFlightCancel(quoteId)
        .then((cancelResponse: AirCancelFulfillSuccess) => {
          const { fulfillmentSessionId } = cancelResponse;

          return new Promise((resolve, reject) => {
            const pollingInterval = setInterval(() => {
              return pollFlightCancelFulfill(fulfillmentSessionId)
                .then((pollRes) => {
                  if (
                    pollRes.AirCancelFulfillmentPollResponse ===
                    AirCancelFulfillmentPollResponseEnum.Success
                  ) {
                    clearInterval(pollingInterval);
                    setDidCancel(true);
                    trackEvent({
                      eventName: SelfServeEvents.ResponseSuccess,
                      properties: getTrackingProps(),
                    });

                    titleRef.current = t("selfServe.cancelationComplete");
                    subtitleRef.current = t(
                      "selfServe.cancelEmailConfirmation"
                    );

                    setHeader({
                      title: t("selfServe.cancelationConfirmation"),
                    });
                    setCancellationCompleted(true);
                    resolve(setCancelStep(CancelStep.CancelationFlowComplete));
                  } else if (
                    pollRes.AirCancelFulfillmentPollResponse ===
                    AirCancelFulfillmentPollResponseEnum.Aborted
                  ) {
                    const errString = `poll_${pollRes}`;

                    setDidCancel(true);
                    clearInterval(pollingInterval);

                    trackEvent({
                      eventName: SelfServeEvents.ResponseFailure,
                      properties: {
                        ...getTrackingProps(),
                        reason: errString,
                      },
                    });

                    titleRef.current = t("selfServe.cancelationComplete");
                    subtitleRef.current = t(
                      "selfServe.cancelEmailConfirmation"
                    );

                    resolve(setCancelStep(CancelStep.CancelationFlowComplete));
                  }
                })
                .catch((e) => {
                  titleRef.current = t("selfServe.cancelErrorTitle");
                  subtitleRef.current = t("selfServe.cancelErrorSubtitle");
                  setCancelStep(CancelStep.CancelationError);
                  clearInterval(pollingInterval);
                  reject(e);
                });
            }, 3000);
          });
        })
        .catch((err) => {
          titleRef.current = t("selfServe.cancelErrorTitle");
          subtitleRef.current = t("selfServe.cancelErrorSubtitle");
          setCancelStep(CancelStep.CancelationError);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTrackingProps, t, flight]);

  const contactCustomerSupport = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;

    if (cancelationInfo && "cancelScenario" in cancelationInfo) {
      const { cancelScenario } = cancelationInfo;
      if ("NonCfar" in cancelScenario) {
        trackEvent({
          eventName: SelfServeEvents.ClickSupport,
          properties: getTrackingProps(),
        });
      }
    }
    closeModal(false);
    onSupportClick
      ? onSupportClick(
          flight.bookedItinerary.id,
          ChatPropertiesType.Air,
          "General"
        )
      : toggleAdaChat();
  }, [closeModal, onSupportClick, flight.bookedItinerary.id, getTrackingProps]);

  /**
   * @description Part 1 of the self serve cancel flow on the BE.
   * Requests the cancel info of the flight to be shown to the
   * user.
   */
  const getCancelInfo = useCallback(() => {
    clearPolicyTimeout();

    subtitleRef.current = t("selfServe.cancelLoading");
    infoItemsRef.current = [];

    setCancelStep(CancelStep.LoadingOrProcessing);

    return getFlightCancelQuote(flight.bookedItinerary.id)
      .then((cancelationInfo: AirCancelQuoteResponse) => {
        if (
          cancelationInfo.AirCancelQuoteResponse ===
          AirCancelQuoteResponseEnum.AirCancelAlreadyRequestedFailure
        ) {
          return setCancelStep(CancelStep.CancelationPending);
        } else if ("cancelScenario" in cancelationInfo) {
          let body, title;
          cancelationInfoRef.current = cancelationInfo; // save this off for confirm request
          const { cancelScenario } = cancelationInfo;
          const { CancelScenario } = cancelScenario;
          trackEvent({
            eventName: SelfServeEvents.ViewedCancelModal,
            properties: getTrackingProps(),
          });

          switch (CancelScenarioEnum[CancelScenario as CancelScenarioEnum]) {
            case CancelScenarioEnum.CancellationPending:
              setCancelStep(CancelStep.CancelationPending);
              break;
            case CancelScenarioEnum.AirlineControl:
              // flight is within 3 hours - contact airline
              if ("copy" in cancelScenario) {
                ({ body, title } = cancelScenario.copy);

                titleRef.current = title;
                subtitleRef.current = body;
                setCancelStep(CancelStep.CancelationError);
              }
              break;
            case CancelScenarioEnum.Departed:
              // flight is in the past
              titleRef.current = t("selfServe.cancelDepartedTitle");
              subtitleRef.current = t("selfServe.cancelDepartedSubTitle");
              setCancelStep(CancelStep.CancelationError);
              break;
            case CancelScenarioEnum.NonCfar: {
              if (!("NonCfar" in cancelScenario)) break;

              const { NonCfar } = cancelScenario;
              let disclaimer, importantInfo, informativeSection;

              switch (NonCfar) {
                case NonCfarEnum.ContactCustomerService: {
                  // contact customer service
                  if ("customerServiceCopy" in cancelScenario) {
                    ({ body, disclaimer, importantInfo, title } =
                      cancelScenario.customerServiceCopy);

                    if (disclaimer) {
                      disclaimerRef.current = disclaimer;
                    }

                    infoItemsRef.current = importantInfo;
                    subtitleRef.current = body;
                    titleRef.current = title;
                  }
                  break;
                }
                case NonCfarEnum.AirlineRefund:
                case NonCfarEnum.NonRefundable:
                case NonCfarEnum.PartialRefund:
                case NonCfarEnum.Ftc:
                case NonCfarEnum.FtcWithPenalty:
                case NonCfarEnum.RefundableComplex:
                case NonCfarEnum.TicketedVoid:
                default:
                  // self serve flight cancel
                  if ("cancelCopy" in cancelScenario) {
                    ({
                      body,
                      disclaimer,
                      importantInfo,
                      informativeSection,
                      title,
                    } = cancelScenario.cancelCopy);

                    infoItemsRef.current = importantInfo;
                    subtitleRef.current = body;
                    titleRef.current = title;

                    if (disclaimer) {
                      disclaimerRef.current = disclaimer;
                    }

                    if (informativeSection) {
                      tcHelpTextRef.current = informativeSection.body;
                    }
                  }
              }
              setCancelStep(CancelStep.CancelationInfo);
              break;
            }
            case CancelScenarioEnum.BookingPending:
            case CancelScenarioEnum.Canceled:
            case CancelScenarioEnum.ContainsRemarks:
            default:
              titleRef.current = t("selfServe.cancelErrorTitle");
              subtitleRef.current = t("selfServe.cancelErrorSubtitle");
              setCancelStep(CancelStep.CancelationError);
          }
        }
      })
      .catch((err) => {
        titleRef.current = t("selfServe.cancelErrorTitle");
        subtitleRef.current = t("selfServe.cancelErrorSubtitle");
        setCancelStep(CancelStep.CancelationError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight, getTrackingProps, open, t]);

  const ItinerarySummaryRow = useMemo(
    () =>
      open ? (
        <div className="uber-itinerary-data-row">
          <MobileFlightSummaryRowNew
            flightSummaryInfo={getFlightSummaryInfo(true)}
            iconSrc={assets?.airplaneDepart}
            customIcon={
              isNubank ? (
                <AirlineIcon
                  airlineCode={getFlightSummaryInfo(true)?.airlineCode}
                />
              ) : null
            }
          />
          {getReturnSlice(flight.bookedItinerary) ? (
            <MobileFlightSummaryRowNew
              flightSummaryInfo={getFlightSummaryInfo(false)}
              iconSrc={assets?.airplaneArrive}
              customIcon={
                isNubank ? (
                  <AirlineIcon
                    airlineCode={getFlightSummaryInfo(false)?.airlineCode}
                  />
                ) : null
              }
            />
          ) : null}
        </div>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flight, open]
  );

  /**
   * @description Opens airline's website in a new browser tab
   */
  const redirectToAirline = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;

    if (cancelationInfo && "cancelScenario" in cancelationInfo) {
      const { cancelScenario } = cancelationInfo;
      let airlineWebLink;

      if (cancelScenario && "airlines" in cancelScenario) {
        const { airlines: scenarioAirlines } = cancelScenario;
        const airlineCode = scenarioAirlines[0]?.code;

        if (airlineCode) {
          airlineWebLink =
            airlines[airlineCode]?.webLinks?.manageBooking ||
            airlines[airlineCode]?.webLinks?.homePage;
        }
      }
      if (airlineWebLink) {
        trackEvent({
          eventName: SelfServeEvents.RedirectToAirline,
          properties: getTrackingProps(),
        });

        openExternalLink(airlineWebLink);
      }
    }
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airlines]);

  /**
   * @description Renders icon, title, subtitle, actions in a column
   * @return {ReactNode}
   */
  const renderCancelationError = useCallback(() => {
    const { current: cancelationInfo } = cancelationInfoRef;
    let isRedirect = false;

    if (cancelationInfo && "cancelScenario" in cancelationInfo) {
      const { cancelScenario } = cancelationInfo;
      isRedirect =
        cancelScenario.CancelScenario === CancelScenarioEnum.AirlineControl ||
        cancelScenario.CancelScenario === CancelScenarioEnum.Departed;
    }

    return (
      <Slot
        id="self-serve-cancellation-error-screen"
        title={titleRef.current}
        subtitle={(subtitleRef.current as string) || ""}
        primaryOnClick={isRedirect ? redirectToAirline : getCancelInfo}
        primaryButtonText={
          isRedirect
            ? t("selfServe.contactAirlineButton")
            : t("selfServe.tryAgainBtn")
        }
        secondaryButtonText={t("tripReviewLinks.getHelp") || ""}
        secondaryOnClick={contactCustomerSupport}
        onBack={closeModal}
        className="error"
        modal
        component={
          <ErrorPopup
            primaryOnClick={isRedirect ? redirectToAirline : getCancelInfo}
            primaryButtonText={
              isRedirect
                ? t("selfServe.contactAirlineButton")
                : t("selfServe.tryAgainBtn")
            }
            secondaryButtonText={t("tripReviewLinks.getHelp") || ""}
            secondaryOnClick={contactCustomerSupport}
            open
            onClose={closeModal}
            title={titleRef.current}
            subtitle={(subtitleRef.current as string) || ""}
          />
        }
      />
    );
  }, [getCancelInfo, redirectToAirline, t, closeModal, contactCustomerSupport]);

  /**
   * @description Renders success icon, title, subtitle, success actions in a column
   * @return {ReactNode}
   */
  const renderCancelationFlowComplete = useCallback(() => {
    return (
      <Slot
        id="self-serve-cancellation-flight-info"
        ItinerarySummary={ItinerarySummaryRow}
        actions={
          <MobileFloatingButton
            wide
            wrapperClassName="actions-container"
            onClick={closeModal}
          >
            {t("done") || ""}
          </MobileFloatingButton>
        }
        subtitle={
          <I18nMarkup
            tKey={"selfServe.cancelEmailConfirmation"}
            replacements={{ email: flight?.emailAddress || "" }}
          />
        }
        title={titleRef.current}
        confirmationNumbers={confirmationNumbers}
        component={
          <UberFlightCancelationInfo
            ItinerarySummary={ItinerarySummaryRow}
            actions={
              <MobileFloatingButton
                wide
                wrapperClassName="actions-container"
                onClick={closeModal}
              >
                {t("done") || ""}
              </MobileFloatingButton>
            }
            subtitle={
              <I18nMarkup
                tKey={"selfServe.cancelEmailConfirmation"}
                replacements={{ email: flight?.emailAddress || "" }}
              />
            }
            title={titleRef.current}
          />
        }
      />
    );
  }, [
    ItinerarySummaryRow,
    closeModal,
    confirmationNumbers,
    flight?.emailAddress,
    t,
  ]);

  /**
   * @description Renders title, subtitle, itinerary summary, important info, and
   * actions in a left-justified column.
   * @return {ReactNode}
   */
  const renderCancelationInfo = () => {
    const { current: cancelationInfo } = cancelationInfoRef;
    if (!cancelationInfo) return;

    if (!isTimedOut) {
      policyTimeoutRef.current = window.setTimeout(() => {
        setIsTimedOut(true);
      }, POLICY_TIMEOUT);
    }

    const { cancelScenario } = cancelationInfo;
    if (!cancelScenario || !("NonCfar" in cancelScenario)) return;
    const { NonCfar } = cancelScenario;
    let PrimaryAction = null;

    const contactAirline = NonCfar === NonCfarEnum.ContactAirline;

    const contactCustomerService =
      NonCfar === NonCfarEnum.ContactCustomerService ||
      NonCfar === NonCfarEnum.RefundableComplex ||
      NonCfar === NonCfarEnum.NonCancellable;

    switch (NonCfar) {
      case NonCfarEnum.ContactAirline:
      case NonCfarEnum.NonCancellable:
        PrimaryAction = (
          <MobileFloatingButton
            wide
            wrapperClassName="actions-container"
            onClick={() => {
              clearPolicyTimeout();
              redirectToAirline();
            }}
          >
            <>
              {t("selfServe.contactAirlineButton")}
              {assets?.redirect ? (
                <img
                  src={assets.redirect}
                  alt=""
                  className="flight-cancellation-redirect-icon"
                />
              ) : (
                <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} />
              )}
            </>
          </MobileFloatingButton>
        );
        break;
      case NonCfarEnum.ContactCustomerService:
      case NonCfarEnum.RefundableComplex:
        PrimaryAction = (
          <MobileFloatingButton
            wide
            wrapperClassName="actions-container"
            onClick={() => {
              clearPolicyTimeout();
              contactCustomerSupport();
            }}
          >
            {t("contactSupport")}
          </MobileFloatingButton>
        );
        break;
      case NonCfarEnum.AirlineRefund:
      case NonCfarEnum.Ftc:
      case NonCfarEnum.FtcWithPenalty:
      case NonCfarEnum.NonRefundable:
      case NonCfarEnum.PartialRefund:
      case NonCfarEnum.TicketedVoid:
      case NonCfarEnum.TicketlessVoid:
      case NonCfarEnum.MultiTicket:
        PrimaryAction = (
          <MobileFloatingButton
            wide
            wrapperClassName="actions-container"
            onClick={() => {
              if (isTimedOut) {
                getCancelInfo();
              } else {
                setCancelStep(CancelStep.ConfirmCancelation);
              }
            }}
          >
            {isTimedOut
              ? t("cancelFlightModal.refreshPolicyBtn")
              : t("cancelFlight")}
          </MobileFloatingButton>
        );
        break;
    }

    return (
      <Slot
        id="self-serve-cancellation-flight-info"
        actions={PrimaryAction}
        disclaimer={disclaimerRef.current}
        infoItems={infoItemsRef.current}
        subtitle={subtitleRef.current}
        title={titleRef.current}
        tcHelpText={tcHelpTextRef.current}
        confirmationNumbers={confirmationNumbers}
        contactAirline={contactAirline}
        contactCustomerService={contactCustomerService}
        ItinerarySummary={ItinerarySummaryRow}
        component={
          <UberFlightCancelationInfo
            ItinerarySummary={ItinerarySummaryRow}
            actions={PrimaryAction}
            disclaimer={disclaimerRef.current}
            infoItems={infoItemsRef.current}
            subtitle={subtitleRef.current}
            tcHelpText={tcHelpTextRef.current}
          />
        }
        onClose={closeModal}
      />
    );
  };

  /**
   * @description Renders just the summary and prompts the user to confirm the
   * cancellation
   * @return {ReactNode}
   */
  const renderConfirmCancelation = useCallback(() => {
    clearPolicyTimeout();

    const { current: cancelationInfo } = cancelationInfoRef;
    if (!cancelationInfo) return;

    policyTimeoutRef.current = window.setTimeout(() => {
      setCancelStep(CancelStep.CancelationInfo);
      setIsTimedOut(true);
    }, POLICY_TIMEOUT);

    const { cancelScenario } = cancelationInfo;
    if (cancelScenario) {
      trackEvent({
        eventName: SelfServeEvents.ClickCancel,
        properties: getTrackingProps(),
      });
      let extraSubs: ReactNode = "";
      if ("cancelCopy" in cancelScenario) {
        extraSubs = cancelScenario.cancelCopy.body;
      }
      if ("cancelConfirmationCopy" in cancelScenario) {
        const { body, title } = cancelScenario.cancelConfirmationCopy;
        titleRef.current = title;
        subtitleRef.current = body;
        subtitle2Ref.current = extraSubs;
        setHeader({
          title: titleRef.current,
        });
      }
    }

    return (
      <Slot
        id="self-serve-cancellation-flight-info"
        ItinerarySummary={ItinerarySummaryRow}
        actions={
          <MobileFloatingButton
            wide
            wrapperClassName="actions-container"
            onClick={() => {
              clearPolicyTimeout();
              confirmCancelation();
            }}
          >
            {t("selfServe.confirmCancel") || ""}
          </MobileFloatingButton>
        }
        subtitle={subtitleRef.current}
        subtitle2={subtitle2Ref.current}
        title={""}
        confirmationNumbers={confirmationNumbers}
        component={
          <UberFlightCancelationInfo
            ItinerarySummary={ItinerarySummaryRow}
            actions={
              <MobileFloatingButton
                wide
                wrapperClassName="actions-container"
                onClick={() => {
                  clearPolicyTimeout();
                  confirmCancelation();
                }}
              >
                {t("selfServe.confirmCancel") || ""}
              </MobileFloatingButton>
            }
            subtitle={subtitleRef.current}
            subtitle2={subtitle2Ref.current}
            title={""}
          />
        }
      />
    );
  }, [
    ItinerarySummaryRow,
    confirmCancelation,
    confirmationNumbers,
    getTrackingProps,
    setHeader,
    t,
  ]);

  /**
   * @description Show a spinner, title, and subtitle when loading cancel info
   * or processing a cancellation request.
   */
  const renderLoadingOrProcessing = useCallback(() => {
    return (
      <B2BLoadingPopup
        open
        className="self-serve-cancel-loading"
        message={" "}
        secondaryMessage={`${subtitleRef.current}`}
        fullScreen
        popupSize={isMobile ? "mobile" : "desktop"}
      />
    );
  }, [subtitleRef, isMobile]);

  /**
   * @description Renders modal for a pending cancellation response
   * @return {ReactNode}
   */
  const renderCancelationPending = useCallback(() => {
    return (
      <Slot
        id="self-serve-cancellation-error-screen"
        title={t("selfServe.cancelPendingTitle")}
        header={t("selfServe.cancelPendingTitle")}
        subtitle={t("selfServe.cancelPendingSubtitle")}
        primaryOnClick={closeModal}
        primaryButtonText={t("backToTrips")}
        onBack={closeModal}
        className="pending"
        modal
        component={
          <ErrorPopup
            primaryOnClick={closeModal}
            primaryButtonText={t("backToTrips")}
            open
            title={t("selfServe.cancelPendingTitle")}
            subtitle={t("selfServe.cancelPendingSubtitle")}
          />
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      clearPolicyTimeout();
    };
  }, []);

  useEffect(() => {
    if (open) {
      getCancelInfo();
    }
  }, [getCancelInfo, open]);

  const ModalContent = useMemo(() => {
    if (!open) return null;

    switch (cancelStep) {
      case CancelStep.CancelationError:
        return renderCancelationError();
      case CancelStep.CancelationPending:
        return renderCancelationPending();
      case CancelStep.CancelationFlowComplete:
        return renderCancelationFlowComplete();
      case CancelStep.CancelationInfo:
        return renderCancelationInfo();
      case CancelStep.ConfirmCancelation:
        return renderConfirmCancelation();
      case CancelStep.LoadingOrProcessing:
        return renderLoadingOrProcessing();
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelStep, open]);

  if (!open) return null;

  return (
    <Box className="uber-self-serve-cancel-flight-modal-content self-serve-cancel-modal-content">
      {ModalContent}
    </Box>
  );
};

export default CancelFlightModalContent;
