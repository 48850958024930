import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import type {
  Airport,
  BookedFlightItineraryWithDepartureTime,
  OrderedFlight,
  TrackingProperties,
} from "@b2bportal/air-booking-api";
import {
  type CipherText,
  DisruptionRebookPollPQResponseEnum,
  type FareDetailsV2,
  RebookPollFulfillResponseEnum,
  type RebookPollPQSuccess,
  type RebookScheduleFulfillRequest,
  type TripDetailsV2,
  type TripSlice,
} from "@b2bportal/air-disruption-api";
import type { Fare, Trip } from "@b2bportal/air-shopping-api";
import {
  DisruptionExerciseContainer,
  DisruptionExerciseInformationCard,
  DisruptionExercisePage,
  DisruptionExercisePageContent,
} from "@b2bportal/core-disruption";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  type AppDispatch,
  CoreDisruptionComponent,
} from "@b2bportal/core-types";
import { useDeviceType } from "@b2bportal/core-utilities";
import {
  FlightCardSummary,
  FlightDetails,
  FlightRebookCheckoutFlightDetails,
} from "@components/flights";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DisruptionTrackingEvents } from "@hopper-b2b/types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { removeTimezone } from "@hopper-b2b/utilities";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { disruptionRebookPQ } from "../../../../features/exercise";
import { disruptionRebookFulfill } from "../../../../features/exercise";
import {
  DisruptionExerciseProgress,
  setDisruptionExerciseProgress,
} from "../../../../features/exercise/store/slice";
import { DisruptionExerciseCheckoutComponent } from "../../components/DisruptionExerciseCheckoutComponent";
import { DisruptionExerciseMoreInfo } from "../../components/DisruptionExerciseMoreInfo";
import { DisruptionExerciseMoreInfoType } from "../../components/DisruptionExerciseMoreInfo/DisruptionExerciseMoreInfo";
import { DisruptionExercisePageWidth } from "../../components/DisruptionExercisePage/DisruptionExercisePage";
import defaultStyles from "./DisruptionExerciseRebookCheckoutPage.module.scss";

export interface DisruptionExerciseRebookCheckoutPageProps {
  disruptedFlight: BookedFlightItineraryWithDepartureTime;
  contractId: string;
  email: string;
  phone: string;
  airports: { [key: string]: Airport };
  selectedFare: Fare;
  selectedTrip: Trip;
  disruptionProductName: string;
  rebookingLimit?: string;
  setRebookedFlight: (rebookedFlight: OrderedFlight) => void;
  termsLink: string;
  delayHours: string;
  hoursString: string;
  onSupportClick: () => void;
  handleRefundClick: () => void;
  cancelFlowAction?: () => void;
}

export const DisruptionExerciseRebookCheckoutPage = ({
  disruptedFlight,
  contractId,
  email,
  phone,
  airports,
  selectedFare,
  selectedTrip,
  disruptionProductName,
  rebookingLimit,
  setRebookedFlight,
  termsLink,
  delayHours,
  hoursString,
  onSupportClick,
  handleRefundClick,
  cancelFlowAction,
}: DisruptionExerciseRebookCheckoutPageProps) => {
  const COMPONENT_KEY =
    CoreDisruptionComponent.DisruptionExerciseRebookCheckoutPage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t, formatDateTime, formatInterval, DateTimeFormatStyle } =
    useI18nContext();
  const { isDesktopAndUp } = useDeviceType();
  const dispatch = useDispatch<AppDispatch>();
  const [rebookQuoteError, setRebookQuoteError] = useState<boolean>(false);
  const [rebookFulfillError, setRebookFulfillError] = useState<boolean>(false);
  const [tripDetails, setTripDetails] = useState<TripDetailsV2 | null>(null);
  const [fareDetails, setFareDetails] = useState<FareDetailsV2 | null>(null);
  const [quoteResponse, setQuoteResponse] =
    useState<RebookPollPQSuccess | null>(null);
  const [openBagsLearnMore, setOpenBagsLearnMore] = useState(false);
  const [openReturnLearnMore, setOpenReturnLearnMore] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>(
    t(
      "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.priceQuoteLoadingMessage"
    )
  );
  const [token, setToken] = useState<CipherText | null>(null);
  const [expandFlightDetails, setExpandFlightDetails] =
    useState<boolean>(false);

  const handleGoBack = () => {
    dispatch(
      setDisruptionExerciseProgress(DisruptionExerciseProgress.RebookReview)
    );
  };

  const handleResponse = useCallback(
    (pqResponse: RebookPollPQSuccess, token: CipherText) => {
      setToken(token);
      setQuoteResponse(pqResponse);
      setTripDetails(pqResponse.tripDetails);
      const selectedFareDetails = pqResponse.tripDetails.fareDetails.find(
        (fareDetail) => fareDetail.id === selectedFare.id
      );
      if (selectedFareDetails) {
        setFareDetails(selectedFareDetails);
      }
    },
    [setFareDetails, setTripDetails, selectedFare.id]
  );

  useEffect(() => {
    if (selectedTrip.id && selectedFare.id && contractId && email && phone) {
      setLoading(true);
      setRebookQuoteError(false);
      dispatch(
        disruptionRebookPQ({
          contractId,
          tripId: selectedTrip.id,
          fareId: selectedFare.id,
          emailAddress: email,
          phoneNumber: phone,
        })
      )
        .then((payload) => {
          const data = unwrapResult(payload);
          setLoading(false);
          if (
            data.response.DisruptionRebookPollPQResponse ===
            DisruptionRebookPollPQResponseEnum.RebookPollPQSuccess
          ) {
            handleResponse(data.response, data.token);
          } else {
            setRebookQuoteError(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setRebookQuoteError(true);
        });
    } else {
      setRebookQuoteError(true);
    }
  }, []);

  const onCloseBagsLearnMore = useCallback(
    () => setOpenBagsLearnMore(false),
    [setOpenBagsLearnMore]
  );

  const onCloseReturnLearnMore = useCallback(
    () => setOpenReturnLearnMore(false),
    [setOpenReturnLearnMore]
  );

  const onOpenReturnLearnMore = useCallback(
    () => setOpenReturnLearnMore(true),
    [setOpenReturnLearnMore]
  );

  const onOpenBagsLearnMore = useCallback(
    () => setOpenBagsLearnMore(true),
    [setOpenBagsLearnMore]
  );

  const trackExerciseEvent = useCallback((props?: TrackingProperties) => {
    if (props) {
      trackEvent({
        eventName: DisruptionTrackingEvents.DISRUPTION_POLICY_EXERCISED,
        encryptedProperties:
          props.encryptedProperties != null ? [props.encryptedProperties] : [],
        properties: props.properties,
      });
    }
  }, []);

  const fetchDisruptionRebookFulfill = useCallback(() => {
    if (contractId && token) {
      setLoadingMessage(
        t(
          "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.fulfillLoadingMessage"
        )
      );
      setLoading(true);

      const req: RebookScheduleFulfillRequest = {
        contractId,
        token: token,
      };

      return dispatch(disruptionRebookFulfill(req))
        .then((payload) => {
          const data = unwrapResult(payload);
          setLoading(false);
          if (
            data?.RebookPollFulfillResponse ===
            RebookPollFulfillResponseEnum.PollFulfillFailure
          ) {
            setRebookFulfillError(true);
          } else if (
            data?.RebookPollFulfillResponse ===
            RebookPollFulfillResponseEnum.PollFulfillSuccess
          ) {
            const rebookedFlight = data?.rebookedFlight;
            if (rebookedFlight) {
              setRebookedFlight(rebookedFlight);
              trackExerciseEvent(rebookedFlight.trackingProperties);
            }
            dispatch(
              setDisruptionExerciseProgress(
                DisruptionExerciseProgress.ConfirmationPage
              )
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setRebookFulfillError(true);
        });
    } else {
      setRebookFulfillError(true);
    }
  }, [contractId, token, dispatch, setRebookedFlight, t, trackExerciseEvent]);

  const bullets = [
    {
      text: t(
        "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.importantInfo.bulletThree"
      ),
      learnMore: {
        title: t("core-disruption.disruptionUniversalExercise.learnMore"),
        onClick: onOpenBagsLearnMore,
      },
      index: 1,
    },
    {
      text: t(
        "core-disruption.disruptionUniversalExercise.rebook.rebookOnboardingPage.importantInfo.bulletFourWithRebookLimit",
        {
          rebookLimit: rebookingLimit,
        }
      ),
      learnMore: {
        title: t("core-disruption.disruptionUniversalExercise.learnMore"),
        onClick: onOpenReturnLearnMore,
      },
      index: 2,
    },
  ];

  const bagsLearnMore = (
    <DisruptionExerciseMoreInfo
      open={openBagsLearnMore}
      onClose={onCloseBagsLearnMore}
      type={DisruptionExerciseMoreInfoType.Baggage}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      rebookingLimit={rebookingLimit}
      disruptionProductName={disruptionProductName}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
    ></DisruptionExerciseMoreInfo>
  );

  const returnLearnMore = (
    <DisruptionExerciseMoreInfo
      open={openReturnLearnMore}
      onClose={onCloseReturnLearnMore}
      type={DisruptionExerciseMoreInfoType.Return}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      rebookingLimit={rebookingLimit}
      disruptionProductName={disruptionProductName}
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
    ></DisruptionExerciseMoreInfo>
  );

  const originDestination = useMemo(() => {
    if (tripDetails) {
      const tripSlice = tripDetails.slices[0];
      const originLabel = airports[tripSlice.originCode]?.cityName;
      const destinationLabel = airports[tripSlice.destinationCode]?.cityName;
      return (
        <>
          <span
            className="flight-origin-destination"
            dangerouslySetInnerHTML={{
              __html: t(
                "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.originToDestination",
                {
                  origin: originLabel,
                  destination: destinationLabel,
                }
              ),
            }}
          ></span>
          <FlightRebookCheckoutFlightDetails
            departureTime={formatDateTime(
              dayjs(
                removeTimezone(
                  quoteResponse?.tripDetails.slices[0].departureTime ?? ""
                )
              ).toDate(),
              DateTimeFormatStyle.ShortTime
            )}
            arrivalTime={formatDateTime(
              dayjs(
                removeTimezone(
                  quoteResponse?.tripDetails.slices[0].arrivalTime ?? ""
                )
              ).toDate(),
              DateTimeFormatStyle.ShortTime
            )}
            stops={quoteResponse?.tripDetails.slices[0].stops ?? 0}
            airlineName={
              quoteResponse?.tripDetails.slices[0].segmentDetails[0]
                .airlineName ?? ""
            }
            totalDurationMinutes={formatInterval(
              Number(
                quoteResponse?.tripDetails.slices[0].totalDurationMinutes
              ) ?? 0
            )}
            destinationCode={
              quoteResponse?.tripDetails.slices[0].segmentDetails[0]
                .destinationCode ?? ""
            }
            originCode={
              quoteResponse?.tripDetails.slices[0].segmentDetails[0]
                .originCode ?? ""
            }
          />
        </>
      );
    } else {
      return <div></div>;
    }
  }, [airports, t, tripDetails]);

  const alonTravelers = disruptedFlight.bookedItinerary.passengers.alone.map(
    (passenger) => {
      return passenger.person.givenName + " " + passenger.person.surname;
    }
  );
  const travelersWithInfant =
    disruptedFlight.bookedItinerary.passengers.withLapInfants.map(
      (passenger) => {
        return (
          passenger.adult.person.givenName +
          " " +
          passenger.adult.person.surname
        );
      }
    );
  const passengers = alonTravelers.concat(travelersWithInfant);

  const getFlightCardProps = (slice: TripSlice) => {
    const totalDurationMinutes = slice.totalDurationMinutes;
    const departureTime = dayjs(removeTimezone(slice.departureTime));
    const arrivalTime = dayjs(removeTimezone(slice.arrivalTime));
    return {
      originCode: slice.originCode, //TODO: use airlines to get city name
      destinationCode: slice.destinationCode, //TODO: use airlines to get city name
      duration: `${Math.floor(totalDurationMinutes / 60)}h ${
        totalDurationMinutes % 60
      }m`,
      departureDate: departureTime.format("ddd, D MMM"),
      departureTime: departureTime.format("HH:mm"),
      arrivalTime: arrivalTime.format("HH:mm"),
      airlineCode: slice.segmentDetails[0].airlineCode,
      airlineName: slice.segmentDetails[0].airlineName,
      flightStops: slice.stops,
      isOutgoing: slice.outgoing,
      currentPriceText: "",
      brandName: "",
      primaryCarrier: slice.segmentDetails[0].airlineCode,
      tags: [],
    };
  };

  const flightSummary = useMemo(() => {
    if (tripDetails && fareDetails) {
      const props = getFlightCardProps(tripDetails.slices[0]);
      return (
        <DisruptionExerciseContainer
          content={
            <div
              onClick={() => {
                setExpandFlightDetails(!expandFlightDetails);
              }}
            >
              <FlightCardSummary {...props} />
              {expandFlightDetails && (
                <FlightDetails
                  flightSlice={tripDetails.slices[0]}
                  flightDetails={tripDetails}
                  isOutgoing={true}
                />
              )}
            </div>
          }
        />
      );
    } else {
      return <div></div>;
    }
  }, [fareDetails, tripDetails, setExpandFlightDetails, expandFlightDetails]);

  const mobileTopContent = (
    <div className={cn("mobileHeaderContainer")}>
      <div className={cn("title")}>
        {t(
          "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.title"
        )}
      </div>
      <div className={cn("subtitle")}>
        {t(
          "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.subtitle"
        )}
      </div>
    </div>
  );

  const title = !isDesktopAndUp
    ? disruptionProductName
    : t(
        "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.title"
      );
  const subtitle = !isDesktopAndUp
    ? ""
    : t(
        "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.subtitle"
      );

  const rightConent = !isDesktopAndUp ? (
    <div></div>
  ) : (
    <DisruptionExerciseCheckoutComponent
      travelers={passengers}
      onSubmit={() => {
        fetchDisruptionRebookFulfill();
      }}
      productName={disruptionProductName}
    ></DisruptionExerciseCheckoutComponent>
  );
  const leftContent = !isDesktopAndUp ? (
    <DisruptionExerciseContainer
      content={[
        mobileTopContent,
        originDestination ?? <div></div>,
        flightSummary,
        <hr></hr>,
        <DisruptionExerciseCheckoutComponent
          travelers={passengers}
          onSubmit={() => {
            fetchDisruptionRebookFulfill();
          }}
          productName={disruptionProductName}
        ></DisruptionExerciseCheckoutComponent>,
        <hr></hr>,
        <DisruptionExerciseInformationCard
          onSupportClick={onSupportClick}
          bulletPoints={bullets}
          onRefundClick={handleRefundClick}
          inContainer={false}
        ></DisruptionExerciseInformationCard>,
      ]}
    />
  ) : (
    <DisruptionExerciseContainer
      content={[
        originDestination,
        flightSummary,
        <DisruptionExerciseInformationCard
          onSupportClick={onSupportClick}
          bulletPoints={bullets}
          onRefundClick={handleRefundClick}
          inContainer={false}
        ></DisruptionExerciseInformationCard>,
      ]}
    />
  );

  return (
    <DisruptionExercisePage
      className={cn("disruptionUniversalExerciseRebookFlightCheckoutPage")}
      pageWidth={DisruptionExercisePageWidth.Large}
      title={loading ? "" : title}
      subtitle={loading ? "" : subtitle}
      onBack={handleGoBack}
      openErrorModal={rebookQuoteError || rebookFulfillError}
      onCloseErrorModal={() => {
        setRebookQuoteError(false);
        setRebookFulfillError(false);
        handleGoBack();
      }}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      submitButtonHidden={isDesktopAndUp}
      submitButtonAction={fetchDisruptionRebookFulfill}
      submitButtonTitle={t(
        "core-disruption.disruptionUniversalExercise.rebook.flightCheckoutPage.submitButton"
      )}
      isLoading={loading}
      loadingMessage={loadingMessage}
      onCancelFlow={cancelFlowAction}
      content={
        loading ? (
          <></>
        ) : (
          [
            <DisruptionExercisePageContent
              leftContent={leftContent}
              rightConent={rightConent}
            />,
            bagsLearnMore,
            returnLearnMore,
          ]
        )
      }
      delayHours={delayHours}
      numberOfHours={hoursString}
      termsLink={termsLink}
    ></DisruptionExercisePage>
  );
};

export default DisruptionExerciseRebookCheckoutPage;
