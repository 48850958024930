import { getObjectKeysAsObject } from "@checkout/helpers";
import {
  trackEditTravelers,
  trackNewTraveler,
} from "@checkout/tracking/passengerInformation";
import { deleteTravelerService } from "./deleteTravelerService";
import { fetchTravelersService } from "./fetchTravelersService";
import { updateTravelerService } from "./updateTravelerService";

export const services = {
  deleteTravelerService,
  fetchTravelersService,
  updateTravelerService,
  trackEditTravelers,
  trackNewTraveler,
};

export const ServiceTypes = getObjectKeysAsObject(services);
