import { PassengerPricing, TripType } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./ReviewBreakdown.module.scss";
import clsx from "clsx";
import { useMemo } from "react";

export interface ReviewBreakdownComponentProps extends PlatformComponentProps {
  passengerPricing: PassengerPricing;
  tripType: TripType;
}

export const ReviewBreakdown = ({
  passengerPricing,
  tripType,
  className,
  children,
}: ReviewBreakdownComponentProps) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.ReviewBreakdown,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.ReviewBreakdown
  );

  const { t, formatFiatCurrency } = useI18nContext();

  const subtitle = useMemo(() => {
    switch (tripType) {
      case TripType.one_way: {
        return t("oneWayTripCategoryPassenger");
      }
      case TripType.open_jaw: {
        return t("roundTripCategoryPassenger");
      }
      case TripType.round_trip: {
        return t("roundTripCategoryPassenger");
      }
    }
  }, [t, tripType]);

  return (
    <div className={clsx(block, className)}>
      <div className={cn("price")}>
        {formatFiatCurrency(passengerPricing.pricing.total.fiat)}
      </div>
      <div className={cn("price-subtitle")}>{subtitle}</div>
      {children}
    </div>
  );
};
