import { useCallback, useEffect, useState, useRef } from "react";
import { trackEvent } from "@hopper-b2b/api";
import { apiConfig } from "@hopper-b2b/common-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { type IApiConfig, LodgingShopTrackingEvents } from "@hopper-b2b/types";
import { useDebounce } from "@hopper-b2b/utilities";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import {
  type AutocompleteRenderGroupParams,
  type AutocompleteRenderInputParams,
  Autocomplete as MuiAutocomplete,
} from "@material-ui/lab";
import { Loading } from "../Loading";
import styles from "./Autocomplete.module.scss";

export type AutocompleteProps<T> = {
  id: string;
  defaultValue?: T;
  label: string;
  disabled?: boolean;
  fetch: (
    apiConfig: IApiConfig | undefined,
    search: string,
    callback: (newOptions: T[]) => void
  ) => Promise<void>;
  onChange: (value: T) => void;
  sortOptions?: (options?: T[]) => T[];
  groupBy?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  getGroupLabelKey?: (group: string) => string;
  icon?: string;
  onOpen?: () => void;
  paperClassName?: string;
  className?: string;
  shrinkLabel?: boolean;
  animated?: true;
  renderOption?: (option: T) => React.ReactNode;
};

export const Autocomplete = <T,>({
  id,
  defaultValue,
  label,
  disabled,
  fetch,
  sortOptions,
  onChange,
  groupBy,
  getOptionLabel,
  getGroupLabelKey,
  icon,
  onOpen,
  paperClassName,
  className,
  shrinkLabel,
  animated,
  renderOption,
}: AutocompleteProps<T>) => {
  const { t } = useI18nContext();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shrink, setShrink] = useState<boolean>(false);

  // Initialize with defaultValue but don't re-set it when defaultValue changes
  const initialDefaultValue = useRef(defaultValue);
  const [value, setValue] = useState<T | null>(
    () => initialDefaultValue.current || null
  );
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<T[]>([]);

  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    if (!open) return;

    setLoading(true);
    fetch(apiConfig, debouncedSearch, (newOptions: T[]) => {
      setOptions(sortOptions ? sortOptions(newOptions) : newOptions);
      setLoading(false);
    });
  }, [fetch, open, debouncedSearch, sortOptions]);

  const handleOnInputChange = useCallback((_event, newInputValue: string) => {
    setSearch(
      newInputValue.length === 1
        ? newInputValue.toLocaleUpperCase()
        : newInputValue
    );
  }, []);

  const handleOnChangeValue = useCallback(
    (_event, newValue) => {
      if (newValue === null) return;

      const char_typed =
        search !== newValue?.id?.lodgingSelection?.searchTerm
          ? search
          : undefined;
      const location_type = newValue?.id?.lodgingSelection?.placeTypes;
      const search_term = newValue?.id?.lodgingSelection?.searchTerm;

      setValue(newValue);
      onChange(newValue);

      trackEvent({
        eventName: LodgingShopTrackingEvents.tapped_search_suggestion,
        properties: {
          location_type,
          search_term,
          char_typed,
          // position in the list of suggestions
          location_list_index: _event.target.dataset.optionIndex + 1,
          ...newValue?.["trackingPropertiesV2"],
        },
      });
    },
    [onChange, search]
  );

  const handleOnOpen = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnFocus = useCallback(() => {
    setShrink(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setShrink(false);
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={value && !animated ? null : label}
        aria-label={label}
        variant="filled"
        InputLabelProps={{
          shrink: animated ? shrink || !!value : shrinkLabel,
          focused: false,
          className: styles.label,
          disableAnimation: !animated,
        }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: (
            <>
              <InputAdornment
                position="start"
                className={styles.startAdornment}
              >
                {icon ? <img src={icon} alt="" /> : null}
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ),
          endAdornment: (
            <>
              <InputAdornment position="end" className={styles.endAdornment}>
                <Loading loading={loading} />
              </InputAdornment>
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [value, animated, label, shrink, shrinkLabel, icon, loading]
  );

  const renderGroup = useCallback(
    ({ key, group, children }: AutocompleteRenderGroupParams) => (
      <div key={key} className={styles.group}>
        <Typography variant="caption" color="textSecondary">
          {t?.(getGroupLabelKey ? getGroupLabelKey(group) : group)}
        </Typography>
        {children}
      </div>
    ),
    [getGroupLabelKey, t]
  );

  return (
    <MuiAutocomplete
      id={id}
      open={open}
      disabled={disabled}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      value={value}
      inputValue={search}
      options={options}
      loading={loading}
      loadingText={t?.("loading")}
      noOptionsText={t?.("noOptions")}
      classes={{ paper: paperClassName }}
      onChange={handleOnChangeValue}
      onInputChange={handleOnInputChange}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      disableClearable={loading}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      renderInput={renderInput}
      renderGroup={renderGroup}
      renderOption={renderOption}
      className={className}
      // Disable UI filtering
      filterOptions={(options: T[]) => options}
    />
  );
};
