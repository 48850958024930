import type { Intervals } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";

export interface IPredictionForecastProps {
  className?: string;
  intervals: Intervals;
  departureString?: string;
  onClick?: () => void;
  darkMode?: boolean;
}

export const PredictionForecast = (props: IPredictionForecastProps) => {
  const {
    className,
    onClick,
    intervals,
    departureString,
    darkMode = false,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const { t } = useI18nContext();

  return (
    <Box
      className={clsx(
        className,
        { "prediction-forecast": matchesMobile },
        { "desktop-prediction-forecast": !matchesMobile }
      )}
      onClick={onClick}
    >
      {Object.keys(intervals).map((key) => (
        <Box key={key} className="prediction-forecast-item">
          <Box className="line"></Box>
          <Box className={clsx("icon", key, { "dark-mode": darkMode })}></Box>
          <Box className="description">
            <Typography variant="h6" className="dates">
              {intervals[key]?.dates}
            </Typography>
            <Typography variant="subtitle2" className="copy">
              <div
                dangerouslySetInnerHTML={{
                  __html: intervals[key]?.copy || "",
                }}
              ></div>
            </Typography>
          </Box>
        </Box>
      ))}
      <Box className={clsx("prediction-forecast-item", "end")}>
        <Box className={clsx("icon", "departure")}></Box>
        <Box className="description">
          <Typography variant="h6" className="dates">
            {departureString || (t("departure") as string)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
