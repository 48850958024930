import { useCallback } from "react";
import type {
  AEvent,
  EventData,
  EventName,
  SmartEmitRawEvent,
} from "../createUseEmitRawEvent";
import { useEventTracking } from "../TrackingProvider";

export function useEmitRawEvent<
  TAnyEvent extends AEvent,
  TEventName extends EventName<TAnyEvent>
>(
  eventName: TEventName
): SmartEmitRawEvent<Extract<TAnyEvent, AEvent<TEventName>>> {
  type TExtractedEvent = Extract<TAnyEvent, AEvent<TEventName>>;

  const { emitRawEventHooks } = useEventTracking();

  const emitRawEvents = emitRawEventHooks.map(function useHook(
    useEmitRawEventDownstream
  ) {
    return useEmitRawEventDownstream(eventName);
  });

  return useCallback(
    (eventData?: EventData<TExtractedEvent>) => {
      emitRawEvents.forEach((emitRawEvent) =>
        emitRawEvent(eventData ?? ({} as EventData<TExtractedEvent>))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...emitRawEvents]
  );
}
