import { useCallback, useContext, useMemo, useState } from "react";
import {
  type MessageMethod,
  MessageMethodEnum,
  ShopFilter,
} from "@b2bportal/air-price-watch-api";
import { useI18nContext, Trans } from "@hopper-b2b/i18n";
import { MobileFloatingButton, MobilePopoverCard } from "@hopper-b2b/ui";
import { SessionContext } from "@hopper-b2b/utilities";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "../../assets/client/close.svg";
import { CheckboxRowItem } from "../CheckboxRowItem";
import { ToggleRowItem } from "../ToggleRowItem";
import styles from "./styles.module.scss";

const PriceWatchPopoverContent = ({
  onSubmit,
}: {
  onSubmit: (method: { method: MessageMethod }, filter: ShopFilter) => void;
}) => {
  const [nonstopOnlySelected, setNonstopOnlySelected] = useState(false);
  const [excludeBasicSelected, setExcludeBasicSelected] = useState(false);
  const [emailSelected, setEmailSelected] = useState(true);
  const { email: sessionEmail } = useContext(SessionContext);
  const [notificationsSelected, setNotificationsSelected] = useState(true);
  const [openLearnMore, setOpenLearnMore] = useState(false);

  const { t } = useI18nContext();

  const handleClickLearnMore = useCallback(
    (e) => {
      e.stopPropagation();
      setOpenLearnMore(true);
    },
    [setOpenLearnMore]
  );

  const handleCloseLearnMore = useCallback(
    () => setOpenLearnMore(false),
    [setOpenLearnMore]
  );

  const content = useMemo(
    () => ({
      flightTypes: [
        {
          title: t("priceWatch.nonstopOnly"),
          selected: nonstopOnlySelected,
          onCheckClick: setNonstopOnlySelected,
        },
        {
          title: t("priceWatch.excludeBasic"),
          selected: excludeBasicSelected,
          onCheckClick: setExcludeBasicSelected,
        },
      ],
      notificationMethods: [
        {
          title: t("priceWatch.appNotifications"),
          subtitle: (
            <Trans
              i18nKey="priceWatch.appNotificationsSubtitle"
              components={[
                <p></p>,
                <span></span>,
                <button
                  className="learn-more-link"
                  onClick={(e) => handleClickLearnMore(e)}
                ></button>,
              ]}
            />
          ),
          selected: notificationsSelected,
          onClick: setNotificationsSelected,
        },
        {
          title: t("priceWatch.emailDefault"),
          subtitle: sessionEmail,
          selected: emailSelected,
          onClick: setEmailSelected,
        },
      ],
    }),
    [
      sessionEmail,
      emailSelected,
      excludeBasicSelected,
      nonstopOnlySelected,
      notificationsSelected,
      t,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    const priceWatchInfo: { method: MessageMethod } = {
      method: {
        MessageMethod: MessageMethodEnum.MultiMethod,
        ...(emailSelected && { email: { email: sessionEmail } }),
        ...(notificationsSelected && { push: {} }),
      },
    };

    const filter =
      nonstopOnlySelected && excludeBasicSelected
        ? ShopFilter.NonStopNoLCC
        : nonstopOnlySelected
        ? ShopFilter.NonStop
        : excludeBasicSelected
        ? ShopFilter.NoLCC
        : ShopFilter.NoFilter;

    onSubmit(priceWatchInfo, filter);
  }, [
    sessionEmail,
    emailSelected,
    excludeBasicSelected,
    nonstopOnlySelected,
    notificationsSelected,
    onSubmit,
  ]);

  return (
    <>
      <div className={styles.PriceWatchPopoverContent}>
        {/* <div className="preferences flight">
          <h4>{t("priceWatch.preferences")}</h4>
          {content.flightTypes.map((typeOption, i) => (
            <CheckboxRowItem {...typeOption} key={i} />
          ))}
        </div> */}
        <div className="preferences notify">
          <h4>{t("priceWatch.alertPreferences")}</h4>
          {content.notificationMethods.map((notificationMethod, i) => (
            <ToggleRowItem {...notificationMethod} key={i} />
          ))}
        </div>
      </div>
      <MobileFloatingButton
        onClick={onSubmitHandler}
        wide
        disabled={!emailSelected && !notificationsSelected}
      >
        {t("priceWatch.update")}
      </MobileFloatingButton>
      <MobilePopoverCard
        open={openLearnMore}
        onClose={handleCloseLearnMore}
        className={clsx(styles.PriceWatchLearnMoreModal, "half-sheet")}
        contentClassName="flight-watch-opt-in-content-wrapper"
        topLeftButton={
          <button className="half-sheet-close" onClick={handleCloseLearnMore}>
            <CloseIcon className={styles.Close} />
          </button>
        }
      >
        <>
          <h2>{t("priceWatch.learnMore.title")}</h2>
          <Trans i18nKey="priceWatch.learnMore.content" components={[<p />]} />
          <MobileFloatingButton onClick={handleCloseLearnMore} wide>
            {t("close.button")}
          </MobileFloatingButton>
        </>
      </MobilePopoverCard>
    </>
  );
};

export default PriceWatchPopoverContent;
