import React from "react";

import type { TripDetailsV2, TripSlice } from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { InfoBanner, InfoBannerSeverity } from "@b2bportal/core-ui";
import { FlightDetailsSegment } from "@components/flights";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import { removeTimezone } from "@hopper-b2b/utilities";
import clsx from "clsx";
import dayjs from "dayjs";
import defaultStyles from "./FlightDetails.module.scss";

export interface FlightDetailsComponentProps extends PlatformComponentProps {
  flightDetails?: TripDetailsV2;
  flightSlice: TripSlice;
  cabinClass?: string;
  planeInfo?: string;
  plusDays?: string;
  isOutgoing: boolean;
  classNames?: string;
  viRestrictionsLink?: React.ReactNode;
}

export const FlightDetails = ({
  flightSlice,
  cabinClass,
  planeInfo,
  plusDays,
  isOutgoing = true,
  viRestrictionsLink,
  classNames,
  children,
}: FlightDetailsComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightDetails;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t, formatDateTime, DateTimeFormatStyle } = useI18nContext();

  if (flightSlice == null) {
    return null;
  }

  const isVi = flightSlice.segmentDetails.some(
    (segment) => segment.isSelfTransferLayover ?? false
  );

  return (
    <div className={clsx(block, classNames)}>
      <div className={cn("title")}>
        <span className={cn("title-text")}>
          <I18nMarkup
            tKey={
              isOutgoing
                ? "flightsPageTitles.detailsOutbound"
                : "flightsPageTitles.detailsReturn"
            }
            replacements={
              isOutgoing
                ? { outboundCity: flightSlice.destinationName }
                : { returnCity: flightSlice.destinationName }
            }
          />
        </span>{" "}
        <span className={cn("title-date")}>
          {t("departingOnWithDate", {
            date: formatDateTime(
              dayjs(removeTimezone(flightSlice.departureTime)).toDate(),
              DateTimeFormatStyle.ShortMonthDayShortWeekday
            ),
          })}
        </span>
      </div>
      <div className={cn("slice")}>
        {isVi ? (
          <InfoBanner
            className={cn("vi-banner")}
            severity={InfoBannerSeverity.INFO}
            icon={IconNameEnum.airplaneDiagonal}
          >
            <div>{t("core-flights.vi.multipleAirlinesNotice")}</div>
            {viRestrictionsLink != null ? (
              <div>{viRestrictionsLink}</div>
            ) : null}
          </InfoBanner>
        ) : null}

        {flightSlice.segmentDetails.map((segment, index) => (
          <FlightDetailsSegment
            key={`segment-${index}`}
            flightSlice={flightSlice}
            segment={segment}
            cabinClass={cabinClass}
            planeInfo={planeInfo}
          />
        ))}

        {plusDays != null ? (
          <div>
            <div className={cn("plus-days")}>{t("flightNextDay")}</div>
          </div>
        ) : null}
      </div>
      {children}
    </div>
  );
};
