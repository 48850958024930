import type {
  Fare,
  FareSliceOutbound,
  Flights,
  Prices,
  Slice,
  TripSlice,
  TripSummary,
} from "@b2bportal/air-shopping-api";
import type {
  IFlightListData,
  IFlightNumberFilter,
  ITimeRange,
} from "@b2bportal/core-types";
import { removeTimezone } from "@hopper-b2b/utilities";
import dayjs from "dayjs";

import {
  type AirlineCode,
  FlightRatingsEnum,
  SliceStopCountFilter,
} from "../../../../../types";

export const performStopOptionFilter = (
  flight: TripSlice,
  stopsOption: SliceStopCountFilter
): boolean => {
  switch (stopsOption) {
    case SliceStopCountFilter.ONE_OR_LESS:
      return flight.stops <= 1;
    case SliceStopCountFilter.TWO_OR_LESS:
      return flight.stops <= 2;
    case SliceStopCountFilter.NONE:
      return flight.stops === 0;
    default:
      return true;
  }
};

export const performMaxPriceFilter = (
  trip: TripSummary,
  maxPriceFilter: number
) => {
  return trip.tripFares.some(
    (fare) => fare.amount.fiat.value <= maxPriceFilter
  );
};

export const performTimeRangeFilter = (
  flight: TripSlice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightDeparture = dayjs(removeTimezone(flight.departureTime));
  const flightArrival = dayjs(removeTimezone(flight.arrivalTime));

  const flightDepartureMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();
  const flightArrivalMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();

  const validDeparture =
    departureTime.max >= flightDepartureMinutes &&
    flightDepartureMinutes >= departureTime.min;
  const validArrival =
    arrivalTime.max >= flightArrivalMinutes &&
    flightArrivalMinutes >= arrivalTime.min;

  return validDeparture && validArrival;
};

export const performAirlineFilter = (
  flight: TripSlice,
  airlineFilter: AirlineCode[]
) => {
  return airlineFilter.includes(flight.segmentDetails[0].airlineCode);
};

export const performAirportFilter = (
  slice: TripSlice,
  airportFilter: string[]
) => {
  return airportFilter.includes(slice.originCode);
};

export const performFlightNumberFilter = (
  flight: TripSlice,
  flightNumberFilter: IFlightNumberFilter[]
) => {
  return flightNumberFilter.some((filter) => {
    const [firstSegment] = flight.segmentDetails;
    return (
      firstSegment.airlineCode === filter.airlineCode &&
      firstSegment.flightNumber === filter.flightNumber
    );
  });
};

export const performFareClassFilter = (
  flight: TripSummary,
  fareClassOptionFilter: string[],
  isReturn: boolean,
  outgoingRating?: number
) => {
  const getAllFlightRating = () => {
    return flight.tripFares.reduce<Record<string, boolean>>(
      (flightRating, fare) => {
        //Only add return rating if outgoing flight rating matches in the fare.
        const rating =
          isReturn &&
          (outgoingRating === undefined ||
            outgoingRating === fare.fareShelf?.outgoing.rating)
            ? fare.fareShelf?.returning?.rating
            : !isReturn
            ? fare.fareShelf?.outgoing.rating
            : null;

        //zero should not be considered as false
        if (rating || rating === 0) flightRating[rating] = true;
        return flightRating;
      },
      {}
    );
  };

  // If the flight has *any* of the users selected fare classes, return true.
  return fareClassOptionFilter.some((fare) => {
    return getAllFlightRating()[FlightRatingsEnum[fare.toLocaleLowerCase()]];
  });
};

///
export const performStopOptionFilterV2 = (
  flight: Slice,
  stopsOption: SliceStopCountFilter
): boolean => {
  switch (stopsOption) {
    case SliceStopCountFilter.ONE_OR_LESS:
      return flight.stops <= 1;
    case SliceStopCountFilter.TWO_OR_LESS:
      return flight.stops <= 2;
    case SliceStopCountFilter.NONE:
      return flight.stops === 0;
    default:
      return true;
  }
};

export const performMaxPriceFilterV2 = (
  fares: { amount: Prices }[],
  maxPriceFilter: number
) => {
  return fares.some((fare) => fare.amount.fiat.value <= maxPriceFilter);
};

export const performTimeRangeFilterV2 = (
  flight: Slice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightDeparture = dayjs(removeTimezone(flight.departure));
  const flightArrival = dayjs(removeTimezone(flight.arrival));

  const flightDepartureMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();
  const flightArrivalMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();

  const validDeparture =
    departureTime.max >= flightDepartureMinutes &&
    flightDepartureMinutes >= departureTime.min;
  const validArrival =
    arrivalTime.max >= flightArrivalMinutes &&
    flightArrivalMinutes >= arrivalTime.min;

  return validDeparture && validArrival;
};

export const performAirlineFilterV2 = (
  flight: Slice,
  airlineFilter: AirlineCode[]
) => {
  return airlineFilter.includes(flight.segments[0].marketingAirline);
};

export const performAirportFilterV2 = (
  slice: Slice,
  airportFilter: string[]
) => {
  return (
    airportFilter.includes(slice.origin) ||
    airportFilter.includes(slice.destination)
  );
};

export const performFlightNumberFilterV2 = (
  flight: Slice,
  flightNumberFilter: IFlightNumberFilter[]
) => {
  return flightNumberFilter.some((filter) => {
    const [firstSegment] = flight.segments;
    return (
      firstSegment.marketingAirline === filter.airlineCode &&
      firstSegment.flightNumber === filter.flightNumber
    );
  });
};

export const performFareClassFilterV2 = (
  flights: Flights,
  flight: IFlightListData<Fare | FareSliceOutbound>,
  fareClassOptionFilter: string[]
) => {
  const getAllFlightRating = () => {
    const getFareSliceId = (fare: any) => fare?.fareSlice || fare.return;
    return flight.fares.reduce((flightRating: any, fare: any) => {
      const rating = flights.fareSlices[getFareSliceId(fare)].fareShelf.value;
      if (rating || rating === 0) flightRating[rating] = true;
      return flightRating;
    }, {});
  };
  // If the flight has *any* of the users selected fare classes, return true.
  return fareClassOptionFilter.some((fare) => {
    return getAllFlightRating()[FlightRatingsEnum[fare.toLocaleLowerCase()]];
  });
};
