import type { LodgingCfarPriceQuoteDataV1 } from "@b2bportal/lodging-cfar-api";
import { type Prices, Product } from "@b2bportal/purchase-api";
import { ParentState } from "@checkout/types";
import { getEnvVariables } from "@hopper-b2b/utilities";
import type { State } from "xstate";
import { getChildState } from "../../../helpers";
import { CartQuoteState } from "../../../types";
import type { CartContext } from "../context";
import { CartState } from "../types";

type CartQuoteStateType = State<CartContext>;
type CartQuoteStateWithoutValue = Pick<CartQuoteStateType, "context">;

export const getPriceQuoteCipherText = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].priceQuoteCipherText;

export const getOpenCartQuoteModal = (state: CartQuoteStateType) =>
  [
    // These are the loading states for CartQuote
    CartQuoteState.polling,
    CartQuoteState.schedule,
    CartQuoteState.discard,
    CartQuoteState.route,
  ].includes(getChildState(state.value) as CartQuoteState) &&
  Object.keys(state.value).includes(ParentState.cartQuote);

export const getCartQuoteError = (state: CartQuoteStateType) =>
  state.context[CartState.cartQuote].error;

export const getCartQuoteFailedAt = (state: CartQuoteStateType) =>
  state.context[CartState.cartQuote].error?.failedAt;

export const getCartQuoteProducts = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].products;

export const getCartQuoteCustomerEmail = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].customerEmail;

export const getQuoteBreakdown = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].quoteBreakdown;

export const getCartQuoteIsLocked = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].cartLocked;

export const getOpenCartQuoteErrorModal = (state: CartQuoteStateType) =>
  getChildState(state.value) === CartQuoteState.error;

export const getCartQuotePriceChange = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].priceChange;

export const getUnavailableProducts = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].unavailableProducts;

export const getAllowQuoteRetry = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) =>
  state.context[CartState.cartQuote].retries <
  parseInt(getEnvVariables("allowedQuoteRetries")?.toString() || "0", 10);

export const getCartQuoteTrackingProperties = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context.cartQuote.trackingProperties;

export const getRemainingBalance = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].quoteBreakdown?.balance;

export const getCartQuoteCallState = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => state.context[CartState.cartQuote].callState;

export const getCartBreakdownProductTypes = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
) => {
  const breakdown = getQuoteBreakdown(state);
  return breakdown?.products.map((p) => p.product.type);
};

export const getCartBreakdownBalance = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
): Prices | undefined =>
  state.context[ParentState.cartQuote].quoteBreakdown?.balance;

export type CartBreakdownProducts = {
  [Product.LodgingCfar]?: {
    totalPremiumAmount: Prices;
  };
};

export const getCartBreakdownProducts = (
  state: CartQuoteStateType | CartQuoteStateWithoutValue
): CartBreakdownProducts =>
  state.context[ParentState.cartQuote].quoteBreakdown?.products.reduce(
    (acc, { product }) => {
      if (product.type === Product.LodgingCfar) {
        acc[Product.LodgingCfar] = product.value as LodgingCfarPriceQuoteDataV1;
      }
      return acc;
    },
    {} as CartBreakdownProducts
  ) ?? {};
