import {
  CompleteInteractiveAuthResponseEnum,
  type CompleteInteractiveAuthRequest,
  purchaseApi,
  type Success,
} from "@b2bportal/purchase-api";
import { type IApiConfig, NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { trackEvent } from "../tracking/trackEvent";

export const completeInteractiveFulfill = async (
  req: CompleteInteractiveAuthRequest,
  apiConfig?: IApiConfig
): Promise<Success> => {
  try {
    const res = await purchaseApi(
      axiosInstance
    ).apiV1PurchaseFulfillCompleteInteractiveAuthPut(req);

    if (
      res &&
      res.data &&
      res.data.CompleteInteractiveAuthResponse ===
        CompleteInteractiveAuthResponseEnum.Success
    ) {
      return res.data;
    }

    throw ""; // Will get caught below
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};
