import { useModuleBEM, useFlightStyles } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import type { FlightSteps } from "@b2bportal/core-utilities";
import { Breadcrumbs } from "@components/ui";
import clsx from "clsx";
import defaultStyles from "./FlightShopBreadcrumbs.module.scss";
import { useFlightShopBreadcrumbs } from "./hooks/useFlightShopBreadcrumbs";

export interface FlightShopBreadcrumbsComponentProps
  extends PlatformComponentProps {
  currentStep: FlightSteps;
}

export const FlightShopBreadcrumbs = ({
  currentStep,
  className,
  children,
}: FlightShopBreadcrumbsComponentProps) => {
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightShopBreadcrumbs,
    defaultStyles
  );
  const [block] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightShopBreadcrumbs
  );

  const { shopSteps } = useFlightShopBreadcrumbs({
    currentStepId: currentStep,
  });

  return (
    <div className={clsx(block, className)}>
      <Breadcrumbs steps={shopSteps} />
      {children}
    </div>
  );
};
