import React, { type PropsWithChildren } from "react";
import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { noop } from "@hopper-b2b/utilities";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import clsx from "clsx";
import defaultStyles from "./Dialog.module.scss";

export interface DialogContentComponentProps
  extends DialogPrimitive.DialogContentProps,
    React.RefAttributes<HTMLDivElement> {
  /**
   * @deprecated
   * This property is deprecated and will be removed in a future version.
   */
  onClose?: () => void;
  title?: string;
  description?: string;
  fullScreen?: boolean;
  className?: string;
  /**
   * @deprecated
   * This property is deprecated and will be removed in a future version.
   * Reason: DialogContent should not be aware of special use cases.
   *
   */
  showBackButton?: boolean;
  showCloseButton?: boolean;
  bottomFixedContent?: React.ReactNode;
  /**
   * Dialog overlay is controlled by if dialog is modal or not.
   * Prop to override that to show overlay when its not a modal.
   */
  showOverlay?: boolean;
  /**
   * @deprecated
   * This property is deprecated and will be removed in a future version.
   * Reason: DialogContent style should not be controlled with props but through css
   * or Style/Component overrides where its being consumed.
   *
   * Show the bottom border of the header section.
   */
  showHeaderBottomBorder?: boolean;
}

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  DialogContentComponentProps
>(({ children, ...props }, forwardedRef) => {
  const styles = useUiStyles(CoreUiComponents.Dialog, defaultStyles);

  const { t } = useI18nContext();

  const {
    onClose,
    title,
    description,
    className,
    bottomFixedContent,
    fullScreen = false,
    showCloseButton = true,
    showBackButton = false,
    showOverlay,
    showHeaderBottomBorder = true,
  } = props;

  const [block, cn] = useModuleBEM(styles, CoreUiComponents.Dialog);

  const backButton = (
    <CloseButton
      label={t("back")}
      className={cn("button", { left: true })}
      onClose={onClose ?? noop}
    >
      <Icon iconName={IconNameEnum.backIcon} />
    </CloseButton>
  );

  const closeButton = (
    <CloseButton
      label={t("close.button")}
      className={cn("button", { right: true })}
      onClose={onClose ?? noop}
    >
      <Icon iconName={IconNameEnum.close} />
    </CloseButton>
  );

  return (
    <DialogPrimitive.Portal>
      {showOverlay ? (
        <div
          className={clsx(
            cn("overlay", {
              fullScreen: fullScreen,
            })
          )}
        ></div>
      ) : (
        <DialogPrimitive.Overlay
          className={clsx(
            cn("overlay", {
              fullScreen: fullScreen,
            })
          )}
        />
      )}
      <DialogPrimitive.Content
        ref={forwardedRef}
        forceMount={props.forceMount}
        className={clsx(
          cn("root", {
            fullScreen: fullScreen,
          }),
          className
        )}
      >
        <div
          className={cn("header", { bottomBorder: !!showHeaderBottomBorder })}
        >
          {showBackButton && backButton}
          {title != null ? (
            <DialogPrimitive.Title className={cn("title")}>
              {title}
            </DialogPrimitive.Title>
          ) : null}
          {showCloseButton && closeButton}
        </div>

        {children != null && (
          <div className={cn("content")}>
            {description != null ? (
              <DialogPrimitive.Description className={cn("description")}>
                {description}
              </DialogPrimitive.Description>
            ) : null}
            {children}
          </div>
        )}

        {bottomFixedContent ? (
          <div className={cn("bottom")}>{bottomFixedContent}</div>
        ) : null}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  );
});

export type CLoseButtonProps = PropsWithChildren<{
  onClose: () => void;
  className?: string;
  label: string;
}>;

export const CloseButton = ({
  children,
  onClose,
  className,
  label,
}: CLoseButtonProps) => {
  return (
    <DialogPrimitive.Close asChild>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onClose?.();
        }}
        className={className}
        aria-label={label}
      >
        {children}
      </button>
    </DialogPrimitive.Close>
  );
};

export interface DialogProps extends DialogPrimitive.DialogProps {
  onOpen?: () => void;
  onClose?: () => void;
}

export const Dialog = ({ onOpen, onClose, ...props }: DialogProps) => {
  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen && onOpen) {
      onOpen();
    } else if (!isOpen && onClose) {
      onClose();
    }

    if (props.onOpenChange) {
      props.onOpenChange(isOpen);
    }
  };

  return <DialogPrimitive.Root {...props} onOpenChange={handleOpenChange} />;
};

export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;
export const DialogTitle = DialogPrimitive.Title;
