import type { FiatPrice } from "@b2bportal/air-shopping-api";
import {
  useExperiment,
  useGetExperimentVariant,
} from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { ButtonWrap } from "@hopper-b2b/ui";
import { DisplayedPrice } from "@hopper-b2b/wallet";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { EXPERIMENTS } from "../../App";
import { ReactComponent as ArrowRight } from "../../assets/client/arrow-right.svg";
import "./styles.scss";

interface ISharedFlightCtaSectionProps {
  className?: string;
  discountedPrice: FiatPrice | null;
  totalPrice: FiatPrice;
  tripCategory: TripCategory;
  onClick: () => void;
  buttonDisabled?: boolean;
  showCombinedTotal?: boolean;
  totalPriceString?: string;
  installmentCopyKey?: string;
  buttonContent?: JSX.Element;
  buttonClassname?: string;
}

const SharedFlightCtaSection = ({
  className,
  discountedPrice,
  totalPrice,
  tripCategory,
  onClick,
  totalPriceString,
  buttonDisabled = false,
  showCombinedTotal = false,
  installmentCopyKey,
  buttonContent = null,
  buttonClassname,
}: ISharedFlightCtaSectionProps) => {
  const { t } = useI18nContext();
  const blackFridayEnabled = useExperiment(EXPERIMENTS.NUBANK_BLACK_FRIDAY);
  const nubankMaxInstallments = useGetExperimentVariant(
    EXPERIMENTS.MAX_INSTALLMENTS
  );

  return (
    <div className={clsx(className, "fare-details-bottom-wrapper")}>
      <div className="fare-details-price-summary-wrapper">
        <div className="fare-details-summary-section">
          <div className="description-text">
            {tripCategory === TripCategory.ONE_WAY
              ? t(
                  showCombinedTotal
                    ? "oneWayTripCategoryTotal"
                    : "oneWayPerTraveler"
                )
              : t(
                  showCombinedTotal
                    ? "roundTripCategoryTotal"
                    : "roundTripPerTraveler"
                )}
          </div>
          <div className="price-text">
            <Typography className="content" variant="body1">
              <DisplayedPrice
                discountedCostFiat={discountedPrice}
                totalCostFiat={totalPrice}
                totalCostString={totalPriceString}
              />
            </Typography>
            <div className="installments-badge">
              {/* Black Friday Banner, Remove after 11/12/2024 */}
              {blackFridayEnabled ? (
                <p>
                  {t("upToInterestFreeInstallmentsShort", {
                    count: parseInt(nubankMaxInstallments, 10),
                  })}
                  {" - "}
                  {t("blackFriday.title")}
                </p>
              ) : (
                <p>
                  {t(
                    installmentCopyKey
                      ? installmentCopyKey
                      : "upToInterestFreeInstallments",
                    {
                      count: parseInt(nubankMaxInstallments, 10),
                    }
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
        <ButtonWrap
          className={clsx("fare-details-button-section", buttonClassname)}
          onClick={buttonDisabled ? null : onClick}
        >
          {buttonContent ? (
            buttonContent
          ) : (
            <ArrowRight className={buttonDisabled ? "disabled" : ""} />
          )}
        </ButtonWrap>
      </div>
    </div>
  );
};

export default SharedFlightCtaSection;
