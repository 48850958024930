import { useCallback, useState } from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { createGeneralSupportConversation } from "@hopper-b2b/self-serve";
import { ClientName } from "@hopper-b2b/types";
import {
  ActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import clsx from "clsx";
import { EXPERIMENTS } from "../../../App";
import { ReactComponent as Headset } from "../../../assets/client/headset.svg";
import { ContactSupportModalContent } from "../ContactSupportModalContent";
import styles from "./ContactSupportAction.module.scss";

export const ContactSupportAction = () => {
  const { t, brand } = useI18nContext();

  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);

  const chatEntrypoint = useExperiment(EXPERIMENTS.NUBANK_CHAT_ENTRYPOINT);

  const handleChatEntrypoint = useCallback(() => {
    window.location.assign(brand.urls.chat);
  }, [brand.urls.chat]);

  const handleOpenContactSupportModal = useCallback(
    () => setOpenContactSupportModal(true),
    []
  );

  const handleCloseContactSupportModal = useCallback(
    () => setOpenContactSupportModal(false),
    []
  );

  const handleOpenChat = useCallback(() => {
    handleCloseContactSupportModal();
    createGeneralSupportConversation(ClientName.NUBANK);
  }, [handleCloseContactSupportModal]);

  const handleContactSupportClick = useCallback(
    () =>
      chatEntrypoint ? handleChatEntrypoint() : handleOpenContactSupportModal(),
    [chatEntrypoint, handleChatEntrypoint, handleOpenContactSupportModal]
  );

  return (
    <>
      <button
        className={styles.contactSupportButton}
        onClick={handleContactSupportClick}
      >
        <Headset className="mobile-action-link-icon help" />
        {t("contactSupport")}
      </button>
      <MobilePopoverCard
        open={openContactSupportModal}
        className="contact-support-modal"
        scroll="paper"
        fullScreen
        topRightButton={
          <ActionLink
            className="introduction-modal-close-button"
            data-cy="contact-support-modal-close"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={handleCloseContactSupportModal}
          />
        }
        onClose={handleCloseContactSupportModal}
      >
        <ContactSupportModalContent
          onClose={handleCloseContactSupportModal}
          onContactSupport={handleOpenChat}
        />
      </MobilePopoverCard>
    </>
  );
};
