import {
  type FilterState,
  getVehicleClass,
  type VehicleAvailabilityWithSupplier,
  getBaggageQty,
  type FilterOptions,
  getIsFreeCancel,
} from "@apac/components/cars";
import { IconNameEnum } from "@b2bportal/core-themes";
import { TransmissionType } from "@b2bportal/ground-api";
import type { TFunction } from "i18next";
import { maxBy, minBy, uniq } from "lodash-es";

export interface CarsFilter {
  title: (t: TFunction) => string;
  iconName: IconNameEnum;
  getOptions: (cars: VehicleAvailabilityWithSupplier[]) => unknown;
  apply: (
    car: VehicleAvailabilityWithSupplier,
    filterValue: unknown
  ) => boolean;
}

type FilterKeys = keyof FilterState;

export enum FilterOptionsValue {
  freeCancellation = "freeCancellation",
  airConditioning = "airConditioning",
  automaticTransmission = "automaticTransmission",
  unlimitedMileage = "unlimitedMileage",
  damageWaiver = "damageWaiver",
}

export const CARS_FILTERS: Record<FilterKeys, CarsFilter> = {
  priceRange: {
    title: (t) => t("apac.cars.filters.price.title"),
    iconName: IconNameEnum.fareDetailsCharge,
    getOptions: (cars): FilterOptions["priceRange"] => {
      const carsPrice = cars.flatMap((car) => {
        const { totalCharge } = car;
        return totalCharge == null ? [] : [totalCharge.fiat];
      });
      const minPrice = minBy(carsPrice, (fiat) => fiat.value) ?? carsPrice[0];
      const maxPrice = maxBy(carsPrice, (fiat) => fiat.value) ?? carsPrice[0];
      return {
        min: minPrice,
        max: maxPrice,
      };
    },
    apply: (car, filterValue: NonNullable<FilterState["priceRange"]>) => {
      return (
        filterValue.min <= car.totalCharge.fiat.value &&
        filterValue.max >= car.totalCharge.fiat.value
      );
    },
  },
  vehicleClass: {
    title: (t) => t("apac.cars.filters.vehicleClass.title"),
    iconName: IconNameEnum.car,
    getOptions: (cars): FilterOptions["vehicleClass"] => {
      return uniq(cars.map((car) => getVehicleClass(car.vehicle)));
    },
    apply: (car, filterValue: NonNullable<FilterState["vehicleClass"]>) => {
      return filterValue.includes(getVehicleClass(car.vehicle));
    },
  },
  passengerQty: {
    title: (t) => t("apac.cars.filters.passengerQty.title"),
    iconName: IconNameEnum.guest,
    getOptions: (cars): FilterOptions["passengerQty"] => {
      const passengerQtys = cars.map((car) =>
        car.vehicle.passengerQty.toString()
      );
      return uniq(passengerQtys).sort();
    },
    apply: (car, filterValue: NonNullable<FilterState["passengerQty"]>) => {
      return filterValue.includes(car.vehicle.passengerQty.toString());
    },
  },
  baggageQty: {
    title: (t) => t("apac.cars.filters.baggageQty.title"),
    iconName: IconNameEnum.baggageChecked,
    getOptions: (cars): FilterOptions["baggageQty"] => {
      const baggageQtys = cars.map((car) =>
        getBaggageQty(car.vehicle).toString()
      );
      return uniq(baggageQtys).sort();
    },
    apply: (car, filterValue: NonNullable<FilterState["baggageQty"]>) => {
      const baggageQty = getBaggageQty(car.vehicle);
      return filterValue.includes(baggageQty.toString());
    },
  },
  specification: {
    title: (t) => t("core-cars.filters.specifications.title"),
    iconName: IconNameEnum.info,
    getOptions: (cars): FilterOptions["specification"] => {
      return [
        {
          value: FilterOptionsValue.airConditioning,
          // t("core-cars.filters.specifications.options.airConditioning")
          labelKey: "core-cars.filters.specifications.options.airConditioning",
        },
        {
          value: FilterOptionsValue.automaticTransmission,
          labelKey:
            // t("core-cars.filters.specifications.options.automaticTransmission")
            "core-cars.filters.specifications.options.automaticTransmission",
        },
        {
          value: FilterOptionsValue.unlimitedMileage,
          // t("core-cars.filters.specifications.options.unlimitedMileage")
          labelKey: "core-cars.filters.specifications.options.unlimitedMileage",
        },
        {
          value: FilterOptionsValue.damageWaiver,
          // t("core-cars.filters.specifications.options.damageWaiver")
          labelKey: "core-cars.filters.specifications.options.damageWaiver",
        },
      ];
    },
    apply: (car, filterValue: NonNullable<FilterState["specification"]>) => {
      // And logic here: if user selects multiple options, the car must have all of them
      let valid = true;
      filterValue.forEach((specification) => {
        if (specification === FilterOptionsValue.airConditioning) {
          valid &&= car.vehicle.airConditioning;
        } else if (specification === FilterOptionsValue.automaticTransmission) {
          valid &&= car.vehicle.transmissionType === TransmissionType.Automatic;
        } else if (specification === FilterOptionsValue.unlimitedMileage) {
          valid &&= car.hasUnlimitedMileage;
        } else if (specification === FilterOptionsValue.damageWaiver) {
          valid &&= car.collisionDamageWaiver ?? false;
        }
      });
      return valid;
    },
  },
  rentalCompany: {
    title: (t) => t("core-cars.filters.rentalCompany.title"),
    iconName: IconNameEnum.mapPin,
    getOptions: (cars): FilterOptions["rentalCompany"] => {
      return uniq(
        cars.map((car) => car.supplierName).filter((c) => c != null)
      ).sort();
    },
    apply: (car, filterValue: NonNullable<FilterState["rentalCompany"]>) => {
      return car.supplierName != null && filterValue.includes(car.supplierName);
    },
  },
  cancellation: {
    title: (t) => t("core-cars.cancellationPolicy"),
    // override in portals
    iconName: IconNameEnum.cancelForAnyReason,
    getOptions: (cars): FilterOptions["cancellation"] => {
      return [
        {
          value: FilterOptionsValue.freeCancellation,
          // t("core-cars.cancellation.free")
          labelKey: "core-cars.cancellation.free",
        },
      ];
    },
    apply: (car, filterValue: NonNullable<FilterState["baggageQty"]>) => {
      return filterValue.includes(getIsFreeCancel(car));
    },
  },
};
