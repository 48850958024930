import { createContext } from "react";
import type { ChatPropertiesType } from "@b2bportal/chat-api";
import type { ChatTransfer } from "@b2bportal/ivr-api";

export interface ISupportContext {
  productSupportChat: (
    productId: string,
    productType: ChatPropertiesType,
    requestType: string,
    chatTransfer?: ChatTransfer
  ) => Promise<void>;
  generalSupportChat: (chatTransfer?: ChatTransfer) => Promise<void>;
  openChatWindow: () => void;
  setHopperUserId: (hopperUserId: string) => void;
  setKustomerAccessToken: (kustomerAccessToken: string) => void;
  unreadCount: number;
}

export const SupportContext = createContext<Partial<ISupportContext>>({});
