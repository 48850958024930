import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { AlertKey } from "@b2bportal/air-price-watch-api";
import { Dialog, DialogContent } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton, B2BSpinner } from "@hopper-b2b/ui";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface ConfirmDeleteProps {
  open: boolean;
  closeModal: () => void;
  onDelete: () => void;
  refresh?: boolean;
}

const ConfirmDeleteModal = ({
  open,
  closeModal,
  onDelete,
  refresh = false,
}: ConfirmDeleteProps) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const confirmDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
      refresh && navigate(-1);
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogContent className={styles.HalfSheetDialogContent} fullScreen>
        {loading ? (
          <div className={styles.halfSheetSpinnerWrapper}>
            <B2BSpinner />
          </div>
        ) : (
          <>
            <h1>{t("priceWatch.confirmDelete")}</h1>
            <ActionButton
              className={clsx(styles.deleteButton, "primary-button-wrapper")}
              buttonClassName={"primary-button"}
              message={t("priceWatch.deleteCta")}
              onClick={confirmDelete}
            />
            <ActionButton
              className={clsx(styles.closeButton, "secondary-button-wrapper")}
              buttonClassName={"secondary-button"}
              message={t("priceWatch.cancelCta")}
              onClick={closeModal}
            />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
