import React, { useMemo, useState } from "react";

import type {
  Amenities,
  AmenitiesAndUtasPerSlice,
  FareSliceDetailsV2,
  SegmentShelf,
  TripSegment,
  UtaCategory,
} from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  AirRestrictionStatus,
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import {
  collectRestrictions,
  localizedAmenityLabel,
  localizedUtaCategoryLabel,
} from "@b2bportal/core-utilities";
import { AirlineIcon, Icon } from "@components/ui";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./FareDetails.module.scss";

const config = {
  MAX_FARE_DETAILS: 4,
};

export const FARE_CLASS_DETAILS_I18N_KEY = {
  0: "fareClass.basicDetails",
  1: "fareClass.standardDetails",
  2: "fareClass.enhancedDetails",
  3: "fareClass.premiumDetails",
  4: "fareClass.luxuryDetails",
};

export interface IRestrictionProps {
  symbol: AirRestrictionStatus;
  description: string;
  key?: string;
}

export interface FareDetailsComponentProps extends PlatformComponentProps {
  fareSlice: FareSliceDetailsV2;
  segments: TripSegment[];
  restrictions?: AmenitiesAndUtasPerSlice;
  multiFareNotice?: boolean;
  numberOfFareDetails?: number;
  showAllFareDetails?: boolean;
}

export const FareDetails = ({
  fareSlice,
  multiFareNotice = false,
  numberOfFareDetails,
  showAllFareDetails = false,
  restrictions,
  segments,
  className,
  children,
}: FareDetailsComponentProps) => {
  const { t } = useI18nContext();
  const MAX_FARE_DETAILS = showAllFareDetails
    ? Number.MAX_SAFE_INTEGER
    : numberOfFareDetails != null
    ? numberOfFareDetails
    : config.MAX_FARE_DETAILS;

  const [showAllDetails, setShowAllDetails] = useState(showAllFareDetails);

  const styles = useFlightStyles(
    CoreFlightsComponent.FareListFareDetails,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FareListFareDetails
  );

  const isViSlice = useMemo(
    () =>
      fareSlice.fareDetails.segments.some(
        (segment) => segment.isSelfTransferLayover
      ),
    [fareSlice.fareDetails.segments]
  );

  const getUtaCategoryLabel = React.useCallback(
    (uta: UtaCategory) => localizedUtaCategoryLabel(uta, t),
    [t]
  );
  const getAmenityLabel = React.useCallback(
    (key: keyof Amenities) => localizedAmenityLabel(key, t),
    [t]
  );

  const fareDetailsList = useMemo(() => {
    const amenitiesUtas = fareSlice.amenitiesUtas;
    if (amenitiesUtas == null) {
      return [];
    }
    return collectRestrictions(
      amenitiesUtas,
      getUtaCategoryLabel,
      getAmenityLabel
    );
  }, [fareSlice.amenitiesUtas, getUtaCategoryLabel, getAmenityLabel]);

  const fareDetailsIcon: { [k in AirRestrictionStatus]: React.ReactNode } =
    useMemo(
      () => ({
        [AirRestrictionStatus.INCLUDED]: (
          <Icon
            className={cn("icon", { available: true })}
            iconName={IconNameEnum.fareDetailsAvailable}
          />
        ),
        [AirRestrictionStatus.PURCHASABLE]: (
          <Icon
            className={cn("icon", { charge: true })}
            iconName={IconNameEnum.fareDetailsCharge}
          />
        ),
        [AirRestrictionStatus.UNAVAILABLE]: (
          <Icon
            className={cn("icon", { unavailable: true })}
            iconName={IconNameEnum.fareDetailsUnavailable}
          />
        ),
        [AirRestrictionStatus.GENERIC]: (
          <Icon
            className={cn("icon", { generic: true })}
            iconName={IconNameEnum.fareDetailsGeneric}
          />
        ),
        [AirRestrictionStatus.UNKNOWN]: null,
      }),
      [cn]
    );

  return (
    <div className={clsx(block, className)}>
      {isViSlice && restrictions != null ? (
        <ul className={cn("segments-list")}>
          {restrictions.amenitiesAndUtas.map((amenitiesUtas, index) => {
            const segment = segments[index];
            return (
              <li className={cn("segment")} key={index}>
                <div className={cn("segment-route")}>
                  <Trans
                    i18nKey="core-flights.vi.segmentRoute"
                    values={{
                      originName: segment.originName,
                      originCode: segment.originCode,
                      destinationName: segment.destinationName,
                      destinationCode: segment.destinationCode,
                    }}
                    components={{
                      bold: <span className={cn("segment-route", ["bold"])} />,
                    }}
                  />
                </div>
                <div className={cn("segment-airline")}>
                  <AirlineIcon size="small" airlineCode={segment.airlineCode} />{" "}
                  {segment.airlineName}
                </div>
                <ul className={cn("list")} key={index}>
                  <FareDetailsList
                    restrictions={collectRestrictions(
                      amenitiesUtas,
                      getUtaCategoryLabel,
                      getAmenityLabel
                    )}
                    fareShelf={fareSlice.fareShelf}
                    showAllDetails={showAllDetails}
                    setShowAllDetails={setShowAllDetails}
                    icons={fareDetailsIcon}
                    maxRestrictions={MAX_FARE_DETAILS}
                    classes={{
                      restriction: cn("item"),
                      detailsButton: cn("more-details-button"),
                      generalDetail: cn("general-detail"),
                    }}
                  />
                </ul>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className={cn("list")}>
          <FareDetailsList
            restrictions={fareDetailsList}
            fareShelf={fareSlice.fareShelf}
            showAllDetails={showAllDetails}
            setShowAllDetails={setShowAllDetails}
            icons={fareDetailsIcon}
            maxRestrictions={MAX_FARE_DETAILS}
            classes={{
              restriction: cn("item"),
              detailsButton: cn("more-details-button"),
              generalDetail: cn("general-detail"),
            }}
          />
        </ul>
      )}
      {/* TODO: tooltip */}
      {multiFareNotice ? (
        <div className={cn("multi-fare-notice")}>
          {t("combinationFlightWarning")}
        </div>
      ) : null}
      {children}
    </div>
  );
};

const FareDetailsList = ({
  restrictions,
  fareShelf,
  showAllDetails,
  setShowAllDetails,
  icons,
  classes,
  maxRestrictions,
}: {
  showAllDetails: boolean;
  setShowAllDetails: (showAll: boolean) => void;
  restrictions: IRestrictionProps[];
  icons: { [k in AirRestrictionStatus]: React.ReactNode };
  fareShelf?: SegmentShelf;
  maxRestrictions: number;
  classes: {
    restriction: string;
    detailsButton: string;
    generalDetail: string;
  };
}) => {
  const { t } = useI18nContext();
  return restrictions.length > 0 ? (
    <>
      {restrictions
        ?.slice(0, showAllDetails ? restrictions.length : maxRestrictions)
        .map((restriction, i) => (
          <li className={classes.restriction} key={`fare-details-${i}`}>
            {icons[restriction.symbol]}
            <div>{restriction.description}</div>
          </li>
        ))}
      {restrictions.length > maxRestrictions ? (
        <li key={"fare-details-more"}>
          <button
            onClick={() => {
              setShowAllDetails(!showAllDetails);
            }}
            className={classes.detailsButton}
          >
            {!showAllDetails
              ? t("moreFareDetails", {
                  number: restrictions.length - maxRestrictions,
                })
              : t("lessFareDetails")}
          </button>
        </li>
      ) : null}
    </>
  ) : fareShelf == null ? null : (
    <li className={classes.generalDetail}>
      {t(FARE_CLASS_DETAILS_I18N_KEY[fareShelf.rating])}
    </li>
  );
};
