import {
  CoreFlightsComponent,
  PlatformComponentProps,
} from "@b2bportal/core-types";
import defaultStyles from "./ReviewFlightHeader.module.scss";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import clsx from "clsx";
import { useI18nContext } from "@hopper-b2b/i18n";

export interface ReviewFlightHeaderComponentProps
  extends PlatformComponentProps {}
export const ReviewFlightHeader = ({
  className,
}: ReviewFlightHeaderComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.ReviewFlightHeader;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();

  return (
    <div className={clsx(block, className)}>
      <h2 className={cn("title")}>{t("flightReview.header.title")}</h2>
      <div className={cn("subtitle")}>{t("flightReview.header.subtitle")}</div>
    </div>
  );
};
