import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  type FlightKey,
  type MessageMethod,
  MessageMethodEnum,
  ResponseEnum,
  ShopFilter,
} from "@b2bportal/air-price-watch-api";
import type { PredictionCopy } from "@b2bportal/air-shopping-api";
import { createWatch, trackEvent } from "@hopper-b2b/api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { SessionContext } from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import { ReactComponent as CloseIcon } from "../../../assets/client/close.svg";
import confirmationIcon from "../../../assets/client/green-confirmation-check.svg";
import { openNubankTermsAndConditions } from "../../../utils/nubankHandoff";
import { PATH_TRIPS } from "../../../utils/urlPaths";
import { ConfirmDeleteModal } from "../../ConfirmDeleteModal";
import MobileInfoPopover from "../../MobileInfoPopover";
import PriceWatchPopoverContent from "../../PriceWatchPopoverContent";
import Switch from "../../Switch/component";
import styles from "./styles.module.scss";

enum events {
  VIEWED_PRICE_WATCH_ENTRY = "viewed_price_watch_entry",
  CLOSED_PRICE_WATCH_INFO = "closed_price_watch_info",
  ADD_PRICE_WATCH = "add_price_watch",
  REMOVE_PRICE_WATCH = "remove_price_watch",
}

interface PriceWatchEntryCardProps {
  predictionCopy?: PredictionCopy;
  deleteWatch: () => void;
  watching: boolean;
  onWatchingChange: (isWatching: boolean) => void;
  priceWatchAlertKey?: FlightKey;
  paxCount?: { [key: string]: number };
}

const PriceWatchEntryCard = ({
  watching,
  priceWatchAlertKey,
  paxCount,
  predictionCopy,
  deleteWatch,
  onWatchingChange,
}: PriceWatchEntryCardProps) => {
  const [priceWatchHalfSheetOpen, setPriceWatchHalfSheetOpen] = useState(false);
  const [priceWatchSuccessful, setPriceWatchSuccessful] = useState(false);
  const [priceWatchWatchingHalfSheetOpen, setPriceWatchWatchingHalfSheetOpen] =
    useState(false);
  const [renderPriceWatchToast, setRenderPriceWatchToast] = useState(null);
  const [renderConfirmDeleteModal, setRenderConfirmDeleteModal] =
    useState(false);
  const { t } = useI18nContext();
  const { email } = useContext(SessionContext);

  useEffect(() => {
    if (renderPriceWatchToast) {
      setTimeout(() => setRenderPriceWatchToast(null), 3500);
    }
  }, [renderPriceWatchToast]);

  const baseTrackingProperties = useMemo(() => {
    return {
      origin: priceWatchAlertKey.value.origin.code,
      destination: priceWatchAlertKey.value.destination.code,
      days_until_departure: `${dayjs(
        priceWatchAlertKey.value.departureDate
      ).diff(dayjs(), "days")} days`,
      dealness: predictionCopy.dealness,
    };
  }, [
    predictionCopy.dealness,
    priceWatchAlertKey.value.departureDate,
    priceWatchAlertKey.value.destination.code,
    priceWatchAlertKey.value.origin.code,
  ]);

  const openHalfSheetHandler = useCallback(() => {
    setPriceWatchHalfSheetOpen(true);
    setRenderPriceWatchToast(null);
    trackEvent({
      eventName: events.VIEWED_PRICE_WATCH_ENTRY,
      properties: { ...baseTrackingProperties },
    });
  }, [baseTrackingProperties, deleteWatch, onWatchingChange, watching]);

  const closeHalfSheetHandler = useCallback(() => {
    setPriceWatchHalfSheetOpen(false);
    trackEvent({
      eventName: events.CLOSED_PRICE_WATCH_INFO,
      properties: { ...baseTrackingProperties },
    });
  }, [baseTrackingProperties]);

  const openWatchingHalfSheetHandler = useCallback(() => {
    setPriceWatchWatchingHalfSheetOpen(true);
  }, []);

  const closeWatchingHalfSheetHandler = useCallback(() => {
    setPriceWatchWatchingHalfSheetOpen(false);
  }, []);

  const trackPriceWatchHandler = useCallback(
    (method: { method: MessageMethod }, filter: ShopFilter) => {
      const payload = {
        key: {
          ...priceWatchAlertKey,
          ...{ value: { ...priceWatchAlertKey.value, filter: filter } },
        },
        ...method,
        passengers: paxCount,
      };

      setPriceWatchHalfSheetOpen(false);

      createWatch(payload)
        .then((res: { value: any; Response: ResponseEnum }) => {
          setPriceWatchSuccessful(res.Response === ResponseEnum.Success);
          onWatchingChange(true);
          setRenderPriceWatchToast("create");
          trackEvent({
            eventName: events.ADD_PRICE_WATCH,
            properties: {
              ...baseTrackingProperties,
              successful: res.Response === ResponseEnum.Success,
              method: method.method.MessageMethod,
              filter: filter,
            },
          });
        })
        .catch((e) => {
          setPriceWatchSuccessful(false);
          openWatchingHalfSheetHandler();
          trackEvent({
            eventName: events.ADD_PRICE_WATCH,
            properties: {
              ...baseTrackingProperties,
              successful: false,
              method: method.method.MessageMethod,
              filter: filter,
            },
          });
        });
    },
    [
      baseTrackingProperties,
      onWatchingChange,
      openWatchingHalfSheetHandler,
      paxCount,
      priceWatchAlertKey,
    ]
  );

  const confirmDelete = useCallback(() => {
    deleteWatch();
    setRenderConfirmDeleteModal(false);
    setRenderPriceWatchToast("delete");
    onWatchingChange(false);
    trackEvent({
      eventName: events.REMOVE_PRICE_WATCH,
      properties: { ...baseTrackingProperties },
    });
  }, [deleteWatch, setRenderPriceWatchToast]);

  const handleToggleAlert = useCallback(() => {
    if (watching) {
      setRenderConfirmDeleteModal(true);
    } else {
      const priceWatchInfo: { method: MessageMethod } = {
        method: {
          MessageMethod: MessageMethodEnum.MultiMethod,
          ...{ email: { email } },
          ...{ push: {} },
        },
      };
      trackPriceWatchHandler(priceWatchInfo, ShopFilter.NoFilter);
      trackEvent({
        eventName: events.VIEWED_PRICE_WATCH_ENTRY,
        properties: { ...baseTrackingProperties },
      });
    }
  }, [baseTrackingProperties, deleteWatch, onWatchingChange, watching]);

  return (
    <>
      <div className={styles.PriceWatchEntryCard}>
        <p>
          {watching
            ? t("priceWatch.alertActive")
            : t("priceWatch.alertInactive")}
        </p>
        <Switch onClick={handleToggleAlert} checked={watching} />
      </div>
      <MobileInfoPopover
        isOpen={priceWatchHalfSheetOpen}
        onClose={closeHalfSheetHandler}
        primaryButtonAction={null}
        primaryButtonText={""}
        closeIcon={<CloseIcon className={styles.Close} />}
        className={styles.PriceWatchMobilePopover}
        header={t("priceWatch.alertModalHeader")}
      >
        <PriceWatchPopoverContent onSubmit={trackPriceWatchHandler} />
      </MobileInfoPopover>
      {renderPriceWatchToast ? (
        <div className={styles.PriceWatchToast}>
          <div className="toast-message">
            {renderPriceWatchToast === "create"
              ? t("priceWatch.toastAlertActive")
              : t("priceWatch.toastAlertDeleted")}
          </div>
          {renderPriceWatchToast === "create" ? (
            <button className="toast-button" onClick={openHalfSheetHandler}>
              {t("priceWatch.toastManageAlert")}
            </button>
          ) : null}
        </div>
      ) : null}
      <PriceWatchWatching
        isOpen={priceWatchWatchingHalfSheetOpen}
        onClose={closeWatchingHalfSheetHandler}
        isSuccessful={priceWatchSuccessful}
      />
      <ConfirmDeleteModal
        open={renderConfirmDeleteModal}
        closeModal={() => setRenderConfirmDeleteModal(false)}
        onDelete={confirmDelete}
      />
    </>
  );
};

const PriceWatchWatching = ({
  isOpen,
  onClose,
  isSuccessful,
}: {
  isOpen: boolean;
  onClose: () => void;
  isSuccessful: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useI18nContext();

  const myTripsHandler = useCallback(() => {
    navigate(PATH_TRIPS);
    onClose();
  }, [navigate, onClose]);

  const openTermsHandler = useCallback(() => {
    openNubankTermsAndConditions();
  }, []);

  const content = useMemo(() => {
    return isSuccessful
      ? {
          title: t("priceWatch.watchedHeader"),
          subtitle: (
            <Trans
              i18nKey={"priceWatch.watchedSubtitle"}
              components={[
                <ButtonWrap
                  className="my-trips-link"
                  onClick={myTripsHandler}
                />,
              ]}
            />
          ),
          primaryButtonAction: onClose,
          primaryButtonText: t("priceWatch.continueSearch"),
          children: (
            <p className="instructions">
              <Trans
                i18nKey={"priceWatch.termsAndConditions"}
                components={[
                  <ButtonWrap
                    className="terms-link"
                    onClick={openTermsHandler}
                  />,
                ]}
              />
            </p>
          ),
          icon: confirmationIcon,
        }
      : {
          title: t("priceWatch.failure.title"),
          subtitle: t("priceWatch.failure.createWatchSubtitle"),
          primaryButtonAction: onClose,
          primaryButtonText: t("tryAgain"),
          secondaryButtonAction: onClose,
          secondaryButtonText: t("cancel"),
        };
  }, [isSuccessful, myTripsHandler, onClose, openTermsHandler, t]);

  return (
    <MobileInfoPopover
      isOpen={isOpen}
      onClose={onClose}
      {...content}
      closeIcon={<CloseIcon className={styles.Close} />}
      className={styles.PriceWatchWatching}
    >
      {content.children}
    </MobileInfoPopover>
  );
};
export default PriceWatchEntryCard;
