import {
  CallState,
  type HotelSortOptionEnum,
  type SearchDetails,
  type FilterState,
  type PriceRange,
  type MapState,
} from "@b2bportal/core-types";
import { toMapCoordinatesType } from "@b2bportal/core-utilities";
import {
  type GuestsSelection,
  type Suggestion,
  type Initial,
  type More,
  type HotelStarRating,
  type Amenity,
  type DateRange,
  LodgingSelectionEnum,
} from "@b2bportal/lodging-api";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  initialFiltersState,
  initialHotelAvailabilityState,
  initialHotelsAvailabilitySearchState,
} from "./initialState";
import { fetchHotelsNextPage } from "./thunks/fetchHotelsNextPage";
import { fetchInitialHotels } from "./thunks/fetchInitialHotels";
import { initializeSearchState } from "./thunks/initializeHotels";
import type {
  HotelsAvailabilitySearchState,
  HotelsAvailabilityState,
  SearchId,
} from "./types";

const handleFetchInitialFulfilled = (
  state: HotelsAvailabilityState,
  action: PayloadAction<Initial & SearchId>
) => {
  const setCentroid = () => {
    state.map = {
      centroid: action.payload.centroid
        ? toMapCoordinatesType(action.payload.centroid)
        : undefined,
      zoom: initialHotelsAvailabilitySearchState.map.zoom,
    };
  };

  // Autocomplete place search, not map search
  if (state.search?.searchType !== LodgingSelectionEnum.Location) setCentroid();

  state.hotels = {
    state: CallState.Success,
    data: {
      items: action.payload.lodgings,
      searchId: action.payload.searchId,
      nextPageToken: action.payload.nextPageToken ?? null,
    },
  };
};

const handleFetchNextPageFulfilled = (
  state: HotelsAvailabilityState,
  action: PayloadAction<More & SearchId>
) => {
  state.hotels =
    state.hotels.data?.searchId === action.payload.searchId
      ? {
          state: CallState.Success,
          data: {
            items:
              state.hotels.data?.items != null
                ? [...state.hotels.data.items, ...action.payload.lodgings]
                : action.payload.lodgings,
            nextPageToken: action.payload.nextPageToken ?? null,
            searchId: action.payload.searchId,
          },
        }
      : state.hotels;
};

export const handleFetchInitialPending = (state: HotelsAvailabilityState) => {
  state.search = state.requestedSearch;
  state.hotels = {
    state: CallState.InProcess,
    data: {
      items: [],
      nextPageToken: null,
      searchId: state.hotels.data?.searchId ?? null,
    },
  };
};

export const handleFetchInitialRejected = (
  state: HotelsAvailabilityState,
  action: PayloadAction<{ error: string } | undefined>
) => {
  state.hotels = {
    state: CallState.Failed,
    data: {
      items: [],
      nextPageToken: null,
      searchId: null,
    },
    error: action.payload?.error ?? "Error undefined",
  };
};

export const handleFetchNextPagePending = (state: HotelsAvailabilityState) => {
  state.hotels.state = CallState.InProcess;
};

export const handleFetchNextPageRejected = (
  state: HotelsAvailabilityState,
  action: PayloadAction<{ error: string } | undefined>
) => {
  state.hotels = {
    data: state.hotels.data,
    state: CallState.Failed,
    error: action.payload?.error ?? "Error undefined",
  };
};

export const hotelsAvailabilitySlice = createSlice({
  name: "hotelsAvailability",
  initialState: initialHotelAvailabilityState,
  reducers: {
    setNewGuests: (state, action: PayloadAction<GuestsSelection>) => {
      state.searchFormValues.guests = action.payload;
    },
    setNewPlace: (state, action: PayloadAction<Suggestion>) => {
      state.searchFormValues.place = action.payload;
    },
    setNewDateFrom: (state, action: PayloadAction<string | undefined>) => {
      state.searchFormValues.dateRange = {
        ...state.searchFormValues.dateRange,
        from: action.payload,
      };
    },
    setNewDateUntil: (state, action: PayloadAction<string | undefined>) => {
      state.searchFormValues.dateRange = {
        ...state.searchFormValues.dateRange,
        until: action.payload,
      };
    },
    setNewDateRange: (state, action: PayloadAction<DateRange>) => {
      state.searchFormValues.dateRange = action.payload;
    },
    setRequestedSearch: (state, action: PayloadAction<SearchDetails>) => {
      state.searchFormValues = action.payload;
      state.requestedSearch = action.payload;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    setAllFilters(state, action: PayloadAction<FilterState>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    setPriceFilter(state, action: PayloadAction<PriceRange>) {
      state.filters.priceRange = action.payload;
    },
    setStarRatingFilter(state, action: PayloadAction<HotelStarRating[]>) {
      state.filters.starRating = action.payload;
    },
    setCancellationFilter(state, action: PayloadAction<boolean>) {
      state.filters.freeCancellation = action.payload;
    },
    setAmenitiesFilter(state, action: PayloadAction<Amenity[]>) {
      state.filters.amenities = action.payload;
    },
    setSort: (state, action: PayloadAction<HotelSortOptionEnum>) => {
      state.sort = action.payload;
    },
    setMapData: (state, action: PayloadAction<MapState>) => {
      state.map = action.payload;
    },
    handleFetchInitialPending,
    handleFetchInitialRejected,
    handleFetchInitialFulfilled,
    handleFetchNextPagePending,
    handleFetchNextPageRejected,
    handleFetchNextPageFulfilled,
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchFlights
      .addCase(initializeSearchState.pending, (state) => {
        state.initialized = CallState.InProcess;
        state.hotels = initialHotelAvailabilityState.hotels;
      })
      .addCase(
        initializeSearchState.fulfilled,
        (state, action: PayloadAction<HotelsAvailabilitySearchState>) => {
          state.initialized = CallState.Success;
          state.requestedSearch = action.payload.requestedSearch;
          state.searchFormValues = action.payload.searchFormValues;
          state.map = action.payload.map ?? state.map;
          state.filters = action.payload.filters ?? state.filters;
          state.sort = action.payload.sort ?? state.sort;
        }
      )
      .addCase(initializeSearchState.rejected, (state) => {
        state.initialized = CallState.Failed;
      })
      .addCase(fetchInitialHotels.pending, handleFetchInitialPending)
      .addCase(fetchInitialHotels.fulfilled, handleFetchInitialFulfilled)
      .addCase(fetchInitialHotels.rejected, handleFetchInitialRejected)
      .addCase(fetchHotelsNextPage.pending, handleFetchNextPagePending)
      .addCase(fetchHotelsNextPage.fulfilled, handleFetchNextPageFulfilled)
      .addCase(fetchHotelsNextPage.rejected, handleFetchNextPageRejected);
  },
});

export type HotelsAvailabilityActionTypes = ReturnType<
  (typeof HotelsAvailabilityActions)[keyof typeof HotelsAvailabilityActions]
>;

export const HotelsAvailabilityActions = hotelsAvailabilitySlice.actions;

export default hotelsAvailabilitySlice.reducer;
