import {
  FuelType,
  type Vehicle,
  VehicleCategory,
  VehicleType,
  TransmissionType,
  RestrictionCancelEnum,
} from "@b2bportal/ground-api";
import type { TFunction } from "i18next";
import type { VehicleAvailabilityWithSupplier } from "..";

export const VehicleClass = {
  EV: "EV",
  MiniCompact: "MiniCompact",
  Standard: "Standard",
  Fullsize: "Fullsize",
  PremiumLuxury: "PremiumLuxury",
  MinivanWagon: "MinivanWagon",
  SUVSpecialty: "SUVSpecialty",
  Special: "Special",
} as const;
export type VehicleClass = keyof typeof VehicleClass;

/**
 * see https://hopper-jira.atlassian.net/wiki/x/BADEoAE
 */
export const getVehicleClass = (vehicle: Vehicle) => {
  if (vehicle.fuelType === FuelType.Electric) {
    return VehicleClass.EV;
  }
  switch (vehicle.category) {
    case VehicleCategory.Mini:
    case VehicleCategory.MiniElite:
    case VehicleCategory.Economy:
    case VehicleCategory.EconomyElite:
    case VehicleCategory.Compact:
    case VehicleCategory.CompactElite:
      return VehicleClass.MiniCompact;

    case VehicleCategory.Intermediate:
    case VehicleCategory.IntermediateElite:
    case VehicleCategory.Standard:
    case VehicleCategory.StandardElite:
      return VehicleClass.Standard;

    case VehicleCategory.Fullsize:
    case VehicleCategory.FullsizeElite:
      return VehicleClass.Fullsize;

    case VehicleCategory.Premium:
    case VehicleCategory.PremiumElite:
    case VehicleCategory.Luxury:
    case VehicleCategory.LuxuryElite:
      return VehicleClass.PremiumLuxury;

    case VehicleCategory.Oversize:
      return VehicleClass.MinivanWagon;

    case VehicleCategory.Special:
      return VehicleClass.Special;

    default:
    // try using vehicle type
  }

  switch (vehicle.vehicleType) {
    case VehicleType.WagonEstate:
    case VehicleType.PassengerVan:
    case VehicleType.PickUpSingleExtendedCar:
    case VehicleType.PickUpDoubleCab:
    case VehicleType.Crossover:
    case VehicleType.CommercialVanTruck:
      return VehicleClass.MinivanWagon;

    case VehicleType.SUV:
    case VehicleType.OpenAirAllTerrain:
      return VehicleClass.SUVSpecialty;

    case VehicleType.SpecialCar:
      return VehicleClass.Special;

    case VehicleType.SportsCar:
    case VehicleType.Convertible:
      return VehicleClass.PremiumLuxury;

    default:
      // TODO: log datadog error to track any unexpected vehicle class
      return VehicleClass.Standard;
  }
};

export const getVehicleClassLabel = (
  t: TFunction,
  vehicleClass: VehicleClass
) => {
  return {
    [VehicleClass.EV]: t("apac.cars.vehicleClass.EV"),
    [VehicleClass.MiniCompact]: t("apac.cars.vehicleClass.MiniCompact"),
    [VehicleClass.Standard]: t("apac.cars.vehicleClass.Standard"),
    [VehicleClass.Fullsize]: t("apac.cars.vehicleClass.Fullsize"),
    [VehicleClass.PremiumLuxury]: t("apac.cars.vehicleClass.PremiumLuxury"),
    [VehicleClass.MinivanWagon]: t("apac.cars.vehicleClass.MinivanWagon"),
    [VehicleClass.SUVSpecialty]: t("apac.cars.vehicleClass.SUVSpecialty"),
    [VehicleClass.Special]: t("apac.cars.vehicleClass.Special"),
  }[vehicleClass];
};

export const getVehicleClassLabelFromVehicle = (
  t: TFunction,
  vehicle: Vehicle | undefined
) => {
  if (vehicle == null) {
    return;
  }
  const vehicleClass = getVehicleClass(vehicle);
  return getVehicleClassLabel(t, vehicleClass);
};

export const getVehicleTransmissionLabel = (
  t: TFunction,
  transmission: TransmissionType
) => {
  return {
    [TransmissionType.Automatic]: t("apac.cars.transmission.Automatic"),
    [TransmissionType.Cvt]: t("apac.cars.transmission.Cvt"),
    [TransmissionType.Manual]: t("apac.cars.transmission.Manual"),
  }[transmission];
};

export const getBaggageQty = (vehicle: Vehicle) => {
  return Math.max(...Object.values(vehicle.bagsInfo));
};

export const getIsFreeCancel = (
  availability: VehicleAvailabilityWithSupplier
) => {
  const cancellationPolicy = availability.cancellationPolicy;
  const isFreeCancellation =
    cancellationPolicy?.RestrictionCancel !== RestrictionCancelEnum.Paid &&
    cancellationPolicy?.RestrictionCancel !==
      RestrictionCancelEnum.NonRefundable;

  return isFreeCancellation ? "freeCancellation" : "Unknown";
};
