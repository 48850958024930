import {
  ContactEventType,
  type ContactEvents,
  TravelerEventTypes,
} from "@checkout/states";
import type { TravelerEvents } from "@checkout/states/common/TravelerInformation/events";

export enum ContactAndTravelerInformationState {
  idle = "idle",
  createUser = "createUser",
  loading = "loading",
  travelerPicker = "travelerPicker",
  travelerForm = "travelerForm",
  validate = "validate",
  deleteModal = "deleteModal",
  error = "error",
  saving = "saving",
}

export enum ContactAndTravelerInformationChildState {
  idle = "idle",
  new = "new",
  edit = "edit",
  update = "update",
  delete = "delete",
  add = "add",
  validate = "validate",
  route = "route",
  fetch = "fetch",
}

export const ContactAndTravelerInformationEventTypes = {
  ...TravelerEventTypes,
  ...ContactEventType,
};

export type ContactAndTravelerInformationEvents =
  | TravelerEvents
  | ContactEvents;
