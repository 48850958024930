import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  matchPath,
  Route,
  Routes,
  useLocation,
} from "react-router-dom-v5-compat";

import type { ChatPropertiesType } from "@b2bportal/chat-api";
import { ExperimentsProvider } from "@hopper-b2b/experiments";
import I18nProvider, { getLang } from "@hopper-b2b/i18n";
import { PortalCore } from "@hopper-b2b/portal";
import {
  createGeneralSupportConversation,
  createSupportConversationForProduct,
  SupportContextProvider,
  useKustomerConfig,
} from "@hopper-b2b/self-serve";
import { installCssConfig } from "@hopper-b2b/themes";
import { ClientName } from "@hopper-b2b/types";
import {
  type ISessionContextProps,
  SessionContextProvider,
  SessionStateEnum,
  TenantContextProvider,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";

import "./App.css";
import "./fonts.scss";

import corePortalConfigFromBasicConfig from "../config";
import type { NubankSessionInfo } from "./api/session/startSession";
import { ProtectedRoute } from "./components/Auth";
import { AuthModule } from "./components/AuthModule";
import AxiosInterceptorWrapper from "./components/AxiosInterceptorWrapper";
import Body from "./components/Body";
import { darkModeIcons, lightModeIcons } from "./components/Body/sharedIcons";
import { B2BGenericFooter } from "./components/Footer";
import Header from "./components/Header";
import { ImpersonationBanner } from "./components/ImpersonationBanner";
import { TermsAndConditionsWrapper } from "./components/TermsAndConditionsWrapper";
import { useWindowSize } from "./hooks/useWindowSize";
import { branding } from "./modules/branding";

import { muiDarkTheme, nubankDarkVariables } from "./modules/darkTheme";
import { muiTheme, nubankVariables } from "./modules/defaultTheme";
import {
  muiUltravioletaDarkTheme,
  nubankUltravioletaDarkVariables,
} from "./modules/ultravioletaDarkTheme";
import {
  muiUltravioletaTheme,
  nubankUltravioletaVariables,
} from "./modules/ultravioletaDefaultTheme";

import coreStore from "./store";
import {
  getColorModeCookie,
  isUserTypeCookieUltravioleta,
  useDarkModePreferred,
} from "./utils/colors";
import config from "./utils/config";
import {
  apiConfig,
  HIDDEN_FOOTER_PATHS,
  HIDDEN_HEADER_PATHS,
  HIDDEN_HEADER_PATHS_MOBILE,
  PATH_HOTELS_AVAILABILITY,
  POSITION_FIXED_FOOTER_PATHS,
  ROUTE_AUTH,
} from "./utils/urlPaths";

// Nubank Feature Flags (copied from Uber)
export enum FEATURE_FLAGS {
  MAINTENANCE = "nubank-maintenance",

  // Fintech Flags
  AIR_CFAR = "nubank-cfar",
  AIR_CHFAR = "nubank-chfar",
  AIR_DISRUPTION = "nubank-disruption",
  AIR_DISRUPTION_EXERCISE_REBOOKING = "nubank-air-disruption-exercise-rebooking",
  AIR_MISSED_CONNECTION = "nubank-air-missed-connection",
  AIR_PRICE_FREEZE = "nubank-air-price-freeze",
  AIR_PRICE_WATCH = "FlightsPriceWatch",
  PRICE_PREDICTION = "nubank-price-prediction",
  PRICE_DROP_PROTECTION = "nubank-price-drop-protection",
  DISRUPTION_PRODUCT_AVAILABILITY = "DisruptionProductAvailability",
  NUBANK_DISRUPTION_FE = "nubank-disruption-fe",
  ENABLE_DISRUPTION_EXERCISE_REBOOK = "EnableDisruptionExerciseRebook",

  // Features
  AIR_EXCHANGE = "air-self-serve-exchange",
  APP_DARKMODE = "darkmode",
  AIR_SHOP_V4 = "nubank-air-shop-v4",
  LOADING_STEPS = "nubank-loading-steps",
  LODGING = "lodging",
  HFv2 = "ProvidersExperimentEnableHF",
  WALLET_CREDITS = "nubank-wallet-credits",
  WALLET_OFFERS = "nubank-wallet-offer",
  SHOW_LODGING_MAP = "lodging-map",
  LODGING_EXPRESS_CHECKOUT = "LodgingExpressCheckout",
}

export enum EXPERIMENTS {
  AIR_FINTECH_SELECTION = "nubank-air-fintech-selection",
  HIDE_AIR_ITINERARY_REVIEW = "nubank-air-hide-itinerary-review",
  HIDE_FARE_DETAILS = "nubank-air-hide-fare-details",
  HIDE_SEATS = "nubank-hide-seats",
  VOUCHERS_10 = "hotel-voucher-discount-2024-10-percent",
  VOUCHERS_20 = "hotel-voucher-discount-2024-20-percent",
  REPLACE_MATERIAL_UI = "ReplaceMaterialUI",
  RENDER_LODGING_STRIKETHROUGH_PRICE = "RenderLodgingStrikethroughPrice",
  RENDER_HOTEL_MAP_ENTRY_POINT = "RenderHotelMapEntryPoint",
  NUBANK_BLACK_FRIDAY = "NubankBlackFriday",
  MAX_INSTALLMENTS = "MaxNupayInstallments",
  RENDER_LODGING_SHOP_VALUE_PROPS = "RenderLodgingShopValueProps",
  RENDER_EXTRA_HOTEL_AMENITIES = "RenderExtraHotelAmenities",
  NUBANK_TRAVEL_INSURANCE = "NubankTravelInsurance",
  RENDER_HOTEL_MAP_AS_DEFAULT = "RenderHotelMapAsDefaultView",
  HOTEL_DETAILS_CTA_COPY = "HotelDetailsCtaCopy",
  ROOM_DETAILS_CTA_COPY = "RoomDetailsCtaCopy",
  INSTALLMENTS_CAMPAIGN = "InstallmentsCampaign",
  CROSS_SELL_CONFIRMATION_SCREEN = "CrossSellConfirmationScreen",
  NUBANK_CHAT_ENTRYPOINT = "nubank-chat-entrypoint",
}

// Uncomment to add partner experiments
// const PARTNER_EXPERIMENTS_QUERY_PARAM = "partner_experiments";
// const DEFAULT_PARTNER_EXPERIMENTS = "air_fintech_control";

// const PARTNER_EXPERIMENTS_SESSION_STORAGE_KEY = "b2bportal-partner-experiments";

const generateClassName = createGenerateClassName({
  productionPrefix: "ptBaseModule",
  seed: "ptBaseModule",
});

// TODO: Set default locale
const DEFAULT_LOCALE = getLang("pt-BR");

export const StyleContext = createContext({});

type UserInfoContext = {
  sessionInfo: NubankSessionInfo;
  updateSessionInfo: (info: NubankSessionInfo) => void;
};

export const UserContext = createContext<Partial<UserInfoContext>>({});

const setViewWidthAndHeight = (width: string, height: string) => {
  document.body.style.setProperty(`--vw`, width);
  document.body.style.setProperty(`--vh`, height);
};

const App = () => {
  const kustomerConfig = useKustomerConfig(ClientName.NUBANK, config);
  const location = useLocation();
  const [theme, setTheme] = useState(muiTheme);

  const darkModePreferred = useDarkModePreferred();
  const [isDarkMode, setIsDarkMode] = useState(darkModePreferred);

  const { matchesMobile } = useDeviceTypes();

  // TODO: update to use Nubank's method of notifying us that the user is UV
  const isUltravioletaUser = isUserTypeCookieUltravioleta();

  useEffect(() => {
    if (isUltravioletaUser) {
      if (isDarkMode) {
        setTheme(muiUltravioletaDarkTheme);
      } else {
        setTheme(muiUltravioletaTheme);
      }
    }
    if (isDarkMode) {
      setTheme(muiDarkTheme);
    }
  }, [isDarkMode, isUltravioletaUser]);

  const corePortalConfigTheme = useMemo(() => {
    return corePortalConfigFromBasicConfig(
      isDarkMode,
      isUltravioletaUser ? "uv" : "default"
    );
  }, [isDarkMode, isUltravioletaUser]);

  // NOTE : with corePortalConfigTheme pretty sure we dont need this anymore
  const changeTheme = useCallback(
    (isDark: boolean) => {
      installCssConfig(
        isUltravioletaUser
          ? isDark
            ? nubankUltravioletaDarkVariables
            : nubankUltravioletaVariables
          : isDark
          ? nubankDarkVariables
          : nubankVariables
      );
    },
    [isUltravioletaUser]
  );
  changeTheme(isDarkMode);

  const handleDarkModeChange = useCallback(
    (val: boolean) => {
      setIsDarkMode(val);
      changeTheme(val);
    },
    [changeTheme]
  );

  const [sessionInfo, setSessionInfo] = useState<NubankSessionInfo>(null);

  const updateSessionInfo = (sessionInfo) => {
    setSessionInfo(sessionInfo);
  };

  const sessionContext: ISessionContextProps = useMemo(() => {
    const [firstName, lastName] =
      sessionInfo?.userInfoResponse?.name?.split(" ") || "";
    return {
      sessionType: SessionStateEnum.Authenticated,
      isLoggedIn: true,
      email: sessionInfo?.userInfoResponse?.email,
      firstName,
      lastName,
    };
  }, [sessionInfo]);

  const windowSize = useWindowSize();
  // Add a variable for vh to use for specifying full-screen height
  // 100vh does not work properly on iOS. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  setViewWidthAndHeight(
    `${windowSize.width * 0.01}px`,
    `${windowSize.height * 0.01}px`
  );

  const [launchEventSent, setLaunchEventSent] = useState(false);

  const isWebview = useCallback(() => {
    try {
      return /WebView/.test(navigator.userAgent);
    } catch (e) {
      return false;
    }
  }, []);

  const appClasses = useMemo((): string => {
    const classes = ["App-container"];
    if (isWebview()) {
      classes.push("App-Framed");
    }
    if (isDarkMode) {
      classes.push("dark-mode");
    } else {
      classes.push("light-mode");
    }
    if (isUltravioletaUser) {
      classes.push("ultravioleta");
    }
    return classes.join(" ");
  }, [isDarkMode, isUltravioletaUser, isWebview]);

  useEffect(() => {
    const cookiePresent = getColorModeCookie();
    if (cookiePresent) {
      document.body.classList.add(isDarkMode ? "dark-mode" : "light-mode");
      document.body.classList.remove(isDarkMode ? "light-mode" : "dark-mode");
    }
  }, [darkModePreferred, isDarkMode]);

  const openSupportChat = (
    productId?: string,
    productType?: ChatPropertiesType,
    requestType?: string
  ) => {
    if (!productId || !productType || !requestType) {
      console.error("Missing required parameters to open Kustomer chat");
      return;
    }

    createSupportConversationForProduct(productId, productType, requestType);
  };

  // Uncomment to add partner experiments
  // const partnerExperiments = useMemo(() => {
  //   let partnerExperiments = new URLSearchParams(location.search).get(
  //     PARTNER_EXPERIMENTS_QUERY_PARAM
  //   );

  //   if (partnerExperiments) {
  //     console.debug("Partner supplied experiments", partnerExperiments);
  //     sessionStorage.setItem(
  //       PARTNER_EXPERIMENTS_SESSION_STORAGE_KEY,
  //       partnerExperiments
  //     );
  //   } else {
  //     partnerExperiments = sessionStorage.getItem(
  //       PARTNER_EXPERIMENTS_SESSION_STORAGE_KEY
  //     );
  //     console.debug(
  //       "Retrieving partner experiments from session",
  //       partnerExperiments
  //     );
  //   }

  //   return partnerExperiments || DEFAULT_PARTNER_EXPERIMENTS;
  // }, [location.search]);

  const matchPaths = useCallback(
    (arr: string[]) => arr.some((path) => matchPath(path, location.pathname)),
    [location.pathname]
  );

  const showHeader = useMemo(
    () =>
      !matchPaths(
        matchesMobile ? HIDDEN_HEADER_PATHS_MOBILE : HIDDEN_HEADER_PATHS
      ),
    [matchPaths, matchesMobile]
  );

  const showFooter = useMemo(
    () =>
      !matchPaths(HIDDEN_FOOTER_PATHS) &&
      !location.pathname.includes(PATH_HOTELS_AVAILABILITY),
    [matchPaths, location.pathname]
  );

  const partnerExperiments = useMemo(
    () => sessionInfo?.userInfoResponse?.partnerExperiments || "none",
    [sessionInfo]
  );

  return (
    // note: can remove this fallback when we move to the public/env.js strategy
    // <LoadScript googleMapsApiKey={config.googleMapsApiKey || ""}>
    <div>
      <I18nProvider
        defaultLng={DEFAULT_LOCALE}
        branding={branding}
        useGeneratedTranslations={true}
      >
        <StyleContext.Provider value={{ theme }}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <UserContext.Provider value={{ sessionInfo, updateSessionInfo }}>
                <SessionContextProvider sessionContext={sessionContext}>
                  <ExperimentsProvider
                    apiConfig={apiConfig}
                    isLoggedIn={!!sessionInfo?.userInfoResponse?.email}
                    partnerExperiments={partnerExperiments}
                  >
                    <AxiosInterceptorWrapper
                      hopperSessionToken={sessionInfo?.hopperSessionToken}
                      nubankSessionToken={sessionInfo?.nubankAccessToken}
                    />
                    <TenantContextProviderWithAssets isDarkMode={isDarkMode}>
                      <SupportContextProvider
                        kustomerConfig={{
                          ...kustomerConfig,
                          hopperUserId: sessionInfo?.hopperUserId,
                          kustomerAccessToken:
                            sessionInfo?.userInfoResponse.kustomerAccessToken,
                          forceUserLanguage: "pt-BR",
                        }}
                      >
                        <TermsAndConditionsWrapper>
                          <PortalCore
                            config={corePortalConfigTheme}
                            store={coreStore}
                          >
                            <div className={appClasses}>
                              <Routes>
                                <Route
                                  path={ROUTE_AUTH}
                                  element={<AuthModule />}
                                />
                                <Route
                                  path="/*"
                                  element={
                                    <ProtectedRoute>
                                      <>
                                        {sessionInfo?.isImpersonation ? (
                                          <ImpersonationBanner />
                                        ) : null}
                                        <div className={clsx("App-content")}>
                                          {showHeader ? <Header /> : null}
                                          <Body
                                            theme={theme}
                                            isFirstSession={
                                              sessionInfo?.userInfoResponse
                                                .isFirstSession
                                            }
                                            launchEventSent={launchEventSent}
                                            setLaunchEventSent={
                                              setLaunchEventSent
                                            }
                                            openSupportChat={openSupportChat}
                                            onDarkModeChange={
                                              handleDarkModeChange
                                            }
                                          />
                                        </div>
                                        {showFooter ? (
                                          <B2BGenericFooter
                                            className={clsx("generic-footer", {
                                              "position-fixed":
                                                location.pathname &&
                                                POSITION_FIXED_FOOTER_PATHS.includes(
                                                  location.pathname
                                                ),
                                            })}
                                            openSupportChat={() =>
                                              createGeneralSupportConversation(
                                                "nubank"
                                              )
                                            }
                                          />
                                        ) : null}
                                      </>
                                    </ProtectedRoute>
                                  }
                                />
                              </Routes>
                            </div>
                          </PortalCore>
                        </TermsAndConditionsWrapper>
                      </SupportContextProvider>
                    </TenantContextProviderWithAssets>
                  </ExperimentsProvider>
                </SessionContextProvider>
              </UserContext.Provider>
            </ThemeProvider>
          </StylesProvider>
        </StyleContext.Provider>
      </I18nProvider>
    </div>
    // </LoadScript>
  );
};

const TenantContextProviderWithAssets = ({
  children,
  isDarkMode,
}: {
  children: ReactNode | undefined;
  isDarkMode: boolean;
}) => {
  const lightIcons = {
    locationMarker: lightModeIcons.locationMarker,
    guest: lightModeIcons.user,
    multipleGuests: lightModeIcons.twoUsers,
    hotelIcon: lightModeIcons.hotelIcon,
    back: lightModeIcons.back,
    greenShieldCheck: lightModeIcons.greenShieldCheck,
    grayGuest: lightModeIcons.twoUsers,
    bed: lightModeIcons.bed,
    unavailable: lightModeIcons.unavailable,
    airplaneDouble: lightModeIcons.airplaneDouble,
    backWhite: lightModeIcons.backWhite,
    mapIcon: darkModeIcons.locationMarker,
    reload: lightModeIcons.reload,
    copy: lightModeIcons.copy,
  };
  const darkIcons = {
    locationMarker: darkModeIcons.locationMarker,
    guest: darkModeIcons.user,
    multipleGuests: darkModeIcons.twoUsers,
    hotelIcon: darkModeIcons.hotelIcon,
    back: darkModeIcons.back,
    greenShieldCheck: darkModeIcons.greenShieldCheck,
    grayGuest: darkModeIcons.twoUsers,
    bed: darkModeIcons.bed,
    unavailable: darkModeIcons.unavailable,
    airplaneDouble: lightModeIcons.airplaneDouble,
    backWhite: lightModeIcons.backWhite,
    mapIcon: darkModeIcons.locationMarker,
    reload: darkModeIcons.reload,
    copy: darkModeIcons.copy,
  };

  const tenantContext = {
    icons: isDarkMode ? darkIcons : lightIcons,
  };
  return (
    <TenantContextProvider tenantContext={tenantContext} children={children} />
  );
};

export default App;
