import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from "react";
import { useI18nContext, I18nMarkup, Trans } from "@hopper-b2b/i18n";
import {
  ActionLink,
  ButtonWrap,
  MobileFloatingSection,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import { noop } from "@hopper-b2b/utilities";
import clsx from "clsx";
import Close from "../../assets/client/close.svg";
import CloseWhite from "../../assets/client/darkMode/close-white.svg";
import "./styles.scss";
import { useDarkModePreferred } from "../../utils/colors";

export const TermsAndConditionsContext = createContext<{
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
}>({ openModal: false, setOpenModal: noop });

export const TermsAndConditionsWrapper = ({ children }: PropsWithChildren) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  return (
    <TermsAndConditionsContext.Provider value={{ openModal, setOpenModal }}>
      {children}
      <TermsModal openModal={openModal} setOpenModal={setOpenModal} />
    </TermsAndConditionsContext.Provider>
  );
};

const TermsModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
}) => {
  const { t } = useI18nContext();
  const isDarkMode = useDarkModePreferred();

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  return (
    <MobilePopoverCard
      open={openModal}
      className={clsx("full-screen", "nubank-terms")}
      contentClassName="modal-content"
      onClose={closeModal}
      centered={true}
      disableEnforceFocus
      disablePortal
      topLeftButton={
        <ActionLink
          onClick={closeModal}
          content={
            <img
              className="close-icon"
              src={isDarkMode ? CloseWhite : Close}
              alt=""
            />
          }
        />
      }
      bottomButton={
        <MobileFloatingSection
          className="continue-button"
          primaryButton={{
            children: t("close.button"),
            onClick: closeModal,
          }}
        />
      }
    >
      <div className="modal-content-container">
        {/* Nu Viagens Terms */}
        <div className="page">
          <h2>{t("nubankTC.title")}</h2>
          {/* Section 1 */}
          <div className="section">
            <h3>
              <SectionNumber>{"1."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.first.title")}
            </h3>
            <p>
              <I18nMarkup tKey="nubankTC.NuViagensTerms.first.description" />
            </p>
          </div>
          {/* Section 2 */}
          <div className="section">
            <h3>
              <SectionNumber>{"2."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.second.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"2.1."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.second.first.title"}
                />
              </p>
              <div className="subsection">
                <p>
                  <SectionNumber>{"2.1.1."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.second.first.first"}
                  />
                </p>
              </div>
              <div className="subsection">
                <p>
                  <SectionNumber>{"2.1.2."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.second.first.second"}
                  />
                </p>
              </div>
              <p>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.second.first.description"}
                />
              </p>
            </div>
            <div>
              <p>
                <SectionNumber>{"2.2."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.second.second.title"}
                />
              </p>
              <div className="subsection">
                <p>
                  <SectionNumber>{"2.2.1."}</SectionNumber>
                  <Trans
                    i18nKey={"nubankTC.NuViagensTerms.second.second.first"}
                    components={[
                      <ButtonWrap
                        className="link"
                        onClick={() =>
                          window.open(
                            "https://nubank.com.br/contrato/",
                            "_blank"
                          )
                        }
                      />,
                    ]}
                  />
                </p>
              </div>
              <div className="subsection">
                <p>
                  <SectionNumber>{"2.2.2."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.second.second.second"}
                  />
                </p>
              </div>
              <div className="subsection">
                <p>
                  <SectionNumber>{"2.2.3."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.second.second.third"}
                  />
                </p>
              </div>
              <div className="subsection">
                <p>
                  <SectionNumber>{"2.2.4."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.second.second.fourth"}
                  />
                </p>
              </div>
            </div>
            <div>
              <p>
                <SectionNumber>{"2.3."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.second.third"} />
              </p>
              <p>
                <SectionNumber>{"2.4."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.second.fourth"} />
              </p>
              <p>
                <SectionNumber>{"2.5."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.second.fifth"} />
              </p>
              <p>
                <SectionNumber>{"2.6."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.second.sixth"} />
              </p>
            </div>
          </div>
          {/* Section 3 */}
          <div className="section">
            <h3>
              <SectionNumber>{"3."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.third.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"3.1."}</SectionNumber>
                <Trans
                  i18nKey={"nubankTC.NuViagensTerms.third.first"}
                  components={[
                    <ButtonWrap
                      className="link"
                      onClick={() =>
                        window.open(
                          "https://nubank.com.br/contrato/politica-privacidade/",
                          "_blank"
                        )
                      }
                    />,
                  ]}
                />
              </p>
              <p>
                <SectionNumber>{"3.2."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.third.second"} />
              </p>
              <p>
                <SectionNumber>{"3.3."}</SectionNumber>
                <Trans
                  i18nKey={"nubankTC.NuViagensTerms.third.third"}
                  components={[
                    <ButtonWrap
                      className="link"
                      onClick={() =>
                        window.open(
                          "https://hopper.com/legal/privacy-policy",
                          "_blank"
                        )
                      }
                    />,
                  ]}
                />
              </p>
            </div>
          </div>
          {/* Section 4 */}
          <div className="section">
            <h3>
              <SectionNumber>{"4."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.fourth.title")}
            </h3>
            <div>
              <p>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.fourth.first.title"}
                />
              </p>
              <p>
                <SectionNumber>{"4.1."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.fourth.first.description"}
                />
              </p>
              <p>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.fourth.second.title"}
                />
              </p>
              <p>
                <SectionNumber>{"4.2."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.fourth.second.description"}
                />
              </p>
              <p>
                <SectionNumber>{"4.3."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fourth.third"} />
              </p>
              <p>
                <SectionNumber>{"4.4."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fourth.fourth"} />
              </p>
              <p>
                <SectionNumber>{"4.5."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fourth.fifth"} />
              </p>
              <p>
                <SectionNumber>{"4.6."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fourth.sixth"} />
              </p>
              <p>
                <SectionNumber>{"4.7."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fourth.seventh"} />
              </p>
            </div>
          </div>
          {/* Section 5 */}
          <div className="section">
            <h3>
              <SectionNumber>{"5."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.fifth.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"5.1."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fifth.first"} />
              </p>
              <p>
                <SectionNumber>{"5.2."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.fifth.second"} />
              </p>
            </div>
          </div>
          {/* Section 6 */}
          <div className="section">
            <h3>
              <SectionNumber>{"6."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.sixth.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"6.1."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.sixth.first"} />
              </p>
            </div>
          </div>
          {/* Section 7 */}
          <div className="section">
            <h3>
              <SectionNumber>{"7."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.seventh.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"7.1."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.seventh.first.title"}
                />
              </p>
              <div className="subsection">
                <p>
                  <SectionNumber>{"7.1.1."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.seventh.first.first"}
                  />
                </p>
                <p>
                  <SectionNumber>{"7.1.2."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.seventh.first.second"}
                  />
                </p>
                <p>
                  <SectionNumber>{"7.1.3."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.seventh.first.third"}
                  />
                </p>
                <p>
                  <SectionNumber>{"7.1.4."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.seventh.first.fourth"}
                  />
                </p>
              </div>
            </div>
          </div>
          {/* Section 8 */}
          <div className="section">
            <h3>
              <SectionNumber>{"8."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.eighth.title")}
            </h3>
            <p>{t("nubankTC.NuViagensTerms.eighth.description")}</p>
          </div>
          {/* Section 9 */}
          <div className="section">
            <h3>
              <SectionNumber>{"9."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.ninth.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"9.1."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.ninth.first"} />
              </p>
              <p>
                <SectionNumber>{"9.2."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.ninth.second"} />
              </p>
            </div>
          </div>
          {/* Section 10 */}
          <div className="section">
            <h3>
              <SectionNumber>{"10."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.tenth.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"10.1."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.tenth.first"} />
              </p>
              <p>
                <SectionNumber>{"10.2."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.tenth.second"} />
              </p>
            </div>
          </div>
          {/* Section 11 */}
          <div className="section">
            <h3>
              <SectionNumber>{"11."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.eleventh.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"11.1."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.eleventh.first"} />
              </p>
              <p>
                <SectionNumber>{"11.2."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.eleventh.second.title"}
                />
              </p>
              <div className="subsection">
                <p>
                  <SectionNumber>{"11.2.1."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.eleventh.second.first"}
                  />
                </p>
                <p>
                  <SectionNumber>{"11.2.2."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.eleventh.second.second"}
                  />
                </p>
                <p>
                  <SectionNumber>{"11.2.3."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.eleventh.second.third"}
                  />
                </p>
                <p>
                  <SectionNumber>{"11.2.4."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.eleventh.second.fourth"}
                  />
                </p>
                <p>
                  <SectionNumber>{"11.2.5."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.eleventh.second.fifth"}
                  />
                </p>
                <p>
                  <SectionNumber>{"11.2.6."}</SectionNumber>
                  <I18nMarkup
                    tKey={"nubankTC.NuViagensTerms.eleventh.second.sixth"}
                  />
                </p>
              </div>
              <p>
                <SectionNumber>{"11.3."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.eleventh.third"} />
              </p>
              <p>
                <SectionNumber>{"11.4."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.eleventh.fourth"} />
              </p>
            </div>
          </div>
          {/* Section 12 */}
          <div className="section">
            <h3>
              <SectionNumber>{"12."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.twelfth.title")}
            </h3>
            <p>
              <I18nMarkup
                tKey={"nubankTC.NuViagensTerms.twelfth.description"}
              />
            </p>
          </div>
          {/* Section 13 */}
          <div className="section">
            <h3>
              <SectionNumber>{"13."}</SectionNumber>
              {t("nubankTC.NuViagensTerms.thirteenth.title")}
            </h3>
            <div>
              <p>
                <SectionNumber>{"13.1."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.thirteenth.first"} />
              </p>
              <p>
                <SectionNumber>{"13.2."}</SectionNumber>
                <I18nMarkup
                  tKey={"nubankTC.NuViagensTerms.thirteenth.second"}
                />
              </p>
              <p>
                <SectionNumber>{"13.3."}</SectionNumber>
                <I18nMarkup tKey={"nubankTC.NuViagensTerms.thirteenth.third"} />
              </p>
            </div>
          </div>
          {/* Footnote */}
          <p>
            <I18nMarkup tKey={"nubankTC.NuViagensTerms.footNote"} />
          </p>
        </div>
        {/* HopperTerms Terms */}
        <div className="page">
          <h2>
            <I18nMarkup tKey="nubankTC.HopperTerms.title" />
          </h2>
          <div className="section">
            <p>
              <I18nMarkup tKey="nubankTC.HopperTerms.description" />
            </p>
            <h3>
              <SectionNumber>{"1."}</SectionNumber>
              {t("nubankTC.HopperTerms.first.title")}
            </h3>
            <p>
              <SectionNumber>{"1.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.first.first" />
            </p>
            <p>
              <SectionNumber>{"1.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.first.second" />
            </p>
            <p>
              <SectionNumber>{"1.3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.first.third" />
            </p>
            <p>
              <SectionNumber>{"1.4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.first.fourth" />
            </p>
            <p>
              <SectionNumber>{"1.5."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.first.fifth" />
            </p>
            <p>
              <SectionNumber>{"1.6."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.first.sixth" />
            </p>
          </div>
          {/* Section 2 */}
          <div className="section">
            <h3>
              <SectionNumber>{"2."}</SectionNumber>
              {t("nubankTC.HopperTerms.second.title")}
            </h3>
            <p>
              <SectionNumber>{"2.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.second.first" />
            </p>
            <p>
              <SectionNumber>{"2.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.second.second" />
            </p>
            <p>
              <SectionNumber>{"2.4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.second.fourth" />
            </p>
            <p>
              <SectionNumber>{"2.5."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.second.fifth" />
            </p>
            <p>
              <SectionNumber>{"2.6."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.second.sixth" />
            </p>
          </div>
          {/* Section 3 */}
          <div className="section">
            <h3>
              <SectionNumber>{"3."}</SectionNumber>
              {t("nubankTC.HopperTerms.third.title")}
            </h3>
            <p>
              <SectionNumber>{"3.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.third.first" />
            </p>
            <p>
              <SectionNumber>{"3.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.third.second" />
            </p>
            <p>
              <SectionNumber>{"3.3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.third.third" />
            </p>
            <p>
              <SectionNumber>{"3.4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.third.fourth" />
            </p>
          </div>
          {/* Section 4 */}
          <div className="section">
            <h3>
              <SectionNumber>{"4."}</SectionNumber>
              {t("nubankTC.HopperTerms.fourth.title")}
            </h3>
            <p>
              <SectionNumber>{"4.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fourth.first" />
            </p>
            <p>
              <SectionNumber>{"4.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fourth.second" />
            </p>
          </div>
          {/* Section 5 */}
          <div className="section">
            <h3>
              <SectionNumber>{"5."}</SectionNumber>
              {t("nubankTC.HopperTerms.fifth.title")}
            </h3>
            <p>
              <SectionNumber>{"5.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifth.first" />
            </p>
            <p>
              <SectionNumber>{"5.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifth.second" />
            </p>
            <p>
              <SectionNumber>{"5.3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifth.third" />
            </p>
            <p>
              <SectionNumber>{"5.4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fourth" />
            </p>
            <p>
              <SectionNumber>{"5.5."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.title" />
            </p>
            <div className="subsection">
              <p>
                <SectionNumber>{"5.5.1."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.first" />
              </p>
              <p>
                <SectionNumber>{"5.5.2."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.second" />
              </p>
              <p>
                <SectionNumber>{"5.5.3."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.third" />
              </p>
              <p>
                <SectionNumber>{"5.5.4."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.fourth" />
              </p>
              <p>
                <SectionNumber>{"5.5.5."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.fifth" />
              </p>
              <p>
                <SectionNumber>{"5.5.6."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.sixth" />
              </p>
              <p>
                <SectionNumber>{"5.5.7."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.seventh" />
              </p>
              <p>
                <SectionNumber>{"5.5.8."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.eighth" />
              </p>
              <p>
                <SectionNumber>{"5.5.9."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.ninth" />
              </p>
              <p>
                <SectionNumber>{"5.5.10."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.tenth" />
              </p>
              <p>
                <SectionNumber>{"5.5.11."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.eleventh" />
              </p>
              <p>
                <SectionNumber>{"5.5.12."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.twelfth" />
              </p>
              <p>
                <SectionNumber>{"5.5.13."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.thirteenth" />
              </p>
              <p>
                <SectionNumber>{"5.5.14."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.fourteenth" />
              </p>
              <p>
                <SectionNumber>{"5.5.15."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.fifteenth" />
              </p>
              <p>
                <SectionNumber>{"5.5.16."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.sixteenth" />
              </p>
              <p>
                <SectionNumber>{"5.5.17."}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.fifth.fifth.seventeenth" />
              </p>
            </div>
            <p>
              <SectionNumber>{"5.6."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifth.sixth" />
            </p>
          </div>
          {/* Section 6 */}
          <div className="section">
            <p>
              <SectionNumber>
                <strong>{"6."}</strong>
              </SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.sixth.title" />
            </p>
            <h3>
              <SectionNumber>{"6.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.sixth.first.title" />
            </h3>
            <div className="subsection">
              <p>
                <SectionNumber>{"6.1.1"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.first.first" />
              </p>
              <p>
                <SectionNumber>{"6.1.2"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.first.second" />
              </p>
              <p>
                <SectionNumber>{"6.1.3"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.first.third" />
              </p>
            </div>
            <h3>
              <SectionNumber>{"6.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.title" />
            </h3>
            <div className="subsection">
              <p>
                <SectionNumber>{"6.2.1"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.first" />
              </p>
              <p>
                <SectionNumber>{"6.2.2"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.second.title" />
              </p>
              <div className="subsection">
                <p>
                  <SectionNumber>{"a."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.second.bulletA" />
                </p>
                <p>
                  <SectionNumber>{"b."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.second.bulletB" />
                </p>
                <p>
                  <SectionNumber>{"c."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.second.bulletC" />
                </p>
                <p>
                  <SectionNumber>{"d."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.second.bulletD" />
                </p>
              </div>
              <p>
                <SectionNumber>{"6.2.3"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.third" />
              </p>
              <p>
                <SectionNumber>{"6.2.4"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.fourth" />
              </p>
              <p>
                <SectionNumber>{"6.2.5"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.fifth" />
              </p>
              <p>
                <SectionNumber>{"6.2.6"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperTerms.sixth.second.sixth" />
              </p>
            </div>
          </div>
          {/* Section 7 */}
          <div className="section">
            <p>
              <SectionNumber>
                <strong>{"7."}</strong>
              </SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.seventh" />
            </p>
          </div>
          {/* Section 8 */}
          <div className="section">
            <h3>
              <SectionNumber>{"8."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eight.title" />
            </h3>
            <p>
              <SectionNumber>{"8.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eight.first" />
            </p>
            <p>
              <SectionNumber>{"8.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eight.second" />
            </p>
            <p>
              <SectionNumber>{"8.3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eight.third" />
            </p>
            <p>
              <SectionNumber>{"8.4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eight.fourth" />
            </p>
          </div>
          {/* Section 9 */}
          <div className="section">
            <h3>
              <SectionNumber>{"9."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.ninth.title" />
            </h3>
            <p>
              <SectionNumber>{"9.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.ninth.first" />
            </p>
            <p>
              <SectionNumber>{"9.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.ninth.second" />
            </p>
            <p>
              <SectionNumber>{"9.3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.ninth.third" />
            </p>
          </div>
          {/* Section 10 */}
          <div className="section">
            <h3>
              <SectionNumber>{"10."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.tenth.title" />
            </h3>
            <p>
              <SectionNumber>{"10.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.tenth.first" />
            </p>
            <p>
              <SectionNumber>{"10.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.tenth.second" />
            </p>
          </div>
          {/* Section 11 */}
          <div className="section">
            <h3>
              <SectionNumber>{"11."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.title" />
            </h3>
            <p>
              <SectionNumber>{"11.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.first" />
            </p>
            <p>
              <SectionNumber>{"11.2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.second" />
            </p>
            <p>
              <SectionNumber>{"11.3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.third" />
            </p>
            <p>
              <SectionNumber>{"11.4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.fourth" />
            </p>
            <p>
              <SectionNumber>{"11.5."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.fifth" />
            </p>
            <p>
              <SectionNumber>{"11.6."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.sixth" />
            </p>
            <p>
              <SectionNumber>{"11.7."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.eleventh.seventh" />
            </p>
          </div>
          {/* Section 12 */}
          <div className="section">
            <h3>
              <SectionNumber>{"12."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.twelfth.title" />
            </h3>
            <p>
              <SectionNumber>{"12.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.twelfth.first" />
            </p>
          </div>
          {/* Section 13 */}
          <div className="section">
            <h3>
              <SectionNumber>{"13."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.thirteenth.title" />
            </h3>
            <p>
              <SectionNumber>{"13.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.thirteenth.first" />
            </p>
            <p>
              <SectionNumber>{"13.7."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.thirteenth.seventh" />
            </p>
          </div>
          {/* Section 14 */}
          <div className="section">
            <h3>
              <SectionNumber>{"14."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fourteenth.title" />
            </h3>
            <p>
              <SectionNumber>{"14.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fourteenth.first" />
            </p>
          </div>
          {/* Section 15 */}
          <div className="section">
            <h3>
              <SectionNumber>{"15."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifteenth.title" />
            </h3>
            <p>
              <SectionNumber>{"15.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.fifteenth.first" />
            </p>
          </div>
          {/* Section 16 */}
          <div className="section">
            <h3>
              <SectionNumber>{"16."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.sixteenth.title" />
            </h3>
            <p>
              <SectionNumber>{"16.1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperTerms.sixteenth.first" />
            </p>
          </div>
          {/* Footnote */}
          <p>
            <I18nMarkup tKey="nubankTC.HopperTerms.footNote" />
          </p>
        </div>
        {/* Hopper Price Freeze Terms */}
        <div className="page">
          <h3>
            <I18nMarkup tKey="nubankTC.PriceFreeze.title" />
          </h3>
          <p>
            <I18nMarkup tKey="nubankTC.PriceFreeze.description" />
          </p>
          <div className="subsection">
            <p>
              <SectionNumber>{"1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.first" />
            </p>
            <p>
              <SectionNumber>{"2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.second" />
            </p>
            <p>
              <SectionNumber>{"3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.third" />
            </p>
            <p>
              <SectionNumber>{"4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.fourth" />
            </p>
            <p>
              <SectionNumber>{"5."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.fifth" />
            </p>
            <p>
              <SectionNumber>{"6."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.sixth" />
            </p>
            <p>
              <SectionNumber>{"7."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.seventh" />
            </p>
            <p>
              <SectionNumber>{"8."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.PriceFreeze.eighth" />
            </p>
          </div>
        </div>
        {/* Hopper Hopper Interruption Terms */}
        <div className="page">
          <h3>
            <I18nMarkup tKey="nubankTC.HopperInterruption.title" />
          </h3>
          <p>
            <I18nMarkup tKey="nubankTC.HopperInterruption.description" />
          </p>
          <div className="subsection">
            <p>
              <SectionNumber>{"1."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.first" />
            </p>
            <p>
              <SectionNumber>{"2."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.second.description" />
            </p>
            <div className="subsection">
              <div className="subsection">
                <p>
                  <SectionNumber>{"a."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperInterruption.second.bulletA" />
                </p>
                <p>
                  <SectionNumber>{"b."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperInterruption.second.bulletB" />
                </p>
              </div>
              <p>
                <SectionNumber>{"2.1"}</SectionNumber>
                <I18nMarkup tKey="nubankTC.HopperInterruption.second.first" />
              </p>
            </div>
            <p>
              <SectionNumber>{"3."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.third" />
            </p>
            <p>
              <SectionNumber>{"4."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.fourth.title" />
            </p>
            <div className="subsection">
              <div className="subsection">
                <p>
                  <SectionNumber>{"a."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperInterruption.fourth.bulletA" />
                </p>
                <p>
                  <SectionNumber>{"b."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperInterruption.fourth.bulletB" />
                </p>
                <p>
                  <SectionNumber>{"c."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperInterruption.fourth.bulletC" />
                </p>
                <p>
                  <SectionNumber>{"d."}</SectionNumber>
                  <I18nMarkup tKey="nubankTC.HopperInterruption.fourth.bulletD" />
                </p>
              </div>
              <p>
                <I18nMarkup tKey="nubankTC.HopperInterruption.fourth.description" />
              </p>
            </div>
            <p>
              <SectionNumber>{"5."}</SectionNumber>
              {/* TODO: Update keys + accommodate for no bullet points */}
              <I18nMarkup tKey="nubankTC.HopperInterruption.fifth" />
            </p>
            <p>
              <SectionNumber>{"6."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.sixth" />
            </p>
            <p>
              <SectionNumber>{"7."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.seventh" />
            </p>
            <p>
              <SectionNumber>{"8."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.eighth" />
            </p>
            <p>
              <SectionNumber>{"9."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.ninth" />
            </p>
            <p>
              <SectionNumber>{"10."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.tenth" />
            </p>
            <p>
              <SectionNumber>{"11."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.eleventh" />
            </p>
            <p>
              <SectionNumber>{"12."}</SectionNumber>
              <I18nMarkup tKey="nubankTC.HopperInterruption.twelfth" />
            </p>
          </div>
        </div>
        <CancellationTerms />
        {/* Hotel Offer Terms */}
        <HotelOfferTerms />
      </div>
    </MobilePopoverCard>
  );
};

const SectionNumber = ({ children }: PropsWithChildren) => (
  <span className="section-number">{children}</span>
);

const CancellationTerms = () => (
  <div className="page">
    <h3>
      <I18nMarkup tKey="nubankTC.CancellationTerms.title" />
    </h3>
    <p>
      <I18nMarkup tKey="nubankTC.CancellationTerms.description" />
    </p>
    <div className="subsection">
      <p>
        <SectionNumber>{"1."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.CancellationTerms.first" />
      </p>
      <p>
        <SectionNumber>{"2."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.CancellationTerms.second" />
      </p>
      <p>
        <SectionNumber>{"3."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.CancellationTerms.third" />
      </p>
      <p>
        <SectionNumber>{"4."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.CancellationTerms.fourth.title" />
        <div className="subsection">
          <p>
            <SectionNumber>{"a."}</SectionNumber>
            <I18nMarkup tKey="nubankTC.CancellationTerms.fourth.bulletA" />
          </p>
          <p>
            <SectionNumber>{"b."}</SectionNumber>
            <I18nMarkup tKey="nubankTC.CancellationTerms.fourth.bulletB" />
          </p>
          <p>
            <SectionNumber>{"c."}</SectionNumber>
            <I18nMarkup tKey="nubankTC.CancellationTerms.fourth.bulletC" />
          </p>
          <p>
            <SectionNumber>{"d."}</SectionNumber>
            <I18nMarkup tKey="nubankTC.CancellationTerms.fourth.bulletD" />
          </p>
          <p>
            <I18nMarkup tKey="nubankTC.CancellationTerms.fourth.description" />
          </p>
        </div>
      </p>
      <p>
        <SectionNumber>{"5."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.CancellationTerms.fifth" />
      </p>
      <p>
        <SectionNumber>{"6."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.CancellationTerms.sixth" />
      </p>
    </div>
  </div>
);

const HotelOfferTerms = () => (
  <div className="page">
    <h3>
      <I18nMarkup tKey="nubankTC.HotelOffers.title" />
    </h3>
    <p>
      <I18nMarkup tKey="nubankTC.HotelOffers.description" />
    </p>
    <div className="subsection">
      <p>
        <SectionNumber>{"1."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.HotelOffers.first" />
      </p>
      <p>
        <SectionNumber>{"2."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.HotelOffers.second" />
      </p>
      <p>
        <SectionNumber>{"3."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.HotelOffers.third" />
      </p>
      <p>
        <SectionNumber>{"4."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.HotelOffers.fourth" />
      </p>
      <p>
        <SectionNumber>{"5."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.HotelOffers.fifth" />
      </p>
      <p>
        <SectionNumber>{"6."}</SectionNumber>
        <I18nMarkup tKey="nubankTC.HotelOffers.sixth" />
      </p>
    </div>
  </div>
);

export const HotelOfferTermsModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
}) => {
  const { t } = useI18nContext();
  const isDarkMode = useDarkModePreferred();

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  return (
    <MobilePopoverCard
      open={openModal}
      className={clsx("full-screen", "nubank-terms")}
      contentClassName="modal-content"
      onClose={closeModal}
      centered={true}
      topLeftButton={
        <ActionLink
          onClick={closeModal}
          content={
            <img
              className="close-icon"
              src={isDarkMode ? CloseWhite : Close}
              alt=""
            />
          }
        />
      }
      bottomButton={
        <MobileFloatingSection
          className="continue-button"
          primaryButton={{
            children: t("close.button"),
            onClick: closeModal,
          }}
        />
      }
    >
      <div className="modal-content-container">
        <HotelOfferTerms />
      </div>
    </MobilePopoverCard>
  );
};
