import {
  trackChoosePaymentMethod,
  trackNewPaymentMethod,
  trackViewPaymentMethods,
} from "@checkout/tracking/paymentMethods";
import { getObjectKeysAsObject } from "../../../../helpers";
import { deletePaymentMethodService as deletePaymentMethod } from "./deletePaymentMethod";
import { listPaymentMethodsService as listPaymentMethods } from "./listPaymentMethods";
import { verifyPaymentService as verifyPayment } from "./verifyPayment";

export const services = {
  deletePaymentMethod,
  listPaymentMethods,
  trackChoosePaymentMethod,
  trackNewPaymentMethod,
  trackViewPaymentMethods,
  verifyPayment,
};

export const ServiceTypes = getObjectKeysAsObject(services);
