import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  IconNameEnum,
  useUiStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import { Icon } from "@components/ui";
import {
  type FirstDayOfWeek,
  type MonthType,
  type OnDatesChangeProps,
  useDatepicker,
  useMonth,
} from "@datepicker-react/hooks";
import { getLang, useI18nContext } from "@hopper-b2b/i18n";
import { customFormatDateTime } from "@hopper-b2b/utilities";
import { DateTimeFormatStyle } from "@hopper-i18n/formatter";
import clsx from "clsx";

import { firstWeekDay } from "../../DateRangePicker";
import { PickerType } from "../../types";
import { generateRenderWeekdayLabels, Month } from "../Month";
import defaultStyles from "./ColumnView.module.scss";

export interface IColumnViewProps {
  startDate: Date | null;
  cleanedEndDate: Date | null;
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  minAllowedDate: Date | null;
  maxAllowedDate: Date | null;
  numberOfMonths: number;
  focusedMonthIndex?: number;
  pickerType?: PickerType;
  priceTagsElement: JSX.Element;
  startDateLabel?: string;
  endDateLabel?: string;
  className?: string;
  isMissingDate?: boolean;
  showIcons?: boolean;
  hideWeekdayLabels?: boolean;
}

export const ColumnView = ({
  startDate,
  cleanedEndDate,
  setStartDate,
  setEndDate,
  minAllowedDate,
  maxAllowedDate,
  numberOfMonths,
  focusedMonthIndex,
  pickerType,
  priceTagsElement,
  startDateLabel,
  endDateLabel,
  className,
  showIcons = true,
  hideWeekdayLabels = true,
}: IColumnViewProps) => {
  const styles = useUiStyles(
    CoreUiComponents.DatePickerColumnView,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreUiComponents.DatePickerColumnView
  );

  const { t, brand, language } = useI18nContext();
  const [scrolledOnStart, setScrolledOnStart] = useState<boolean>(false);
  const monthRefs = Array.from({ length: numberOfMonths }, (_) => {
    return useRef<HTMLDivElement | null>(null);
  });

  const handleDateChange = (props: OnDatesChangeProps) => {
    const { startDate, endDate } = props;
    setStartDate && setStartDate(startDate);
    setEndDate && setEndDate(endDate);
  };

  const { firstDayOfWeek, activeMonths } = useDatepicker({
    firstDayOfWeek: firstWeekDay(getLang()) as FirstDayOfWeek, // 0 is Sunday.
    startDate: minAllowedDate,
    endDate: maxAllowedDate,
    focusedInput: null,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const [firstMonth] = activeMonths;

  const getFormattedWeekdayLabel = useCallback(
    (date: Date) => {
      const formatted = customFormatDateTime(
        date,
        language,
        DateTimeFormatStyle.Custom({
          weekday: "short",
        }),
        brand?.customDateTimeFormats?.calendarDay
      );

      return brand?.customDateTimeFormats?.calendarDay
        ? formatted
        : formatted[0].toLocaleUpperCase(language);
    },
    [language, brand]
  );

  const { weekdayLabels } = useMonth({
    year: firstMonth.year,
    month: firstMonth.month,
    firstDayOfWeek: firstDayOfWeek,
    weekdayLabelFormat: (date: Date) => getFormattedWeekdayLabel(date),
  });

  const currentFocusIndex = useMemo(() => {
    return (
      focusedMonthIndex ??
      // when focusedMonthIndex is not given, use startDate to determine index
      (startDate && minAllowedDate
        ? // left side returns 1 or 0; increment is 12-based
          (startDate.getFullYear() - minAllowedDate.getFullYear()) * 12 +
          (startDate.getMonth() - minAllowedDate.getMonth())
        : 0)
    );
  }, [focusedMonthIndex, minAllowedDate, startDate]);

  useEffect(() => {
    if (!scrolledOnStart) {
      monthRefs[currentFocusIndex]?.current?.scrollIntoView();
      setScrolledOnStart(true);
    }
  }, [scrolledOnStart, currentFocusIndex, monthRefs]);

  const formatDate = useCallback(
    (date: Date) =>
      customFormatDateTime(
        date,
        language,
        DateTimeFormatStyle.ShortMonthDayShortWeekday,
        brand?.customDateTimeFormats?.calendar
      ),
    [language, brand]
  );

  return (
    <div className={clsx(block, className)}>
      <div className={clsx(cn("section"), cn("selected-dates-section"))}>
        <div
          className={clsx(cn("selected-date"), cn("start-date"), {
            [cn("selected")]: !!startDate,
          })}
        >
          <div
            className={clsx(
              cn("date-text", ["start"], {
                filled: startDate != null,
              })
            )}
          >
            {showIcons && (
              <Icon
                iconName={IconNameEnum.calendar}
                className={cn("calendar-icon")}
              />
            )}
            {startDate ? formatDate(startDate) : startDateLabel ?? t("depart")}
          </div>
        </div>
        {pickerType === PickerType.RANGE && (
          <div className={clsx(cn("selected-date"), cn("end-date"))}>
            <div
              className={clsx(
                cn("date-text", ["end"], {
                  filled: cleanedEndDate != null,
                })
              )}
            >
              {showIcons && (
                <Icon
                  iconName={IconNameEnum.calendar}
                  className={cn("calendar-icon")}
                />
              )}
              {cleanedEndDate
                ? formatDate(cleanedEndDate)
                : endDateLabel ?? t("return")}
            </div>
          </div>
        )}
      </div>
      {priceTagsElement && (
        <div
          className={clsx(
            cn("section"),
            cn("price-range-tags-section", {
              empty: Boolean(priceTagsElement),
            })
          )}
        >
          {priceTagsElement}
        </div>
      )}
      <div className={clsx(cn("section"), cn("weekday-label-section"))}>
        {generateRenderWeekdayLabels({
          month: firstMonth.month,
          year: firstMonth.year,
        })(weekdayLabels)}
      </div>
      <div className={clsx(cn("section"), cn("scrollable-months-container"))}>
        {renderMonths(
          activeMonths,
          firstDayOfWeek,
          monthRefs,
          brand?.calendarMonthFormatStyle ??
            DateTimeFormatStyle.Custom({ month: "short" }),
          hideWeekdayLabels
        )}
      </div>
    </div>
  );
};

const renderMonths = (
  months: MonthType[],
  firstDayOfWeek: FirstDayOfWeek,
  monthRefs: React.MutableRefObject<HTMLDivElement | null>[],
  monthFormat?: DateTimeFormatStyle,
  hideWeekdayLabels?: boolean
) => {
  const styles = useUiStyles(
    CoreUiComponents.DatePickerColumnView,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreUiComponents.DatePickerColumnView
  );

  return months.map((month: MonthType, index: number) => (
    <div
      ref={monthRefs[index]}
      key={`${month.year}-${month.month}`}
      className={cn("month-wrapper")}
    >
      <Month
        year={month.year}
        month={month.month}
        firstDayOfWeek={firstDayOfWeek}
        date={month.date}
        hideWeekdayLabels={hideWeekdayLabels}
        headerClassName={cn("month-label")}
        monthFormat={monthFormat}
      />
    </div>
  ));
};
