import {
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  type Airline,
  type Airport,
  type BookedFlightItineraryWithDepartureTime,
  TravelItineraryEnum,
} from "@b2bportal/air-booking-api";
import {
  type Aborted,
  type AirCancelFulfillmentPollResponse,
  AirCancelFulfillmentPollResponseEnum,
  type AirCancelQuoteResponse,
  AirCancelQuoteResponseEnum,
  type AirCancelQuoteSuccess,
  type CancelScenario,
  CancelScenarioEnum,
  NonCfarEnum,
} from "@b2bportal/air-cancel-api";
import { ensureExhaustive } from "@b2bportal/core-utilities";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  confirmFlightCancel,
  confirmNonCartFlightCancel,
  getBookingSupportId,
  getFlightCancelEligibility,
  getFlightCancelQuote,
  getFlightCancelQuoteAny,
  pollFlightCancelFulfill,
  pollFlightCancelInfo,
  pollNonCartFlightCancelFulfill,
  trackEvent,
} from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  BookingType,
  ClientName,
  type IOpenModal,
  SelfServeEvents,
} from "@hopper-b2b/types";
import {
  ActionLink,
  ContactSupportModalContent,
  FlightCancelationInfo,
  GenericModalContent,
  HappyBunny,
  ItinerarySummary,
  LostConnectionBunny,
  SlicePickerModalContent,
  Slot,
} from "@hopper-b2b/ui";
import { ActionButton } from "@hopper-b2b/ui-core";
import {
  getEnvVariables,
  toggleAdaChat,
  useEnableHFv2,
} from "@hopper-b2b/utilities";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import clsx from "clsx";

import { getTrackingNonCfar } from "../../utils";

import styles from "./styles.module.scss";

const POLICY_TIMEOUT = 50000; // time before policy expires - 50 seconds
const POLL_INTERVAL = 2000; // time between polling requests - 2 seconds

export enum CancelStep {
  CancelationError,
  CancelationTenantError,
  CancelationPending,
  CancelationFlowComplete,
  CancelationInfo,
  ConfirmCancelation,
  ContactSupport,
  LoadingOrProcessing,
  SlicePicker,
}

export interface ICancelFlightModalContentProps {
  airlines: Record<string, Airline>;
  airports: Record<string, Airport>;
  flight: BookedFlightItineraryWithDepartureTime;
  getStatusTag: (isOutgoing: boolean) => ReactNode;
  isMobile?: boolean;
  onClose?: (cancelSuccess?: boolean) => void;
  setOpenModal: (newModal?: IOpenModal) => void;
  onFulfillSuccess?: () => void;
  onFulfillError?: (
    res: Aborted,
    onClose: () => void
  ) => ReactElement | undefined;
  defaultErrorAction?: ReactNode;
}

/**
 * @description Dynamically render flight cancelation states
 * @param {ICancelFlightModalContentProps} props
 * @return {JSX.Element}
 */
const CancelFlightModalContent = (props: ICancelFlightModalContentProps) => {
  const {
    airlines,
    airports,
    flight,
    getStatusTag,
    onClose,
    setOpenModal,
    onFulfillSuccess,
    onFulfillError,
    defaultErrorAction,
  } = props;
  const { t } = useI18nContext();
  const enableHFv2 = useEnableHFv2();

  const cancelInfoRef = useRef<AirCancelQuoteResponse>();
  const loadingMsgRef = useRef(t("cancelFlightModal.loadingContext"));
  const locatorRef = useRef("");
  const numEligibleRef = useRef(0);
  const pollIdRef = useRef<number>();
  const timeoutIdRef = useRef<number>();
  const titleRef = useRef<ReactNode>(t("cancelFlightModal.error.cancelTitle"));
  const tenantErrorRef = useRef<ReactElement>();

  const [cancelStep, setCancelStep] = useState<CancelStep>(
    CancelStep.LoadingOrProcessing
  );
  const [scenario, setScenario] = useState<CancelScenario>();
  const [timedOut, setTimedOut] = useState(false);

  const isHopperWeb = getEnvVariables("clientName") === ClientName.HOPPER;

  /**
   * @description For multi-provider/multi-ticket itineraries.
   * The individual slices may have different cancellation policies
   * so we fetch their cancel eligibilities and display the slice picker.
   */
  const getCancelEligibility = useCallback(
    async (resId) => {
      try {
        const cancelEligibility = await getFlightCancelEligibility(resId);

        if (cancelEligibility) {
          cancelInfoRef.current = cancelEligibility;
          setCancelStep(CancelStep.SlicePicker);
        }
      } catch (err) {
        titleRef.current = t("cancelFlightModal.error.policyTitle");

        setCancelStep(CancelStep.CancelationError);
      }
    },
    [t]
  );

  /**
   * @description Part 1 of the self serve cancel flow on the BE.
   * Requests the cancel info of the flight to be shown to the
   * user.
   * @param locator Optional booking reference to be used in cases of
   * multi-ticket to get the cancel policy for a specific flight.
   */
  const getCancelInfo = useCallback(
    async (locator?: string) => {
      loadingMsgRef.current = t("cancelFlightModal.loadingContext");
      setCancelStep(CancelStep.LoadingOrProcessing);

      try {
        const { id: reservationId } = flight.bookedItinerary;
        let cancelInfo: AirCancelQuoteResponse;

        if (isHopperWeb) {
          cancelInfo = await getFlightCancelQuoteAny(reservationId, locator);
        } else {
          cancelInfo = await getFlightCancelQuote(reservationId);
        }

        cancelInfoRef.current = cancelInfo;

        if ("flightCancelSessionId" in cancelInfo) {
          cancelInfo = await pollCancelQuote(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cancelInfo.flightCancelSessionId as string
          );
        }

        if ("cancelScenario" in cancelInfo) {
          handleCancelInfo(cancelInfo.cancelScenario);
        } else if (
          cancelInfo.AirCancelQuoteResponse ===
          AirCancelQuoteResponseEnum.AirCancelAlreadyRequestedFailure
        ) {
          setCancelStep(CancelStep.CancelationPending);
        } else {
          setCancelStep(CancelStep.ContactSupport);
        }
      } catch (err) {
        titleRef.current = t("cancelFlightModal.error.policyTitle");

        setCancelStep(CancelStep.CancelationError);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flight, isHopperWeb, t]
  );

  const ItinerarySummaryRow = useMemo(
    () => {
      const { travelItinerary: tIt } = flight.bookedItinerary;
      const { current: locator } = locatorRef;

      if (
        tIt.TravelItinerary === TravelItineraryEnum.MultiTravelItinerary &&
        locator
      ) {
        const itinerary = tIt.travelItineraries.find(
          (it) => it.locators.b2b === locator
        );

        if (itinerary) {
          return (
            <div
              className={clsx(styles.itineraryDataRow, "itinerary-data-row")}
            >
              {itinerary.slices.map((slice, idx) => (
                <Slot
                  id="self-serve-flight-itinerary-summary"
                  slice={slice}
                  context={{ airports, airlines }}
                  component={
                    <ItinerarySummary
                      hideMixedCabinTooltip
                      airlineMap={airlines}
                      airportMap={airports}
                      className={
                        idx === 0 ? "outgoing-itinerary" : "return-itinerary"
                      }
                      isOutgoing={idx === 0}
                      key={`slice-${idx}`}
                      slice={slice}
                      statusTag={getStatusTag(idx === 0)}
                    />
                  }
                ></Slot>
              ))}
            </div>
          );
        }

        return null;
      }

      return (
        <div className={clsx(styles.itineraryDataRow, "itinerary-data-row")}>
          <Slot
            id="self-serve-flight-itinerary-summary"
            slice={flight.bookedItinerary.travelItinerary.slices[0]}
            context={{ airports, airlines }}
            component={
              <ItinerarySummary
                hideMixedCabinTooltip
                isOutgoing
                airlineMap={airlines}
                airportMap={airports}
                className="outgoing-itinerary"
                flight={flight}
                statusTag={getStatusTag(true)}
              />
            }
          />
          {flight.bookedItinerary.travelItinerary.slices.length > 1 ? (
            <Slot
              id="self-serve-flight-itinerary-summary"
              slice={flight.bookedItinerary.travelItinerary.slices.at(-1)}
              context={{ airports, airlines }}
              component={
                <ItinerarySummary
                  hideMixedCabinTooltip
                  airlineMap={airlines}
                  airportMap={airports}
                  className="return-itinerary"
                  flight={flight}
                  statusTag={getStatusTag(false)}
                />
              }
            />
          ) : undefined}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flight, scenario]
  );

  function clearPolicyTimeout() {
    const { current: timeoutId } = timeoutIdRef;

    if (timeoutId) {
      window.clearTimeout(timeoutId);

      timeoutIdRef.current = 0;
    }

    if (timedOut) {
      setTimedOut(false);
    }
  }

  function clearPollInterval() {
    const { current: pollId } = pollIdRef;

    if (pollId) {
      window.clearInterval(pollId);

      pollIdRef.current = 0;
    }
  }

  function getTrackingProps() {
    if (!scenario) return;

    return {
      cancel_scenario: scenario.CancelScenario,
      non_cfar:
        "NonCfar" in scenario ? getTrackingNonCfar(scenario.NonCfar) : "",
      penalty_amount:
        ("penaltyAmount" in scenario ? scenario.penaltyAmount : false) || "",
      product: "flight",
    };
  }

  function handleCancelInfo(scenario: CancelScenario) {
    trackEvent({
      eventName: SelfServeEvents.ViewedCancelModal,
      properties: getTrackingProps(),
    });

    setScenario(scenario);

    switch (scenario.CancelScenario) {
      case CancelScenarioEnum.AirlineControl:
      case CancelScenarioEnum.BookingPending:
      case CancelScenarioEnum.Canceled:
      case CancelScenarioEnum.Cfar:
      case CancelScenarioEnum.Departed:
        return setCancelStep(CancelStep.CancelationError);
      case CancelScenarioEnum.CancellationPending:
        return setCancelStep(CancelStep.CancelationPending);
      case CancelScenarioEnum.NonCfar:
        if (scenario.NonCfar === NonCfarEnum.ContactCustomerService) {
          return setCancelStep(CancelStep.ContactSupport);
        } else if (scenario.NonCfar === NonCfarEnum.MultiProvider) {
          if (!enableHFv2) {
            return setCancelStep(CancelStep.ContactSupport);
          }
        }

        setCancelStep(CancelStep.CancelationInfo);
        break;
      default:
    }
  }

  function pollCancelFulfillment(pollSessionId, isNonCart = false) {
    return new Promise<void>((resolve, reject) => {
      pollIdRef.current = window.setInterval(async () => {
        try {
          let res: AirCancelFulfillmentPollResponse | undefined;

          if (isNonCart) {
            res = await pollNonCartFlightCancelFulfill(pollSessionId);
          } else {
            res = await pollFlightCancelFulfill(pollSessionId);
          }

          if (res == null) {
            reject();
          }
          const responseType = res.AirCancelFulfillmentPollResponse;
          switch (responseType) {
            case AirCancelFulfillmentPollResponseEnum.Success:
              onFulfillSuccess?.();
              clearPollInterval();
              resolve();
              break;
            case AirCancelFulfillmentPollResponseEnum.Aborted:
              clearPollInterval();
              tenantErrorRef.current = onFulfillError?.(res, () =>
                closeModal(false)
              );
              reject();
              break;
            case AirCancelFulfillmentPollResponseEnum.Pending:
              break;
            default:
              ensureExhaustive(responseType);
          }
        } catch (err) {
          reject(err);
        }
      }, POLL_INTERVAL);
    });
  }

  function pollCancelQuote(cancelSessionId: string) {
    return new Promise<AirCancelQuoteSuccess>((resolve, reject) => {
      pollIdRef.current = window.setInterval(async () => {
        try {
          const res = await pollFlightCancelInfo(cancelSessionId);

          if (res.AirCancelQuotePollResponse === "AirCancelQuotePollSuccess") {
            clearPollInterval();
            resolve(res as unknown as AirCancelQuoteSuccess);
          }
        } catch (err) {
          clearPollInterval();
          reject(err);
        }
      }, POLL_INTERVAL);
    });
  }

  /**
   * @description Updates the redux store to close this modal
   */
  const closeModal = (cancelSuccess = false) => {
    const closedModal = { type: null, selectedItinerary: null };
    if (onClose) onClose(cancelSuccess);

    setOpenModal(closedModal);
  };

  /**
   * @description Part 2 of the self serve cancel flow on the BE.
   * Confirms the cancelation of the flight.
   */
  const confirmCancellation = useCallback(async () => {
    const { current: cancelInfo } = cancelInfoRef;

    if (cancelInfo) {
      let confirmRes;

      loadingMsgRef.current = t("cancelFlightModal.processingCancel");
      setCancelStep(CancelStep.LoadingOrProcessing);
      clearPolicyTimeout();
      trackEvent({
        eventName: SelfServeEvents.ConfirmCancelation,
        properties: getTrackingProps(),
      });

      try {
        if ("quoteId" in cancelInfo) {
          const { quoteId } = cancelInfo;

          confirmRes = await confirmFlightCancel(quoteId);
          await pollCancelFulfillment(confirmRes.fulfillmentSessionId);
        } else if ("flightCancelSessionId" in cancelInfo) {
          const { flightCancelSessionId: fcsId } = cancelInfo;

          confirmRes = await confirmNonCartFlightCancel(fcsId);
          await pollCancelFulfillment(fcsId, true);
        }

        setCancelStep(CancelStep.CancelationFlowComplete);
      } catch (err) {
        if (tenantErrorRef.current == null) {
          setCancelStep(CancelStep.CancelationError);
        } else {
          setCancelStep(CancelStep.CancelationTenantError);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const contactCustomerSupport = () => {
    clearPolicyTimeout();

    if ("NonCfar" in scenario) {
      trackEvent({
        eventName: SelfServeEvents.ClickSupport,
        properties: getTrackingProps(),
      });
    }

    closeModal();
    toggleAdaChat();
  };

  /**
   * @description Opens airline's website in a new browser tab
   */
  const redirectToAirline = () => {
    clearPolicyTimeout();

    if (!scenario) return;

    let airlineWebLink;

    if (scenario && "airlines" in scenario) {
      const { airlines: scenarioAirlines } = scenario;
      const airlineCode = scenarioAirlines[0].code;
      if (airlines[airlineCode]) {
        airlineWebLink = airlines[airlineCode]?.webLinks.manageBooking;
      }
    }
    if (airlineWebLink) {
      trackEvent({
        eventName: SelfServeEvents.RedirectToAirline,
        properties: getTrackingProps(),
      });

      window.open(airlineWebLink, "_blank");
      closeModal();
    }
  };

  /**
   * @description Renders icon, title, subtitle, actions in a column
   * @return {ReactNode}
   */
  const renderCancellationError = () => {
    let PrimaryAction = null;
    let body: string[] | string = t("cancelFlightModal.error.subtitle");
    let { current: title } = titleRef;

    if (scenario) {
      switch (scenario.CancelScenario) {
        case CancelScenarioEnum.AirlineControl:
        case CancelScenarioEnum.Cfar:
          ({ body, title } = scenario.copy);
          break;
        case CancelScenarioEnum.BookingPending:
          title = t("cancelFlightModal.error.bookingPendingTitle");
          break;
        case CancelScenarioEnum.Canceled:
          title = t("cancelFlightModal.error.canceledTitle");
          break;
        case CancelScenarioEnum.Departed:
          PrimaryAction = (
            <ActionLink
              className="b2b"
              content={
                <>
                  {t("selfServe.visitAirline")}
                  <FontAwesomeIcon icon={faExternalLinkAlt as IconProp} />
                </>
              }
              onClick={redirectToAirline}
            />
          );
          body = t("cancelFlightModal.departedSubtitle");
          title = t("cancelFlightModal.departedTitle");
          break;
        default:
          PrimaryAction = defaultErrorAction ?? (
            <ActionButton
              defaultStyle="h4r-primary"
              message={t("selfServe.tryAgainBtn")}
              onClick={() => getCancelInfo(locatorRef.current)}
            />
          );
      }
    }

    return (
      <GenericModalContent
        actions={PrimaryAction}
        className={clsx(
          styles.flightCancelErrorModal,
          "self-serve-cancel-flight-error-modal"
        )}
        image={
          <img
            alt="error-bunny"
            className="failure-icon"
            src={LostConnectionBunny}
          />
        }
        subtitle={body}
        title={title}
        titleProps={{ variant: "h1" }}
      />
    );
  };

  /**
   * @description Renders success icon, title, subtitle, success actions in a column
   * @return {ReactNode}
   */
  const renderCancellationFlowComplete = () => {
    const { current: numEligible } = numEligibleRef;
    let newNumEligible = numEligible;

    // if numEligible was set - the booking was multi-ticket
    if (numEligible > 1) {
      newNumEligible = numEligible - 1;

      numEligibleRef.current = newNumEligible;
    }

    return (
      <GenericModalContent
        actions={
          <>
            {newNumEligible > 1 && (
              <ActionButton
                className="cancel-again-btn"
                defaultStyle="h4r-primary"
                message={t("cancelFlightModal.cancelAnotherBtn")}
                onClick={() => getCancelInfo()}
              />
            )}
            <ActionButton
              className="done-btn"
              defaultStyle={
                newNumEligible > 1 ? "h4r-secondary" : "h4r-primary"
              }
              message={t("done")}
              onClick={() => closeModal(true)}
            />
          </>
        }
        className={clsx(
          styles.flightCancelFlowComplete,
          "flight-cancel-flow-complete"
        )}
        content={
          <>
            <Typography className="subtitle">
              {t("cancelFlightModal.successSubtitle")}
            </Typography>
            {newNumEligible > 1 && (
              <Typography className="subtitle">
                {t("cancelFlightModal.successSubtitleMultiTicket")}
              </Typography>
            )}
          </>
        }
        image={
          <img alt="happy bunny" className="success-icon" src={HappyBunny} />
        }
        title={t("cancelFlightModal.successTitle")}
      />
    );
  };

  /**
   * @description Renders title, subtitle, itinerary summary, important info, and
   * actions in a left-justified column.
   * @return {ReactNode}
   */
  const renderCancellationInfo = () => {
    if (!scenario || !("NonCfar" in scenario)) return;

    timeoutIdRef.current = window.setTimeout(() => {
      setTimedOut(true);
    }, POLICY_TIMEOUT);

    let PrimaryAction = null;
    let body, disclaimer, importantInfo, informativeSection, title;
    let policyName = scenario.NonCfar;
    if ("cancelCopy" in scenario) {
      ({ body, disclaimer, importantInfo, informativeSection, title } =
        scenario.cancelCopy);
    } else if (
      "subTicketPolicies" in scenario &&
      scenario.subTicketPolicies.length > 0
    ) {
      // For Multi-Provider cancellations, source the scenario from the sub-ticket policies
      // Since we can only select one flight leg at a time, use the first sub-ticket policy
      ({ body, disclaimer, importantInfo, informativeSection, title } =
        scenario.subTicketPolicies[0].cancelCopy);
      policyName = scenario.subTicketPolicies[0].policyName as NonCfarEnum;
    }

    switch (policyName) {
      case NonCfarEnum.ContactAirline:
      case NonCfarEnum.NonCancellable:
        PrimaryAction = (
          <ActionButton
            className="visit-airline-btn"
            defaultStyle="h4r-primary"
            message={t("selfServe.visitAirline")}
            onClick={() => {
              clearPolicyTimeout();
              redirectToAirline();
            }}
          />
        );
        break;
      case NonCfarEnum.ContactCustomerService:
      case NonCfarEnum.RefundableComplex:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={t("contactSupport")}
            onClick={() => {
              clearPolicyTimeout();
              contactCustomerSupport();
            }}
          />
        );
        break;
      case NonCfarEnum.AirlineRefund:
      case NonCfarEnum.Ftc:
      case NonCfarEnum.FtcWithPenalty:
      case NonCfarEnum.MultiProvider:
      case NonCfarEnum.MultiTicket:
      case NonCfarEnum.NonRefundable:
      case NonCfarEnum.PartialRefund:
      case NonCfarEnum.TicketedVoid:
      case NonCfarEnum.TicketlessVoid:
        PrimaryAction = (
          <ActionButton
            className="confirm-btn"
            defaultStyle="h4r-primary"
            message={
              timedOut
                ? t("cancelFlightModal.refreshPolicyBtn")
                : t("cancelFlightModal.cancelBtn")
            }
            onClick={() => {
              clearPolicyTimeout();

              if (timedOut) {
                getCancelInfo();
              } else {
                setCancelStep(CancelStep.ConfirmCancelation);
              }
            }}
          />
        );
        break;
    }

    return (
      <FlightCancelationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={PrimaryAction}
        className={styles.flightCancelInfo}
        disclaimer={disclaimer}
        infoItems={importantInfo}
        subtitle={body}
        tcHelpText={informativeSection?.body}
        title={title}
      />
    );
  };

  /**
   * @description Renders just the summary and prompts the user to confirm the
   * cancelation
   * @return {ReactNode}
   */
  const renderConfirmCancellation = () => {
    const isHFv2 =
      scenario.CancelScenario === CancelScenarioEnum.NonCfar &&
      scenario.NonCfar === NonCfarEnum.MultiProvider;
    let body, title;

    if (!scenario) return;

    trackEvent({
      eventName: SelfServeEvents.ClickCancel,
      properties: getTrackingProps(),
    });

    timeoutIdRef.current = window.setTimeout(() => {
      setTimedOut(true);
      setCancelStep(
        isHFv2 ? CancelStep.SlicePicker : CancelStep.CancelationInfo
      );
    }, POLICY_TIMEOUT);

    if ("cancelConfirmationCopy" in scenario) {
      ({ body, title } = scenario.cancelConfirmationCopy);
    } else if (isHFv2) {
      title = t("cancelFlightModal.multiProviderTitle");
    }

    return (
      <FlightCancelationInfo
        ItinerarySummary={ItinerarySummaryRow}
        actions={
          <ActionButton
            className="confirm-cancelation-btn"
            defaultStyle="h4r-primary"
            fill="red"
            message={t("cancelFlightModal.confirmBtn")}
            onClick={confirmCancellation}
          />
        }
        className={styles.flightCancelConfirm}
        subtitle={body}
        title={title}
      />
    );
  };

  const renderContactCustomerService = () => {
    return (
      <ContactSupportModalContent
        bookingId={flight.bookedItinerary.id}
        bookingUuid={flight.bookedItinerary.id}
        bookingType={BookingType.Flight}
        className={clsx(
          styles.flightCancelCustomerSupport,
          "flight-cancel-customer-support"
        )}
        requestType="Cancel"
        getSupportId={getBookingSupportId}
        showHelpLink={false}
        subtitle={t("selfServe.supportSubtitle")}
        title={t("selfServe.supportTitle")}
      />
    );
  };

  /**
   * @description Show a spinner, title, and subtitle when loading cancel info
   * or processing a cancelation request.
   */
  const renderLoadingOrProcessing = () => {
    const { current: loadingMsg } = loadingMsgRef;

    return (
      <Slot
        id="self-serve-flight-cancel-loading"
        component={
          <GenericModalContent
            className={clsx(
              styles.flightCancelLoading,
              "flight-cancel-loading"
            )}
            image={
              <CircularProgress className="cancel-flight-policy-loading" />
            }
            subtitle={<Typography variant="subtitle2">{loadingMsg}</Typography>}
          />
        }
      />
    );
  };

  /**
   * @description Renders modal for a pending cancelation response
   * @return {ReactNode}
   */
  const renderCancellationPending = () => {
    return (
      <GenericModalContent
        actions={
          <ActionButton
            defaultStyle="h4r-primary"
            message={t("done")}
            onClick={() => closeModal(false)}
          />
        }
        className={clsx(styles.flightCancelPending, "flight-cancel-pending")}
        columnAlign="center"
        image={
          <img alt="happy bunny" className="success-icon" src={HappyBunny} />
        }
        title={t("cancelFlightModal.pendingTitle")}
        subtitle={t("cancelFlightModal.pendingSubtitle")}
      />
    );
  };

  const renderSlicePicker = () => {
    const { current: multiTicketPolicy } = cancelInfoRef;
    // temp ignore until BE types are updated
    // eslint-disable-next-line
    // @ts-ignore
    const { cancellationEligibilities = [] } = multiTicketPolicy as unknown;
    const eligibilityMap: Record<string, string> = {};
    let eligibleSlices = 0;

    for (let i = 0; i < cancellationEligibilities.length; i += 1) {
      const {
        bookingReference,
        eligibility: { Eligibility },
      } = cancellationEligibilities[i];

      eligibilityMap[bookingReference] = Eligibility;

      // TODO replace string with enum
      if (Eligibility !== "Ineligible") {
        eligibleSlices += 1;
      }
    }

    numEligibleRef.current = eligibleSlices;

    return (
      <SlicePickerModalContent
        isRadio
        airlines={airlines}
        airports={airports}
        checkboxLabel={t("choose")}
        className={clsx(
          styles.flightCancelSlicePicker,
          "flight-cancel-slice-picker"
        )}
        flight={flight}
        isSliceDisabled={(_, __, locator) =>
          !eligibilityMap[locator] || eligibilityMap[locator] === "Ineligible"
        }
        onContactSupport={() => {
          setCancelStep(CancelStep.ContactSupport);
        }}
        onContinue={(selected) => {
          const selectedLocator = selected[0].locator;

          locatorRef.current = selectedLocator;
          getCancelInfo(selectedLocator);
        }}
        subtitle={t("cancelFlightModal.slicePickerSubtitle")}
        title={t("cancelFlightModal.slicePickerTitle")}
      />
    );
  };

  const ModalContent = useMemo(() => {
    switch (cancelStep) {
      case CancelStep.CancelationError:
        return renderCancellationError();
      case CancelStep.CancelationTenantError:
        return tenantErrorRef.current ?? renderCancellationError();
      case CancelStep.CancelationPending:
        return renderCancellationPending();
      case CancelStep.CancelationFlowComplete:
        return renderCancellationFlowComplete();
      case CancelStep.CancelationInfo:
        return renderCancellationInfo();
      case CancelStep.ConfirmCancelation:
        return renderConfirmCancellation();
      case CancelStep.ContactSupport:
        return renderContactCustomerService();
      case CancelStep.LoadingOrProcessing:
        return renderLoadingOrProcessing();
      case CancelStep.SlicePicker:
        return renderSlicePicker();
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelStep, timedOut]);

  // kick off the workflow when the itinerary changes
  useEffect(() => {
    const {
      id,
      travelItinerary: { TravelItinerary },
    } = flight.bookedItinerary;

    if (TravelItinerary === TravelItineraryEnum.MultiTravelItinerary) {
      if (enableHFv2) {
        getCancelEligibility(id);
      } else {
        setCancelStep(CancelStep.ContactSupport);
      }
    } else {
      getCancelInfo();
    }

    return () => {
      clearPolicyTimeout();
      clearPollInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCancelInfo]);

  return (
    <Box
      className={clsx(
        styles.cancelFlightContainer,
        "self-serve-cancel-flight-modal-content"
      )}
    >
      {ModalContent}
    </Box>
  );
};

export default CancelFlightModalContent;
