import { CallState, type MapState } from "@b2bportal/core-types";
import {
  type Suggestion,
  type AvailabilityResponse,
  GroundSelectionEnum,
} from "@b2bportal/ground-api";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchCars } from "..";
import type {
  CarSortOptionEnum,
  FilterState,
  SearchDetails,
  DropoffType,
} from "../../../types";
import { toMapCoordinates } from "../../../utilities";
import {
  initialFiltersState,
  initialCarAvailabilityState,
  initialCarsAvailabilitySearchState,
} from "./initialState";
import { initializeSearchState } from "./thunks/initializeCars";
import type { CarsAvailabilitySearchState } from "./types";

export const carsAvailabilitySlice = createSlice({
  name: "carsAvailability",
  initialState: initialCarAvailabilityState,
  reducers: {
    setNewPickupLocation: (state, action: PayloadAction<Suggestion>) => {
      state.searchFormValues.pickupLocation = action.payload;
    },
    setNewPickupDateTime: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.searchFormValues.pickupDateTime = action.payload;
    },
    setNewDropoffType: (state, action: PayloadAction<DropoffType>) => {
      state.searchFormValues.dropoffType = action.payload;
    },
    setNewDropoffLocation: (state, action: PayloadAction<Suggestion>) => {
      state.searchFormValues.dropoffLocation = action.payload;
    },
    setNewDropoffDateTime: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.searchFormValues.dropoffDateTime = action.payload;
    },
    setNewDriverAge: (state, action: PayloadAction<number | undefined>) => {
      state.searchFormValues.driverAge = action.payload;
    },
    setRequestedSearch: (state, action: PayloadAction<SearchDetails>) => {
      state.searchFormValues = action.payload;
      state.requestedSearch = action.payload;
    },
    resetFilters(state) {
      state.filters = initialFiltersState;
    },
    setAllFilters(state, action: PayloadAction<FilterState>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    setSort: (state, action: PayloadAction<CarSortOptionEnum>) => {
      state.sort = action.payload;
    },
    setMapData: (state, action: PayloadAction<MapState>) => {
      state.map = action.payload;
    },
    clearSearchDetails(state) {
      state.search = undefined;
    },
    clearSearchFormValues(state) {
      state.searchFormValues =
        initialCarsAvailabilitySearchState.searchFormValues;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchFlights
      .addCase(initializeSearchState.pending, (state) => {
        state.initialized = CallState.InProcess;
      })
      .addCase(
        initializeSearchState.fulfilled,
        (state, action: PayloadAction<CarsAvailabilitySearchState>) => {
          state.initialized = CallState.Success;
          state.requestedSearch = action.payload.requestedSearch;
          state.searchFormValues = action.payload.searchFormValues;
          state.map = action.payload.map ?? state.map;
          state.filters = action.payload.filters ?? state.filters;
          state.sort = action.payload.sort ?? state.sort;
        }
      )
      .addCase(initializeSearchState.rejected, (state) => {
        state.initialized = CallState.Failed;
      })
      .addCase(fetchCars.pending, (state) => {
        state.search = state.requestedSearch;
        state.searchResult = {
          state: CallState.InProcess,
          data: undefined,
        };
      })
      .addCase(
        fetchCars.fulfilled,
        (state, action: PayloadAction<AvailabilityResponse>) => {
          if (
            state.map.centroid == null ||
            state.search?.searchType !== GroundSelectionEnum.Location
          ) {
            const searchCentroid = toMapCoordinates(
              action.payload.searchCoordinates
            );
            const firstResult = action.payload.vehicles[0];

            state.map.centroid =
              firstResult != null
                ? toMapCoordinates(firstResult.pickUp.coordinates)
                : searchCentroid;
            state.map.zoom = initialCarsAvailabilitySearchState.map.zoom;
          }

          state.searchResult = {
            state: CallState.Success,
            data: action.payload,
          };
        }
      )
      .addCase(fetchCars.rejected, (state, action) => {
        state.searchResult = {
          state: CallState.Failed,
          data: undefined,
          error: action.payload?.error ?? JSON.stringify(action.error),
        };
      });
  },
});

export type CarsAvailabilityActionTypes = ReturnType<
  (typeof CarsAvailabilityActions)[keyof typeof CarsAvailabilityActions]
>;

export const CarsAvailabilityActions = carsAvailabilitySlice.actions;

export default carsAvailabilitySlice.reducer;
