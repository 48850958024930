import type {
  AirCfarExerciseFailure,
  AirCfarExerciseQuoteResponse,
} from "@b2bportal/air-cfar-api";
import { CallState, type ExternalResource } from "@b2bportal/core-types";
import {
  handleAsyncThunk,
  type ThunkErrorType,
} from "@b2bportal/core-utilities";
import { createSlice } from "@reduxjs/toolkit";

import { fetchQuote, fulfillQuote } from "./thunks";

export type QuoteFetchExternalResource = ExternalResource<
  AirCfarExerciseQuoteResponse,
  ThunkErrorType<string>
>;

export type FulfillQuoteFetchExternalResource = ExternalResource<
  unknown,
  ThunkErrorType<string | AirCfarExerciseFailure>
>;

export interface CfarExerciseState {
  quoteFetchState: QuoteFetchExternalResource;
  fulfillQuoteFetchState: FulfillQuoteFetchExternalResource;
}

const initialState: Readonly<CfarExerciseState> = Object.freeze({
  quoteFetchState: {
    state: CallState.NotCalled,
  },
  fulfillQuoteFetchState: {
    state: CallState.NotCalled,
  },
} as const);

export const cfarExerciseSlice = createSlice({
  name: "cfar/exercise",
  initialState: { ...initialState },
  reducers: {
    resetCfarExerciseState: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    handleAsyncThunk(builder, fetchQuote, (state, resource) => {
      state.quoteFetchState = resource;
      state.fulfillQuoteFetchState = { ...initialState.fulfillQuoteFetchState };
    });

    handleAsyncThunk(builder, fulfillQuote, (state, resource) => {
      state.fulfillQuoteFetchState = resource;
    });
  },
});

export const cfarExerciseActions = {
  ...cfarExerciseSlice.actions,
  fetchQuote,
  fulfillQuote,
};

export const cfarExerciseReducer = cfarExerciseSlice.reducer;
