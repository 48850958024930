import {
  type PassengersOk,
  type PassengersResponse,
  PassengersResponseEnum,
  type ProtectionId,
} from "@b2bportal/air-booking-api";
import type { ValidateUserTravelerEvent } from "@checkout/states/common/TravelerInformation/events";
import {
  getSelectedTrip,
  getSearchPassengerNumber,
} from "@checkout/states/Products/Flight/states/PassengerInformationV2/selectors";
import type { FlightContextV2 } from "@checkout/states/Products/Flight/types";
import { PassengerErrorModalTypes } from "@checkout/types";
import { trackEvent, validateUserPassengers } from "@hopper-b2b/api";
import {
  ContactInformationServices,
  getObjectKeysAsObject,
  type PartialParentWithTravelerContext,
  TravelerSelectors,
  TravelerServices,
} from "@hopper-b2b/checkout";
import { SEARCH_PASSENGER_COUNT_NOT_REACHED } from "@hopper-b2b/types";
import {
  getNumTravelerAlertDismissed,
  getSelectedInfantTravelerIds,
} from "./selectors";

export const validateUserPassengersService = (
  context: PartialParentWithTravelerContext & FlightContextV2,
  event?: ValidateUserTravelerEvent
) =>
  new Promise((resolve, reject) => {
    const selectedTrip = getSelectedTrip({ context });
    const travelers = TravelerSelectors.getTravelers({ context });
    const selectedTravelerIds = TravelerSelectors.getSelectedTravelerIds({
      context,
    });
    const selectedLapInfantIds = getSelectedInfantTravelerIds({ context });
    const numTravelers = getSearchPassengerNumber({ context });
    const numTravelerAlertDismissed = getNumTravelerAlertDismissed({
      context,
    });

    const ancillaryIds: ProtectionId[] = []; // ancillaryIdsSelector(state);

    // turn on if any portal need this. e.g. getEnvVariables("clientName") === ClientName.HSBC_SG;
    const allowTravelerNumberDiff = false;

    if (!selectedTrip?.tripId || !selectedTrip?.returnFareId) {
      type NoSelectedTripError = any;
      const errorMessage = "No selected trip.";
      const error: NoSelectedTripError = {
        message: errorMessage,
      };
      reject(error);
    }
    if (!travelers?.length) {
      type NoSelectedTravelerError = any;
      const errorMessage = "No selected traveler";
      const error: NoSelectedTravelerError = {
        message: errorMessage,
      };
      reject(error);
    }

    validateUserPassengers({
      tripId: selectedTrip.tripId,
      fareId: selectedTrip.returnFareId
        ? selectedTrip.returnFareId
        : selectedTrip.outgoingFareId,
      seatedPassengers: travelers
        .filter((p) => selectedTravelerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: travelers
        .filter((p) => selectedLapInfantIds.includes(p.id))
        .map((u) => u.id),
      ancillaryIds,
    })
      .then((response: PassengersResponse) => {
        switch (response.PassengersResponse) {
          case PassengersResponseEnum.InvalidPassengers: {
            if ("errors" in response || "warnings" in response) {
              reject({
                type:
                  response.errors?.at(0)?.code ||
                  response.warnings?.at(0)?.code,
                data: response.errors?.at(0) || response.warnings?.at(0),
              });
            } else {
              reject({
                type: PassengerErrorModalTypes.ValidatePassengers,
                data: {
                  code: PassengerErrorModalTypes.ValidatePassengers,
                  message: "Unknown InvalidPassenger error",
                },
              });
            }
            break;
          }
          case PassengersResponseEnum.PassengersOk:
            // Do not trigger numPassengerAlert on SELECT_PASSENGER event and trigger alert only once in checkout flow
            if (
              !allowTravelerNumberDiff &&
              selectedTravelerIds.length + selectedLapInfantIds.length <
                numTravelers &&
              !numTravelerAlertDismissed &&
              !event.travelerId
            ) {
              trackEvent({
                eventName: SEARCH_PASSENGER_COUNT_NOT_REACHED,
                properties: {},
              });
              reject({
                type: PassengerErrorModalTypes.SearchPassengerNumNotReached,
              });
            } else {
              resolve(response as PassengersOk);
            }
            break;
        }
      })
      .catch((e) => {
        reject({
          type: PassengerErrorModalTypes.ValidatePassengers,
          data: e,
        });
      });
  });

export const Services = {
  validateUserPassengersService,
  ...ContactInformationServices,
  ...TravelerServices,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
