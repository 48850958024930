import { getObjectKeysAsObject } from "@checkout/helpers";
import {
  trackChooseTraveler,
  trackEditTravelers,
  trackNewTraveler,
  trackViewTravelers,
} from "@checkout/tracking/passengerInformation";
import { deleteUserPassengersService } from "./deleteUserPassenger";
import { fetchUserPassengersService } from "./fetchUserPassengers";
import { updateUserPassengerService } from "./updateUserPassenger";
import { validateUserPassengersService } from "./validateUserPassengers";
import { validateUserPassengersParentService } from "./validateUserPassengersParent";

export const services = {
  deleteUserPassengersService,
  fetchUserPassengersService,
  trackChooseTraveler,
  trackEditTravelers,
  trackNewTraveler,
  trackViewTravelers,
  updateUserPassengerService,
  validateUserPassengersParentService,
  validateUserPassengersService,
};

export const ServiceTypes = getObjectKeysAsObject(services);
