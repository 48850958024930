import { useCallback } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ActionButton } from "@hopper-b2b/ui";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { ReactComponent as Error } from "../../assets/client/error.svg";
import { closeNubankWebview } from "../../utils/nubankHandoff";
import styles from "./Maintenance.module.scss";

export const Maintenance = () => {
  const { t } = useI18nContext();

  const handleClose = useCallback(() => {
    closeNubankWebview();
  }, []);

  return (
    <Container maxWidth="md" className={styles.fullHeight}>
      <Grid container direction="column" className={styles.fullHeight}>
        <Grid item xs={2} />
        <Grid item>
          <Box my={4} textAlign="center">
            <Error height={64} width={64} />
          </Box>
        </Grid>
        <Grid item>
          <Box textAlign="center">
            <Typography className={styles.text} variant="h3" gutterBottom>
              {t("maintenanceTitle")}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box textAlign="center">
            <Typography className={styles.text}>
              {t("maintenanceSubtitle")}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box mt={4} textAlign="center">
            <ActionButton
              className={"primary-button-wrapper"}
              buttonClassName={"primary-button"}
              message={t("close.button")}
              onClick={handleClose}
            />
          </Box>
        </Grid>
        <Grid item xs />
      </Grid>
    </Container>
  );
};
