import type { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { trackEvent } from "@hopper-b2b/api";
import { merge } from "lodash-es";
import { getBaseTrackingProperties } from "./getBaseTrackingProperties";

export const trackBaseEvent = async (
  context: unknown,
  request: TrackEventRequest
) => {
  const baseTrackingProperties = getBaseTrackingProperties(context);

  try {
    await trackEvent(
      merge({}, request, {
        properties: baseTrackingProperties,
      })
    );
    return;
  } catch {
    return;
  }
};
