import {
  CompleteInteractiveAuthResponseEnum,
  type Success,
} from "@b2bportal/purchase-api";
import { completeInteractiveFulfill } from "@hopper-b2b/api";
import {
  CartFulfillErrorModal,
  CartFulfillState,
  type CartFulfillError,
} from "../../../../types";
import type { CartContext } from "../../context";
import { getCompleteInteractiveFulFillRequestParams } from "../selectors";

export const completeInteractiveFulfillService = async (
  context: CartContext,
  _event: unknown
): Promise<Success> => {
  const req = getCompleteInteractiveFulFillRequestParams({
    context,
  });
  const cartFulfillError: Omit<CartFulfillError, "type"> = {
    failedAt: CartFulfillState.completeInteractive,
  };

  try {
    const res = await completeInteractiveFulfill(req);

    if (
      res &&
      res.CompleteInteractiveAuthResponse ===
        CompleteInteractiveAuthResponseEnum.Success
    ) {
      return res;
    } else {
      throw ""; // Will get caught down
    }
  } catch (e) {
    throw {
      failedAt: CartFulfillState.completeInteractive,
      ...cartFulfillError,
      type: CartFulfillErrorModal.Unknown,
    };
  }
};
