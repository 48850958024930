import { type FC, useCallback } from "react";
import { useFintechUiStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFintechUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { useDeviceType } from "@b2bportal/core-utilities";
import { RadioGroup } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";

import type { SelectionOfferItemProp } from "../FintechProduct";
import defaultStyles from "./FintechProductOptionsSection.module.scss";

export interface FintechProductOptionsSectionProps
  extends PlatformComponentProps {
  selectionOffers: {
    options: SelectionOfferItemProp[];
  };
  showTitle?: boolean;
  setShowSelectionRow: (isSelected: boolean) => void;
}

export const FintechProductOptionsSection = ({
  selectionOffers,
  showTitle,
  setShowSelectionRow,
  className,
}: FintechProductOptionsSectionProps) => {
  const COMPONENT_KEY = CoreFintechUiComponents.FintechProductOptionsSection;
  const styles = useFintechUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  const title =
    showTitle === true ? (
      <span className={cn("title")}>
        {t("core-disruption.optionsSectionTitle")}
      </span>
    ) : null;

  return (
    <div className={clsx(block, className)}>
      {title}

      {selectionOffers.options.map((offer, index) => {
        return (
          <FintechProductOption
            key={index}
            offer={offer}
            setShowSelectionRow={setShowSelectionRow}
          />
        );
      })}
    </div>
  );
};

const FintechProductOption: FC<{
  offer: SelectionOfferItemProp;
  setShowSelectionRow: (isSelected: boolean) => void;
}> = (props) => {
  const { offer, setShowSelectionRow } = props;

  const styles = useFintechUiStyles(
    CoreFintechUiComponents.FintechProductOptionsSection,
    defaultStyles
  );
  const [, cn] = useModuleBEM(
    styles,
    CoreFintechUiComponents.FintechProductOptionsSection
  );

  const { t } = useI18nContext();
  const { isDesktopAndUp } = useDeviceType();

  const onClick = useCallback(() => {
    offer.onClick();

    if (isDesktopAndUp) {
      setShowSelectionRow(true);
    }
  }, [isDesktopAndUp, offer, setShowSelectionRow]);

  return (
    <div
      onClick={onClick}
      className={cn("option-container", {
        selected: offer.selected,
      })}
    >
      <RadioGroup
        options={[
          {
            label: undefined,
            value: String(offer.selected),
            className: cn("radio-button"),
          },
        ]}
        classNames={{
          itemContainer: cn("radio-item-container"),
        }}
        selectedOption={String(true)}
        onSelect={onClick}
        onSelectValue={onClick}
        aria-label={t("fintechProduct")}
      />

      <div className={cn("option-content")}>
        <span className={cn("option-title")}>{offer.title}</span>
        <span className={cn("option-description")}>{offer.description}</span>
      </div>
    </div>
  );
};
