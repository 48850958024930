import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import "./styles.scss";
import type { FiatPrice } from "@b2bportal/air-shopping-api";
import { Slot } from "@hopper-b2b/ui";

export const DisplayedPrice = ({
  discountedCostFiat,
  totalCostFiat,
  totalCostString,
}: {
  discountedCostFiat?: FiatPrice | null;
  totalCostFiat: FiatPrice | null;
  totalCostString?: string;
}) => {
  const { formatFiatCurrency } = useI18nContext();

  const showDiscountedPrice = !!discountedCostFiat;

  return (
    <>
      {showDiscountedPrice && (
        <span className="content discounted-price">
          {formatFiatCurrency(discountedCostFiat, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      )}
      <Slot
        id="price-with-decimals"
        totalCostFiat={totalCostFiat}
        className="content total-price"
        component={
          <span
            className={clsx("content total-price", {
              "strike-through": !!showDiscountedPrice,
            })}
          >
            {totalCostString
              ? totalCostString
              : formatFiatCurrency(totalCostFiat, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
          </span>
        }
      />
    </>
  );
};
