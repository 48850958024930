import { asB2BMuiTheme } from "@hopper-b2b/themes";
import { nubankColors as nubankDefaultColors } from "./defaultTheme";

export const nubankColors = {
  ...nubankDefaultColors,
  "dark-content-primary": "#B68BE3",
  "dark-content-default": "rgba(255, 255, 255, 0.88)",
  "dark-content-subtle": "rgba(255, 255, 255, 0.64)",
  "dark-surface-dark": "#2B154E",
  "dark-surface-subtle": "var(--gray-200)",
  "dark-content-disabled": "#FFFFFF52",
  "dark-border-default": "var(--gray-200)",
  "dark-accent-primary-subtle": "#2B154E",
  "dark-primary": "#5A1D8C",
  // override existing colors for dark mode
  "dark-red-10": "#6A0000",
  "dark-red-60": "#EE7C7A",
  "dark-red-70": "#4D0000",
  "dark-orange-10": "#311D13",
  "dark-orange-30": "#482919",
  "dark-orange-60": "#E4863F",
  "dark-yellow-10": "#301E09",
  "dark-yellow-60": "#D39035",
  "dark-green-10": "#10381D",
  "dark-green-50": "#1E6B38",
  "dark-green-60": "#47B169",
  "dark-green-70": "#0B2715",
  "dark-purple-50": "#1E1B4E",
  "dark-purple-60": "#8132C5",
  "dark-purple-70": "#3D1E6F",
  "dark-purple-80": "#4E268D",
  "dark-purple-90": "#7243B6",
  "dark-grey-10": "#5D5D5D",
};
export const nubankDarkVariables = {
  ...nubankColors,
  primary: "var(--dark-primary)",
  "primary-text": "var(--dark-content-default)",
  "secondary-text": "var(--dark-content-subtle)",
  "base-body-background": "var(--black)",
  "secondary-button-background": "var(--grey)",
  "primary-hover": "var(--black-hover)",
  "primary-disabled": "var(--dark-surface-subtle)",
  "button-background": "var(--dark-surface-subtle)",
  "button-background-disabled": "var(--primary-disabled)",
  "button-background-hover": "var(--primary-hover)",
  "button-border": "2px solid var(--primary)",
  "button-border-radius": "64px",
  "mobile-banner": "var(--primary)",
  "loading-popup": "var(--base-body-background)",
  "loading-screen-bar-background": "var(--gray-200)",
  "popup-background-color": "var(--base-body-background)",
  "link-text": "var(--dark-content-default)",
  "action-link-active-text": "var(--dark-content-primary)",
  "hamburger-menu-color": "var(--white)",
  "filter-background-selected": "var(--dark-primary)",
  "inverse-text": "var(--white)",
  "price-freeze-tag": "var(--blue)",
  "price-freeze-text": "var(--blue-400)",
  "spinner-border-color": "var(--grey-10)",
  "spinner-top-border-color": "var(--primary)",
  "date-hover-color": "transparent",
  "content-default": "var(--dark-content-default)",
  "content-subtle": "var(--dark-content-subtle)",
  "content-disabled": "var(--dark-content-disabled)",
  "content-feedback-neutral": "var(--dark-content-subtle)",
  "content-feedback-success": "var(--dark-green-60)",
  "surface-subtle": "var(--dark-surface-subtle)",
  "surface-disabled": "var(--primary-disabled)",
  "surface-decorative-01-subtle": "var(--dark-purple-50)",
  "surface-feedback-attention": "var(--dark-orange-10)",
  "border-default": "var(--dark-border-default)",
  "page-header-background-color": "var(--base-body-background)",
  "page-header-text-color": "var(--dark-content-default)",
  "card-border": "2px solid var(--dark-border-default)",
  "card-border-thin": "1px solid var(--dark-border-default)",
  "card-border-color": "var(--dark-border-default)",
  "floating-button-background": "var(--black)",
  "card-tag-text": "var(--dark-green-60)",
  "card-tag-background": "var(--dark-green-10)",
  "input-label-text": "var(--primary-text)",
  "input-background": "transparent",
  "red-error-color": "var(--red-50)",
  "selected-fare-background": "var(--dark-accent-primary-subtle)",
  "selected-fare-text": "var(--dark-content-primary)",
  "trips-link-color": "var(--dark-primary)",
  "divider-color": "var(--gray-200)",
  "trips-confirmed-title-background": "var(--dark-green-50)",
  "filter-slider-color": "var(--dark-purple-70)",
  "expand-chevron": "var(--content-subtle)",
  "insurance-disclaimer-background-color": "var(--dark-accent-primary-subtle)",
  // edit traveler count buttons
  "traveler-count-picker-button-disabled": "var(--dark-content-disabled)",
  "traveler-count-picker-button-disabled-background":
    "var(--dark-surface-subtle)",
  "traveler-count-picker-button": "var(--content-default)",
  "traveler-count-picker-button-background": "var(--dark-surface-subtle)",
  // Autocomplete
  "search-autocomplete-subheader-background": "var(--base-body-background)",
  // Calendar
  "calendar-weekday-labels-background": "transparent",
  "calendar-weekday-labels-text": "var(--dark-content-subtle)",
  // calendar colors
  "date-disabled-color": "transparent",
  "date-selected-color": "var(--dark-accent-primary-subtle)",
  "date-text-color": "var(--content-default)",
  "bucket-0-color": "var(--dark-green-10)",
  "bucket-1-color": "var(--dark-yellow-10)",
  "bucket-2-color": "var(--dark-orange-30)",
  "bucket-3-color": "var(--dark-red-10)",
  "bucket-0-color-text": "var(--dark-green-60)",
  "bucket-1-color-text": "var(--dark-yellow-60)",
  "bucket-2-color-text": "var(--dark-orange-60)",
  "bucket-3-color-text": "var(--dark-red-60)",
  // a11y calendar colors
  "bucket-0-color-a11y": "var(--violet-20)",
  "bucket-1-color-a11y": "var(--violet-30)",
  "bucket-2-color-a11y": "var(--violet-50)",
  "bucket-3-color-a11y": "var(--violet-80)",
  "bucket-0-color-a11y-text": "var(--violet-90)",
  "bucket-1-color-a11y-text": "var(--violet-90)",
  "bucket-2-color-a11y-text": "var(--white)",
  "bucket-3-color-a11y-text": "var(--white)",
  "content-on-color": "var(--white)",
  // homescreen
  "home-header-text": "var(--dark-content-default)",
  "home-button-background": "var(--dark-surface-subtle)",
  // hotels
  "tag-background-color": "var(--dark-accent-primary-subtle)",
  "unavailable-badge-background": "var(--dark-surface-subtle)",
  "unavailable-badge-text": "var(--dark-content-subtle)",
  "info-details-popup-background": "var(--base-body-background)",
  "carousel-marker-focused": "var(--content-default)",
  "carousel-marker-unfocused": "var(--dark-surface-subtle)",
  "hotel-shop-show-more": "var(--dark-content-primary)",
  "hotel-shop-content-selected": "var(--dark-purple-80)",
  "tag-font-color": "var(--dark-content-primary)",
  "checkout-edit-color": "var(--dark-content-primary)",
  // hackerfares
  "hackerfare-badge-background": "var(--gray-50)",
  "hackerfare-badge-text": "var(--content-default)",
  // map
  "map-btn-search-area-background": "var(--black)",
  "map-btn-search-area-loading": "var(--gray-200)",
  "map-marker-background": "var(--black-2)",
  "map-bubble-border": "var(--gray-200)",
  "map-marker-selected-background": "var(--primary)",
  "map-bubble-selected-border": "#818181",
  // Value Props
  "value-prop-color": "var(--dark-content-primary)",
  "value-prop-border-color": "var(--dark-content-primary)",
  "value-prop-background-color": "var(--dark-surface-dark)",
  // Modals
  "iframe-dialog-background-filter": "invert(100%) hue-rotate(180deg)",
  "modal-top-margin": "6rem",
  // loading shimmer
  "loading-shimmer-background":
    "to right, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.08) 35%, rgba(255, 255, 255, 0.04) 50%, rgba(255, 255, 255, 0.08) 65%, rgba(255, 255, 255, 0.16) 100%",
  "disruption-banner-backround-entry": "var(--gray-200)",
  "disruption-banner-backround-disrupted": "var(--dark-orange-10)",
  "disruption-banner-link-color": "var(--dark-content-primary)",
  // payment
  "payment-method-select-background": "var(--dark-surface-dark)",
  "payment-method-select-color": "var(--dark-content-primary)",
  // Price watch
  "tag-background-buy": "var(--dark-green-70)",
  "tag-text-buy": "var(--dark-green-60)",
  "tag-background-wait": "var(--dark-red-70)",
  "tag-text-wait": "var(--dark-red-60)",
  "tag-background-alert": "var(--surface-feedback-attention)",
  "tag-text-alert": "var(--dark-orange-60)",
  "toggle-enabled": "var(--dark-purple-90)",
  "toggle-disabled": "var(--dark-grey-10)",
  "toggle-switch": "var(--white)",
};

const nubankDarkTheming = {
  palette: {
    type: "dark",
    primary: {
      light: "#01AAE4",
      main: "#01AAE4",
      contrastText: "#01AAE4",
    },
    secondary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: "#FA6866",
    },
    text: {
      primary: "#bbb",
      secondary: "#878787",
      disabled: "#FFF",
      hint: "#FFF",
    },
  },
  typography: {
    fontFamily: "Graphik, sans-serif",
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.375rem",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1.25rem",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    button: {
      fontFamily: "Graphik, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: "#878787",
      },
      underlineHover: {
        "&:hover": {
          "text-decoration": "none",
        },
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 12,
        fontWeight: 500,
      },
      standardSuccess: {
        backgroundColor: nubankColors.black,
        color: nubankColors.white,
      },
      standardWarning: {
        backgroundColor: nubankColors.black,
        color: nubankColors.white,
      },
      standardError: {
        backgroundColor: nubankColors.black,
        color: nubankColors.white,
      },
      icon: {
        color: `${nubankColors.white} !important`,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: nubankDarkVariables["base-body-background"],
        color: nubankDarkVariables["content-default"],
      },
    },
    MuiSlider: {
      root: {
        color: nubankDarkVariables["filter-slider-color"],
      },
    },
  },
};

export const muiDarkTheme = asB2BMuiTheme(nubankDarkTheming);
