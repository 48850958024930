import {
  ActionLink,
  Divider,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import "./styles.scss";

interface IMobileInfoPopoverProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  subtitle?: string | JSX.Element;
  primaryButtonAction: () => void;
  primaryButtonText: string;
  secondaryButtonAction?: () => void;
  secondaryButtonText?: string;
  className?: string;
  closeIcon?: JSX.Element;
  icon?: string;
  children?: JSX.Element;
  header?: string | JSX.Element;
}

const MobileInfoPopover = ({
  isOpen,
  onClose,
  title,
  subtitle,
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonAction,
  secondaryButtonText,
  className,
  closeIcon,
  icon,
  children,
  header,
}: IMobileInfoPopoverProps) => {
  return (
    <MobilePopoverCard
      className={`mobile-info-popover ${className}`}
      contentClassName="info-popover-content"
      open={isOpen}
      fullScreen={false}
      centered
      onClose={onClose}
      topLeftButton={
        <ActionLink
          className="close-button"
          content={
            closeIcon ? (
              closeIcon
            ) : (
              <IconComponent
                ariaLabel="Close button icon"
                className={"close-button-icon"}
                name={IconName.Close}
              />
            )
          }
          label="Close"
          onClick={onClose}
        />
      }
      headerElement={header}
    >
      {icon ? <img src={icon} alt="" className="info-popover-icon" /> : null}
      {title ? <h2 className="info-popover-title">{title}</h2> : null}
      {subtitle ? <p className="info-popover-subtitle">{subtitle}</p> : null}
      <Divider />
      {children}
      <div className="info-popover-button-container">
        <button
          className="info-popover-button primary"
          onClick={primaryButtonAction}
        >
          {primaryButtonText}
        </button>
        {secondaryButtonAction && secondaryButtonText ? (
          <button
            className="info-popover-button secondary"
            onClick={secondaryButtonAction}
          >
            {secondaryButtonText}
          </button>
        ) : null}
      </div>
    </MobilePopoverCard>
  );
};

export default MobileInfoPopover;
