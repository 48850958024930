import { useLayoutEffect, useRef } from "react";
import type { AEvent } from "../createUseEmitRawEvent";
import { useEmitRawEvent } from "./useEmitRawEvent";

export function useEmitBeforeLoad<TAnyEvent extends AEvent>(
  event: TAnyEvent,
  condition = true
) {
  const isTracked = useRef(false);

  const emitRawEvent = useEmitRawEvent(event.name);
  useLayoutEffect(() => {
    if (condition && !isTracked.current) {
      isTracked.current = true;
      emitRawEvent(event);
    }
  }, [condition, emitRawEvent, event]);
}
