import {
  type ShopSummaryRequest,
  type ShopSummaryResponseV4Response,
  type ShopSummaryResponseV4Success,
  airShoppingApi,
} from "@b2bportal/air-shopping-api";
import { trackEvent, axiosInstance } from "@hopper-b2b/api";
import {
  ShopFilter,
  SliceStopCountFilter,
  NETWORK_CALL_FAILED,
  VIEWED_FLIGHT_SHOP_LOADING,
  Platform,
  type IApiConfig,
  NO_AVAILABILITY_AIR,
  PERFORMED_FLIGHT_SEARCH,
  ClientName,
} from "@hopper-b2b/types";
import { getEnvVariables, tenantFlagsDisabled } from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import type { IShopParams } from "../../../modules/shop/actions/actions";

export const fetchFlights = async (
  shopParams: IShopParams,
  isMobile: boolean | undefined,
  apiConfig?: IApiConfig,
  controller?: AbortController
): Promise<ShopSummaryResponseV4Response> => {
  return new Promise(async (resolve, reject) => {
    const {
      origin,
      destination,
      departureDate,
      returnDate,
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
      stopsOption,
      fareclassOptionFilter,
    } = shopParams;
    const passengerObj = {};

    if (adultsCount > 0) passengerObj["ADT"] = adultsCount;
    if (infantsInSeatCount > 0) passengerObj["INS"] = infantsInSeatCount;
    if (infantsOnLapCount > 0) passengerObj["INF"] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj["CNN"] = childrenCount;

    //TODO refactor
    let tripFilter = ShopFilter.NoFilter;
    const filterOutBasicFares =
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard;
    if (fareclassOptionFilter && filterOutBasicFares) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    const request: ShopSummaryRequest = {
      passengers: passengerObj,
      departureDate,
      ...(returnDate && { returnDate }),
      route: {
        origin,
        destination,
      },
      tripFilter,
      platform: isMobile ? Platform.Mobile : Platform.Desktop,
    };

    try {
      const startTime = dayjs();
      const res = await airShoppingApi(axiosInstance).apiV4ShopSummaryPost(
        request,
        { signal: controller?.signal || null }
      );
      trackEvent(
        {
          eventName: VIEWED_FLIGHT_SHOP_LOADING,
          properties: {
            runtime: dayjs().diff(startTime, "seconds", true),
          },
        },
        apiConfig
      );

      trackEvent({
        eventName: PERFORMED_FLIGHT_SEARCH,
        properties: {
          searched_pax_total: Object.values(passengerObj).reduce(
            (a: number, b: number) => a + b,
            0
          ),
          searched_pax_total_infant_lap: infantsOnLapCount,
          total_stops: stopsOption,
          trip_filter: tripFilter,
          trip_type: returnDate ? "round_trip" : "one_way",
        },
      });

      if (
        (res.data as ShopSummaryResponseV4Success)?.value?.flights?.outbound
          .length === 0
      ) {
        trackEvent(
          {
            eventName: NO_AVAILABILITY_AIR,
            properties: {
              url: window.location.pathname,
            },
          },
          apiConfig
        );
      }

      if (tenantFlagsDisabled.FlightShopPrediction) {
        (res.data as ShopSummaryResponseV4Success).value.prediction = undefined;
      }

      fireUberStartSession();
      if (res.data.Response === "Failure") {
        reject(res.data.errors);
        return;
      }
      resolve(res.data as ShopSummaryResponseV4Response);
    } catch (e) {
      trackEvent(
        {
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
          },
        },
        apiConfig
      );
      fireUberStartSession();
      reject(e);
    }
  });
};

const fireUberStartSession = () => {
  // When deeplinking into the Uber portal, the Uber app waits for a `START_SESSION`
  // event to be fired before hiding their loader.
  if (getEnvVariables("clientName") === ClientName.UBER) {
    window.parent.postMessage(
      {
        type: "START_SESSION",
        payload: null,
      },
      import.meta.env["VITE_UBER_URL"]
    );
  }
};
