import { getObjectKeysAsObject } from "@checkout/helpers";
import { trackSkipSeatSelection } from "@checkout/tracking/seatSelection";
import { fetchSeatMapService } from "./fetchSeatMap";

export const services = {
  fetchSeatMapService,
  trackSkipSeatSelection,
};

export const ServiceTypes = getObjectKeysAsObject(services);
