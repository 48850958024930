import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import type {
  Fare,
  FareSliceOutbound,
  Flights,
  Tags,
  TripDetailsV2,
  TripSlice,
} from "@b2bportal/air-disruption-api";
import {
  AlgomerchTag,
  type AppDispatch,
  type FareclassOptionFilter,
  FlightRatingsEnum,
  type IFareData,
  type IFareTrip,
  type IFlightFares,
  type IFlightListData,
  TripCategory,
} from "@b2bportal/core-types";
import type { FlightCardSummaryComponentProps } from "@components/flights";
import { useI18nContext } from "@hopper-b2b/i18n";
import { removeTimezone } from "@hopper-b2b/utilities";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { toPairs } from "lodash-es";
import { FetchTripDetailsThunk } from "../../../types";

const DEFAULT_TAGS_LIMIT = 2;

export interface UseDisruptionFlightCardParams {
  flight: IFlightListData<Fare | FareSliceOutbound>;
  isSelected: boolean;
  sliceIndex: number;
  onClick?: () => void;
  flights: Flights;
  fetchTripDetails: FetchTripDetailsThunk;
}

export type UseDisruptionFlightCardContext = {
  isFlightDetailsLoading: boolean;
  open: boolean;
  flightCardSummary?: FlightCardSummaryComponentProps;
  fareData: IFlightFares;
  tripCategory: TripCategory;
  fareShelfFilter: Array<number>;
  flightDetails?: TripDetailsV2;
  flightSlice?: TripSlice;
  fareShelfFilterWithUpsell: Array<number>;
  openFlightDetailsDialog: boolean;
  flightFareTrips: IFareTrip[];
};

export interface UseDisruptionFlightCardResponse {
  context: UseDisruptionFlightCardContext;
  handlers: {
    handleFlightCardClick: () => void;
    setOpenFlightDetailsDialog: Dispatch<SetStateAction<boolean>>;
    handleFareGridSelection: (fare: IFareData) => void;
  };
}

export const getFareSliceId = (fare: FareSliceOutbound | Fare): string => {
  if ("fareSlice" in fare) {
    return (fare as FareSliceOutbound).fareSlice || "";
  } else {
    return (fare as Fare).return || "";
  }
};

export const getFareId = (fare: FareSliceOutbound | Fare): string => {
  if ("example" in fare) {
    return (fare as FareSliceOutbound).example?.fare || "";
  } else {
    return (fare as Fare).id || "";
  }
};

export const useDisruptionFlightCard = ({
  flight,
  isSelected,
  sliceIndex = 0,
  onClick,
  flights,
  fetchTripDetails,
}: UseDisruptionFlightCardParams): UseDisruptionFlightCardResponse => {
  const dispatch = useDispatch<AppDispatch>();
  const { t, formatFiatCurrency, formatDateTime, DateTimeFormatStyle } =
    useI18nContext();

  const { sliceId, fares } = flight;

  // INTERNAL STATE
  const [fareGridFareShelfClick, setFareGridFareShelfClick] =
    useState<number>(0);
  const [openFlightDetailsDialog, setOpenFlightDetailsDialog] = useState(false);
  const [tripsById, setTripsById] = useState<{ [key: string]: TripDetailsV2 }>(
    {}
  );

  const flightFareTrips = useMemo(() => {
    const flightFares = flight.fares;
    return flightFares.map((fare: Fare | FareSliceOutbound) => {
      return "example" in fare
        ? { fareId: fare.example.fare, tripId: fare.example.trip }
        : { fareId: fare.id, tripId: fare.tripId };
    });
  }, [flight.fares]);

  const getFlightData = useCallback((flights: Flights, sliceId: string) => {
    if (flights == null) return undefined;

    const slice = flights.slices[sliceId];

    const departureDateTime = slice.departure;
    const arrivalDateTime = slice.arrival;

    const durationDiff = dayjs.duration(slice.totalDurationMinutes, "minutes");
    const days = durationDiff.days();
    const hours = durationDiff.hours();
    const minutes = durationDiff.minutes();
    const duration = {
      days,
      hours,
      minutes,
    };

    const plusDays = slice.segments.reduce((totalPlusDays, segment) => {
      return totalPlusDays + segment.plusDays;
    }, 0);

    const airlineCode = slice.marketingAirline;
    const airlineName = flights.airlines[airlineCode].displayName;
    const stops = slice.segments.length - 1;

    return {
      arrivalDateTime,
      departureDateTime,
      duration,
      originCode: slice.origin,
      destinationCode: slice.destination,
      flightStops: stops,
      plusDays,
      airlineCode,
      airlineName,
    };
  }, []);

  const getTags = (fareTags: Tags): AlgomerchTag[] => {
    const tagMapping: Record<string, AlgomerchTag> = {
      isCheapest: AlgomerchTag.Cheapest,
      isFastest: AlgomerchTag.Fastest,
      isBest: AlgomerchTag.BestFlight,
      isBestQuality: AlgomerchTag.BestQuality,
    };

    return Object.entries(fareTags)
      .filter(([_, value]) => value)
      .map(([key]) => tagMapping[key]);
  };

  const getFareData = useCallback(
    (flights: Flights, fares: (Fare | FareSliceOutbound)[]) => {
      if (flights == null) return {};

      return fares.reduce((acc, fare) => {
        const fareSliceId = getFareSliceId(fare);
        const fareSlice = flights.fareSlices[fareSliceId];
        const fareClass = FlightRatingsEnum[fareSlice.fareShelf.value];
        const fareShelf = fareSlice.fareShelf.value;
        // Check if the fare has fiat value
        if (fare.amount?.fiat.value != null) {
          const existingFare: IFareData | undefined = acc[fareClass];
          // Check if there is no existing fare or the new fare has a lower price

          if (!existingFare) {
            const tags = getTags(fareSlice?.tags);
            acc[fareClass] = {
              fareId: getFareId(fare),
              fareName: fareSlice?.fareBrandName ?? "",
              fareClass: fareClass,
              fareShelf,
              price: fare.amount,
              tags,
              numberOfFares: -1,
              segments: fareSlice.segments,
            };
          }
          if (acc[fareClass].price.fiat.value > fare.amount.fiat.value) {
            acc[fareClass].price = fare.amount;
          }
          acc[fareClass].numberOfFares = acc[fareClass].numberOfFares + 1;
        }

        return acc;
      });
    },
    []
  );

  const getSelectedFareShelfRatings = useCallback(
    (fareClassFilters: FareclassOptionFilter) => {
      const filtersToShelfRatingMap = {
        basic: 0,
        standard: 1,
        enhanced: 2,
        premium: 3,
        luxury: 4,
      };
      const selectedFareShelf = toPairs(fareClassFilters).reduce(
        (ratings, [filter, isActive]) =>
          isActive ? [...ratings, filtersToShelfRatingMap[filter]] : ratings,
        [] as Array<number>
      );

      return selectedFareShelf.length ? selectedFareShelf : [0, 1, 2, 3, 4];
    },
    []
  );

  const getTripDetails = useCallback(
    (
      tripDetailsById: { [key: string]: TripDetailsV2 },
      fareTrips: IFareTrip[]
    ) => {
      const fetchedAllFareDetails =
        fareTrips.length > 0
          ? fareTrips?.reduce((hasFetchedFareTrip: boolean, fareTrip) => {
              return (
                hasFetchedFareTrip && tripDetailsById[fareTrip.tripId] != null
              );
            }, true)
          : false;

      if (fetchedAllFareDetails) {
        const data = fareTrips.reduce(
          (acc: TripDetailsV2, fareTrip) => {
            const fareDetailByFareId = tripDetailsById[
              fareTrip.tripId
            ].fareDetails.find(
              (fareDetail) => fareDetail.id === fareTrip.fareId
            );

            if (fareDetailByFareId) {
              acc.fareDetails.push(fareDetailByFareId);
            }

            return acc;
          },
          { ...tripDetailsById[fareTrips[0]?.tripId], fareDetails: [] }
        );
        return { isLoading: false, isError: false, data };
      } else {
        return {
          isLoading: true,
          isError: false,
          data: undefined,
        };
      }
    },
    []
  );

  // STATE
  const flightData = getFlightData(flights, sliceId);
  const fareData = getFareData(flights, fares);
  const fareShelfFilter = getSelectedFareShelfRatings({
    basic: false,
    standard: false,
    enhanced: false,
    premium: false,
    luxury: false,
  });

  const { isLoading: isFlightDetailsLoading, data: flightDetails } =
    getTripDetails(tripsById, flightFareTrips);
  const tripCategory = TripCategory.ONE_WAY;

  // CHILD PROPS
  const flightCardSummary = useMemo(() => {
    if (flightData == null) return undefined;

    const getTags = (fares: IFlightFares) => {
      return Object.values(fares).reduce(
        (tags: AlgomerchTag[], fare: IFareData) => {
          return [...tags, ...(fare.tags ?? [])];
        },
        [] as AlgomerchTag[]
      );
    };

    const formatDuration = ({ days, hours, minutes }) => {
      const units = [
        { value: days, key: "days" },
        { value: hours, key: "hours" },
        { value: minutes, key: "minutes" },
      ];

      const durationString = units
        .filter((unit) => unit.value)
        .map((unit) => t(`flightDuration.${unit.key}`, { count: unit.value }))
        .join(" ");

      return durationString.trim();
    };

    return {
      duration: formatDuration(flightData.duration),
      currentPriceText: "Free",
      originCode: flightData.originCode,
      destinationCode: flightData.destinationCode,
      departureTime: formatDateTime(
        dayjs(removeTimezone(flightData.departureDateTime)).toDate(),
        DateTimeFormatStyle.ShortTime
      ),
      arrivalTime: formatDateTime(
        dayjs(removeTimezone(flightData.arrivalDateTime)).toDate(),
        DateTimeFormatStyle.ShortTime
      ),
      brandName: "",
      primaryCarrier: flightData.airlineCode,
      airlineName: flightData.airlineName,
      tags: getTags(fareData),
      tagsLimit: DEFAULT_TAGS_LIMIT,
      flightStops: flightData.flightStops,
    };
  }, [fareShelfFilter, flightData, fareData, formatFiatCurrency, t]);

  const fareShelfFilterWithUpsell = useMemo(() => {
    return fareShelfFilter.filter(
      (fareShelf) => fareShelf >= fareGridFareShelfClick
    );
  }, [fareShelfFilter, fareGridFareShelfClick]);

  const flightSlice = flightDetails?.slices[sliceIndex];

  // ON CLICKS
  const fetchFlightTripsDetails = useCallback(
    (fareTrips: IFareTrip[]) => {
      const uniqueTripIds: string[] = Array.from(
        new Set(fareTrips.map((fareTrip) => fareTrip.tripId))
      );
      uniqueTripIds.forEach((tripId: string) => {
        dispatch(fetchTripDetails({ tripId })).then((payload) => {
          const data = unwrapResult(payload);
          setTripsById({
            ...tripsById,
            [tripId]: data,
          });
        });
      });
    },

    [dispatch, tripsById]
  );

  const handleFlightCardClick = useCallback(() => {
    fetchFlightTripsDetails(flightFareTrips);
    onClick?.();
  }, [flightFareTrips, fetchFlightTripsDetails, onClick]);

  const handleFareGridSelection = useCallback(
    (fare: IFareData) => {
      // Set fare id or fare shelf and only upsell in fare details.
      setFareGridFareShelfClick(fare.fareShelf);
      handleFlightCardClick();
    },
    [handleFlightCardClick, setFareGridFareShelfClick]
  );

  return {
    context: {
      isFlightDetailsLoading,
      open: isSelected,
      flightCardSummary,
      fareData,
      tripCategory,
      fareShelfFilter,
      flightDetails,
      flightSlice,
      fareShelfFilterWithUpsell,
      openFlightDetailsDialog,
      flightFareTrips,
    },
    handlers: {
      handleFlightCardClick,
      setOpenFlightDetailsDialog,
      handleFareGridSelection,
    },
  };
};
