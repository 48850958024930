import { createContext, useContext, type ReactNode } from "react";
import type { TenantGlobalTrackingAttributes } from "@tenant/types";

const TrackingScopeContext = createContext<TrackingScopeContext>({
  trackingAttributes: {} as Partial<TenantGlobalTrackingAttributes>,
});

export interface TrackingScopeContext {
  trackingAttributes: Partial<TenantGlobalTrackingAttributes>;
}

export interface TrackingScopeProps<
  TTAttrs extends object = TenantGlobalTrackingAttributes
> {
  children: ReactNode;
  trackingAttributes?: Partial<TTAttrs>;
}

export function useTrackingScopeContext(): TrackingScopeContext {
  return useContext(TrackingScopeContext);
}

export function TrackingScope<TTAttrs extends object>(
  props: TrackingScopeProps<TTAttrs>
) {
  const { children } = props;

  const localTrackingAttributes: Partial<TenantGlobalTrackingAttributes> =
    props.trackingAttributes ?? {};

  const prevContext = useContext(TrackingScopeContext);

  const newTrackingAttributes = {
    ...prevContext.trackingAttributes,
    ...localTrackingAttributes,
  };

  return (
    <TrackingScopeContext.Provider
      value={{
        trackingAttributes: newTrackingAttributes,
      }}
    >
      {children}
    </TrackingScopeContext.Provider>
  );
}
