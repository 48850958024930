import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import clsx from "clsx";
import defaultStyles from "./Loading.module.scss";

export const Loading = ({ className }: { className?: string }) => {
  const styles = useUiStyles(CoreUiComponents.Loading, defaultStyles);
  const [block] = useModuleBEM(styles, CoreUiComponents.Loading);
  return <div className={clsx(block, className)} />;
};
